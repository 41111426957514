import { Directive, ElementRef } from '@angular/core';


@Directive({
    // tslint:disable-next-line
    selector: 'input[uppercase]',
    // tslint:disable-next-line
    host: {
        '(input)': 'ref.nativeElement.value=upperCase($event.target.value)',
    }

})
export class UpperCaseDirective {
    constructor(private ref: ElementRef) {
    }

    upperCase(value: string) {
    	return value.toUpperCase();
    }
}
