import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { AdminService } from '../../services/admin.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AccountService } from '../../services/account.service';
import { ProgressService } from '../../services/progress.service';
import { AlertService } from '../../services/alert.service';
import { EchoService } from '../../services/echo.service';
import { SpinnerService } from '../../services/spinner.service';
import { FormDataService } from '../../services/form-data.service';
import { TooltipService } from '../../services/tooltip.service';
import { LicensesService } from '../../services/licenses.service';
import {
  officeLinkMessages,
  toastMsgTypes,
  commonErrorMessages,
  pbxTypes,
  applicationNames,
  applicationNamesLong
} from '../../shared/constants';
import { Account, Group, GroupPutRequest, Site } from '@mitel/cloudlink-sdk/admin';
import { Pbxlink } from '@mitel/cloudlink-sdk/tunnel';
import { Odata, Utils } from '@mitel/cloudlink-sdk';
import { ParentCommsService } from '../../services/parent-comms.service';
@Component({
  selector: 'app-office-connect',
  templateUrl: './office-connect.component.html',
  styleUrls: ['./office-connect.component.css']
})
export class OfficeConnectComponent implements OnInit, OnDestroy {
  numberOfLines = 4;
  lines = [
    { label: '1' },
    { label: '2' },
    { label: '3' },
    { label: '4' },
  ];
  selectedLines: any = _.times(this.numberOfLines, _.constant(0));
  lines_options_data = [];
  lines_options = [];
  groupsWithTagId: any;

  account: Account;
  site: Site;
  accountTags: any = {};
  accountSubscription: Subscription;
  siteSubscription: Subscription;
  accountTagsSubscription: Subscription;

  isOverview = false;
  isSubmitting = false;
  sessionData: any;
  isDataLoaded = false;

  pbxLink: Pbxlink;
  pbxSubcription: Subscription;
  pbxTypes = pbxTypes;
  applicationName: string;
  applicationNameLong: string;
  accountIdTranslated: string;

  isIframed: boolean;
  routeToUsersList: boolean = false;

  constructor(private adminSvc: AdminService,
    private authSvc: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private accountSvc: AccountService,
    private progressSvc: ProgressService,
    private alertSvc: AlertService,
    private echoSvc: EchoService,
    public spinnerSvc: SpinnerService,
    private formDataSvc: FormDataService,
    private analyticsSvc: AnalyticsService,
    private tooltipSvc: TooltipService,
    private parentCommsService: ParentCommsService,
    private licensesService : LicensesService) { }

  ngOnInit() {

    this.isIframed = this.parentCommsService.isInsideIframe();

    const formItem = sessionStorage.getItem('office-connect-form');
    if (typeof formItem !== 'undefined' && formItem) {
      this.sessionData = JSON.parse(formItem);
    }
    this.setFormWithSessionStorage();

    this.accountSubscription = this.accountSvc.accountChanged
      .subscribe(account => {
        this.account = account;
        this.echoSvc.getEcho();
      });
    this.siteSubscription = this.accountSvc.siteChanged
      .subscribe(site => {
        this.site = site;
        this.echoSvc.getEcho();
        this.initLines();
      });
    this.accountTagsSubscription = this.accountSvc.accountTagsChanged
      .subscribe(accountTags => {
        this.accountTags = accountTags;
        this.echoSvc.getEcho();
      });
    this.pbxSubcription = this.accountSvc.pbxChanged
      .subscribe(pbxLink => {
        this.pbxLink = pbxLink;
        this.applicationName = this.accountSvc.getApplicationName();
        this.applicationNameLong = this.accountSvc.getApplicationNameLong();
        if(this.isIframed && this.applicationName == 'Mitel MiVoice Office'){
          this.setApplicationDisplayName();
        }
      });

    this.account = this.accountSvc.getAccount();
    this.site = this.accountSvc.getSite();
    this.accountTags = this.accountSvc.getAccountTags();
    this.pbxLink = this.accountSvc.getPbxlink();
    this.applicationName = this.accountSvc.getApplicationName();
    this.applicationNameLong = this.accountSvc.getApplicationNameLong();
    if(this.isIframed && this.applicationName == 'Mitel MiVoice Office'){
      this.setApplicationDisplayName();
    }
    this.echoSvc.setGatewayConnect('officelink');
    this.initLines();

    this.route.params.subscribe((params: Params) => {
      this.isOverview = this.route.snapshot.url[0].path === 'overview';
    });
  }

  async setApplicationDisplayName(){
      this.spinnerSvc.show();
      let result = await this.licensesService.shouldBeDisplayedMitelOne();
      this.spinnerSvc.hide();
      if(result){
        this.applicationName = applicationNames.MONE;
        this.applicationNameLong = applicationNamesLong.MONE;
      }
  }

  setFormWithSessionStorage() {
    if (this.sessionData) {
      this.selectedLines = this.sessionData.selectedLines;
      this.lines_options_data = this.sessionData.lines_options_data;
      this.lines_options = this.sessionData.lines_options;
    }
  }

  getAccountTagParameters(succeeded: boolean, reason?: string): any {
    let tagParams = {};
    if (succeeded) {
      tagParams = { 'on-board-progress': { 'step': 5, 'name': 'officelink', 'succeeded': succeeded } };
      tagParams['process-complete'] = true;
    } else {
      tagParams = { 'on-board-progress': { 'step': 5, 'name': 'officelink', 'succeeded': succeeded, 'reason': reason } };
    }

    if (!this.accountTags || !this.accountTags['on-board-progress'] || this.accountTags['on-board-progress'].step <= 5) {
      return tagParams;
    }
    return undefined;
  }

  async initLines() {
    // #457 Update the way groups are obtained. Only show Phantoms,
    // Change TagId to ParkPosition
    const filter = this.getGroupFilter();
    if (filter) {
      let groups = await this.getGroupsFromAccount(filter);
      if (groups) {
        groups = groups.filter(group => {
          return (group.subType === 'PHANTOM' && group.dialableNumber);
        });

        this.lines_options_data = groups.sort((a: any, b: any) => {
          return a.dialableNumber - b.dialableNumber;
        });
        for (let n = 0; n < this.numberOfLines; n++) {
          this.lines_options.push(this.lines_options_data);
        }

        this.groupsWithTagId = await this.getGroupsWithTagId(filter);
        if (this.groupsWithTagId) {
          this.setSelectedLines(this.groupsWithTagId);
        }
        this.setLineOptions();
        this.isDataLoaded = true;
      }
    }
  }

  getGroupsFromAccount(odata?: Odata): Promise<Group[]> {
    const promise = new Promise<Group[]>(async (resolve, reject) => {
      try {
        let response;
        let groups: Group[];
        if (odata) {
          response = await this.adminSvc.getGroupsFromAccount(this.account.accountId, odata);
        } else {
          response = await this.adminSvc.getGroupsFromAccount(this.account.accountId);
        }
        if (response && response.count > 0 && response._embedded && response._embedded.items) {
          groups = response._embedded.items;  // Utils.getItemsFromCollection(response);
          const next = Utils.getOdataNext(response);
          if (next && next.$SkipToken) {
            if (!next.$Filter) {
              next.$Filter = `siteId eq '${this.site.siteId}'`;
            }
            const moreGroups = await this.getGroupsFromAccount(next);
            if (moreGroups) {
              groups = groups.concat(moreGroups);
            }
          }
        }
        resolve(groups);
      } catch (reason) {
        if (reason && reason.statusCode === 401) {
          console.error('failed to retrieve groups', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
          reject(reason);
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('failed to retrieve groups', res);
            const body = JSON.parse(res);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'getGroupsFromAccount.' + body.name);
            reject(res);
          });
        } else if (reason instanceof Error) {
          console.error('failed to retrieve groups', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          } else if (reason.message === commonErrorMessages.TYPE_ERROR) {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, officeLinkMessages.ERR_RETRIEVE_GROUPS)
          }
          reject(reason.message);
        } else {
          console.error('failed to retrieve groups', reason);
          if (reason && reason.body && reason.body.name) {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'getGroupsFromAccount.' + reason.body.name);
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, reason);
          }
          reject(reason);
        }
      }
    });

    return promise;
  }

  getGroupsWithTagId(odata: Odata): Promise<Group[]> {
    const promise = new Promise<Group[]>(async (resolve, reject) => {
      try {
        const response = await this.adminSvc.getGroupsWithTagIdFromAccount(this.account.accountId, 'ParkPosition', odata);
        let groups: Group[];
        const next = Utils.getOdataNext(response);
        if (response && response.count > 0 && response._embedded && response._embedded.items) {
          groups = response._embedded.items.filter(g => g.tags && g.tags.ParkPosition);
        }
        if (next && next.$SkipToken) {
          if (!next.$Filter) {
            next.$Filter = `siteId eq '${this.site.siteId}'`;
          }
          const moreGroups = await this.getGroupsWithTagId(next);
          if (moreGroups) {
            groups = groups.concat(moreGroups);
          }
        }
        resolve(groups);
      } catch (reason) {
        if (typeof reason === 'string') {
          console.error('failed to retrieve groups tags', reason);
          reject(reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to retrieve groups tags', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
          reject(reason);
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('failed to retrieve groups tags', res);
            reject(res);
          });
        } else if (reason instanceof Error) {
          console.error('failed to retrieve groups tags', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
          reject(reason.message);
        }
      }
    });

    return promise;
  }

  getGroupFilter(): Odata {
    if (this.site) {
      const odata = {
        $Filter: `siteId eq '${this.site.siteId}'`,
        $SkipToken: ''
      };
      return odata;
    }
    return undefined;
  }

  setSelectedLines(groups: any) {
    if (this.sessionData) {
      return;
    } else {
      for (let i = 0; i < groups.length; i++) {
        if (groups[i] && groups[i].tags && groups[i].tags.ParkPosition) {
          const lineNumber = +groups[i].tags.ParkPosition.substr(-1);
          this.selectedLines[lineNumber - 1] = groups[i].groupId;
        }
      }
    }
  }

  setLineOptions() {
    for (let i = 0; i < this.lines_options.length; i++) {
      const selectedLine = [];
      selectedLine.push(this.selectedLines[i]);

      const filtersArray = _.difference(this.selectedLines, selectedLine);
      this.lines_options[i] = _.filter(this.lines_options_data, (data) => {
        return !_.includes(filtersArray, data.groupId);
      });
    }
  }

  async updateGroupandUpdateTag(groupId: string, body: GroupPutRequest, position: number): Promise<any> {
    const promise = new Promise<any>(async (resolve, reject) => {
      try {
        await this.adminSvc.updateGroupFromAccount(this.account.accountId, groupId, body);
        console.log('Updated group');
        const result = await this.adminSvc.createGroupTagFromAccount(this.account.accountId, groupId, { ParkPosition: `${position + 1}` });
        console.log('Set Group tag');
        resolve(result);
      } catch (reason) {
        if (reason && reason.statusCode === 401) {
          console.error('Could not update group');
          this.authSvc.redirectToLogin();
          reject(reason);
        } else if (reason && reason.text && typeof reason.text === 'function') {
          console.error('Could not update group');
          reason.text().then(res => {
            console.error(res);
            reject(res);
          }).catch(err => reject(err));
        } else if (reason instanceof Error) {
          console.error('Could not update group', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
          reject(reason.message);
        } else {
          if (reason && reason.body) {
            reject(reason.body);
          } else {
            reject(reason);
          }
        }
      }
    });

    return promise;
  }

  async updateGroupandDeleteTag(groupId: string, body: GroupPutRequest): Promise<any> {
    const promise = new Promise<any>(async (resolve, reject) => {
      try {
        await this.adminSvc.updateGroupFromAccount(this.account.accountId, groupId, body);
        console.log('Updated group');
        const result = await this.adminSvc.deleteGroupTagFromAccount(this.account.accountId, groupId, 'ParkPosition');
        console.log('Set Group tag');
        resolve(result);
      } catch (reason) {
        if (reason && reason.statusCode === 401) {
          console.error('Could not update group', reason);
          this.authSvc.redirectToLogin();
          reject(reason);
        } else if (reason && reason.text && typeof reason.text === 'function') {
          console.error('Could not update group');
          reason.text().then(res => {
            console.error(res);
            reject(res);
          }).catch(err => reject(err));
        } else if (reason instanceof Error) {
          console.error('Could not update group', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
          reject(reason.message);
        } else {
          console.error('Could not update group', reason);
          if (reason && reason.body) {
            reject(reason.body);
          } else {
            reject(reason);
          }
        }
      }
    });

    return promise;
  }

  async updateAccountTagsWithProgress(success: boolean, reason?: string) {
    const tagParams = this.getAccountTagParameters(success, reason);
    if (tagParams) {
      try {
        const result = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
        console.log('account tag updated');
        this.accountSvc.setAccountTags(result);
        this.isSubmitting = false;
      } catch (reason) {
        this.isSubmitting = false;
        if (typeof reason === 'string') {
          console.error('failed to update account tag', reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to update account tag', reason);
          this.authSvc.redirectToLogin();
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => console.error('failed to update account tag', res));
        } else if (reason instanceof Error) {
          console.error('failed to update account tag', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        }
      }
    }
  }

  async onSelect(index: number, selectedLine: string) {
    this.selectedLines[index] = selectedLine;
    this.setLineOptions();

    // Immediately update tags
    const filter = this.getGroupFilter();
    this.groupsWithTagId = await this.getGroupsWithTagId(filter);
    let groupsToDeleteTag = this.groupsWithTagId;
    const promises = [];

    const groupId = selectedLine.toString();
    if (this.isGroupId(groupId)) {
      promises.push(this.updateGroupandUpdateTag(groupId, { type: <Group.TypeEnum>"PARK" }, index));
    }

    this.selectedLines.forEach((selectedLine, i) => {
      const groupId = selectedLine.toString();
      if (this.isGroupId(groupId)) {
        if (groupsToDeleteTag) {
          groupsToDeleteTag = groupsToDeleteTag.filter(g => g.groupId !== groupId);
        }
      }
    });

    if (groupsToDeleteTag) {
      groupsToDeleteTag.forEach(g => {
        promises.push(this.updateGroupandDeleteTag(g.groupId, { type: <Group.TypeEnum>"APP" }));
      });
    }

    try {
      await Promise.all(promises);
      this.updateAccountTagsWithProgress(true);
      this.alertSvc.setAlert(toastMsgTypes.INFO, officeLinkMessages.UPDATE_KEY_LINES);
    } catch (reason) {
      console.error('failed to update tags', reason);
      this.alertSvc.setAlert(toastMsgTypes.ERROR, officeLinkMessages.ERR_UPDATE_KEY_LINE);
      this.updateAccountTagsWithProgress(false, officeLinkMessages.ERR_UPDATE_KEY_LINE);
    }
  }

  isGroupId(groupId: string) {
    const group = this.lines_options_data.filter(option => {
      return option.groupId === groupId;
    });

    if (group && group[0]) {
      return true;
    }
    return false;
  }

  onBack() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview OfficeLink' : 'OfficeLink', 'Back');
    if (this.isEditMode()) {
      this.formDataSvc.redirectToDashboard(undefined, undefined, 'account');// redirect to accounts page when clicked if iframed
    } else {
      if (!this.isOverview) {
        const backUrl = `/accounts/${this.account.accountId}/sites/${this.site.siteId}/connect`;
        this.router.navigateByUrl(backUrl);
      } else {
        const element = document.getElementsByTagName('app-connect-state')[0];
        element.scrollIntoView();
      }
    }
  }
  async onComplete() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview OfficeLink' : 'OfficeLink', 'Complete');
    this.alertSvc.clearAlert();
    if (!this.isOverview) {
      window.scrollTo(0, 0);
    }
    this.sessionData = null;
    this.isSubmitting = true;
    if (this.pbxLink &&
      (
        this.pbxLink.type === pbxTypes.MIVO400 ||
        this.pbxLink.type === pbxTypes.MIVO250 ||
        this.pbxLink.type === pbxTypes.MIVC ||
        this.pbxLink.type === pbxTypes.MIVB ||
        this.pbxLink.type === pbxTypes.MV5000 ||
        this.pbxLink.type === pbxTypes.MXONE
      )) {
      await this.updateAccountTagsWithProgress(true);
    }

    let iframeRedirectDestination = this.routeToUsersList ? 'usersList' : 'account';

    this.formDataSvc.redirectToDashboard('', false, iframeRedirectDestination);
    this.isSubmitting = false;
  }

  goAdvanced() {
    if (!this.isOverview) {
      const url = '/accounts/' + this.account.accountId + '/sites/' + this.site.siteId + '/advanced';
      this.router.navigateByUrl(url);
    } else {
      const element = document.getElementsByTagName('app-advanced-form')[0];
      element.scrollIntoView();
    }
  }

  isEditMode() {
    if (this.accountTags && this.accountTags['on-board-progress']) {
      const progressOffcelinkIdx = this.progressSvc.getProgressIdx('officelink');
      const lastProgressIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress'].name);

      if (progressOffcelinkIdx < lastProgressIdx) {
        return true;
      } else if (progressOffcelinkIdx === lastProgressIdx && this.accountTags['on-board-progress'].succeeded) {
        return true;
      }
    }

    return false;
  }

  infoIconItem(labelName: string): any {
    return this.tooltipSvc.getTooltipItem(labelName);
  }

  infoIconItemForApp(labelName: string): any {
    let appPrefix ;
    if(this.pbxLink && (this.pbxLink.type === pbxTypes.MV5000 || this.pbxLink.type === pbxTypes.MXONE)){
      appPrefix = 'mxone';
    }
    else {
      appPrefix = this.accountSvc.getApplicationName() === 'OfficeLink' ? 'officelink'
                                                                        :(this.applicationName === 'Mitel One' ? 'mone' : 'moma');
    }

    const newLabelName = 'tooltips.' + appPrefix + labelName;
    return this.tooltipSvc.getTooltipItem(newLabelName);
  }

  ngOnDestroy() {
    if (this.isDataLoaded) {
      sessionStorage.setItem('office-connect-form', JSON.stringify({
        selectedLines: this.selectedLines,
        lines_options_data: this.lines_options_data,
        lines_options: this.lines_options
      }));
    }
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
    if (this.accountTagsSubscription) {
      this.accountTagsSubscription.unsubscribe();
    }
  }

}
