import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { AdminService } from './admin.service';
import { Admin2Service } from './admin2.service';
import { AuthenticationService } from './authentication.service';
import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { ProgressService } from './progress.service';
import { Pbxlink } from '@mitel/cloudlink-sdk/tunnel';
import { TunnelService } from './tunnel.service';
import { Utils, Odata, Token } from '@mitel/cloudlink-sdk';
import { Site, Account } from '@mitel/cloudlink-sdk/admin';
import {
  gatewayConnectStateMessages,
  toastMsgTypes,
  pbxStatus,
  pbxMessages,
  commonErrorMessages,
  pbxConnectError,
  pbxTypes,
  commonMessages
} from '../shared/constants';
import { FetchInterceptorService } from "./fetch-interceptor.service";


@Injectable()
export class EchoService {
	account: Account;
  site: Site;
  pbxLink: Pbxlink;
  accountTags: any = {};
	checkGatewayConnect = false;
  retrying = false;
  isRequestInProgress = false;

  constructor(private spinnerSvc: SpinnerService,
  						private accountSvc: AccountService,
              private adminSvc: AdminService,
              private admin2Svc: Admin2Service,
              private authSvc: AuthenticationService,
              private alertSvc: AlertService,
              private progressSvc: ProgressService,
              private tunnelSvc: TunnelService,
  						private router: Router,
              private interceptor: FetchInterceptorService
  ) {
    this.interceptor.requestInProgress.subscribe((status) => this.isRequestInProgress = status)
  }

  setGatewayConnect(navigationStage: string) {
    const gatewayConnectItem = sessionStorage.getItem('gateway-connection');
    if (typeof gatewayConnectItem !== 'undefined' && gatewayConnectItem) {
      this.checkGatewayConnect = false;
      const gatewayConnected = JSON.parse(gatewayConnectItem);
      this.account = this.accountSvc.getAccount();
      if (!gatewayConnected) {
        this.router.navigateByUrl(`/accounts/${this.account.accountId}/gateway-connection-error`);
      } else {
        this.accountTags = this.accountSvc.getAccountTags();
        if (this.accountTags && this.accountTags['pbx-status']) {
          if(navigationStage !== 'advanced'){
            if (this.accountTags['pbx-status']['status'] === pbxStatus.DOWN){
              this.router.navigateByUrl(`/accounts/${this.account.accountId}/pbx-connection-error`);
            } else if(this.accountTags['pbx-status']['status'] === pbxStatus.MISSING) {
              this.router.navigateByUrl(`/accounts/${this.account.accountId}/sites/${this.site.siteId}/pbx/new`);
            }
          }
        }
      }
    } else {
      this.checkGatewayConnect = true;
      this.getEcho();
    }
  }

  async getEcho(retrying: boolean = false) {
    this.account = this.accountSvc.getAccount();
    this.site = this.accountSvc.getSite();
    this.accountTags = this.accountSvc.getAccountTags();

    if ((this.checkGatewayConnect || retrying) && this.account && this.site && this.accountTags) {
      this.checkGatewayConnect = false;
      this.retrying = retrying;
      this.spinnerSvc.show();
      this.spinnerSvc.setMessage(gatewayConnectStateMessages.CHECKING_CONNECT);
      try {
        const assumeTokenHeader = await this.getAssumeRoleHeader(this.account.accountId);
        const result = await this.tunnelSvc.getEcho(this.account.accountId, this.site.siteId, assumeTokenHeader);
        if (result.data === 'ECHO..echo..echo') {
          sessionStorage.setItem('gateway-connection', JSON.stringify(true));
          this.updateGatewayConnectionTag(true, undefined, assumeTokenHeader);
          return true;
        } else {
          console.warn('unexpected echo response', result.data);
          sessionStorage.setItem('gateway-connection', JSON.stringify(false));
          this.updateGatewayConnectionTag(false, `connect fail: ${result.data && result.data !== '' ? result.data : 'unknown'}`, assumeTokenHeader);
          return false;
        }
      } catch (reason) {
        if (typeof reason === 'string') {
          console.warn('echo failed', reason);
          sessionStorage.setItem('gateway-connection', JSON.stringify(false));
          this.updateGatewayConnectionTag(false,
            `connect fail: ${reason}`);
        } else if (reason && reason.statusCode === 401) {
          console.warn('echo failed', JSON.stringify(reason));
          this.updateGatewayConnectionTag(false,
            `connect fail: ${commonMessages.TUNNEL_AUTH_ERR}`);
        } else if (reason && reason.body) {
          console.warn('echo failed', JSON.stringify(reason));
          sessionStorage.setItem('gateway-connection', JSON.stringify(false));
          this.updateGatewayConnectionTag(false,
            `connect fail: ${reason.body.message && reason.body.message !== '' ? reason.body.message : 'unknown'}`);
        } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('echo failed', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          console.warn('echo failed', JSON.stringify(reason));
          sessionStorage.setItem('gateway-connection', JSON.stringify(false));
          this.updateGatewayConnectionTag(false,
                      `connect fail: ${reason ? JSON.stringify(reason) : 'unknown'}`);
        }
        return false;
      }
    }
  }

  async getPbxStatusAccountTagsParameters(account: Account, site: Site, accountTags: any, options?: any) {
    const tagParams = {};
    // Get pbx data, check connection to pbx
    if (this.shouldGetPbxData(accountTags)) {
      try {
        const assumeTokenHeader = options || await this.getAssumeRoleHeader(account.accountId);
        const response = await this.tunnelSvc.getPbxlinks(account.accountId, site.siteId, assumeTokenHeader);

        tagParams['status'] = pbxStatus.MISSING;
        if (response) {
          const pbxs = Utils.getItemsFromCollection(response);
          if (pbxs[0]) {
            this.pbxLink = pbxs[0];
            tagParams['status'] = pbxStatus.UP;
          }
        }
      } catch (error) {
        tagParams['status'] = pbxStatus.DOWN;
        if (error && error.statusCode === 401) {
          console.error('failed to get PBX links', JSON.stringify(error));
          this.authSvc.redirectToLogin();
          this.spinnerSvc.hide();
          return;
        } else if (error.status === 503) {
          tagParams['reason'] = pbxMessages.CANNOT_CONNECT_PBX;
        } else if (error.status === 504) {
          tagParams['reason'] = pbxMessages.SYSTEM_DATA_TIMEOUT;
        } else if (typeof error === 'string') {
          console.error('failed to get PBX links', error);
          tagParams['reason'] = (error && error !== '') ? error : 'common.unknown';
        } else if (error && error.body) {
          console.error('failed to get PBX links', JSON.stringify(error));
          tagParams['reason'] = (error.body.name && error.body.name !== '') ?
                                ('getPbxlinks.' + error.body.name) : 'common.unknown';
        } else if (error instanceof Error) {
          console.error('failed to get PBX links', error.message);
          if (error.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
            this.spinnerSvc.hide();
            return;
          } else if (error.message === commonErrorMessages.TYPE_ERROR) {
            tagParams['reason'] = pbxMessages.ERR_GET_PBX;
          } else {
            tagParams['reason'] = (error.message && error.message !== '' ?
                                                 error.message : 'common.unknown');
          }
        }
      }
    } else {
      tagParams['status'] = pbxStatus.UNKNOWN;
    }

    return tagParams;
  }

  async getGatewayConnectionAccountTagsParameters(account: Account, site: Site, accountTags: any, options?: any) {
    let tagParams = {};
    let pbxConnected = true;
    let statusMsg = '';
    let myPbxlink: Pbxlink;
    // Check cloudlinkStatus
    if (this.shouldCheckPbxConnection(accountTags)) {
      let cloudlinkStatus: any;
      try {
        const assumeTokenHeader = options || await this.getAssumeRoleHeader(account.accountId);
        const response = await this.tunnelSvc.getPbxlinks(account.accountId, site.siteId, assumeTokenHeader);
        if (response) {
          const pbxs = Utils.getItemsFromCollection(response);
          if (pbxs[0]) {
            myPbxlink = pbxs[0];
          }
        }
        cloudlinkStatus = await this.tunnelSvc.getCloudlinkStatus(account.accountId, site.siteId, assumeTokenHeader);
      } catch (err) {
        console.warn('get cloudlinkStatus failed', err);
      }

      if (cloudlinkStatus) {
        if (!cloudlinkStatus.cstaproxy) {
          statusMsg = gatewayConnectStateMessages.LOST_CSTA_CONNECTION;
        } else if (cloudlinkStatus.pbx_links === 'down') {
          pbxConnected = false;
          statusMsg = gatewayConnectStateMessages.LOST_OAI_CONNECTION;
        } else if (this.pbxLink && this.pbxLink['connect_error'] && this.pbxLink['connect_error'] !== '') {
          pbxConnected = false;
          statusMsg = this.getStatusMessageFromConnectError(this.pbxLink['connect_error']);
        } else if (cloudlinkStatus.pbx_links === 'init') {
          pbxConnected = false;
          statusMsg = gatewayConnectStateMessages.NOT_START_PBX_CONNECTION;
        } else if (myPbxlink && myPbxlink.type === pbxTypes.MIVO250) {
          // Only check registration status if pbxType is mivo250
          if (cloudlinkStatus.freeswitch_registrations === 0 || !cloudlinkStatus.cloudgateway) {
            pbxConnected = false;
            if (cloudlinkStatus.freeswitch_registrations === 0) {
              if (!cloudlinkStatus.cloudgateway) {
                statusMsg = gatewayConnectStateMessages.FAILED_REGISTER_PBX_MEDIA;
              } else {
                statusMsg = gatewayConnectStateMessages.FAILED_REGISTER_PBX;
              }
            } else {
              statusMsg = gatewayConnectStateMessages.FAILED_REGISTER_MEDIA;
            }
          }
        } else { // Check the other pbx types ignoring freeswitch_registrations, check cloudgateway
          if (!cloudlinkStatus.cloudgateway) {
            statusMsg = gatewayConnectStateMessages.FAILED_REGISTER_MEDIA;
          }
        }
      }

      if (pbxConnected) {
        tagParams = { connected: true, pbxConnected: pbxConnected };
      } else {
        tagParams = { connected: true, pbxConnected: pbxConnected, reason: statusMsg };
      }
    } else {
      tagParams = { connected: true };
    }

    return tagParams;
  }

  async getAccountTagsParameters(connected: boolean, reason?: string, options?: any) {
    this.account = this.accountSvc.getAccount();
    this.site = this.accountSvc.getSite();
    this.accountTags = this.accountSvc.getAccountTags();
    const tagParams = {};
    if (connected) {
      const pbxStatusTagParams = await this.getPbxStatusAccountTagsParameters(this.account, this.site, this.accountTags, options);
      tagParams['pbx-status'] = pbxStatusTagParams;

      const gatewayConnectionTagParams = await this.getGatewayConnectionAccountTagsParameters(this.account, this.site, this.accountTags, options);
      tagParams['gateway-connection'] = gatewayConnectionTagParams;
    } else {
      tagParams['gateway-connection'] = { connected: connected, reason: reason };
      tagParams['pbx-status'] = { status: pbxStatus.UNKNOWN };
    }

    return tagParams;
  }

  async updateGatewayConnectionTag(connected: boolean, reason?: string, options?: any) {
    try {
      const tagParams = await this.getAccountTagsParameters(connected, reason, options);
      const tags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
      this.accountSvc.setAccountTags(tags);
      if (!this.isRequestInProgress) {
        this.spinnerSvc.hide();
      }
      if (!connected && !this.retrying) {
        this.router.navigateByUrl(`/accounts/${this.account.accountId}/gateway-connection-error`);
      } else if (tags['pbx-status']['status'] === pbxStatus.DOWN && !this.retrying) {
        this.router.navigateByUrl(`/accounts/${this.account.accountId}/pbx-connection-error`);
      } else if (connected && tags['gateway-connection']['pbxConnected'] === false) {
        // get pbxLink
        if (this.accountSvc.getPbxlink()) {
          this.pbxLink = this.accountSvc.getPbxlink();
          this.router.navigateByUrl(`/accounts/${this.account.accountId}/sites/${this.site.siteId}/pbx/${this.pbxLink._id}`);
        }
        this.alertSvc.setAlert(toastMsgTypes.ERROR, tagParams['gateway-connection']['reason']);
      }
    } catch (error) {
      // if there is an error, hide the spinner
      if (!this.isRequestInProgress) {
        this.spinnerSvc.hide();
      }
      if (typeof error === 'string') {
        console.error('failed to update gateway connection tag', error);
      } else if (error && error.statusCode === 401) {
        console.error('failed to update gateway connection tag', JSON.stringify(error));
        this.authSvc.redirectToLogin();
      } else if (typeof error.text === 'function') {
        error.text().then(res => {
          console.error('failed to update gateway connection tag', res);
        }).catch(err => console.error('failed to update gateway connection tag', err));
      } else if (error instanceof Error) {
        console.error('failed to update gateway connection tag', error.message);
        if (error.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      }
    }
  }

  getStatusMessageFromConnectError(connectErr: string): string {
    let statusMsg = '';
    switch (connectErr) {
      case pbxConnectError.INVALID_TRUSTED_APP_PASSWORD:
        statusMsg = gatewayConnectStateMessages.INVALID_TRUSTED_APP_PASSWORD;
        break;
      case pbxConnectError.INVALID_IP:
        statusMsg = gatewayConnectStateMessages.INVALID_IP;
        break;
      case pbxConnectError.INVALID_GATEWAY_IP:
        statusMsg = gatewayConnectStateMessages.INVALID_GATEWAY_IP;
        break;
      case pbxConnectError.INVALID_PBX_IP:
        statusMsg = gatewayConnectStateMessages.INVALID_PBX_IP;
        break;
      case pbxConnectError.TRUSTED_APP_DISABLED:
        statusMsg = gatewayConnectStateMessages.TRUSTED_APP_DISABLED;
        break;
      case pbxConnectError.INVALID_TRUNK_GROUP:
        statusMsg = gatewayConnectStateMessages.INVALID_TRUNK_GROUP;
        break;
      default:
        statusMsg = gatewayConnectStateMessages.NOT_START_PBX_CONNECTION;
        break;
    }

    return statusMsg;
  }

  shouldCheckPbxConnection(accountTags: any) {
    if (accountTags && accountTags['on-board-progress']) {
      const lastStepIdx = this.progressSvc.getProgressIdx(accountTags['on-board-progress']['name']);
      const lastStepSucceeded = accountTags['on-board-progress'].succeeded;
      if (lastStepIdx >= 4 || (lastStepIdx === 3 && lastStepSucceeded)) { // check if pbx is completed
        return true;
      }
    }

    return false;
  }

  shouldGetPbxData(accountTags: any, isUpdating?: boolean) {
    if (accountTags && accountTags['on-board-progress']) {
      const lastStepIdx = this.progressSvc.getProgressIdx(accountTags['on-board-progress']['name']);
      const verify = isUpdating ? (lastStepIdx === 3 && !accountTags['on-board-progress'].reason) || (lastStepIdx > 3) : lastStepIdx >= 3;
      if (verify) { // get the pbxLinkinfo
        return true;
      }
    }

    return false;
  }

  private getAssumeRoleParams(accountId) {
    let params;
    if (accountId) {
      params = { accountId: accountId, role: 'ACCOUNT_ADMIN' };
    }
    return params;
  }

  private getAssumedRoleOptions(token: Token) {
    const headers = { 'authorization': 'Bearer ' + token.access_token };
    const options = {
      headers: headers
    };

    return options;
  }

  private async getAssumeRoleHeader(accountId) {
    const assumeParams = this.getAssumeRoleParams(accountId);
    if (assumeParams) {
      const token = await this.authSvc.getAssumedRoleToken(assumeParams);
      return this.getAssumedRoleOptions(token);
    } else {
      return undefined;
    }
  }

}
