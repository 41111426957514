import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { appRouting } from './app.routing';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';

import { AppService } from './services/app.service';
import { AuthenticationService } from './services/authentication.service';
import { AdminService } from './services/admin.service';
import { Admin2Service } from './services/admin2.service';
import { CountryService } from './services/country.service';
import { AccountService } from './services/account.service';
import { UserService } from './services/user.service';
import { ProgressService } from './services/progress.service';
import { AutoUpdateScheduleService } from './services/auto-update-schedule.service';
import { AuthGuard } from './services/auth-guard.service';
import { PolicyGuard } from './services/policy-guard.service';
import { PartnerGuard } from './services/partner-guard.service';
import { iFrameGuard } from './services/iframe-guard.service';
import { AlertService } from './services/alert.service';
import { SpinnerService } from './services/spinner.service';
import { EchoService } from './services/echo.service';
import { TooltipService } from './services/tooltip.service';
import { CloudResourcesService } from './services/cloudResources.service';
import { FormDataService } from './services/form-data.service';
import { ResyncService } from './services/resync/resync.service';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SortbyPipe } from './pipes/sortby.pipe';
import { TitleCase } from './pipes/titlecase.pipe';
import { BaseContainerComponent } from './components/base-container/base-container.component';
import { SiteComponent } from './components/site/site.component';
import { PreReqComponent } from './components/pre-req/pre-req.component';
import { ConnectStateComponent } from './components/connect-state/connect-state.component';
import { DeactivateConfirmationComponent } from './components/deactivate-confirmation/deactivate-confirmation.component';
import { PbxComponent } from './components/pbx/pbx.component';
import { TagInputItemComponent } from './components/tag-input-item/tag-input-item.component';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { UpperCaseDirective } from './directives/uppercase.directive';
import { TooltipContentComponent } from './components/tooltip-content/tooltip-content.component';
import { AdvancedFormComponent } from './components/advanced-form/advanced-form.component';
import { SystemOptionsConfirmationComponent } from './components/system-options-confirmation/system-options-confirmation.component';
import { CheckmarkComponent } from './components/checkmark/checkmark.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AlertComponent } from './components/alert/alert.component';
import { OverviewComponent } from './components/overview/overview.component';
import { FooterComponent } from './components/footer/footer.component';
import { ConnectErrorComponent } from './components/connect-error/connect-error.component';
import { GatewayConnectionComponent } from './components/gateway-connection/gateway-connection.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { GatewayLinkConfirmationComponent } from './components/gateway-link-confirmation/gateway-link-confirmation.component';
import { PbxConnectionComponent } from './components/pbx-connection/pbx-connection.component';
import { UsersListModalComponent } from './components/users-list-modal/users-list-modal.component';
import { UnsavedChangesModalComponent } from './components/unsaved-changes-modal/unsaved-changes-modal.component';
import { AnalyticsService } from './services/analytics.service';
import { TunnelService } from './services/tunnel.service';
import { WebpackTranslateLoader } from './webpack-translate-loader';
import { UnexpectedModalComponent } from './components/unexpected-modal/unexpected-modal.component';
import { ErrorPermissionsModalComponent } from './components/error-permission-modal/error-permission-modal.component';
import {FetchInterceptorService} from "./services/fetch-interceptor.service";
import { ServerUnavailableModalComponent } from './components/server-unavailable-modal/server-unavailable-modal.component';
import {AgmCoreModule} from "@agm/core";
import {environment} from "../environments/environment";
import { GooglePlacesDirective } from './directives/google-places.directive';
import { ClConsoleComponentsModule, ClHeaderComponent } from '@mitel/cloudlink-console-components';
import {WindowToken, windowProvider } from './services/parent-comms.service';
import { MessageFormComponent } from './components/message-form/message-form.component';
import { OfficeConnectComponent } from './components/office-connect/office-connect.component';
import { GatewayNetworkConfigurationComponent } from './components/gateway-network-configuration/gateway-network-configuration.component';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SearchFilterPipe,
        SortbyPipe,
        TitleCase,
        BaseContainerComponent,
        SiteComponent,
        PreReqComponent,
        ConnectStateComponent,
        DeactivateConfirmationComponent,
        PbxComponent,
        TagInputItemComponent,
        TagInputComponent,
        TooltipDirective,
        UpperCaseDirective,
        TooltipContentComponent,
        AdvancedFormComponent,
        SystemOptionsConfirmationComponent,
        CheckmarkComponent,
        NavbarComponent,
        AlertComponent,
        OverviewComponent,
        FooterComponent,
        ConnectErrorComponent,
        GatewayConnectionComponent,
        SpinnerComponent,
        GatewayLinkConfirmationComponent,
        PbxConnectionComponent,
        UsersListModalComponent,
        UnsavedChangesModalComponent,
        ErrorPermissionsModalComponent,
        UnexpectedModalComponent,
        ServerUnavailableModalComponent,
        GooglePlacesDirective,
        MessageFormComponent,
        OfficeConnectComponent,
        GatewayNetworkConfigurationComponent
    ],
    imports: [
        appRouting,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        LottieModule.forRoot({ player: playerFactory }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: environment.googleApiKey,
            libraries: ["places"]
        }),
        ClConsoleComponentsModule
    ],
    providers: [
        { provide: WindowToken, useFactory: windowProvider },
        AppService,
        AuthenticationService,
        AdminService,
        Admin2Service,
        AccountService,
        CountryService,
        UserService,
        ProgressService,
        AutoUpdateScheduleService,
        AuthGuard,
        PolicyGuard,
        PartnerGuard,
        iFrameGuard,
        AlertService,
        SpinnerService,
        EchoService,
        TooltipService,
        CloudResourcesService,
        FormDataService,
        AnalyticsService,
        TunnelService,
        FetchInterceptorService,
        ClHeaderComponent,
        ResyncService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
