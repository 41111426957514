import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Pbxlink } from '@mitel/cloudlink-sdk/tunnel';
import { AccountService } from '../../services/account.service';
import { pbxTypes, applicationNames } from '../../shared/constants';
import { TunnelService } from '../../services/tunnel.service';
import { ParentCommsService } from '../../services/parent-comms.service';


@Component({
  selector: 'app-pre-req',
  templateUrl: './pre-req.component.html',
  styleUrls: ['./pre-req.component.css']
})
export class PreReqComponent implements OnInit {
  @Output() continue = new EventEmitter();
  @Output() overview = new EventEmitter();
  items = [];
  itemsMiVO250 = [];
  itemsMiVO400 = [];
  itemsMiVB = [];
  itemsMiVC = [];
  itemsMXONE = [];
  itemsMV5000 = [];
  pbxSelectOptions = [];
  pbxSelectBetaOptions = [];
  pbxLink: Pbxlink;
  pbxSubscription: Subscription;
  defaultType: string;
  isIframed: boolean;
  applicationName: string;
  isMitelOneIntegrationOn = undefined;


  constructor(private translateSvc: TranslateService,
              private activeModal: NgbActiveModal,
              private accountSvc: AccountService,
              private parentCommsService: ParentCommsService,
              private tunnel: TunnelService) { }

  async ngOnInit() {
    this.translateSvc.onLangChange.subscribe((lang) => {
      this.setPBXTypes();
      this.onSelect(this.defaultType);
    });
    this.isIframed = this.parentCommsService.isInsideIframe();

    this.pbxSubscription = this.accountSvc.pbxChanged
                                    .subscribe(pbx => this.pbxLink = pbx);
    this.pbxLink = this.accountSvc.getPbxlink();

    this.setPBXTypes();
    this.setDefaultItems();
    this.onSelect(this.defaultType);

    let platform;
    const tunnel = localStorage.getItem('tunnel');
    const username = localStorage.getItem('username');
    const password =  localStorage.getItem('password');
    if (tunnel && username && password) {
      try {
        platform = await this.tunnel.getInitialPlatform(
          localStorage.getItem('tunnel'),
          localStorage.getItem('username'),
          localStorage.getItem('password')
        );
      } catch (error) {
        console.log('error getting inital platform', error);
        platform = undefined;
      }
    } else {
      platform = this.accountSvc.getPlatform();
    }

    (await this.tunnel.getSupportedPbxTypes(platform)).forEach(v => {
      if (v.betaSupport) {
        this.pbxSelectBetaOptions.push(v);
      } else {
        this.pbxSelectOptions.push(v);
      }
    });
  }

  setPBXTypes() {
    let translateObject = this.translateSvc.instant('preReqItemsMiVO250');
    if (translateObject) {
      this.itemsMiVO250 = Object.keys(translateObject).map(key => {
        const item = {
          description: translateObject[key]?.description ? translateObject[key]?.description() : '',
          info: {
            title: translateObject[key]?.info?.title(),
            content: translateObject[key]?.info?.content({ applicationName: this.applicationName }),
            help: translateObject[key]?.info?.help()
          }
        };
        return item;
      });
    }
    translateObject = this.translateSvc.instant('preReqItemsMiVO400');
    if (translateObject) {
      this.itemsMiVO400 = Object.keys(translateObject).map(key => {
        const item = {
          description: translateObject[key]?.description ? translateObject[key]?.description() : '',
          info: {
            title: translateObject[key]?.info?.title(),
            content: translateObject[key]?.info?.content ? translateObject[key]?.info?.content({ applicationName: this.applicationName }) : '',
            help: translateObject[key]?.info?.help()
          }
        };
        return item;
      });
    }
    translateObject = this.translateSvc.instant('preReqItemsMiVB');
    if (translateObject) {
      this.itemsMiVB = Object.keys(translateObject).map(key => {
        let info = translateObject[key]?.info;
        const item = {
          description: translateObject[key]?.description ? translateObject[key]?.description() : '',
          info: {
            title: info?.title ? info.title() : '',
            content: info?.content ? info.content({ applicationName: this.applicationName }) : '',
            help: info?.help ? info.help() : ''
          }
        };
        return item;
      });
    }
    translateObject = this.translateSvc.instant('preReqItemsMiVC');
    if (translateObject) {
      this.itemsMiVC = Object.keys(translateObject).map(key => {
        let info = translateObject[key]?.info;
        const item = {
          description: translateObject[key]?.description ? translateObject[key]?.description() : '',
          info: {
            title: info?.title ? info.title() : '',
            content: info?.content ? info.content({ applicationName: this.applicationName }) : '',
            help: info?.help ? info.help() : ''
          }
        };
        return item;
      });
    }
    translateObject = this.translateSvc.instant('preReqItemsMXONE');
    if (translateObject) {
      this.itemsMXONE = Object.keys(translateObject).map(key => {
        let info = translateObject[key]?.info;
        const item = {
          description: translateObject[key].description ? translateObject[key].description() : '',
          info: {
            title: info?.title ? info.title() : '',
            content: info?.content ? info.content({ applicationName: this.applicationName }) : '',
            help: info?.help ? info.help() : ''
          }
        };
        return item;
      });
    }
    translateObject = this.translateSvc.instant('preReqItemsMV5000');
    if (translateObject) {
      this.itemsMV5000 = Object.keys(translateObject).map(key => {
        let info = translateObject[key]?.info;
        const item = {
          description: translateObject[key].description ? translateObject[key].description() : '',
          info: {
            title: info?.title ? info.title() : '',
            content: info?.content ? info.content({ applicationName: this.applicationName }) : '',
            help: info?.help ? info.help() : ''
          }
        };
        return item;
      });
    }
  }

  setDefaultItems() {
    if (this.pbxLink) {
      this.items = this.pbxLink.type === pbxTypes.MIVO250 ? this.itemsMiVO250
          : this.pbxLink.type === pbxTypes.MIVO400 ? this.itemsMiVO400
          : this.pbxLink.type === pbxTypes.MIVB ? this.itemsMiVB
          : this.pbxLink.type === pbxTypes.MIVC ? this.itemsMiVC
          : this.pbxLink.type === pbxTypes.MXONE ? this.itemsMXONE : this.itemsMV5000;
      this.defaultType = this.pbxLink.type;
    } else if (this.accountSvc.getSelectedPbxType()) {
      const type = this.accountSvc.getSelectedPbxType();
      this.items = type === pbxTypes.MIVO250 ? this.itemsMiVO250
          : type === pbxTypes.MIVO400 ? this.itemsMiVO400
          : type === pbxTypes.MIVB ? this.itemsMiVB
          : type === pbxTypes.MIVC ? this.itemsMiVC
          : type === pbxTypes.MXONE ? this.itemsMXONE : this.itemsMV5000;
      this.defaultType = type;
    } else {
      // this.items = this.itemsMiVO250;
      this.defaultType = undefined;
    }
  }

  async onSelect(type: string) {
    this.applicationName = this.accountSvc.getApplicationName(type);
    if (type === pbxTypes.MIVO250) {
      this.items = this.itemsMiVO250;
    }
    else if(type) {
      this.items = [];
      this.itemsMiVO250 = [];
      this.itemsMiVO400 = [];
      this.itemsMiVB = [];
      this.itemsMiVC = [];
      this.itemsMXONE = [];
      this.itemsMV5000 = [];
      await this.setApplicationName(type);
      this.setPBXTypes();
      if (type === pbxTypes.MIVO400) {
        this.items = this.itemsMiVO400;
      } else if (type === pbxTypes.MIVB) {
        this.items = this.itemsMiVB;
      } else if (type === pbxTypes.MIVC) {
        this.items = this.itemsMiVC;
      } else if (type === pbxTypes.MV5000) {
        this.items = this.itemsMV5000;
      } else if (type === pbxTypes.MXONE) {
        this.items = this.itemsMXONE;
      }
    }
  }

  async setApplicationName(type : string) {
    if(this.isMitelOneIntegrationOn === undefined) {
      // we need to check the tags
      let tags = this.accountSvc.getAccountTags();
      if(tags && tags.products && tags.products.length > 0 && tags.products.includes("MitelOne")) {
        //if MitelOne tag is there, set the isMitelOneIntegrationOn to true
        this.isMitelOneIntegrationOn = true;
      }
      else {
        this.isMitelOneIntegrationOn = false;
      }
    }
    if (this.isMitelOneIntegrationOn) {
      if (type === pbxTypes.MIVO400) {
        // change the application name to Mitel One
        this.applicationName = applicationNames.MONE;
      }
    }
}



  onClose() {
    this.activeModal.close();
  }

  onOverview() {
    this.overview.emit();
    this.onClose();
  }

  onContinue() {
    // If new customer, save the select in sessionStorage
    if (!this.pbxLink) {
      this.accountSvc.setSelectedPbxType(this.defaultType);
    }
  	this.continue.emit();
    this.onClose();
  }

}
