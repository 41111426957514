// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
	width: 675px;
	border: 1px solid #E6E7E8;
	border-bottom: 4px solid #E6E7E8;
	position: relative;
	padding-top: 20px;
	padding-left: 18px;
	padding-right: 18px;
	background: #FFF;
	min-width: 675px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/base-container/base-container.component.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,yBAAyB;CACzB,gCAAgC;CAChC,kBAAkB;CAClB,iBAAiB;CACjB,kBAAkB;CAClB,mBAAmB;CACnB,gBAAgB;CAChB,gBAAgB;AACjB","sourcesContent":[".container {\n\twidth: 675px;\n\tborder: 1px solid #E6E7E8;\n\tborder-bottom: 4px solid #E6E7E8;\n\tposition: relative;\n\tpadding-top: 20px;\n\tpadding-left: 18px;\n\tpadding-right: 18px;\n\tbackground: #FFF;\n\tmin-width: 675px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
