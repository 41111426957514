import { Injectable } from '@angular/core';
import { BillingService,
	UserSubscriptions,
	License} from '@mitel/cloudlink-sdk/billing';

  export class SubscriptionItem {
    id: string;
    subscriptionNumber: string;
    details: string;
    orderDate: string;
    expired?: boolean;
    perpetual?: boolean;
    expiring: boolean;
    quantity: number;
  };

  export class SubscriptionDetails {
    productName: string;
    isConcurrent: boolean;
    totalCount: number;
    used: number;
    overProvisioned: number;
    tierList: SubscriptionDetails[];
    subsList: SubscriptionItem[];
  }

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private readonly billingService: BillingService;

  constructor() {
    this.billingService = new BillingService({});
  }

  getAvailableLicenses(): Promise<any> {
		console.log("call to get remaining licenses");
		let availableLicenses = {};
		let subDetailsList: SubscriptionDetails[] = [];

		return this.billingService.getLicencesByAccount({}).then( licenseCollection => {
			console.log("This is the data returned by the getLicensesByAccount", licenseCollection );

			if ( licenseCollection &&
				licenseCollection.count > 0 &&
				licenseCollection._embedded &&
				licenseCollection._embedded.items )
			{
				console.log("The licenses from the back end", licenseCollection._embedded.items);
				let licenseList: License[] = licenseCollection._embedded.items;

				for ( var i = 0; i < licenseList.length; i++ )
				{
					// only for user licenses not concurrent
					if ( licenseList[i].used != -1 )
					{
						//need the product name to determine we have an entry for it yet
						let licenseName = this.findLicenseName(licenseList[i]);
						if ( licenseName != undefined )
						{
							let subDetail = subDetailsList.find( item => {
								return item.productName == licenseName;
							});

							if ( subDetail == undefined ) // not in the list yet, add it
							{
								subDetail = new SubscriptionDetails();
								subDetail.productName = licenseName;

								subDetail.used = licenseList[i].used;
								subDetail.totalCount = licenseList[i].available;
								subDetail.overProvisioned = 0;
								subDetailsList.push( subDetail );
							}
							else
							{
								// there is an entry already, just add to the totals
								subDetail.used += licenseList[i].used;
								subDetail.totalCount += licenseList[i].available;
							}

						}
					}

				}
				// calculating available licenses
				for ( var m = 0; m < subDetailsList.length; m++ )
				{
					availableLicenses[subDetailsList[m].productName] = subDetailsList[m].totalCount - subDetailsList[m].used;
				}
				console.log("returning the available licenses ", availableLicenses);
				return availableLicenses;
			}
			else
			{
				console.log("returning the empty available licenses ", availableLicenses);
				return availableLicenses;
			}
		});
	}

	findLicenseName(licenseData: License | UserSubscriptions){
		if(licenseData.tags) {
			let licenseName = licenseData.tags.find(tag => tag.name === "tier" && tag.value != "none")?.value;
			if(!licenseName) {
				licenseName = licenseData.tags.find(tag => tag.name === "productName" && tag.value)?.value;
			}
			return licenseName;
		}
	}
}
