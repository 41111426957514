import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl} from '@angular/forms';
import { Subscription, throwError, Observable, of, firstValueFrom } from 'rxjs';
import { saveAs } from "file-saver";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VersionUpdateService } from '../../services/version-update.service';
import { SystemOptionsConfirmationComponent } from '../system-options-confirmation/system-options-confirmation.component';
import { AutoUpdateScheduleService } from '../../services/auto-update-schedule.service';
import { AdminService } from '../../services/admin.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { EchoService } from '../../services/echo.service';
import { SpinnerService } from '../../services/spinner.service';
import { CloudResourcesService } from '../../services/cloudResources.service';
import { FormDataService } from '../../services/form-data.service';
import { TooltipService } from '../../services/tooltip.service';
import { toastMsgTypes,
  advancedMessages,
  commonErrorMessages,
  pbxStatus } from '../../shared/constants';
import {
  PutFreeswitchTraceRequest,
  Pbxlink,
  CloudlinkGatewayStatus
} from '@mitel/cloudlink-sdk/tunnel';
import { Account, Site } from '@mitel/cloudlink-sdk/admin';

import * as jstz from 'jstz';
import * as moment from 'moment-timezone';
import { platformTypes } from '../../shared/constants';
import { TunnelService } from '../../services/tunnel.service';
import { FetchInterceptorService } from "../../services/fetch-interceptor.service";
import { map, retryWhen, delay, mergeMap, take, concatWith, finalize } from 'rxjs/operators';
import { Util } from '../../shared/Util';
import { ParentCommsService } from './../../services/parent-comms.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-advanced-form',
  templateUrl: './advanced-form.component.html',
  styleUrls: ['./advanced-form.component.css']
})
export class AdvancedFormComponent implements OnInit, OnDestroy {
  advancedForm: FormGroup;
  modalRef: NgbModalRef;

  autoUpdate = false;
  isDST = false;
  frequencies = [];
  weekdays = [];
  updateTimes = [];

  traceTime = 60;
  tracingCalls = false;
  downloadable = false;
  logsDownloadable = true;
  interval: any;

  versions = {
    cloudlink: {
      currentVersion: '',
      newVersion: '',
    },
    freeswitch: {
      currentVersion: '',
      newVersion: '',
    },
    tunnel: {
      currentVersion: '',
      newVersion: '',
    },
    patch: {
      currentVersion: '',
      newVersion: '',
    }
  };

  versionsUpToDate : boolean;

  zone = '';

  account: Account;
  site: Site;
  pbxLink: Pbxlink;
  accountTags: any = {};
  subscription: Subscription;

  isOverview = false;
  isSubmitting = false;
  sessionData: any;
  isAutoUpdateToggled = false;
  checkGatewayConnect = false;

  clock: any; // For setTimeout
  timeout: any;

  // Logs
  fileId: string;
  downloadUrl: string;
  timeoutTime: number;
  defaultTimeoutTime = 720000; // 12 minutes in ms

  platform: any;
  platformTypes = platformTypes;

  cloudlinkVersion : boolean = false;
  freeswitchVersion : boolean = false;
  tunnelVersion : boolean = false;

  requestInProgress = false;
  isReboot = false;
  isRestart = false;
  scheduledUpdate: any;
  
  isIframedSupport: boolean;

  constructor(private autoUpdateSvc: AutoUpdateScheduleService,
              private modalSvc: NgbModal,
              private versionSvc: VersionUpdateService,
              private adminSvc: AdminService,
              private authSvc: AuthenticationService,
              private accountSvc: AccountService,
              private spinnerSvc: SpinnerService,
              private route: ActivatedRoute,
              private router: Router,
              private alertSvc: AlertService,
              private cloudResourcesSvc: CloudResourcesService,
              private echoSvc: EchoService,
              private formDataSvc: FormDataService,
              private analyticsSvc: AnalyticsService,
              private tunnelSvc: TunnelService,
              private tooltipSvc: TooltipService,
              private interceptorService: FetchInterceptorService,
              private parentCommsService: ParentCommsService,
              private http: HttpClient
  ) { }

  async ngOnInit() {
    this.isIframedSupport = this.parentCommsService.isIframedSupportPage;
    this.formDataSvc.setSessionData = true;
    const formItem = sessionStorage.getItem('advanced-form');
    if (typeof formItem !== 'undefined' && formItem) {
      this.sessionData = JSON.parse(formItem);
    }
    this.frequencies = this.autoUpdateSvc.getFrequencies();
    this.weekdays = this.autoUpdateSvc.getWeekdays();
    this.updateTimes = this.autoUpdateSvc.getTimeOptions();

    this.subscription = this.interceptorService.requestInProgress.subscribe(attempting => {
      console.log('Function: requestInProgress, attempting: ', attempting);
      this.requestInProgress = attempting;
    });

    this.subscription.add(this.accountSvc.accountChanged
      .subscribe(async account => {
        console.log('Function: , : accountChanged', account);
        this.account = account;
        await this.echoSvc.getEcho();
        await this.setUpgradeSchedule();
        if (this.site) {
          console.log('Function: , : accountChanged CALL setVersions');
          firstValueFrom(this.setVersions())
            .then((response)=> {
              if (response && response.error) {
                console.log('Function: , err setVersions: ', response.error)
              }
            });
        }
      }));

    this.subscription.add(this.accountSvc.siteChanged
      .subscribe(async site => {
        console.log('Function: , : siteChanged', site);
        this.site = site;
        await this.echoSvc.getEcho();
        this.setDialingRules();
        await this.setUpgradeSchedule();
        if (this.account) {
          console.log('Function: , : siteChanged CALL setVersions');
          firstValueFrom(this.setVersions())
            .then((response)=> {
              if (response && response.error) {
                console.log('Function: , err setVersions: ', response.error)
              }
            });
        }
    }));

    this.subscription.add(this.accountSvc.accountTagsChanged
      .subscribe(async accountTags => {
        this.accountTags = accountTags;
        await this.echoSvc.getEcho();
      }));

    this.subscription.add(this.accountSvc.platformChanged.subscribe(platform => {
      this.platform = platform;
      this.scheduledUpdate = this.platform ? this.platform.capabilities['scheduled_container_update'] : false;
    }));

    this.account = this.accountSvc.getAccount();
    this.site = this.accountSvc.getSite();
    this.accountTags = this.accountSvc.getAccountTags();
    this.platform = this.accountSvc.getPlatform();
    this.scheduledUpdate = this.platform ? this.platform.capabilities['scheduled_container_update'] : false;
    this.echoSvc.setGatewayConnect('advanced');
    
    this.initForm();
    this.setFormWithSessionStorage();
    this.setDialingRules();
    await this.setUpgradeSchedule();
    if(this.account && this.site) {
      console.log('Function: ngOnInit, INITIAL SET VERSIONS: ');
      firstValueFrom(this.setVersions())
        .then((response)=> {
          if (response && response.error) {
            console.log('Function: , err setVersions: ', response.error)
          }
        });
    }
    this.setTimezone();

    this.route.params.subscribe((params: Params) => {
      this.isOverview = this.route.snapshot.url[0].path === 'overview';
    });
  }

  async setUpgradeSchedule() {
    if (this.sessionData) {
      return;
    }

    if (this.account && this.site) {
      try {
        const schedule = await this.tunnelSvc.getUpgradeSchedule(this.account.accountId, this.site.siteId);
        this.accountSvc.setUpgradeSchedule(schedule);
        this.cloudResourcesSvc.setCloudAppliance();
        if (<any>schedule.host_dayOfWeek && <any>schedule.host_dayOfWeek !== '') {
          this.autoUpdate = true;
          const baseTimezone = this.autoUpdateSvc.getBaseTimezone();
          this.setTimezone(); // Set this.zone to local

          let date: string;
          let days = <any>schedule.host_dayOfWeek.toString();
          days = days.split(',');
          if (days.length > 1) {
            date = this.autoUpdateSvc.getDate('Sun');
          } else {
            date = this.autoUpdateSvc.getDate(days[0]);
          }
          date = `${date} ${this.autoUpdateSvc.getTime(schedule.host_hour.toString())}`;
          const m = moment.tz(date, baseTimezone);
          const localMoment = m.clone().tz(this.zone);
          const localDay = localMoment.day();
          const localTime = localMoment.hour();

          this.isDST = localMoment.isDST();

          if (days.length > 1) {
            this.advancedForm.patchValue({
              autoUpdateSchedule: {
                frequency: 'Daily',
                time: localTime,
              }
            });
          } else {
            this.advancedForm.patchValue({
              autoUpdateSchedule: {
                frequency: 'Weekly',
                day: this.weekdays[localDay].value,
                time: localTime,
              }
            });
          }
        } else {
          this.autoUpdate = false;
        }
      } catch (reason) {
        if (typeof reason === 'string') {
          console.error('failed to get upgrade schedule', reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to get upgrade schedule', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to get sync schedule, Permission Error', JSON.stringify(reason));
          // show modal
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason instanceof Error) {
          console.error('failed to get sync schedule', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to get sync schedule', JSON.stringify(reason));
        }
      }
    }
  }

  setDialingRules() {
    if (this.sessionData) {
      return;
    }

    if (this.site && this.site.dialingRules) {
      const dialingRules = this.site.dialingRules;
      this.advancedForm.patchValue({
        'inboundDigits': dialingRules.inboundLeadingDigitsPattern || '',
        'outboundDigits': dialingRules.outboundLeadingDigitsPattern || '',
      });
    }
  }

  setTimezone() {
    if (this.site && this.site.location && this.site.location.timezone) {
      this.zone = this.site.location.timezone;
    } else {
      this.zone = jstz.determine().name();
    }
  }

  setVersions(): Observable<any> {
    return new Observable((observer) => {
      if (this.account && this.site && !this.requestInProgress) {
        this.tunnelSvc.getSystemVersion(this.account.accountId, this.site.siteId)
          .then(response => {
            if (response) {
              const versionRes = <any>response;

              for(var key in versionRes) {
                if(versionRes[key]['name']){
                  if(versionRes[key]['name'] === 'CloudLink'){
                    this.cloudlinkVersion = true;
                    this.versions.cloudlink.currentVersion = versionRes[key].local_version ?
                    versionRes[key].local_version : '(unknown)';
                    this.versions.cloudlink.newVersion = versionRes[key].cloud_version ?
                    versionRes[key].cloud_version : '(unknown)';
                  }
                  if(versionRes[key]['name'] === 'FreeSWITCH'){
                    this.freeswitchVersion = true;
                    this.versions.freeswitch.currentVersion = versionRes[key].local_version ?
                    versionRes[key].local_version : '(unknown)';
                    this.versions.freeswitch.newVersion = versionRes[key].cloud_version ?
                    versionRes[key].cloud_version : '(unknown)';
                  }
                  if(versionRes[key]['name'] === 'Tunnel'){
                    this.tunnelVersion = true;
                    this.versions.tunnel.currentVersion = versionRes[key].local_version ?
                    versionRes[key].local_version : '(unknown)';
                    this.versions.tunnel.newVersion = versionRes[key].cloud_version ?
                    versionRes[key].cloud_version : '(unknown)';
                  }
                }
             }

              this.versions.patch.currentVersion = versionRes.patch_level || '(unknown)';
              this.versions.patch.newVersion = versionRes.cloud_patch_level || '(unknown)';
              this.versionsUpToDate = this.versionSvc.isAllVersionsUpdated(versionRes, this.platform);
              observer.next(versionRes);
            } else {
              observer.next({error: {message: 'error: no response found'}});
            }
          })
          .catch(reason => {
            if(!this.requestInProgress) {
              if (typeof reason === 'string') {
                console.error('failed to get system version', reason);
              } else if (reason && reason.statusCode === 401) {
                console.error('failed to get system version', JSON.stringify(reason));
                this.authSvc.redirectToLogin();
              } else if (reason && reason.statusCode === 403) {
                console.error('failed to get system version', JSON.stringify(reason));
                this.formDataSvc.redirectToDashboardOrLogout();
              } else if (reason instanceof Error) {
                console.error('failed to get system version', reason.message);
                if (reason.message === commonErrorMessages.AUTH_ERROR) {
                  this.authSvc.redirectToLogin();
                }
              } else {
                console.error('failed to get system version', JSON.stringify(reason));
              }
              observer.next({error: reason});
            }
          })
      } else  {
        observer.next({error: {message: 'no account/site selected or request in progress'}});
      }
    })
  }


  isVersionNotMatch(name: string) {
    return (this.versions[name].newVersion !== '' &&
      this.versions[name].newVersion !== '(unknown)' &&
      this.versions[name].currentVersion !== '' &&
      this.versions[name].currentVersion !== this.versions[name].newVersion);
  }

  setFormWithSessionStorage() {
    if (this.sessionData) {
      this.autoUpdate = JSON.parse(sessionStorage.getItem('advanced-form-autoUpdate'));
      this.advancedForm.patchValue(this.sessionData);
    }
  }

  toggleAutoUpdate() {
    this.isAutoUpdateToggled = true;
    this.autoUpdate = !this.autoUpdate;
    this.formDataSvc.setCurrentFormDirty('advanced', this.isFormFieldEdited());
  }

  /**
   * Download Logs Process
   * 1. downloadLogs => tunnelSvc.createLogRequest, request to start collecting logs
   * 2. getLogCollectionStatus => tunnelSvc.getLogCreationStatus, call to determine the status of process of collecting logs
   *  if 200 is returned, then the app can request the log file to be uploaded in the s3
   * 3. getLogCollectionStatus => tunnelSvc.getLogRequest, request to start upload log to s3
   *  and returns the file metadata to retrieve later and get status on upload
   * 4. requestUploadFile => tunnelSvc.getLogUploadStatus, get the status of uploaded file.
   *  if 200, the the file is ready to be dowloaded with the url provided in step 3
   */

  clearLogsTimers(enableButton: boolean) {
    clearTimeout(this.clock);
    clearInterval(this.interval);
   
    this.logsDownloadable = enableButton;
  }

  async downloadLogs() {
    this.alertSvc.clearAlert();
    this.spinnerSvc.show();
    this.spinnerSvc.setMessage(advancedMessages.COLLECT_LOGS);
    this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Advanced' : 'Advanced', 'Download logs');
    try {
      this.clearLogsTimers(false);
      await this.tunnelSvc.createLogRequest(this.account.accountId, this.site.siteId); // POST /logs
      this.clock = setTimeout(() => {
        console.log('get log status');
        this.getLogCollectionStatus(true);
        this.clearLogsTimers(false);
      }, this.defaultTimeoutTime); // set timeout time for log collection status
      this.interval = setInterval(() => this.getLogCollectionStatus(false), 3000); // check log request every 3 seconds
    } catch (reason) {
      this.clearLogsTimers(true);
      this.spinnerSvc.hide();
      this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERR_COLLECT_LOGS);
      if (typeof reason === 'string') {
        console.error('failed to request collect logs', reason);
      } else if (reason && reason.statusCode === 401) {
        console.error('failed to request collect logs', JSON.stringify(reason));
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('failed to request collect logs, Permission Error', JSON.stringify(reason));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (reason instanceof Error) {
        console.error('failed to request collect logs', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to request collect logs', JSON.stringify(reason));
      }
    }
  }

  async getLogCollectionStatus(timedout: boolean) {
    try {
      const response = await this.tunnelSvc.getLogCreationStatus(this.account.accountId, this.site.siteId); // GET /logs/status
      if (response.status === 'completed') {
        // Start Upload log process
        this.clearLogsTimers(false);
        this.requestUploadFile();
      } else if (timedout) {
        this.spinnerSvc.hide();
        this.clearLogsTimers(true);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERR_LOG_TIMEOUT);
      }
    } catch (reason) {
      // Error collecting logs
      this.spinnerSvc.hide();
      this.clearLogsTimers(true);
      if (timedout) {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERR_LOG_TIMEOUT);
      } else {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERR_COLLECT_LOGS);
      }
      if (typeof reason === 'string') {
        console.error('failed to collect logs status', reason);
      } else if (reason && reason.statusCode === 401) {
        console.error('failed to collect logs status', JSON.stringify(reason));
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('failed to request collect logs status, Permission Error', JSON.stringify(reason))
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (reason instanceof Error) {
        console.error('failed to collect logs', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to collect logs status', JSON.stringify(reason));
      }
    }
  }

  async requestUploadFile() {
    this.spinnerSvc.setMessage(advancedMessages.COLLECT_LOGS);
    try {
      const response = await this.tunnelSvc.getLogRequest(this.account.accountId, this.site.siteId); // GET /logs
      console.log('response from get log request', response);
      if (response) {
        this.downloadUrl = response.url;
        this.fileId = response.id;
        this.timeoutTime = response.expires * 1000; // convert to milliseconds
        this.clock = setTimeout(() => {
          this.getFileStatus(true);
          this.clearLogsTimers(false);
        }, this.timeoutTime); // set timeout time from returned response
        this.interval = setInterval(() => this.getFileStatus(false), 3000); // check log request every 3 seconds
      }
    } catch (reason) {
      // Error requesting logs
      this.spinnerSvc.hide();
      this.clearLogsTimers(true);
      if (typeof reason === 'string') {
        console.error('failed to request for logs', reason);
      } else if (reason && reason.statusCode === 401) {
        console.error('failed to request for logs', JSON.stringify(reason));
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('failed to request collect logs, Permission Error', JSON.stringify(reason))
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (reason instanceof Error) {
        console.error('failed to request for logs', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to request for logs', JSON.stringify(reason));
      }
      this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERR_COLLECT_LOGS);
    }
  }

  async getFileStatus(timedout: boolean) {
    if (this.fileId) {
      try {
        const response = await this.tunnelSvc.getLogUploadStatus(this.account.accountId, this.site.siteId, this.fileId); // GET /logs/id/status
        if (response.status === 'completed') {
          // download file as blob and save to computer
          this.clearLogsTimers(true);
          let fileName = this.fileId;
          this.subscription.add(this.getDataAsBlob(this.downloadUrl).pipe(
            finalize(() => {
              this.fileId = undefined;
              this.downloadUrl = undefined;
              this.spinnerSvc.hide();
              this.alertSvc.setAlert(toastMsgTypes.INFO, advancedMessages.LOGS_COMPLETE);
            })
          ).subscribe(data => { 
            saveAs(data, fileName);
          }));
 
        } else if (timedout) {
          this.clearLogsTimers(true);
          this.spinnerSvc.hide();
          this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERR_LOG_TIMEOUT);
        }
      } catch (reason) {
        this.spinnerSvc.hide();
        this.clearLogsTimers(true);
        if (timedout) {
          console.log('uploading logs timedout');
          this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERR_LOG_TIMEOUT);
        } else {
          console.log('error uploading logs', reason);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERR_COLLECT_LOGS);
        }
        if (typeof reason === 'string') {
          console.error('failed to get upload logs status', reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to get upload logs status', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to get upload logs status, Permission Error', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason instanceof Error) {
          console.error('failed to get upload logs status', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to get upload logs status', JSON.stringify(reason));
        }
      }
    }
  }

  getDataAsBlob(url) {
    return this.http.get(url, { responseType: 'blob' });
  }

  async startTracingCalls() {
    this.tracingCalls = true;
    this.advancedForm.get('traceTime').disable();

    if (this.traceTime > 0) {
      this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Advanced' : 'Advanced', 'Trace calls');
      const traceParams: PutFreeswitchTraceRequest = {
        detail_trace_sec: this.traceTime,
        default_trace_level: 3,
        detailed_trace_level: 9
      };

      try {
        const response = await this.tunnelSvc.sendFreeswitchTraceRequest(this.account.accountId, this.site.siteId, traceParams);
        if (response) {
          if (response.error_log_trace || response.error_sip_trace) {
            console.error('Failed to send trace request');
            this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERR_SEND_TRACE);
          }
        }
      } catch (reason) {
        if (typeof reason === 'string') {
          console.error('failed to send trace request', reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to send trace request', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to get logs status, Permission Error', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason instanceof Error) {
          console.error('failed to send trace request', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to send trace request', JSON.stringify(reason));
        }
      }
    }

    this.interval = setInterval(() => {
      this.traceTime -= 1;
      if (this.traceTime <= 0) {
        this.traceTime = 0;
        this.stopTracingCalls();
      }
    }, 1000);
  }

  async stopTracingCalls() {
    this.tracingCalls = false;
    clearInterval(this.interval);

    const traceParams: PutFreeswitchTraceRequest = {
      detail_trace_sec: 0,
      default_trace_level: 3,
      detailed_trace_level: 9
    };

    await this.tunnelSvc.sendFreeswitchTraceRequest(this.account.accountId, this.site.siteId, traceParams);
    this.advancedForm.get('traceTime').enable();
    this.downloadable = true;
  }

  onRevertToFactoryDefault() {
    this.modalRef = this.modalSvc.open(SystemOptionsConfirmationComponent);

    const contentComponentInstance = this.modalRef.componentInstance as SystemOptionsConfirmationComponent;
    contentComponentInstance.title = 'Revert to Factory Default';
    contentComponentInstance.keyword = 'revert';
    contentComponentInstance.customerName = this.accountSvc.getAccount().name;
    contentComponentInstance.customerAddress = this.accountSvc.getNetworkConfig().lan1.ipv4['current-address'];
    contentComponentInstance.confirm.subscribe(async () => {
      try {
        await this.tunnelSvc.sendFactoryResetRequest(this.account.accountId, this.site.siteId);
        console.log('sent factory reset request');
        this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Advanced' : 'Advanced', 'Factory Default');
        this.modalRef.close();

        this.alertSvc.setAlert(toastMsgTypes.INFO, advancedMessages.FACTORY_RESET);
        this.formDataSvc.revertRoleAndNavigate();
      } catch (reason) {
        this.modalRef.close();
        if (typeof reason === 'string') {
          console.error('failed to send factory reset request', reason);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendFactoryResetRequest.failed');
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to send factory reset request', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to send factory reset request, Permission Error', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason instanceof Error) {
          console.error('failed to send factory reset request', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          } else if (reason.name === 'TimeoutError') {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendFactoryResetRequest' + reason.name);
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendFactoryResetRequest.failed');
          }
        } else {
          console.error('failed to send factory reset request', JSON.stringify(reason));
          this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendFactoryResetRequest.failed');
        }
      }
    });
  }

  onUpdate() {
    this.modalRef = this.modalSvc.open(SystemOptionsConfirmationComponent);

    const contentComponentInstance = this.modalRef.componentInstance;
    contentComponentInstance.title = 'Update';
    contentComponentInstance.keyword = 'update';
    contentComponentInstance.confirm.subscribe(async () => {
      try {
        this.isSubmitting = true;
        await this.tunnelSvc.sendUpgradeRequest(this.account.accountId, this.site.siteId);
        console.log('sent update request');
        this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Advanced' : 'Advanced', 'Update All Software');
        this.modalRef.close();

        this.alertSvc.setAlert(toastMsgTypes.INFO, advancedMessages.UPDATE);
        this.spinnerSvc.show();
        this.spinnerSvc.setMessage(advancedMessages.UPDATE_SYSTEM);

        await this.handleSetVersion()

      } catch (reason) {
        this.modalRef.close();
        this.isSubmitting = false;
        if (typeof reason === 'string') {
          console.error('failed to send update request', reason);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendUpgradeRequest.failed');
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to send update request', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to send update request, Permission Error', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason instanceof Error) {
          console.error('failed to send update request', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          } else if (reason.name === 'TimeoutError') {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendUpgradeRequest.' + reason.name);
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendUpgradeRequest.failed');
          }
        } else {
          console.error('failed to send update request', JSON.stringify(reason));
          this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendUpgradeRequest.failed');
        }
      }
    });
  }

  onReboot() {
    this.modalRef = this.modalSvc.open(SystemOptionsConfirmationComponent);

    const contentComponentInstance = this.modalRef.componentInstance;
    contentComponentInstance.title = 'Reboot';
    contentComponentInstance.keyword = 'reboot';
    contentComponentInstance.customerName = this.accountSvc.getAccount().name;
    contentComponentInstance.customerAddress = this.accountSvc.getNetworkConfig().lan1.ipv4['current-address'];
    contentComponentInstance.confirm.subscribe(async () => {
      try {
        await this.tunnelSvc.sendRebootRequest(this.account.accountId, this.site.siteId);
        console.log('sent reboot request');
        this.isReboot = true;
        this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Advanced' : 'Advanced', 'Reboot');
        this.modalRef.close();
        this.alertSvc.setAlert(toastMsgTypes.INFO, advancedMessages.REBOOT);
        this.spinnerSvc.show();
        this.spinnerSvc.setMessage(advancedMessages.REBOOT_SYSTEM);

        await this.handleSetVersion()

      } catch (reason) {
        this.modalRef.close();
        if (typeof reason === 'string') {
          console.error('failed to send reboot request', reason);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendRebootRequest.failed');
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to send reboot request', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason instanceof Error) {
          console.error('failed to send reboot request', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          } else if (reason.name === 'TimeoutError') {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendRebootRequest.' + reason.name);
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendRebootRequest.failed');
          }
        } else {
          console.error('failed to send reboot request', JSON.stringify(reason));
          this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendRebootRequest.failed');
        }
      }
    });
  }

  onRestart() {
    this.modalRef = this.modalSvc.open(SystemOptionsConfirmationComponent);

    const contentComponentInstance = this.modalRef.componentInstance;
    contentComponentInstance.title = 'Restart';
    contentComponentInstance.keyword = 'restart';
    contentComponentInstance.customerName = this.accountSvc.getAccount().name;
    let networkConfig = this.accountSvc.getNetworkConfig();
    contentComponentInstance.customerAddress = networkConfig?.lan1.ipv4['address'] ?? networkConfig?.lan1.ipv4['current-address']; //static or dhcp
    contentComponentInstance.confirm.subscribe(async () => {
      try {
        await this.tunnelSvc.sendRestartRequest(this.account.accountId, this.site.siteId);
        console.log('sent restart request');
        this.isRestart = true;
        this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Advanced' : 'Advanced', 'Restart');
        this.modalRef.close();
        this.alertSvc.setAlert(toastMsgTypes.INFO, advancedMessages.RESTART);
        this.spinnerSvc.show();
        this.spinnerSvc.setMessage(advancedMessages.RESTART_SYSTEM);

        await this.handleSetVersion()
      } catch (reason) {
        this.modalRef.close();
        if (typeof reason === 'string') {
          console.error('failed to send restart request', reason);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendRestartRequest.failed');
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to send restart request', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason instanceof Error) {
          console.error('failed to send restart request', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          } else if (reason.name === 'TimeoutError') {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendRestartRequest.' + reason.name);
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendRestartRequest.failed');
          }
        } else {
          console.error('failed to send restart request', JSON.stringify(reason));
          this.alertSvc.setAlert(toastMsgTypes.ERROR, 'sendRestartRequest.failed');
        }
      }
    });
  }

  async handleSetVersion() {
    await new Promise(resolve => setTimeout(resolve, 60000)); // wait for 1 mins to start checking versions
    this.interceptorService.skipInterceptions = true;
    this.isSubmitting = true;

    const handler = this.setVersions().pipe(
      map(response => {
        if (response && (response.error || !this.versionSvc.isAllVersionsUpdated(response, this.platform))) {
          throw response;
        }
        return response;
      }),
      retryWhen(setVersionResult => {
        return setVersionResult.pipe(
          mergeMap((setVersionResult: any) => {
            if (setVersionResult.error || !this.versionSvc.isAllVersionsUpdated(setVersionResult, this.platform)) {
              return of(setVersionResult).pipe(delay(15000));
            }
            return throwError(() => new Error('No retry'));
          }),
          take(80), // retry for 20 minutes
          concatWith(throwError(() => new Error('Sorry, there was an error (after 240 retries)')))
        )
      })
    );

    this.subscription.add(handler.subscribe(
      () => {
        console.log('Function: , setVersion success');
        this.isSubmitting = false;
        this.getEcho();
      }, error => {
        this.getEcho();
        this.isSubmitting = false;
        this.interceptorService.skipInterceptions = false;
        console.log('Function: , setVersion error: ', error);
      }));
  }

  async getEchoWithThreeTries(accountId: string, siteId: string) {
    try {
        const result = await this.tunnelSvc.getEcho(accountId, siteId);
        return result;
    } catch(error) {
        try {
          await Util.timeout(50);
          const result = await this.tunnelSvc.getEcho(accountId, siteId);
          return result;
        } catch( error1) {
          try {
            await Util.timeout(50);
            const result = await this.tunnelSvc.getEcho(accountId, siteId);
            return result;
          } catch( error2) {
            throw(error2);
          }
        }
      }
    }

  async getEcho() {
    this.account = this.accountSvc.getAccount();
    this.site = this.accountSvc.getSite();
    this.accountTags = this.accountSvc.getAccountTags();

    if (this.account && this.site && this.accountTags) {
      try {
        this.isSubmitting = true;
        const result = await this.getEchoWithThreeTries(this.account.accountId, this.site.siteId);
        this.interceptorService.skipInterceptions = false;
        if (result.data === 'ECHO..echo..echo') {
          console.log('Echo successful');
          this.checkServiceStatus();
        } else {
          this.updateGatewayConnectionTag(false, `connect fail: ${result.data && result.data !== '' ? result.data : 'unknown'}`);
          sessionStorage.setItem('gateway-connection', JSON.stringify(false));
          this.alertSvc.setAlert(toastMsgTypes.ERROR, `sendRebootRequest.failed`);
        }
        this.isSubmitting = false;
      } catch (reason) {
        this.isSubmitting = false;
        if (typeof reason === 'string') {
          this.updateGatewayConnectionTag(false, `connect fail: ${reason && reason !== '' ? reason : 'unknown'}`);
          sessionStorage.setItem('gateway-connection', JSON.stringify(false));
          this.alertSvc.setAlert(toastMsgTypes.ERROR, `sendRebootRequest.failed`);
        } else if (reason && reason.statusCode === 401) {
          console.error('echo failed', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('echo failed', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          this.updateGatewayConnectionTag(false,
            `connect fail: ${reason && reason.body && reason.body.message !== '' ? reason.body.message : 'unknown'}`);
          sessionStorage.setItem('gateway-connection', JSON.stringify(false));
          this.alertSvc.setAlert(toastMsgTypes.ERROR, `sendRebootRequest.failed`);
        }
      }
    }
  }

  async checkServiceStatus() {
    this.interceptorService.skipInterceptions = true;
    this.isSubmitting = true;

    const serviceStatusHandler = this.getCloudlinkStatus().pipe(
      map(response => {
        console.log('Function: , response: ', response);
        if (response && (response.error || !this.areServicesReady(response))) {
          throw response;
        }
        return response;
      }),
      retryWhen(statusResult => {
        return statusResult.pipe(
          mergeMap((statusResult: any) => {
            console.log('Function: , statusResult: ', statusResult);
            if (statusResult.error || !this.areServicesReady(statusResult)) {
              return of(statusResult).pipe(delay(5000)); // check cloudlink status every 5 seconds
            }
            return throwError(() => new Error('No retry'));
          }),
          take(60), // retry for 5 minutes
          concatWith(throwError(() => new Error('Sorry, there was an error (after 60 retries)')))
        )
      })
    );

    this.subscription.add(serviceStatusHandler.subscribe(() => {
      console.log('Function: , getCloudlinkStatus success');
      this.isSubmitting = false;
      this.interceptorService.skipInterceptions = false;
      if (this.isReboot) {
        this.isReboot = false;
        this.alertSvc.setAlert(toastMsgTypes.INFO, advancedMessages.REBOOT_SUCCESS);
      } else if (this.isRestart) {
        this.isRestart = false;
        this.alertSvc.setAlert(toastMsgTypes.INFO, advancedMessages.RESTART_SUCCESS);
      }else {
        this.alertSvc.setAlert(toastMsgTypes.INFO, advancedMessages.UPDATE_SUCCESS);
      }
      sessionStorage.setItem('gateway-connection', JSON.stringify(true));
      this.updateGatewayConnectionTag(true);
    }, error => {
      this.alertSvc.clearAlert();
      this.isSubmitting = false;
      this.interceptorService.skipInterceptions = false;
      this.isReboot = false;
      this.isRestart = false;
      sessionStorage.setItem('gateway-connection', JSON.stringify(true));
      this.updateGatewayConnectionTag(true);
      console.log('Function: , getCloudlinkStatus error: ', error);
    }));
  }

  getCloudlinkStatus(): Observable<any> {
    this.account = this.accountSvc.getAccount();
    this.site = this.accountSvc.getSite();

    return new Observable(observer => {
      this.tunnelSvc.getCloudlinkStatus(this.account.accountId, this.site.siteId)
        .then(result => observer.next(result))
        .catch(reason => observer.next({error: reason}))
    });
  }

  private areServicesReady(status: CloudlinkGatewayStatus) {
    console.log('Function: areServicesReady, this.isResponseGatewayStatus(status): ', AdvancedFormComponent.isResponseGatewayStatus(status));
    if (AdvancedFormComponent.isResponseGatewayStatus(status) && status.ngrok) {
      if (this.echoSvc.shouldGetPbxData(this.accountTags, true)) {
        if(status.pbx_links !== 'up') {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  static isResponseGatewayStatus(status: any): status is CloudlinkGatewayStatus {
    return (<CloudlinkGatewayStatus>status).cloudgateway !== undefined
  }

  async updateGatewayConnectionTag(connected: boolean, reason?: string) {
    try {
      const tagParams = await this.echoSvc.getAccountTagsParameters(connected, reason);
      const tags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
      this.accountSvc.setAccountTags(tags);
      this.spinnerSvc.hide();
      if (!connected) {
        this.router.navigateByUrl(`/accounts/${this.account.accountId}/gateway-connection-error`);
      } else if (tags['pbx-status']['status'] === pbxStatus.DOWN) {
        this.router.navigateByUrl(`/accounts/${this.account.accountId}/pbx-connection-error`);
      } else {
        this.router.navigateByUrl(`/accounts/${this.account.accountId}/sites/${this.site.siteId}/advanced`);
      }
    } catch (error) {
      if (typeof error === 'string') {
        console.error('failed to update gateway connection tag', error);
      } else if (error && error.statusCode === 401) {
        console.error('failed to update gateway connection tag', JSON.stringify(error));
        this.authSvc.redirectToLogin();
      } else if (typeof error.text === 'function') {
        error.text().then(res => {
          console.error('failed to update gateway connection tag', res);
        }).catch(err => console.error('failed to update gateway connection tag', err));
      } else if (error instanceof Error) {
        console.error('failed to update gateway connection tag', error.message);
        if (error.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to update gateway connection tag', error);
      }
      this.spinnerSvc.hide();
    }
  }

  async onSubmit() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview Advanced' : 'Advanced', 'Save');
    this.alertSvc.clearAlert();
    if (!this.isOverview) {
      window.scrollTo(0, 0);
    }
    this.sessionData = null;
    this.isSubmitting = true;
    const promises = [];

    const siteUpdateParams = this.getSiteUpdateParams();
    promises.push(this.adminSvc.updateSite(this.account.accountId, this.site.siteId, siteUpdateParams));

    const updateScheduleParams = this.getUpdateScheduleParams();
    promises.push(this.tunnelSvc.updateUpgradeSchedule(this.account.accountId, this.site.siteId, updateScheduleParams));

    try {
      const response = await Promise.all(promises);
      this.formDataSvc.removeFormData('advanced');
      this.isAutoUpdateToggled = false;
      this.advancedForm.markAsPristine();
      console.log(response);
      this.accountSvc.setSite(response[0]);
      this.isSubmitting = false;
      this.alertSvc.setAlert(toastMsgTypes.INFO, advancedMessages.UPDATE_ADVANCED);
    } catch (reason) {
      this.isSubmitting = false;
      if (typeof reason === 'string') {
        console.error('failed to update advanced settings', reason);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, reason);
      } else if (reason && reason.statusCode === 401) {
        console.error('failed to update advanced settings', reason);
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('failed to update advanced settings', reason);
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (typeof reason.text === 'function') {
        reason.text().then(res => {
          console.error('failed to update advanced settings', res);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERROR_ADVANCED);
        });
      } else if (reason instanceof Error) {
        console.error('failed to update advanced settings', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to update advanced settings', reason);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, advancedMessages.ERROR_ADVANCED);
      }
    }
  }

  onCancel() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview Advanced' : 'Advanced', 'Cancel');
    this.formDataSvc.redirectToDashboard(undefined, undefined, 'account'); // redirect to accounts page when clicked if iframed
  }

  initForm() {
    this.advancedForm = new FormGroup({
      inboundDigits: new FormControl(''),
      outboundDigits: new FormControl(''),
      traceTime: new FormControl(null),

      autoUpdateSchedule: new FormGroup({
        frequency: new FormControl('Daily'),
        day: new FormControl(this.weekdays[0].value),
        time: new FormControl(this.updateTimes[2].value)
      }),
    });
    this.autoUpdate = false;
    this.onChanges();
  }

  onChanges() {
    this.advancedForm.valueChanges.subscribe(val => {
      this.formDataSvc.setCurrentFormDirty('advanced', this.isFormFieldEdited());
    });
  }

  getSiteUpdateParams() {
    const formValues = this.advancedForm.value;
    return {
      dialingRules: {
        inboundLeadingDigitsPattern: formValues.inboundDigits,
        outboundLeadingDigitsPattern: formValues.outboundDigits,
      }
    };
  }

  getUpdateScheduleParams() {
    const formValues = this.advancedForm.value;
    let params = {};

    if (this.autoUpdate) {
      const baseTimezone = this.autoUpdateSvc.getBaseTimezone();
      this.setTimezone();

      let date: string;
      const time = formValues.autoUpdateSchedule.time;
      const day = formValues.autoUpdateSchedule.day;
      if (formValues.autoUpdateSchedule.frequency === 'Daily') {
        date = `${this.autoUpdateSvc.getDate(this.weekdays[0].value)} ${this.autoUpdateSvc.getTime(time)}`;
      } else {
        date = `${this.autoUpdateSvc.getDate(day)} ${this.autoUpdateSvc.getTime(time)}`;
      }

      const m = moment(date).tz(this.zone, true);
      const convertedMoment = m.clone().tz(baseTimezone);

      let days: any;
      if (formValues.autoUpdateSchedule.frequency === 'Daily') {
        days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      } else {
        days = [this.weekdays[convertedMoment.day()].value];
      }

      params = {
        'host_onBoot': true,
        'host_hour': convertedMoment.hour(),
        'host_minute': '0',
        'host_dayOfWeek': days,
        'container_onBoot': true,
        'container_hour': convertedMoment.hour(),
        'container_minute': '0',
        'container_dayOfWeek': days,
      }
    } else {
      params = {
        'host_onBoot': true,
        'host_hour': '',
        'host_minute': '',
        'host_dayOfWeek': '',
        'container_onBoot': true,
        'container_hour': '',
        'container_minute': '',
        'container_dayOfWeek': ''
      }
    }

    return params;
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  infoIconItem(labelName: string): any {
    return this.tooltipSvc.getTooltipItem(labelName);
  }

  isFormFieldEdited() {
    if(this.advancedForm){
      this.advancedForm.get('traceTime').markAsPristine(); // Set traceTime to pristine since we don't save it
    }
    return (this.advancedForm && this.advancedForm.dirty) || this.isAutoUpdateToggled;
  }

  ngOnDestroy() {
    if (this.isFormFieldEdited()) {
      if(this.formDataSvc.setSessionData){
        sessionStorage.setItem('advanced-form', JSON.stringify(this.advancedForm.value));
        sessionStorage.setItem('advanced-form-autoUpdate', JSON.stringify(this.autoUpdate));
      }
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
