import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Router } from '@angular/router';
import { ParentCommsService } from '../services/parent-comms.service';
import { AuthenticationService } from './authentication.service';

  @Injectable()
  export class PartnerGuard {
    constructor(
        private appSvc: AppService,
        private parentCommsService: ParentCommsService,
        private authSvc: AuthenticationService,
        private router: Router) {}
  
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        const claims = this.appSvc.getClaims();
        const iframed = this.parentCommsService.isInsideIframe();
        /*
         Normally for a Partner_Admin, they will assume role to get to any account-specific page.
         Their claims will change to Account_Admin only if they did a refresh on one of these pages.
        */

        if( !claims || !(claims.role === 'PARTNER_ADMIN' || claims.role === 'ACCOUNT_ADMIN')){
            /* Ultimately need to LOGOUT
             - On app load: Auth guard will catch this and logout
             - On refresh: Auth guard will catch this and logout
             - On internal navigation to '': should be coming from form-data service.
                 - something went wrong with revert role
                 - e.g if you have fake tokens, claims wil be null after revert role
                 - e.g change the original token to have fake access/refresh codes then try to nav to dash
            */
            console.log("Partner Guard: No claims - logging out");
            this.authSvc.redirectToLogin();
            return false;
        }
        else if ( claims.role === 'PARTNER_ADMIN') {
            /*
             - On app load: go to dash. no need to revert role
             - On refresh: Already on partner dash, no need to revert role
             - On internal navigation: should be coming from from-data service would have already reverted role.
             - On internal navigation + iframed: form-data servic will exit iframe
             */
            console.log("PARTNER GUARD: ", true);
            return true;
        } else {
            /* IF IFRAMED:
             - on app load: navigation.service.ts will control where the user goes
             - on refresh: exits the iframe
             - on internal navigation to '': form-data service will exit iframe
            */
            /* IF NOT IFRAMED and ACCOUNT_ADMIN:
             - on app load: take them to Cusomter page
             - on refresh: they haven't revert role yet so stay in account they were looking at
             - on internal navigation to '': should be coming from form-data service and would not be ACCOUNT_ADMIN
             unless it really is an ACCOUNT_ADMIN - so take them to customer page
            */
            if(!iframed && claims.accountId && claims.accountId !=="" && claims.role === "ACCOUNT_ADMIN"){
                console.log("Routing to Customer page from partner guard");
                this.router.navigateByUrl(`/accounts/${claims.accountId}`);
            }
            console.log("PARTNER GUARD: ", false);
            return false;
        }
    }
  }  
