import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { Subscription } from 'rxjs';
import { ProgressService } from '../../services/progress.service';
import { AccountService } from '../../services/account.service';
import { AdminService } from '../../services/admin.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../services/alert.service';
import { FormDataService } from '../../services/form-data.service';
import { commonErrorMessages, pbxStatus, pbxMessages, platformTypes } from '../../shared/constants';
import { TunnelService } from '../../services/tunnel.service';
import { Pbxlink } from '@mitel/cloudlink-sdk/tunnel';
import { Utils, Odata } from '@mitel/cloudlink-sdk';
import { AppService } from '../../services/app.service';
import { ParentCommsService } from '../../services/parent-comms.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../shared/language.enum';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
    accountId: string;
    siteId = 'new';
    pbxId = 'new';
    accountTags = {};
    partnerClaims: boolean;
    accountTagsSubscription: Subscription;
    accountSubscription: Subscription;
    siteSubscription: Subscription;
    pbxSubscription: Subscription;
    pbxLink: Pbxlink;
    platform: any;
    platformTypes = platformTypes;
    platformSubscription: Subscription;
    isIframed: boolean;
    isIframedSupport: boolean;
    isAccountAdmin: boolean;
    languageSubscription: Subscription;
    currentLanguage: Language;

    constructor(public router: Router,
                private appSvc: AppService,
                private progressSvc: ProgressService,
                private route: ActivatedRoute,
                private accountSvc: AccountService,
                private adminSvc: AdminService,
                private authSvc: AuthenticationService,
                private alertSvc: AlertService,
                private userSvc: UserService,
                private formDataSvc: FormDataService,
                private tunnelSvc: TunnelService,
                private translateSvc: TranslateService,
                private parentCommsService: ParentCommsService) {
    };

    ngOnInit() {
        this.isIframed = this.parentCommsService.isInsideIframe();
        this.isIframedSupport = this.parentCommsService.isIframedSupportPage;
        const browserLang = this.translateSvc.getBrowserLang();
        if(browserLang == 'en'){
          const culturalLang = this.translateSvc.getBrowserCultureLang();
          this.currentLanguage = culturalLang.match(/en-GB/)? Language[culturalLang] : Language.en;
        }
        else
          this.currentLanguage = browserLang.match(/fr|de|es|it|nl|pt/)
            ? Language[browserLang]
            : Language.en;
        this.isAccountAdmin = this.appSvc.isAccountAdmin();
        this.accountTagsSubscription = this.accountSvc.accountTagsChanged
                                    .subscribe(accountTags => {
                                        this.accountTags = accountTags;
                                    });
        this.accountSubscription = this.accountSvc.accountChanged
                                    .subscribe(account => {
                                        if (account) {
                                            this.accountId = account.accountId;
                                        }
                                    });
        this.siteSubscription = this.accountSvc.siteChanged
                                    .subscribe(site => {
                                        if (site) {
                                            this.siteId = site.siteId;
                                        }
                                    });

        this.pbxSubscription = this.accountSvc.pbxChanged
                                    .subscribe(pbx => {
                                        if (pbx) {
                                            this.pbxId = pbx._id;
                                            this.pbxLink = pbx;
                                        }
                                    });
        this.platformSubscription = this.accountSvc.platformChanged
                                        .subscribe(platform => this.platform = platform);
        this.languageSubscription = this.translateSvc.onLangChange
                                        .subscribe( () => {
                                            if (this.isIframed) {
                                                this.currentLanguage = Language[this.translateSvc.currentLang];
                                            }
                                        });


        // if assumed role, then show the partner button to go back to partner dashboard
        if (this.appSvc.isAssumedRole()) {
            this.authSvc.getPartnerClaims().then(claims => {
                if (claims == null) {
                    this.partnerClaims = false;
                } else {
                    this.partnerClaims = true;
                }
            });
        } else {
            const claims = this.appSvc.getClaims();
            if (claims.role === 'PARTNER_ADMIN') {
                this.partnerClaims = true;
            } else {
                this.partnerClaims = false;
            }
        }

        this.route.params
         .subscribe(
             async (params: Params) => {
                if (this.router.parseUrl(this.router.url).root.children['primary']) {
                    const routeSegments = this.router.parseUrl(this.router.url).root.children['primary'].segments;
                    this.accountId = routeSegments[1].path;
                    if (routeSegments[3]) {
                        this.siteId = routeSegments[3].path;
                    }
                    if (routeSegments[5]) {
                        this.pbxId = routeSegments[5].path;
                    }
                }

                console.log('getting accountTags');

                this.accountTags = this.accountSvc.getAccountTags();

                this.updateIds();
             }
        );
    }

    setClasses(navigationStage: string) {
        return {
            'complete' : this.isStepComplete(navigationStage),
            'error': this.isStepError(navigationStage),
            'disabled': this.isDisabled(navigationStage),
            'navicon': true
        };
    }

    isStepComplete(navigationStage: string): boolean {
        if (this.accountTags && this.accountTags['on-board-progress']) {
            const lastStepIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress']['name']);
            const navigationStageIdx = this.progressSvc.getProgressIdx(navigationStage);
            return navigationStageIdx <= lastStepIdx;
        }

        return false;
    }

    isStepError(navigationStage: string): boolean {
        if (this.accountTags && this.accountTags['on-board-progress']) {
            if (this.accountTags['on-board-progress']['name'] === navigationStage &&
                    this.accountTags['on-board-progress']['succeeded'] === false) {
                return true;
            }
        }

        if (navigationStage !== 'account' && navigationStage !== 'site') {
            if (this.accountTags && this.accountTags['gateway-connection']) {
                if (navigationStage === 'pbx' &&
                    this.accountTags['gateway-connection']['pbxConnected'] === false) {
                    return true;
                } else if (!this.accountTags['gateway-connection']['connected']) {
                    return true;
                }
            }
        }

        if (navigationStage === 'pbx' || navigationStage === 'connect' || navigationStage === 'officelink') {
            if (this.accountTags && this.accountTags['pbx-status']) {
                if (this.accountTags['pbx-status']['status'] === pbxStatus.DOWN || this.accountTags['pbx-status']['status'] === pbxStatus.MISSING) {
                    return true;
                }
            }
        }

        return false;
    }

    isDisabled(navigationStage: string): boolean {
        if ((!this.accountTags || !this.accountTags['on-board-progress']) && navigationStage === 'account') {
            return false;
        }

        // Disable connect form when pbx has connect_error
        if (navigationStage === 'connect' && this.pbxLink && this.pbxLink['connect_error'] && this.pbxLink['connect_error'] !== '') {
            return true;
        }

        if (navigationStage === 'site' && this.accountTags && this.accountTags['tunnel-creds'] === false) {
            return true;
        }

        if (this.accountTags && this.accountTags['on-board-progress']) {
            const lastStepIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress']['name']);
            const navigationStageIdx = this.progressSvc.getProgressIdx(navigationStage);

            if (navigationStage === 'advanced') {
                const siteStepIdx = this.progressSvc.getProgressIdx('site');
                if (this.accountTags['on-board-progress']['succeeded']) {
                    return siteStepIdx > lastStepIdx;
                } else {
                    return siteStepIdx >= lastStepIdx;
                }
            } else if (this.accountTags['on-board-progress']['name'] === 'connect' && this.accountTags['on-board-progress']['succeeded']) {
                return false;
            } else if (!this.accountTags['on-board-progress']['succeeded']) {
                return navigationStageIdx > lastStepIdx;
            } else {
                return navigationStageIdx > lastStepIdx + 1;
            }
        }

        return true;
    }

    isError(): boolean {
        if (this.accountTags && this.accountTags['gateway-connection']
            && !this.accountTags['gateway-connection']['connected']) {
            return true;
        }

        if (this.accountTags && this.accountTags['pbx-status']
            && this.accountTags['pbx-status']['status'] === pbxStatus.DOWN) {
            return true;
        }

        return false;
    }

    isLongText(navItem: string): boolean {
        let specialCase = navItem === 'deployment' && (this.currentLanguage === Language.pt || this.currentLanguage === Language.de)

        if(this.translateSvc.instant('nav.' + navItem).length > 12) {
            return !specialCase;
        }
        return false;
    }

    updateIds() {

        if (!this.accountId || this.accountId === 'new') {
            this.accountSvc.setAccount(null);
            this.accountSvc.setAccountTags(null);
            this.accountSvc.setSite(null);
            this.accountSvc.setPbxlink(null);
            return;
        }
        this.adminSvc.getAccount(this.accountId)
            .then(async account => {
                this.accountId = account.accountId;

                 // account must be set after setting partner and adminContacts
                 this.accountSvc.setAccount(account);

                try {
                    const tags = await this.adminSvc.getAccountTags(account.accountId);
                    this.accountSvc.setAccountTags(tags);
                    console.log('account tags', this.accountSvc.getAccountTags());
                } catch (error) {
                    if (typeof error === 'string') {
                        console.error('failed to get account tags', error);
                    } else if (error && error.statusCode === 401) {
                        console.error('failed to get account tags', error)
                        this.authSvc.redirectToLogin();
                    } else if (typeof error.text === 'function') {
                        error.text().then(res => console.error('failed to get account tags', res));
                    } else if (error instanceof Error) {
                        console.error('failed to get account tags', error.message);
                        if (error.message === commonErrorMessages.AUTH_ERROR) {
                            this.authSvc.redirectToLogin();
                        }
                    } else {
                        console.error('failed to get account tags', error)
                    }
                    this.accountSvc.setAccountTags(null);
                }

                try {
                    const siteCollection = await this.adminSvc.getSites(account.accountId);

                    if (siteCollection && siteCollection.count > 0 && siteCollection._embedded && siteCollection._embedded.items) {
                        const sites = siteCollection._embedded.items;
                        if (sites[0]) {
                            this.siteId = sites[0].siteId;
                            this.accountSvc.setSite(sites[0]);
                            await this.getPlatform();
                            if (!this.platform || (this.platform.capabilities && this.platform.capabilities.read_network_settings)) {
                                await this.getNetworkSettings();
                            }
                        }
                    } else {
                        this.accountSvc.setSite(null);
                    }
                } catch (error) {
                    if (typeof error  === 'string') {
                        console.error('failed to get sites', error);
                    } else if (error && error.statusCode === 401) {
                        console.error('failed to get sites', error);
                        this.authSvc.redirectToLogin();
                    } else if (typeof error.text === 'function') {
                        error.text().then(res => console.error('failed to get sites', res));
                    } else if (error instanceof Error) {
                        console.error('failed to get sites', error.message);
                        if (error.message === commonErrorMessages.AUTH_ERROR) {
                            this.authSvc.redirectToLogin();
                        }
                    } else {
                        console.error('failed to get sites', error);
                    }
                    this.accountSvc.setSite(null);
                }

                await this.getPbxData();
            }, reason => {
                if (reason && reason.statusCode === 401) {
                    console.error('failed to get account', JSON.stringify(reason));
                    this.authSvc.redirectToLogin();
                } else if (reason && reason.text && typeof reason.text === 'function') {
                    reason.text().then(res => console.error('failed to get account', res));
                } else if (reason instanceof Error) {
                    console.error('failed to get account', reason.message);
                    if (reason.message === commonErrorMessages.AUTH_ERROR) {
                        this.authSvc.redirectToLogin();
                    }
                } else {
                    console.error('failed to get account', reason);
                }
                this.accountSvc.setAccount(null);
                this.accountSvc.setAccountTags(null);
            }).catch(err => console.error('failed to get account', err));

    }

    async getPbxData() {
        if (this.accountId && this.accountId !== 'new'
            && this.siteId && this.siteId !== 'new'
            && this.accountTags && this.accountTags['on-board-progress']) {
            const lastStepIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress']['name']);
           
            const tagParams = this.accountTags;
            // get the pbxLinkinfo if the user has tried to add a pbx with success or fail
            if (lastStepIdx >= 3) {
                try {
                    const p = await this.tunnelSvc.getPbxlinks(this.accountId, this.siteId);
                    tagParams['pbx-status'] = { status: pbxStatus.MISSING };
                    if (p) {
                      const pbxs = Utils.getItemsFromCollection<Pbxlink>(p);
                      if (pbxs[0]) {
                        this.pbxId = pbxs[0]._id;
                        this.accountSvc.setPbxlink(pbxs[0]);
                        tagParams['pbx-status'] = { status: pbxStatus.UP };
                      }
                    }

                    await this.updateAccountTag(this.accountId, tagParams);
                } catch (reason) {
                    let errMsg = '';
                    tagParams['pbx-status'] = { status: pbxStatus.DOWN };
                    if (reason.statusCode === 503) {
                        tagParams['pbx-status']['reason'] = pbxMessages.CANNOT_CONNECT_PBX;
                    } else if (reason.statusCode === 504) {
                        tagParams['pbx-status']['reason'] = pbxMessages.SYSTEM_DATA_TIMEOUT;
                    } else if (typeof reason === 'string') {
                        console.error('failed to get PBX links', reason);
                        tagParams['pbx-status']['reason'] = (reason && reason !== '') ? reason : 'common.unknown';
                    } else if (reason && reason.statusCode === 401) {
                        console.error('failed to get PBX links', reason);
                        this.authSvc.redirectToLogin();
                    } else if (reason instanceof Error) {
                        console.error('failed to get PBX links', reason.message);
                        if (reason.message === commonErrorMessages.AUTH_ERROR) {
                            this.authSvc.redirectToLogin();
                        } else if (reason.message === commonErrorMessages.TYPE_ERROR) {
                            tagParams['pbx-status']['reason'] = pbxMessages.ERR_GET_PBX;
                        } else {
                            tagParams['pbx-status']['reason'] = (reason.message && reason.message !== '' ?
                                                                 reason.message : 'common.unknown');
                        }
                    } else {
                        if (reason && reason.body && reason.body.name) {
                            errMsg = reason.body.name;
                            tagParams['pbx-status']['reason'] = (errMsg && errMsg !== '') ? ('getPbxlinks.' + errMsg) : 'common.unknown';
                        }
                    }
                    await this.updateAccountTag(this.accountId, tagParams);
                    this.accountSvc.setPbxlink(null);
                }
            }
        }
    }

    async getNetworkSettings() {
        if (this.accountId && this.accountId !== 'new'
            && this.siteId && this.siteId !== 'new') {
            try {
                const response = await this.tunnelSvc.getNetworkSettings(this.accountId, this.siteId);
                this.accountSvc.setNetworkConfig(response.result);
            } catch (reason) {
                if (typeof reason === 'string') {
                    console.error('failed to get network settings', reason);
                } else if (reason && reason.statusCode === 401) {
                    console.log('failed to get network settings', JSON.stringify(reason));
                    this.authSvc.redirectToLogin();
                } else if (reason instanceof Error) {
                    console.error('failed to get network settings', reason.message);
                    if (reason.message === commonErrorMessages.AUTH_ERROR) {
                        this.authSvc.redirectToLogin();
                    }
                } else {
                    console.log('failed to get network settings', JSON.stringify(reason));
                }
                this.accountSvc.setNetworkConfig(null);
            }
        }
    }

    async getPlatform() {
        if (this.accountId && this.accountId !== 'new'
            && this.siteId && this.siteId !== 'new') {
            try {
                this.platform = await this.tunnelSvc.getPlatform(this.accountId, this.siteId);
                this.accountSvc.setPlatform(this.platform);
            } catch (reason) {
                if (typeof reason === 'string') {
                    console.error('failed to get platform', reason);
                } else if (reason && reason.statusCode === 401) {
                    console.log('failed to get platform', JSON.stringify(reason));
                    this.authSvc.redirectToLogin();
                } else if (reason instanceof Error) {
                    console.error('failed to get platform', reason.message);
                    if (reason.message === commonErrorMessages.AUTH_ERROR) {
                        this.authSvc.redirectToLogin();
                    }
                } else {
                    console.log('failed to get platform', JSON.stringify(reason));
                }
                this.accountSvc.setPlatform(null);
            }
        }
    }

    goToDashboard() {
        this.formDataSvc.redirectToDashboard();
    }

    private async updateAccountTag(accountId: string, tagParams: any) {
        try {
            const tags = await this.adminSvc.tryUpdateAccountTags(accountId, tagParams);
            this.accountSvc.setAccountTags(tags);
            if (tags['gateway-connection'] && tags['gateway-connection']['connected'] === true &&
                tags['pbx-status']['status'] === pbxStatus.DOWN) {
                this.router.navigateByUrl(`/accounts/${this.accountId}/pbx-connection-error`);
            }
        } catch (reason) {
            if (reason && reason.statusCode === 401) {
                console.error('failed to update account tags', JSON.stringify(reason));
                this.authSvc.redirectToLogin();
            } else if (reason instanceof Error) {
                console.error('failed to update account tags', reason.message);
                if (reason.message === commonErrorMessages.AUTH_ERROR) {
                    this.authSvc.redirectToLogin();
                }
            } else {
                console.error('failed to update account tags', reason);
            }
        }
    }

    ngOnDestroy() {
        sessionStorage.clear();
        this.alertSvc.clearAlert();
        this.accountSvc.setAccount(null);
        this.accountSvc.setAccountTags(null);
        this.accountSvc.setSite(null);
        this.accountSvc.setPbxlink(null);
        this.accountSvc.setNetworkConfig(null);
        this.accountSvc.setUpgradeSchedule(null);
        this.accountSvc.setSelectedPbxType(null);
        this.accountSvc.setPlatform(null);
        this.formDataSvc.resetData();
        if (this.accountTagsSubscription) {
            this.accountTagsSubscription.unsubscribe();
        }
        if (this.accountSubscription) {
            this.accountSubscription.unsubscribe();
        }
        if (this.siteSubscription) {
            this.siteSubscription.unsubscribe();
        }
        if (this.pbxSubscription) {
            this.pbxSubscription.unsubscribe();
        }
        if (this.platformSubscription) {
            this.platformSubscription.unsubscribe();
        }
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

}
