import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { pbxStatus } from '../../shared/constants';
import { AccountService } from '../../services/account.service';
import { AdminService } from '../../services/admin.service';
import { Admin2Service } from '../../services/admin2.service';
import { EchoService } from '../../services/echo.service';
import { Account, Site } from '@mitel/cloudlink-sdk/admin';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-pbx-connection',
  templateUrl: './pbx-connection.component.html',
  styleUrls: ['./pbx-connection.component.css']
})
export class PbxConnectionComponent implements OnInit, OnDestroy {
	account: Account;
	accountSubscription: Subscription;
  site: Site;
  siteSubscription: Subscription;
	accountTags = {};
  accountTagsSubscription: Subscription;
	pbxStatus = pbxStatus;
	state: string;

	retrying = false;

  constructor(private accountSvc: AccountService,
              private adminSvc: AdminService,
              private admin2Svc: Admin2Service,
              private spinnerSvc: SpinnerService,
              private echoSvc: EchoService) { }

  ngOnInit() {
  	this.accountSubscription = this.accountSvc.accountChanged
  									.subscribe(account => this.account = account);
  	this.siteSubscription = this.accountSvc.siteChanged
  													.subscribe(site => this.site = site);
  	this.accountTagsSubscription = this.accountSvc.accountTagsChanged
                                    .subscribe(accountTags => {
                                        this.accountTags = accountTags;
                                        this.setState();
                                    });
    this.account = this.accountSvc.getAccount();
    this.site = this.accountSvc.getSite();
    this.accountTags = this.accountSvc.getAccountTags();
    this.setState();
  }

  setState() {
  	if (this.accountTags && this.accountTags['pbx-status']) {
  		const status = this.accountTags['pbx-status']['status'];
  		if (status === pbxStatus.UP) {
  			this.state = pbxStatus.UP;
  		} else {
  			this.state = pbxStatus.DOWN;
  		}
  	}
    this.retrying = false;
  }

  async getEcho() {
    if (this.account && this.site && this.accountTags) {
      this.retrying = true;
      const success = await this.echoSvc.getEcho(this.retrying);
      this.spinnerSvc.hide();
      this.retrying = false;
    }
  }

  ngOnDestroy() {
  	if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.accountTagsSubscription) {
      this.accountTagsSubscription.unsubscribe();
    }
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
  }

}
