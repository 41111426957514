/// <reference types="@types/googlemaps" />
import {Directive, ElementRef, EventEmitter, Output} from '@angular/core';
import {MapsAPILoader} from "@agm/core";

@Directive({
  selector: '[appGooglePlaces]'
})
export class GooglePlacesDirective {

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  private readonly element: HTMLInputElement;

  constructor(private mapsAPILoader: MapsAPILoader, elRef: ElementRef) {
    this.element = elRef.nativeElement;
  }

  getFormattedAddress(place) {
    //@params: place - Google Autocomplete place object
    //@returns: location_obj - An address object in human readable format
    let location_obj = {};
    for (let i in place.address_components) {
      let item = place.address_components[i];

      location_obj['formatted_address'] = place.formatted_address;
      if (item['types'].indexOf("locality") > -1 || 
          item['types'].indexOf("postal_town") > -1 || 
          item['types'].indexOf("sublocality_level_1") > -1) {
        location_obj['locality'] = item['long_name']
      } else if (item['types'].indexOf("administrative_area_level_1") > -1) {
        location_obj['admin_area_l1'] = item['short_name']
      } else if (item['types'].indexOf("administrative_area_level_2") > -1) {
        location_obj['admin_area_l2'] = item['short_name']
      } else if (item['types'].indexOf("street_number") > -1) {
        location_obj['street_number'] = item['short_name']
      } else if (item['types'].indexOf("route") > -1) {
        location_obj['route'] = item['long_name']
      } else if (item['types'].indexOf("country") > -1) {
        location_obj['country'] = item['long_name']
        location_obj['countryCode'] = item['short_name'];
      } else if (item['types'].indexOf("postal_code") > -1) {
        location_obj['postal_code'] = item['short_name']
      }

    }
    return location_obj;
  }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      let autoComplete = new google.maps.places.Autocomplete(this.element, { types: ["address"] });
      autoComplete.addListener("place_changed", () => {
        console.log('Function: , autoComplete.getPlace(): ', autoComplete.getPlace());
        console.log('Function: , this.getFormattedAddress(autoComplete.getPlace()): ', this.getFormattedAddress(autoComplete.getPlace()));
        this.onSelect.emit(this.getFormattedAddress(autoComplete.getPlace()));
      });
    });
  }
}
