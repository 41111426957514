import { Injectable } from '@angular/core';

import { MivbProvisioningService } from './mivb-provisioning.service';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  constructor(
    private mivbProvisioningService: MivbProvisioningService
  ) { }

  syncForMIVB(accountId: string, siteId: string) {
    this.mivbProvisioningService.startSyncForMivb(accountId, siteId).then(response => {
      console.log("sync response for MiVB is ", response);
    })
    .catch((err => console.error('failed to start MiVB sync', err)))
  }
}
