import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ParentCommsService } from '../../services/parent-comms.service';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.css']
})
export class UnsavedChangesModalComponent implements OnInit {
  @Input() formsUnsavedChanges: any;
  @Output() unsavedForms: string;
  @Output() continue = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
    private translateSvc: TranslateService,
    private parentCommsService: ParentCommsService,
    private appService: AppService
  ) { }
  isIframed: boolean;
  isAccountAdmin: boolean;
  isAccountPage: boolean;
  currentPage: string;
  iframeRedirectDestination: string = null; 

  ngOnInit() {
    if (this.formsUnsavedChanges) {
      const unsavedForms = [
        this.formsUnsavedChanges[0].changes ? this.translateSvc.instant('nav.account') : '',
        this.formsUnsavedChanges[1].changes ? this.translateSvc.instant('nav.site') : '',
        this.formsUnsavedChanges[2].changes ? this.translateSvc.instant('nav.pbx') : '',
        this.formsUnsavedChanges[3].changes ? this.translateSvc.instant('nav.connect') : '',
        this.formsUnsavedChanges[4].changes ? this.translateSvc.instant('nav.officelink') : '',
        this.formsUnsavedChanges[5].changes ? this.translateSvc.instant('nav.advanced') : ''
      ];

      this.unsavedForms = _.compact(unsavedForms).join(', ');
    }
    this.isAccountAdmin = this.appService.isAccountAdmin();
    this.isIframed = this.parentCommsService.isInsideIframe();
  }

  onOk() {
    if (this.isIframed) {
      this.parentCommsService.tellParentToExitGatewayPortal(this.iframeRedirectDestination, false);
    }
    else{
      this.continue.emit();
    }
  	this.onClose();
  }

  onClose() {
  	this.activeModal.close();
  }

}
