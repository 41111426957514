import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CustomValidators } from '../forms/CustomValidators';
import { AdminService } from '../../services/admin.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { EchoService } from '../../services/echo.service';
import { SpinnerService } from '../../services/spinner.service';
import { CloudResourcesService } from '../../services/cloudResources.service';
import { FormDataService } from '../../services/form-data.service';
import { TooltipService } from '../../services/tooltip.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PreReqComponent } from '../pre-req/pre-req.component';
import { Subscription } from 'rxjs';
import {
  toastMsgTypes,
  pbxMessages,
  commonErrorMessages,
  gatewayConnectStateMessages,
  pbxTypes, pbxProducts, gatewayProducts, pbxStatus,
  pbxFormStepName
} from '../../shared/constants';
import { Pbxlink, PutPbxlinkRequest, PostFreeswitchRequest, FreeswitchConfig } from '@mitel/cloudlink-sdk/tunnel';
import { TunnelService } from '../../services/tunnel.service';
import { Account, Site } from '@mitel/cloudlink-sdk/admin';
import * as _ from 'lodash';
import { Utils } from '@mitel/cloudlink-sdk';

@Component({
  selector: 'app-pbx',
  templateUrl: './pbx.component.html',
  styleUrls: ['./pbx.component.css']
})
export class PbxComponent implements OnInit, OnDestroy {

  @ViewChild('uploadCertificate') uploadCertificate: any;
  pbxForm: FormGroup;
  pbxId: string;
  account: Account;
  site: Site;
  siteSubscription: Subscription;
  accountTags: any = {};
  accountTagsSubscription: Subscription;
  accountSubscription: Subscription;
  pbxSubcription: Subscription;
  pbxLink: Pbxlink;
  freeswitchConfig: FreeswitchConfig;
  platform: any;
  isOverview = false;
  isSubmitting = false;
  isFormFieldsChanged = false;

  actualNumberOfTrunks = 0;

  modalRef: NgbModalRef;
  sessionData: any;
  showPswd: boolean;
  showMiCollabPswd: boolean;
  newCloudlinkPswd: boolean = false;
  newTrunkPswd: boolean = false;
  newMiCollabPswd: boolean = false;
  showTruckPswd: boolean;
  tlsCheckBoxSelected = false;
  certificateFormat: boolean = true;
  isCertificateAdded: boolean = false;
  isCertificateInstalled: boolean = false;
  file = null;
  certificateMaxLength: boolean = false;
  certificateBase64;

  interval: any;
  timeout: any;
  ECONNREFUSED = 'ECONNREFUSED';
  ERR_CONNECT_MEDIA = 'connection failed to cloud proxy';

  pbxSelectOptions = [];
  pbxSelectBetaOptions = [];
  pbxConnected: boolean;

  pbxTypes = pbxTypes;

  selectedPbxTypeSubscription: Subscription;
  platformSubscription: Subscription;
  defaultPbxType = pbxTypes.MIVO400;
  usedDefaultPbxType = true;
  mbgConfig: any;
  pbxInterval;
  certificateSubject: any;
  certificateExpiry: any;
  certificateUpdate = false;
  pbxIntervalCount = 0;
  miCollabConfig: any;
  showMiCollabSettings: boolean = false;
  errorUpdatingMiCollabSettings: boolean = false;

  submitStep: string;

  readonly micollabPbxTypes = [pbxTypes.MIVB, pbxTypes.MV5000, pbxTypes.MXONE];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private adminSvc: AdminService,
    private authSvc: AuthenticationService,
    private formBuilder: FormBuilder,
    private modalSvc: NgbModal,
    private accountSvc: AccountService,
    private alertSvc: AlertService,
    private echoSvc: EchoService,
    private spinnerSvc: SpinnerService,
    private cloudResourcesSvc: CloudResourcesService,
    private formDataSvc: FormDataService,
    private analyticsSvc: AnalyticsService,
    private tunnelSvc: TunnelService,
    private tooltipSvc: TooltipService) {
  }

 async ngOnInit() {
  this.formDataSvc.setSessionData = true;
    const formItem = sessionStorage.getItem('pbx-form');
    if (typeof formItem !== 'undefined' && formItem) {
      this.sessionData = JSON.parse(formItem);
    }

    this.accountTagsSubscription = this.accountSvc.accountTagsChanged
      .subscribe(async accountTags => {
        if(!this.pbxLink && !this.sessionData && this.usedDefaultPbxType){
          let pbxTypeFromTags = this.getPbxTypeFromTags();
          if(pbxTypeFromTags && pbxTypeFromTags === this.defaultPbxType){
            //No need for changes
            this.usedDefaultPbxType = false;
          } else if(pbxTypeFromTags) {
            //tags differ from default type - need to change form
            await this.setFormPbxType(pbxTypeFromTags);
            this.usedDefaultPbxType = false;
          }
        }
        this.accountTags = accountTags;
        this.setToastMessage();
        this.echoSvc.getEcho();
      });
    this.accountSubscription = this.accountSvc.accountChanged
      .subscribe(account => {
        this.account = account;
        this.echoSvc.getEcho();
      });
    this.siteSubscription = this.accountSvc.siteChanged
      .subscribe(site => {
        this.site = site;
        this.echoSvc.getEcho();
      });
    this.pbxSubcription = this.accountSvc.pbxChanged
      .subscribe(pbx => {
        this.pbxLink = pbx;
        if (this.pbxLink) {
          this.setForm();
          if (this.pbxLink.type === pbxTypes.MIVB) {
            this.getMBGConfig()
          }
          if(this.showMiCollabSettings){
            this.getMiCollabConfig();
          }
          this.getFreeswitchConfig();
          this.cloudResourcesSvc.setCloudPbx();
        }
      });
    this.platformSubscription = this.accountSvc.platformChanged
      .subscribe(platform => {
        this.platform = platform;
        if(this.platform) {
            this.setPbxOptions();
        }
      });
   this.selectedPbxTypeSubscription = this.accountSvc.selectedPbxTypeChanged
      .subscribe(async selectedPbxType => await this.setFormPbxType(selectedPbxType));

    this.initForm();
    this.setFormWithSessionStorage();

    this.account = this.accountSvc.getAccount();
    this.accountTags = this.accountSvc.getAccountTags();
    this.site = this.accountSvc.getSite();
    this.pbxLink = this.accountSvc.getPbxlink();
    await this.getPbxData();
    this.setToastMessage();
    this.echoSvc.setGatewayConnect('pbx');
    this.platform = this.accountSvc.getPlatform();
    if (this.platform) {
      this.setPbxOptions();
    }

    this.route.params
      .subscribe(
        (params: Params) => {
          this.isOverview = this.route.snapshot.url[0].path === 'overview';
          if (!this.isOverview) {
            this.pbxId = this.route.snapshot.url[3].path;
          }
          if (this.pbxLink) {
            this.setForm();
            if (this.pbxLink.type === pbxTypes.MIVB) {
              this.getMBGConfig();
            }
            if(this.showMiCollabSettings){
              this.getMiCollabConfig();
            }
            this.getFreeswitchConfig();
            this.cloudResourcesSvc.setCloudPbx();
          }
        });
 }

  initForm() {
    let type = this.accountSvc.getSelectedPbxType() || this.getPbxTypeFromTags();
    if (type){
      this.usedDefaultPbxType = false;
    }
    else{
      type = this.defaultPbxType;
    }
    const maxPsd = type !== pbxTypes.MIVO250 ? 255 : 15;
    const portValidation = type === pbxTypes.MV5000 ? CustomValidators.validateString : CustomValidators.validateDigit;
    const defaultPort = this.getDefaultPort(type);
    this.pbxForm = this.formBuilder.group({
      pbxType: [type, [Validators.required]],
      name: ['', [Validators.required]],
      ipAddress: ['', this.getIpValidators(type)],
      username: ['', this.getUsernameValidators(type)],
      password: ['', this.getPasswordValidators(type)],
      mbgIpAddress: ['', [CustomValidators.validateIpAddressOrFQDN, Validators.required]],
      mbgPassword: ['', [CustomValidators.validatePasswordChars, Validators.required]],
      miCollabIpAddress: ['', this.getMiCollabIpAddressValidators(type)],
      miCollabPassword: ['', this.getMiCollabPasswordValidators(type)],
      port: [defaultPort, [Validators.required, portValidation]],
      trunk_username: ['', [Validators.required, CustomValidators.validateCharacters, CustomValidators.validateMaxLength(64)]],
      trunk_password: ['', [Validators.required, CustomValidators.validatePasswordChars, CustomValidators.validateMaxLength(49)]],
      trunk_group_extension: ['', [Validators.required, CustomValidators.validateDigit, CustomValidators.validateMaxLength(5)]]
    });
    this.showMiCollabSettingsIfApplicable();
    this.onChanges();
  }

  onChanges() {
    this.pbxForm.valueChanges.subscribe(val => {
      this.formDataSvc.setCurrentFormDirty('pbx', this.pbxForm.dirty);
    });
  }

  validateMiCollabFieldRequirement(name: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let ipControl = this.pbxForm?.controls['miCollabIpAddress'];
      let passwordControl = this.pbxForm?.controls['miCollabPassword'];
      if(ipControl && passwordControl){
        let ip = ipControl && ipControl.value? ipControl.value : null;
        let password = passwordControl && passwordControl.value? passwordControl.value : null;
        
        if(name === 'miCollabIpAddress'){
          if(ipControl.valid && password && passwordControl.valid){
            if(!ip){
              return { 'required': true };
            }
          }
        }
        else if(name === 'miCollabPassword'){
          if(passwordControl.valid && ip && ipControl.valid){
            if(!password){
              return { 'required': true };
            }
          }
        }
      }
      return null;
    }
  }

  getDefaultPort(type: string) {
    switch (type) {
      case pbxTypes.MIVO250:
        return '4000';
      case pbxTypes.MIVB:
        return '0';
      case pbxTypes.MIVO400:
      case pbxTypes.MIVC:
        return '7001'
      case pbxTypes.MXONE:
        return '8882'
      case pbxTypes.MV5000:
        return '3211'
    }
  }

  getPbxTypeFromTags(){
    const accountTags = this.accountSvc.getAccountTags();
    let productTags = [];
    let pbxType = null;
    if (accountTags && accountTags["products"]){
      productTags = accountTags["products"];
      for (let key in pbxProducts){
        let value = pbxProducts[key][0];
        if(productTags.includes(value)){
          pbxType = key;
          break;
        }
      }
    }
    return pbxType;
  }

  showMiCollabSettingsIfApplicable(){
    let formPBXType = this.pbxForm?.controls['pbxType']?.value? this.pbxForm.controls['pbxType'].value : undefined;
    this.showMiCollabSettings = formPBXType? this.micollabPbxTypes.includes(formPBXType) : false;
  }

  //setSelectedPbxType will trigger the subscription that calls setFormPbxType
  onSelectPbxType(type: string) {
    this.accountSvc.setSelectedPbxType(type);
  }

  isNewPassword(pswd: string, type: string) {
    if (!pswd.length) {
      switch (type) {
          case 'cloud':
              this.newCloudlinkPswd = true;
              break;
          case 'trunk':
              this.newTrunkPswd = true;
              break;
          case 'micollab':
              this.newMiCollabPswd = true;
              break;
          default: ;
      }
    }
  }

  async updateProductTagsIfRequired(type: string){
   let pbxTypeFromTags = this.getPbxTypeFromTags();

    if(type !== pbxTypeFromTags) {
      try {
        let prodArray;
        if(this.accountTags && this.accountTags['products']) {
          prodArray = this.accountTags['products'];
          // Remove any old PBX types and mobile app products related to CloudLink Gateway
          _.remove(prodArray, function(n) {
            return _.includes(gatewayProducts, n);
          });
          // Add new products based on the PBX Type
          prodArray = _.concat(prodArray, pbxProducts[type]);
        } else {
          prodArray = pbxProducts[type];
        }
        const newTags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, {products: prodArray})
        this.accountSvc.setAccountTags(newTags);
      } catch (err) {
        console.error('failed to update account products', err);
      }
    }
  }


  useMBGFields() {
    if (this.pbxLink && this.pbxLink.type !== pbxTypes.MIVB) {
      return false;
    }

    if (this.areMBGFieldsFilled()) {
      return true;
    }

    return false;
  }

  areMBGFieldsFilled() {
    return !!(
      (this.pbxForm.controls['mbgIpAddress'].value && this.pbxForm.controls['mbgPassword'].value) &&
      (this.pbxForm.controls['mbgIpAddress'].value.length > 0 && this.pbxForm.controls['mbgPassword'].value.length > 0)
    )
  }

  noMBGFieldsFilled() {
    return !this.pbxForm.controls['mbgIpAddress'].value && !this.pbxForm.controls['mbgPassword'].value;
  }

  updateUsernameValidation() {
    const type = this.pbxForm.getRawValue().pbxType;
    this.pbxForm.controls['username'].setValidators(this.getUsernameValidators(type));
    this.pbxForm.controls['username'].updateValueAndValidity();
  }

  updatePasswordValidation() {
    const type = this.pbxForm.getRawValue().pbxType;
    this.pbxForm.controls['password'].setValidators(this.getPasswordValidators(type));
    this.pbxForm.controls['password'].updateValueAndValidity();
  }

  updateIpAddressValidation() {
    const type = this.pbxForm.getRawValue().pbxType;
    this.pbxForm.controls['ipAddress'].setValidators(this.getIpValidators(type));
    this.pbxForm.controls['ipAddress'].updateValueAndValidity();
  }

  updatePortValidation() {
    const type = this.pbxForm.getRawValue().pbxType;
    const portValidation = type === pbxTypes.MV5000 ? CustomValidators.validateString : CustomValidators.validateDigit;
    this.pbxForm.controls['port'].setValidators([Validators.required, portValidation]);
    this.pbxForm.controls['port'].updateValueAndValidity();
  }

  updateMiCollabIpAddressValidation() {
    const type = this.pbxForm.getRawValue().pbxType;
    this.pbxForm.controls['miCollabIpAddress'].setValidators(this.getMiCollabIpAddressValidators(type));
    this.pbxForm.controls['miCollabIpAddress'].updateValueAndValidity();
  }

  updateMiCollabPasswordValidation() {
    const type = this.pbxForm.getRawValue().pbxType;
    this.pbxForm.controls['miCollabPassword'].setValidators(this.getMiCollabPasswordValidators(type));
    this.pbxForm.controls['miCollabPassword'].updateValueAndValidity();
  }

  checkValidityOfMiCollabFields(){
    this.pbxForm.controls['miCollabIpAddress'].updateValueAndValidity();
    this.pbxForm.controls['miCollabPassword'].updateValueAndValidity();
  }

  getIpValidators(pbxType: string){
    if (pbxType === pbxTypes.MIVB) {
      return [Validators.required, CustomValidators.validateIpAddressOrFQDN];
    } else {
      return [Validators.required, CustomValidators.validateIpAddress];
    }
  }

  getUsernameValidators(pbxType: string){
    if (pbxType !== pbxTypes.MXONE) {
      return [Validators.required, CustomValidators.validateMaxLength(25)];
    } else {
      return [Validators.nullValidator];
    }
  }

  getPasswordValidators(pbxType: string){
    const maxPsd = pbxType !== pbxTypes.MIVO250 ? 255 : 15;
    if (pbxType !== pbxTypes.MXONE) {
      return [Validators.required, CustomValidators.validatePasswordChars, CustomValidators.validateMaxLength(maxPsd)];
    } else {
      return [Validators.nullValidator];
    }
  }

  getMiCollabIpAddressValidators(pbxType: string){
    if (pbxType && this.micollabPbxTypes.includes(pbxType)) {
      return [CustomValidators.validateIpAddressOrFQDN, this.validateMiCollabFieldRequirement('miCollabIpAddress')];
    } else {
      return [Validators.nullValidator];
    }
  }

  getMiCollabPasswordValidators(pbxType: string){
    if (pbxType && this.micollabPbxTypes.includes(pbxType)) {
      return [CustomValidators.validatePasswordChars, this.validateMiCollabFieldRequirement('miCollabPassword')];
    } else {
      return [Validators.nullValidator];
    }
  }

  /*
  This method was called onClick (probably should have been on blur for accessibility) for the ipAddress input.
  I removed the onClick event (ie removed the mask) to accommodate when we want an FQDN instead of an ip.
  There is still validation on the ipAddress - so we won't get invalid inputs.
  Leaving this code here for now in case someone wants the mask back
  */
  // addMask(event) {
  //   setTimeout(() => {
  //     Inputmask({ alias: 'ip' }).mask(event.target.id);
  //   });
  // }

  openPreReq() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview PBX' : 'PBX', 'Open pre-requisites');
    this.modalRef = this.modalSvc.open(PreReqComponent);

    const contentComponentInstance = this.modalRef.componentInstance;
    contentComponentInstance.overview.subscribe(() => {
      this.router.navigateByUrl(`/accounts/${this.account.accountId}/overview`);
    });
  }

  updateFormValidation(){
    this.showMiCollabSettingsIfApplicable();
    this.updatePortValidation();
    this.updatePasswordValidation();
    this.updateUsernameValidation();
    this.updateIpAddressValidation();
    this.updateMiCollabIpAddressValidation();
    this.updateMiCollabPasswordValidation();
  }

  setForm() {
    console.log("set form called");
    if (this.sessionData) {
      if (this.pbxLink.license_in_use) {
        const numTrunks = parseInt(this.pbxLink.license_in_use, 10);
        this.actualNumberOfTrunks = isNaN(numTrunks) ? 0 : numTrunks;
      }
      return;
    }

    if (this.pbxLink) {
      if (this.pbxLink.connection_password) {
        this.newCloudlinkPswd = false;
      }
      (<FormControl>this.pbxForm.controls['pbxType'])
        .setValue(this.pbxLink.type);
      this.pbxForm.controls['pbxType'].disable();
      (<FormControl>this.pbxForm.controls['name'])
        .setValue(this.pbxLink.name);
      (<FormControl>this.pbxForm.controls['ipAddress'])
        .setValue(this.pbxLink.connection_ip_address);
      (<FormControl>this.pbxForm.controls['password'])
        .setValue(this.pbxLink.connection_password);
      (<FormControl>this.pbxForm.controls['port'])
        .setValue(this.pbxLink.connection_port);
      (<FormControl>this.pbxForm.controls['trunk_group_extension'])
        .setValue(this.pbxLink.sip_trunk_group);
      (<FormControl>this.pbxForm.controls['username'])
        .setValue(this.pbxLink.connection_username);
        this.tlsCheckBoxSelected = !!this.pbxLink.connection_tls;
      if (this.pbxLink.license_in_use) {
        const numTrunks = parseInt(this.pbxLink.license_in_use, 10);
        this.actualNumberOfTrunks = isNaN(numTrunks) ? 0 : numTrunks;
      }
      //sets the certificate
      if (this.pbxLink.type === pbxTypes.MIVB) {
        this.getCertificate();
      }

      this.updateFormValidation();
    }

  }

   getCertificate() {
    this.tunnelSvc.getInstallX509Certificates(this.account.accountId, this.site.siteId)
      .then( cert => {
        // subject and expiration date when certificate already exists
        console.log("cert", cert);
        if (cert && cert["subject"] && cert['expiryDateTime']) {
          this.certificateSubject = cert['subject'];
          this.certificateExpiry = new Date(cert['expiryDateTime']).toUTCString();
          this.isCertificateAdded = true;
          this.isCertificateInstalled = true;
        }
      }).catch(error=> {
      console.log("error in getting certificate", error);
      this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_GET_CERTIFICATE);
    })
  }

  async setFormPbxType(selectedPbxType: string) {
    if (this.pbxLink) {
      return;
    }
    this.usedDefaultPbxType = false;
    this.setFormPort(selectedPbxType);
    this.updateFormValidation();
    await this.updateProductTagsIfRequired(selectedPbxType);
  }

  setFormPort(selectedPbxType: string) {
    (<FormControl>this.pbxForm.controls['pbxType'])
      .setValue(selectedPbxType);
    const defaultPort = this.getDefaultPort(selectedPbxType);
    (<FormControl>this.pbxForm.controls['port'])
      .setValue(defaultPort);
  }

  setMBGFields() {
    if (this.sessionData) {
      return;
    }

    if (this.mbgConfig) {
      (<FormControl>this.pbxForm.controls['mbgIpAddress'])
        .setValue(this.mbgConfig.ipAddressHostname);
      (<FormControl>this.pbxForm.controls['mbgPassword'])
        .setValue(this.mbgConfig.adminPassword);
    }
  }

  setFreeswitchFields() {
    if (this.sessionData) {
      return;
    }

    if (this.freeswitchConfig) {
      this.newTrunkPswd = false;
      (<FormControl>this.pbxForm.controls['trunk_username'])
        .setValue(this.freeswitchConfig.pbx_trunk_username);
      (<FormControl>this.pbxForm.controls['trunk_password'])
        .setValue(this.freeswitchConfig.pbx_trunk_password);
    }
  }

  setFormWithSessionStorage() {
    if (this.sessionData) {
      this.pbxForm.patchValue(this.sessionData);
      if (this.sessionData.typeDisabled) {
        this.pbxForm.controls['pbxType'].disable();
      }
      this.updateFormValidation();
    } else {
      this.newCloudlinkPswd = true;
      this.newTrunkPswd = true;
      this.newMiCollabPswd = true;
    }
  }

  setToastMessage() {
    if (this.accountTags && this.accountTags['gateway-connection']) {
      if (this.accountTags['gateway-connection']['connected'] &&
        this.accountTags['gateway-connection']['pbxConnected'] === false) {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, this.accountTags['gateway-connection']['reason']);
      }
    }
  }

  async checkFreeswitchContainerExist(){
    try {
      const versionResponse = await this.tunnelSvc.getSystemVersion(this.account.accountId, this.site.siteId);
      console.log("versionResponse: ", versionResponse);
      for(var key in versionResponse) {
        if(versionResponse[key]['name'] && versionResponse[key]['name'] === 'FreeSWITCH'){
          return true;
        }
      }
    } catch (reason) {
      console.error('failed to get system version', reason);
    }
    return false;
  }

  async getMBGConfig() {
    try {
      const response = await this.tunnelSvc.getMBG(this.account.accountId, this.site.siteId);
      this.mbgConfig = response;
      this.setMBGFields();
    } catch (reason) {
      if (typeof reason === 'string') {
        console.error('rej to get mbg config:', reason);
      } else if (reason && reason.statusCode === 401) {
        console.error('rej to get mbg config:', JSON.stringify(reason));
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('rej to get mbg config:', JSON.stringify(reason));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (reason instanceof Error) {
        console.error('rej to get mbg config:', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('rej to get mbg config:', JSON.stringify(reason));
      }
    }
  }

  async getFreeswitchConfig() {
    //we only need to getFreeswitchConfig if getSystemVersion returns a container called 'FreeSWITCH'
    const freeSwitchExists = await this.checkFreeswitchContainerExist();
    if(!freeSwitchExists)
      return;

    console.log('getting freeswitch config');
    // Get the Freeswitch Parameters
    try {
      const response = await this.tunnelSvc.getFreeswitchConfig(this.account.accountId, this.site.siteId);
      this.freeswitchConfig = response;
      this.cloudResourcesSvc.setCloudTrunking(this.freeswitchConfig);
      this.setFreeswitchFields();
    } catch (reason) {
      if (typeof reason === 'string') {
        console.error('rej to get freeswitch config:', reason);
      } else if (reason && reason.statusCode === 401) {
        console.error('rej to get freeswitch config:', JSON.stringify(reason));
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('rej to get freeswitch config:', JSON.stringify(reason));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (reason instanceof Error) {
        console.error('rej to get freeswitch config:', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('rej to get freeswitch config:', JSON.stringify(reason));
      }
    }
  }

  async setPbxOptions() {
    (await this.tunnelSvc.getSupportedPbxTypes(this.platform)).forEach(v => {
      if (v.betaSupport) {
        this.pbxSelectBetaOptions.push(v);
      } else {
        this.pbxSelectOptions.push(v);
      }
    });
  }

  onSelectCheckbox() {
    this.tlsCheckBoxSelected = !this.tlsCheckBoxSelected;
    if(this.tlsCheckBoxSelected)
      (<FormControl>this.pbxForm.controls['port']).setValue('8883');
    else
      (<FormControl>this.pbxForm.controls['port']).setValue('8882');
  }

  getPbxParams() {
    const input = this.pbxForm.getRawValue();

    let object: any;
    const type = input.pbxType;
    switch (type) {
      case pbxTypes.MIVO250:
        object = {
          name: input.name,
          type: input.pbxType,
          connection_ip_address: input.ipAddress,
          connection_port: input.port,
          connection_password: input.password,
          snapshot_support: true,
          advanced_logging: false,
          sip_trunk_group: input.trunk_group_extension,
          license_number: 0
        };
        break;
      case pbxTypes.MIVO400:
      case pbxTypes.MIVC:
        object = {
          name: input.name,
          type: input.pbxType,
          connection_ip_address: input.ipAddress,
          connection_port: input.port,
          connection_username: input.username,
          connection_password: input.password,
          snapshot_support: true,
          advanced_logging: false
        };
        break;
      case pbxTypes.MXONE:
        object = {
          name: input.name,
          type: input.pbxType,
          connection_ip_address: input.ipAddress,
          miCollab_ip_address: input.miCollabIpAddress,
          miCollab_password: input.miCollabPassword,
          connection_port: input.port,
          connection_tls: this.tlsCheckBoxSelected,
          connection_username: input.username,
          connection_password: input.password,
          snapshot_support: false,
          advanced_logging: false
        };
        break;
      case pbxTypes.MV5000:
        object = {
          name: input.name,
          type: input.pbxType,
          connection_ip_address: input.ipAddress,
          miCollab_ip_address: input.miCollabIpAddress,
          miCollab_password: input.miCollabPassword,
          connection_port: input.port,
          snapshot_support: false,
          advanced_logging: false
        };
        break;
      case pbxTypes.MIVB:
        object = {
          name: input.name,
          type: input.pbxType,
          connection_ip_address: input.ipAddress,
          connection_port: '0',
          snapshot_support: false,
          advanced_logging: false,
          mbg_ip_address: input.mbgIpAddress,
          mbg_password: input.mbgPassword,
          miCollab_ip_address: input.miCollabIpAddress,
          miCollab_password: input.miCollabPassword
        };
    }

    return object;

  }

    useMiCollabFields() {
        if (!this.showMiCollabSettings) {
            return false;
        }

        return this.areMiCollabFieldsFilled();
    }

    areMiCollabFieldsFilled() {
        return !!(
            (this.pbxForm.controls['miCollabIpAddress'].value && this.pbxForm.controls['miCollabPassword'].value) &&
            (this.pbxForm.controls['miCollabIpAddress'].value.length > 0 && this.pbxForm.controls['miCollabPassword'].value.length > 0)
        )
    }

    noMiCollabFieldsFilled() {
        return !this.pbxForm.controls['miCollabIpAddress'].value && !this.pbxForm.controls['miCollabPassword'].value;
    }

    setMiCollabFields() {
        if (this.sessionData) {
            return;
        }

        if (this.miCollabConfig) {
            this.newMiCollabPswd = false;
            (<FormControl>this.pbxForm.controls['miCollabIpAddress'])
                .setValue(this.miCollabConfig.ipAddressHostname);
            (<FormControl>this.pbxForm.controls['miCollabPassword'])
                .setValue(this.miCollabConfig.adminPassword);
        }
    }

    deleteMiCollabConfigIfNeeded(){
        return this.noMiCollabFieldsFilled() && (this.errorUpdatingMiCollabSettings ||
        !!(this.miCollabConfig && this.miCollabConfig.ipAddressHostname.length > 0 && this.miCollabConfig.adminPassword.length > 0));
    }

    async getMiCollabConfig() {
        try {
          if (this.showMiCollabSettings) {
            const response = await this.tunnelSvc.getMiCollabConfiguration(this.account.accountId, this.site.siteId);
            this.miCollabConfig = response;
            this.setMiCollabFields();
          }
        } catch (reason) {
            if (typeof reason === 'string') {
                console.error('rej to get miCollab config:', reason);
            } else if (reason && reason.statusCode === 401) {
                console.error('rej to get miCollab config:', JSON.stringify(reason));
                this.authSvc.redirectToLogin();
            } else if (reason && reason.statusCode === 403) {
                console.error('rej to get miCollab config:', JSON.stringify(reason));
                this.formDataSvc.redirectToDashboardOrLogout();
            } else if (reason && reason.status === 500) {
                console.log('rej to get miCollab config:', JSON.stringify(reason));
            }
            else if (reason instanceof Error) {
                console.error('rej to get miCollab config:', reason.message);
                if (reason.message === commonErrorMessages.AUTH_ERROR) {
                    this.authSvc.redirectToLogin();
                }
            } else {
                console.error('rej to get miCollab config:', JSON.stringify(reason));
            }
        }
    }

  handleUpload(event) {
    console.log("event", event);
    this.certificateFormat = true;
    this.isSubmitting = false;
    this.isCertificateAdded = false;
    this.certificateMaxLength = false;
    if(event.target.files && event.target.files.length>0){
      this.file = event.target.files[0];
      console.log("size of file is ", this.file);
      const reader = new FileReader();
      reader.onload =  () => {
        //should the format of the certificate and show error message if not the correct format
        if(!reader.result.toString().includes("-----BEGIN")) {
          this.certificateFormat = false;
          this.isSubmitting = true;
          console.log("improper format");
        }
        this.isCertificateAdded = true;
        if(this.certificateFormat) {
          this.validateCertificate(this.file);
        }
      };
      reader.readAsText(this.file);
      this.formDataSvc.setCurrentFormDirty('pbx', true);
    }
}

validateCertificate(certificate) {
  let certificateBase64Size;
  const reader = new FileReader();
  reader.readAsDataURL(certificate);
   reader.onload = () => {
      //should convert the certificate to base 64 and extracting the string
      this.certificateBase64 = reader.result.toString().split(',')[1];

      // calculating base 64 string size in bytes
      certificateBase64Size =  4*(this.certificateBase64.length/3) ;
      console.log("size is base 64", certificateBase64Size);
     if(certificateBase64Size/1024 > 1024) {
      this.certificateMaxLength = true;
      this.isSubmitting = true;
      console.log("size is greater", certificateBase64Size);
    }
  };
}

  removeCertificate() {
    this.file = null;
    this.isCertificateAdded = false;
    this.certificateFormat = true;
    this.certificateMaxLength = false;
    this.certificateBase64 = "";
    this.isSubmitting = false;
    if (this.uploadCertificate)
      this.uploadCertificate.nativeElement.value = "";
    this.formDataSvc.setCurrentFormDirty('pbx', true);
  }

  async setCertificate(){
    let certParam, errorMsg;
    if(this.file){
      certParam = this.certificateBase64;
      errorMsg = pbxMessages.ERR_UPLOAD_CERTIFICATE;
    }
    else if(!this.isCertificateAdded){
      certParam = "";
      //when no file, if isCertificateInstalled is true means that the current certificate is gonna be removed,
      //              otherwise no certificate is being installed, so it's gonna be installing a certificate without file
      errorMsg = this.isCertificateInstalled ? pbxMessages.ERR_REMOVE_CERTIFICATE : pbxMessages.ERR_INSTALL_CERTIFICATE_FILE_NOT_UPLOADED;
    }
    try {
        let cert = await this.tunnelSvc.installX509Certificates(this.account.accountId, this.site.siteId, certParam);
        
        // subject and expiration date when certificate already exists
        console.log("updated cert", cert);
        if (cert && cert["subject"] && cert['expiryDateTime']) {
          this.certificateSubject = cert['subject'];
          this.certificateExpiry = new Date(cert['expiryDateTime']).toUTCString();
          this.isCertificateAdded = true;
          this.isCertificateInstalled = true;
        }
        return true;
    }
    catch(error) {
        console.log("error in uploading or removing certificate", error);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, errorMsg);
        return false;
    }
}


  getAccountTagParameters(succeeded: boolean, reason?: string): any {
    const tagParams = {};

    if (this.accountTags && this.accountTags['gateway-connection']) {
      if (this.accountTags['gateway-connection']['pbxConnected'] ||
        this.accountTags['gateway-connection']['pbxConnected'] === undefined ||
        (this.accountTags['gateway-connection']['pbxConnected'] === false &&
          this.isPbxConnectError(this.accountTags['gateway-connection']['reason']))) {
        if (this.pbxLink) {
          if (this.pbxLink['connect_error'] && this.pbxLink['connect_error'] !== '') {
            const statusMsg = this.echoSvc.getStatusMessageFromConnectError(this.pbxLink['connect_error']);
            tagParams['gateway-connection'] = { connected: true, pbxConnected: false, reason: statusMsg };
          } else if (this.pbxLink.state === 'up') {
            tagParams['gateway-connection'] = { connected: true, pbxConnected: true };
          } else if (!this.certificateUpdate && this.pbxLink.state === 'init') {
            const statusMsg = gatewayConnectStateMessages.NOT_START_PBX_CONNECTION;
            tagParams['gateway-connection'] = { connected: true, pbxConnected: false, reason: statusMsg };
          }
        }
      }
    }

    if (!this.accountTags || !this.accountTags['on-board-progress'] ||
      this.accountTags['on-board-progress'].step <= 3 || this.isFormFieldsChanged) {
      if (succeeded) {
        tagParams['on-board-progress'] = { 'step': 3, 'name': 'pbx', 'succeeded': succeeded };
      } else {
        tagParams['on-board-progress'] = { 'step': 3, 'name': 'pbx', 'succeeded': succeeded, 'reason': reason };
      }
    }

    return tagParams;
  }

  isPbxConnectError(reason: string): boolean {
    if (reason === gatewayConnectStateMessages.INVALID_TRUSTED_APP_PASSWORD ||
      reason === gatewayConnectStateMessages.INVALID_IP ||
      reason === gatewayConnectStateMessages.INVALID_GATEWAY_IP ||
      reason === gatewayConnectStateMessages.INVALID_PBX_IP ||
      reason === gatewayConnectStateMessages.TRUSTED_APP_DISABLED ||
      reason === gatewayConnectStateMessages.INVALID_TRUNK_GROUP ||
      reason === gatewayConnectStateMessages.NOT_START_PBX_CONNECTION) {
      return true;
    } else {
      return false;
    }
  }

  getPbxUpdateParams(): PutPbxlinkRequest {
    const type = this.pbxForm.getRawValue().pbxType;
    const input = this.pbxForm.value;
    let object: any;
    if (type === pbxTypes.MIVO250) {
      object = {
        name: input.name,
        connection_ip_address: input.ipAddress,
        connection_port: input.port,
        connection_password: input.password,
        snapshot_support: true,
        advanced_logging: false,
        sip_trunk_group: input.trunk_group_extension,
        license_number: 0
      };
    } else if (type === pbxTypes.MV5000) {
      object = {
        name: input.name,
        connection_ip_address: input.ipAddress,
        miCollab_ip_address: input.miCollabIpAddress,
        miCollab_password: input.miCollabPassword,
        connection_port: input.port,
        snapshot_support: false,
        advanced_logging: false
      };
    }
    else if (type === pbxTypes.MXONE) {
      object = {
        name: input.name,
        connection_ip_address: input.ipAddress,
        miCollab_ip_address: input.miCollabIpAddress,
        miCollab_password: input.miCollabPassword,
        connection_port: input.port,
        connection_tls: this.tlsCheckBoxSelected,
        connection_username: input.username,
        connection_password: input.password,
        snapshot_support: false,
        advanced_logging: false
      };
    }
    else if (type === pbxTypes.MIVB) {
      object = {
        name: input.name,
        connection_ip_address: input.ipAddress,
        mbg_ip_address: input.mbgIpAddress,
        mbg_password: input.mbgPassword,
        miCollab_ip_address: input.miCollabIpAddress,
        miCollab_password: input.miCollabPassword,
        connection_port: '0',
        snapshot_support: false,
        advanced_logging: false
      };
    } else {
      object = {
        name: input.name,
        connection_ip_address: input.ipAddress,
        connection_port: input.port,
        connection_username: input.username,
        connection_password: input.password,
        snapshot_support: true,
        advanced_logging: false
      };
    }

    return object;
  }

  getFreeswitchParams(): PostFreeswitchRequest {
    const input = this.pbxForm.value;
    const object = {
      pbx_trunk_username: input.trunk_username,
      pbx_trunk_password: input.trunk_password
    };

    return object;
  }

  async sendFreeswitchConfigRequest(retry: boolean = false) {
    // #1322 MiVoice 400, skip Freeswitch Config
    if (this.pbxLink && (this.pbxLink.type === pbxTypes.MIVO400 || this.pbxLink.type === pbxTypes.MIVC)) {
      await this.checkCloudlinkStatus();
      return;
    }
    this.spinnerSvc.setMessage(pbxMessages.CONFIG_FREESWITCH);
    const freeswitchParams = this.getFreeswitchParams();
    try {
      await this.tunnelSvc.createFreeswitchConfig(this.account.accountId, this.site.siteId, freeswitchParams);
      if (retry) {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
      }
      console.log('configured freeswitch');
      const type = this.pbxForm.getRawValue().pbxType;
      if (type === pbxTypes.MIVO250) {
        setTimeout(async () => {
          try {
            // tslint:disable-next-line:max-line-length
            const response = await this.tunnelSvc.updatePbxlinkLicense(this.account.accountId, this.site.siteId, this.pbxLink._id, this.pbxLink.sip_trunk_group, {
              license_number: '100'
            });
            if (response.license) {
              const numTrunks = parseInt(response.license, 10);
              this.actualNumberOfTrunks = isNaN(numTrunks) ? 0 : numTrunks;
            }
            if (response.rc) {
              console.error('updatePbxlinkLicense failed', response);
              throw pbxMessages.ERR_TRUNK_LICENSE;
            }

          } catch (error) {
            console.error('updatePbxlinkLicense failed', error);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_TRUNK_LICENSE);
            this.spinnerSvc.hide();
            this.isSubmitting = false;
            this.updateAccountTagsWithProgress(false, pbxMessages.ERR_TRUNK_LICENSE);
          }
          await this.checkCloudlinkStatus();
        }, 5000);
      } else {
        setTimeout(async () => { await this.checkCloudlinkStatus() }, 40000);
      }
    } catch (rej) {
      let errorMsg = '';
      if (rej && rej.statusCode === 401) {
        console.error('failed to set freeswitch config', rej);
        this.authSvc.redirectToLogin();
      } else if (rej && rej.statusCode === 403) {
        console.error('failed to set freeswitch config', JSON.stringify(rej));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (rej && rej.body) {
        console.error('failed to set freeswitch config', JSON.stringify(rej));
        errorMsg = rej.body.message;
        if (rej.statusCode === 500 && errorMsg.indexOf(this.ECONNREFUSED) > -1) {
          if (!retry) {
            this.interval = setInterval(() => {
              this.sendFreeswitchConfigRequest(true);
            }, 5000); // retry every 5 seconds
            this.timeout = setTimeout(() => {
              clearInterval(this.interval);
              this.alertSvc.setAlert(toastMsgTypes.ERROR, 'createFreeswitchConfig.' + rej.body.name);
              this.spinnerSvc.hide();
              this.isSubmitting = false;
              this.updateAccountTagsWithProgress(false, pbxMessages.ERR_SIP_TRUNK_CONFIG);
            }, 120000); // show error message if > 2mins
          }
          return;
        } else if (errorMsg.indexOf(this.ERR_CONNECT_MEDIA) > -1) {
          errorMsg = pbxMessages.ERR_CONNECT_MEDIA;
        } else {
          if(rej.body.name){
            errorMsg = 'createFreeswitchConfig.' + rej.body.name;
          }
          else{
            errorMsg = 'createFreeswitchConfig.TimeoutError';
          }

        }
      } else if (typeof rej === 'string') {
        console.error('failed to set freeswitch config', rej);
        errorMsg = rej;
      } else if (rej instanceof Error && rej.message === commonErrorMessages.AUTH_ERROR) {
        console.error('failed to set freeswitch config', rej.message);
        this.authSvc.redirectToLogin();
      } else {
        console.error('failed to set freeswitch config', rej);
        errorMsg = pbxMessages.ERR_SIP_TRUNK_CONFIG_TOAST;
      }
      if (retry) {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
      }
      this.alertSvc.setAlert(toastMsgTypes.ERROR, errorMsg);
      this.spinnerSvc.hide();
      this.isSubmitting = false;
      this.updateAccountTagsWithProgress(false, pbxMessages.ERR_SIP_TRUNK_CONFIG);
    }
  }

  async checkCloudlinkStatus(retry: boolean = false) {
    try {
      const cloudlinkStatus = await this.tunnelSvc.getCloudlinkStatus(this.account.accountId, this.site.siteId);
      // update pbxLink state when we get the status
      const pbxLink = await this.tunnelSvc.getPbxlink(this.account.accountId, this.site.siteId, this.pbxLink._id);
      if (pbxLink) {
        this.pbxLink = pbxLink;
      }
      this.pbxConnected = true;
      let statusMsg = '';
      const tagParams = {};
      if (cloudlinkStatus) {
        if (!cloudlinkStatus.cstaproxy) {
          this.pbxConnected = false;
          statusMsg = gatewayConnectStateMessages.LOST_CSTA_CONNECTION;
        } else if (cloudlinkStatus.pbx_links === 'down') {
          this.pbxConnected = false;
          statusMsg = gatewayConnectStateMessages.LOST_OAI_CONNECTION;
        } else if (this.pbxLink && this.pbxLink['connect_error'] && this.pbxLink['connect_error'] !== '') {
          this.pbxConnected = false;
          statusMsg = this.echoSvc.getStatusMessageFromConnectError(this.pbxLink['connect_error']);
        } else if (!this.certificateUpdate && cloudlinkStatus.pbx_links === 'init') {
          this.pbxConnected = false;
          statusMsg = gatewayConnectStateMessages.NOT_START_PBX_CONNECTION;
        } else if (this.pbxLink && this.pbxLink.type === pbxTypes.MIVO250) {
          // TODO: Remove when freeswitch config is ready for mvo400
          if (cloudlinkStatus.freeswitch_registrations === 0 || !cloudlinkStatus.cloudgateway) {
            this.pbxConnected = false;
            if (cloudlinkStatus.freeswitch_registrations === 0) {
              if (!cloudlinkStatus.cloudgateway) {
                statusMsg = gatewayConnectStateMessages.FAILED_REGISTER_PBX_MEDIA;
              } else {
                statusMsg = gatewayConnectStateMessages.FAILED_REGISTER_PBX;
              }
            } else {
              statusMsg = gatewayConnectStateMessages.FAILED_REGISTER_MEDIA;
            }
            this.checkFreeswitchRegistration(retry, statusMsg);
            return;
          }
        } else { // Check cloudgateway for all other pbx types
          if (!cloudlinkStatus.cloudgateway) {
            statusMsg = gatewayConnectStateMessages.FAILED_REGISTER_PBX_MEDIA;
          }
        }
      }

      if (this.pbxConnected) {
        tagParams['gateway-connection'] = { connected: true, pbxConnected: this.pbxConnected };
      } else {
        tagParams['gateway-connection'] = { connected: true, pbxConnected: this.pbxConnected, reason: statusMsg };
      }
      const tags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
      this.accountSvc.setAccountTags(tags);
      this.accountTags = tags;
      this.spinnerSvc.hide();
      // Update Account Tags
      this.updateAccountTagsWithProgress(true);
      if (retry) {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
      }
    } catch (err) {
      this.spinnerSvc.hide();
      this.isSubmitting = false;
      this.updateAccountTagsWithProgress(false, pbxMessages.ERR_SERVER_UNAVAILABLE);
      if (err && err.statusCode === 401) {
        console.error('get cloudlinkStatus failed', JSON.stringify(err));
        this.authSvc.redirectToLogin();
      } else if (err && err.statusCode === 403) {
        console.error('get cloudlinkStatus failed', JSON.stringify(err));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (err && err.text && typeof err.text === 'function') {
        err.text().then(res => console.error('get cloudlinkStatus failed', res))
      } else if (err instanceof Error) {
        console.error('get cloudlinkStatus failed', err.message);
        if (err.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('get cloudlinkStatus failed', err);
      }
    }
  }

  checkFreeswitchRegistration(retry: boolean = false, statusMsg: string) {
    console.log('check freeswitch registration');
    this.spinnerSvc.setMessage(pbxMessages.WAIT_FREESWTICH);
    if (!retry) {
      this.interval = setInterval(() => {
        this.checkCloudlinkStatus(true);
      }, 3000); // retry every 3 seconds
      this.timeout = setTimeout(async () => {
        clearInterval(this.interval);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, statusMsg);
        const tagParams = {};
        tagParams['gateway-connection'] = { connected: true, pbxConnected: false, reason: statusMsg };
        const tags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
        this.accountTags = tags;
        this.spinnerSvc.hide();
        this.updateAccountTagsWithProgress(true);
      }, 60000); // show error message if > 60 seconds
    }
  }

  async updateAccountTagsWithProgress(success: boolean, reason?: string) {
    this.accountSvc.setPbxlink(this.pbxLink);
    // Add Progress to Account tags
    const tagParams = this.getAccountTagParameters(success, reason);
    try {
      const result = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
      console.log('account tag updated', result);
      this.accountSvc.setAccountTags(result);
      this.accountTags = result;
      this.isSubmitting = false;
      this.isFormFieldsChanged = false;

      if (this.accountTags && this.accountTags['gateway-connection'] &&
        this.accountTags['gateway-connection']['pbxConnected'] === false) {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, this.accountTags['gateway-connection']['reason']);
      }

      let canContinue = true;
      if (result && result['gateway-connection'] &&
        result['gateway-connection']['pbxConnected'] === false) {
        canContinue = false;
      }

      if (success && canContinue) { // Only proceed to Next page if successful
        this.goNext();
      }
    } catch (reason) {
      this.isSubmitting = false;
      if (reason && reason.statusCode === 401) {
        console.error('failed to set account tag', JSON.stringify(reason));
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('failed to set account tag', JSON.stringify(reason));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (typeof reason.text === 'function') {
        reason.text().then(res => console.error('failed to set account tag', res))
      } else if (reason instanceof Error) {
        console.error('failed to set account tag', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to set account tag', reason);
      }
    }
  }

  async updateAccountTagsWithPbxConnectError(error: string) {
    const tagParams = {};
    const statusMsg = this.echoSvc.getStatusMessageFromConnectError(error);
    tagParams['gateway-connection'] = { connected: true, pbxConnected: false, reason: statusMsg };
    const tags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
    this.accountSvc.setAccountTags(tags);
  }

  async getPbxData() {
    if ( this.account && this.site &&
      this.accountTags && this.accountTags['on-board-progress']['step'] === 2 &&
      this.accountTags['gateway-connection'] && this.accountTags['gateway-connection']['connected'] &&
      this.accountTags['gateway-connection']['pbxConnected'] === false ) {
        this.spinnerSvc.show();
        this.spinnerSvc.setMessage(pbxMessages.GET_PBXLINKS);
        try {
          const pbxData = await this.tunnelSvc.getPbxlinks(this.account.accountId, this.site.siteId);
          if (pbxData) {
              const pbxs = Utils.getItemsFromCollection<Pbxlink>(pbxData);
              if (pbxs[0]) {
                  this.pbxId = pbxs[0]._id;
                  this.accountSvc.setPbxlink(pbxs[0]);
              }
          }
          this.spinnerSvc.hide();
        } catch (reason) {
            this.spinnerSvc.hide();
            console.error('failed to get PBX links', reason);
            if (reason && reason.statusCode === 401) {
                this.authSvc.redirectToLogin();
            } else if (reason instanceof Error) {
                if (reason.message === commonErrorMessages.AUTH_ERROR) {
                    this.authSvc.redirectToLogin();
                }
            }
        }
    }
  }

  isMBGValid() {
    const fieldsValid = this.pbxForm.controls['mbgIpAddress'].valid && this.pbxForm.controls['mbgPassword'].valid;
    if (this.pbxLink && this.pbxLink.type === pbxTypes.MIVB) {
      return fieldsValid && this.areMBGFieldsFilled();
    } else {
      return fieldsValid && (this.areMBGFieldsFilled() || this.noMBGFieldsFilled());
    }
  }


  isPbxFormValid() {
    const type = this.pbxForm.getRawValue().pbxType;
    switch (type) {
      case pbxTypes.MIVO250:
        return (
          this.pbxForm.controls['name'].valid &&
          this.pbxForm.controls['ipAddress'].valid &&
          this.pbxForm.controls['password'].valid &&
          this.pbxForm.controls['port'].valid &&
          this.pbxForm.controls['trunk_username'].valid &&
          this.pbxForm.controls['trunk_password'].valid &&
          this.pbxForm.controls['trunk_group_extension'].valid
        );
      case pbxTypes.MIVO400:
      case pbxTypes.MIVC:
        return (
          this.pbxForm.controls['name'].valid &&
          this.pbxForm.controls['ipAddress'].valid &&
          this.pbxForm.controls['port'].valid &&
          this.pbxForm.controls['username'].valid &&
          this.pbxForm.controls['password'].valid
        );
      case pbxTypes.MXONE:
      case pbxTypes.MV5000:
        return (
          this.pbxForm.controls['name'].valid &&
          this.pbxForm.controls['ipAddress'].valid &&
          this.pbxForm.controls['miCollabIpAddress'].valid &&
          this.pbxForm.controls['miCollabPassword'].valid &&
          this.pbxForm.controls['port'].valid
        );
      case pbxTypes.MIVB:
        return (
          this.pbxForm.controls['name'].valid &&
          this.pbxForm.controls['ipAddress'].valid &&
          this.isMBGValid() &&
          this.pbxForm.controls['miCollabIpAddress'].valid &&
          this.pbxForm.controls['miCollabPassword'].valid &&
          this.pbxForm.controls['port'].valid
        );
    }
  }

  shouldValidate(fieldName: string) {
    const type = this.pbxForm.getRawValue().pbxType;
    switch (type) {
      case pbxTypes.MIVO250:
        return (
          fieldName === 'name' ||
          fieldName === 'ipAddress' ||
          fieldName === 'password' ||
          fieldName === 'port' ||
          fieldName === 'trunk_username' ||
          fieldName === 'trunk_password' ||
          fieldName === 'trunk_group_extension'
        );
      case pbxTypes.MIVO400:
      case pbxTypes.MIVC:
        return (
          fieldName === 'name' ||
          fieldName === 'ipAddress' ||
          fieldName === 'password' ||
          fieldName === 'port' ||
          fieldName === 'username'
        );
      case pbxTypes.MXONE:
      case pbxTypes.MV5000:
        return (
          fieldName === 'name' ||
          fieldName === 'ipAddress' ||
          fieldName === 'miCollabIpAddress' ||
          fieldName === 'miCollabPassword' ||
          fieldName === 'port'
        );
      case pbxTypes.MIVB:
        return (
          fieldName === 'name' ||
          fieldName === 'ipAddress' ||
          fieldName === 'mbgIpAddress' ||
          fieldName === 'mbgPassword' ||
          fieldName === 'miCollabIpAddress' ||
          fieldName === 'miCollabPassword' ||
          fieldName === 'port'
        );
    }
  }

  getAlertContextString(){
    let contextString = undefined;
    switch (this.submitStep) {
      case pbxFormStepName.UPDATE_PBXLINK:
        contextString = pbxMessages.ERR_UPDATE_PBX;
        break;
      case pbxFormStepName.UPDATE_ACCOUNT_TAGS:
        contextString = pbxMessages.ERR_UPDATE_TAGS;
        break;
      case pbxFormStepName.UPDATE_MBG:
        contextString = pbxMessages.ERR_UPDATE_MBG;
      break;
      case pbxFormStepName.UPDATE_MICOLLAB:
        contextString = pbxMessages.ERR_UPDATE_MICOLLAB;
        break;
      case pbxFormStepName.DELETE_MICOLLAB:
        contextString = pbxMessages.ERR_DELETE_MICOLLAB;
        break;
      case pbxFormStepName.FREESWITCH:
        contextString = pbxMessages.ERR_FREESWITCH;
        break;
      case pbxFormStepName.CREATE_PBXLINK:
        contextString = pbxMessages.ERR_CREATE_PBX;
        break;
      case pbxFormStepName.CREATE_MBG:
        contextString = pbxMessages.ERR_CREATE_MBG;
        break;
      case pbxFormStepName.CREATE_MICOLLAB:
        contextString = pbxMessages.ERR_CREATE_MICOLLAB;
        break;
      default:
        contextString = undefined;
        break;
    }
    return contextString;
  }

  async updatePbx() {
    return (async() => {
      const updateParams = this.getPbxUpdateParams();
      this.submitStep = pbxFormStepName.UPDATE_PBXLINK;
      const response = await this.tunnelSvc.updatePbxlink(this.account.accountId, this.site.siteId, this.pbxLink?._id, updateParams);
      this.formDataSvc.removeFormData('pbx');
      this.pbxForm.markAsPristine();
      this.pbxLink = response;
      this.pbxIntervalCount = 0;
      if (response.connect_error) {
        console.error('error connecting to PBX', response.connect_error);
        this.submitStep = pbxFormStepName.UPDATE_ACCOUNT_TAGS;
        await this.updateAccountTagsWithPbxConnectError(response.connect_error);
        this.spinnerSvc.hide();
        this.isSubmitting = false;
        clearInterval(this.pbxInterval);
        return;
      } else {
        clearInterval(this.pbxInterval);
      }

        if (this.useMBGFields()) {
          this.submitStep = pbxFormStepName.UPDATE_MBG;
          await this.tunnelSvc.updateMBGRequest(this.account.accountId, this.site.siteId, updateParams);
        }
        // Keep the logic for DELETE in case other PBXs come along later and want optional fields to switch between MBG and Freeswitch.
        // else if (
        //   this.noMBGFieldsFilled() &&
        //   (this.mbgConfig && this.mbgConfig.ipAddressHostname.length > 0 && this.mbgConfig.adminPassword.length > 0)) {
        //   await this.tunnelSvc.deleteMBGRequest(this.account.accountId, this.site.siteId);
        // }

      if (this.useMiCollabFields()) {
        try{
          this.submitStep = pbxFormStepName.UPDATE_MICOLLAB;
          await this.tunnelSvc.updateMiCollabRequest(this.account.accountId, this.site.siteId, updateParams);
          this.errorUpdatingMiCollabSettings = false;
        }catch(error){
          this.errorUpdatingMiCollabSettings = true;
          throw error;
        }
      } else {
        if (this.deleteMiCollabConfigIfNeeded()) {
          this.submitStep = pbxFormStepName.DELETE_MICOLLAB;
          await this.tunnelSvc.deleteMiCollabRequest(this.account.accountId, this.site.siteId);
        }
      }
        this.submitStep = pbxFormStepName.FREESWITCH;
        await this.sendFreeswitchConfigRequest();

        if (this.pbxConnected) {
          this.alertSvc.setAlert(toastMsgTypes.INFO, pbxMessages.UPDATE_PBX);
        }
        
    })().catch((reason: any) => {
      console.error('Save failed on PBX form - edit mode');
      let statusCode = reason && reason.statusCode?  reason.statusCode : undefined;
      let alertContext = this.getAlertContextString();
      console.error(`${this.submitStep} failed`, JSON.stringify(reason));
      if (statusCode === 401) {
        this.authSvc.redirectToLogin();
      } else if (statusCode === 403) {
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (statusCode === 500) {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_UPDATE_SERVER, null, alertContext);
      } else if (statusCode === 503) {
        this.pbxIntervalCount++;
        if (this.pbxIntervalCount >= 7) { // Try 7 times
          this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_UPDATE_SERVER, null, alertContext);
        } else {
          return;
        }
      } else if (statusCode === 504) {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_UPDATE_TIMEOUT, null, alertContext);
      } else if (reason.body && reason.body.name) {
        //dont know all the possible names. Could result in missing the string in en.json - let the field drive it
        this.alertSvc.setAlert(toastMsgTypes.ERROR, 'updatePbxlink.' + reason.body.name);
      } else if (reason instanceof Error) {
        console.error(`${this.submitStep} failed`, reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        } else if(alertContext) {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, alertContext);
        } else {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_UPDATE_PBX);
        }
      } else if ((reason?.error?.message?.includes('ENOTFOUND') || reason?.error?.message?.includes('EHOSTUNREACH'))
        && reason?.url.includes('/settings/mbg')) {
        console.error('MBG FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MBG_IP_INVALID);
      } else if ((reason?.error?.message?.includes('ENOTFOUND') || reason?.error?.message?.includes('EHOSTUNREACH'))
        && reason?.url.includes('/settings/micollab')) {
        console.error('MiCollab FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MICOLLAB_IP_INVALID);
      } else if (reason?.error?.message?.includes('failed to authorize') && reason?.url.includes('/settings/mbg')) {
        console.error('MBG FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MBG_FAILURE_TO_AUTHORIZE);
      } else if (reason?.error?.message?.includes('failed to authorize') && reason?.url.includes('/settings/micollab')) {
        console.error('MiCollab FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MICOLLAB_FAILURE_TO_AUTHORIZE);
      } else if (reason?.error?.message?.includes('failed to initiate') && reason?.url.includes('/settings/micollab')) {
        console.error('MiCollab FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MICOLLAB_FAILURE_TO_INITIATE);
      } else {
        if (reason && (typeof reason === 'string')) {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, reason);
        } else if(alertContext) {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, alertContext);
        } else {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_UPDATE_PBX);
        }
      }

      this.pbxIntervalCount = 0;
      clearInterval(this.pbxInterval);
      this.spinnerSvc.hide();
      this.isSubmitting = false;
    });
  }

  async createPbx() {
    return (async () => {
      const params = this.getPbxParams();
      this.submitStep = pbxFormStepName.CREATE_PBXLINK;
      const response = await this.tunnelSvc.createPbxlink(this.account.accountId, this.site.siteId, params);
      this.pbxLink = response;
      this.pbxIntervalCount = 0;
      this.formDataSvc.removeFormData('pbx');
      this.pbxForm.controls['pbxType'].disable();
      this.pbxForm.markAsPristine();
      this.submitStep = pbxFormStepName.UPDATE_ACCOUNT_TAGS;
      await this.updateProductTagsIfRequired(params.type);
      if (response.connect_error) {
        console.error('error connecting to PBX', response.connect_error);
        this.submitStep = pbxFormStepName.UPDATE_ACCOUNT_TAGS;
        await this.updateAccountTagsWithPbxConnectError(response.connect_error);
        this.spinnerSvc.hide();
        this.isSubmitting = false; 
        clearInterval(this.pbxInterval);
        return;
      } else {
        clearInterval(this.pbxInterval);
      }
      this.alertSvc.setAlert(toastMsgTypes.INFO, pbxMessages.CREATE_PBX); 
      
      let tagParams = { 'pbx-status': { status: pbxStatus.UP } };
      this.submitStep = pbxFormStepName.UPDATE_ACCOUNT_TAGS;
      const tags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
      this.accountSvc.setAccountTags(tags);
      this.accountTags = tags;

      // if MBG fields are set for MiVB, then set them
      if (this.useMBGFields()) {
        this.submitStep = pbxFormStepName.CREATE_MBG;
        await this.tunnelSvc.createMBGRequest(this.account.accountId, this.site.siteId, params);
      }

      // if MiCollab fields are set for MiVB, MV5000, MX-ONE --> then set them
      if (this.useMiCollabFields()) {
        this.submitStep = pbxFormStepName.CREATE_MICOLLAB;
        await this.tunnelSvc.createMiCollabRequest(this.account.accountId, this.site.siteId, params);
      }
      this.submitStep = pbxFormStepName.FREESWITCH;
      await this.sendFreeswitchConfigRequest();
    })().catch(reason => {
      console.error('Save failed on PBX form - create mode');
      let statusCode = reason && reason.statusCode?  reason.statusCode : undefined;
      let alertContext = this.getAlertContextString();
      console.error(`${this.submitStep} failed`, JSON.stringify(reason));
      if (statusCode === 401) {
        this.authSvc.redirectToLogin();
      } else if (statusCode === 403) {
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (statusCode === 500) {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_UPDATE_SERVER, null, alertContext);
      } else if (statusCode === 503) {
        this.pbxIntervalCount++;
        if (this.pbxIntervalCount >= 7) {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_UPDATE_SERVER, null, alertContext);
          clearInterval(this.pbxInterval);
        } else {
          return;
        }
      } else if (statusCode === 504) {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_UPDATE_TIMEOUT, null, alertContext);
      } else if (reason.body && reason.body.name) {
        //dont know all the possible names. Could result in missing the string in en.json - let the field drive it
        this.alertSvc.setAlert(toastMsgTypes.ERROR, 'createPbxlink.' + reason.body.name);
      } else if (reason instanceof Error) {
        console.error(`${this.submitStep} failed`, reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        } else if(alertContext) {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, alertContext);
        } else {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_CREATE_PBX);
        }
      } else if ((reason?.error?.message?.includes('ENOTFOUND') || reason?.error?.message?.includes('EHOSTUNREACH'))
      && reason?.url.includes('/settings/mbg')) {
        console.error('MBG FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MBG_IP_INVALID);
      } else if ((reason?.error?.message?.includes('ENOTFOUND') || reason?.error?.message?.includes('EHOSTUNREACH'))
        && reason?.url.includes('/settings/micollab')) {
        console.error('MiCollab FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MICOLLAB_IP_INVALID);
      } else if (reason?.error?.message?.includes('failed to authorize') && reason?.url.includes('/settings/mbg')) {
        console.error('MBG FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MBG_FAILURE_TO_AUTHORIZE);
      } else if (reason?.error?.message?.includes('failed to authorize') && reason?.url.includes('/settings/micollab')) {
        console.error('MiCollab FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MICOLLAB_FAILURE_TO_AUTHORIZE);
      } else if (reason?.error?.message?.includes('failed to initiate') && reason?.url.includes('/settings/micollab')) {
        console.error('MiCollab FQDN/IP address error:', reason.error.message);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.MICOLLAB_FAILURE_TO_INITIATE);
      } else {
        if (reason && (typeof reason === 'string')) {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, reason);
        } else if(alertContext) {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, alertContext);
        } else {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_CREATE_PBX);
        }
      }

      this.pbxIntervalCount = 0;
      clearInterval(this.pbxInterval);
      this.spinnerSvc.hide();
      this.isSubmitting = false;
      this.updateAccountTagsWithProgress(false);
    })
  }

  validateAllFormFields() {
    let targetName;
    Object.keys(this.pbxForm.controls).forEach(field => {
      if (this.shouldValidate(field)) {
        const control = this.pbxForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched();
          if (!control.valid && !targetName) {
            targetName = field;
          }
        }
      }
    });

    // Scroll to the target control
    if (targetName) {
      const element = document.getElementsByName(`pbx-${targetName}`)[0];
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  async onSubmit() {
    if (!this.isPbxFormValid()) {
      this.validateAllFormFields();
      return;
    }
    this.alertSvc.clearAlert();
    if (!this.isOverview) {
      window.scrollTo(0, 0);
    }
    this.sessionData = null;
    this.isSubmitting = true;
    this.newCloudlinkPswd = false;
    this.newTrunkPswd = false;
    this.newMiCollabPswd = false;
    this.showPswd = false;
    this.showTruckPswd = false;
    this.showMiCollabPswd = false;
    this.isFormFieldsChanged = this.isAffectConnectFieldsChanged();
    let intervalTime;
    if (this.isEditMode()) {
      this.spinnerSvc.show();
      this.spinnerSvc.setMessage(pbxMessages.CONFIG_PBX);
      let result;
      
      const type = this.pbxForm.getRawValue().pbxType;
      if (type === pbxTypes.MIVB && !this.isCertificateInstalled) {
        intervalTime = 5000; // This time set feels like a good interval to use
        result = await this.setCertificate();
        this.certificateUpdate = true;
        if (!result) {
          this.spinnerSvc.hide();
          this.isSubmitting = false;
          return;
        }
      }

      if (intervalTime) {
        this.pbxInterval = setInterval(() => {
          this.updatePbx();
        }, intervalTime);
      } else { 
        this.updatePbx();
      }
    } else {
      this.spinnerSvc.show();
      this.spinnerSvc.setMessage(pbxMessages.CONFIG_PBX);
      const type = this.pbxForm.getRawValue().pbxType;
      if (type === pbxTypes.MIVB) {
        intervalTime = 5000;
        const result = await this.setCertificate();
        if(!result){
          this.spinnerSvc.hide();
          this.isSubmitting = false;
          return;
        }
      }

      if (intervalTime) {
        this.pbxInterval = setInterval(() => {
          this.createPbx();
        }, intervalTime);
      } else {
        this.createPbx();
      }
    }
  }

  goBack() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview PBX' : 'PBX', 'Cancel');
    if (this.isEditMode()) {
      this.formDataSvc.redirectToDashboard(undefined, undefined, 'account');// redirect to accounts page when clicked if iframed
    } else {
      if (!this.isOverview) {
        const backUrl = '/accounts/' + this.account.accountId + '/sites/' + this.site.siteId;
        this.router.navigateByUrl(backUrl);
      } else {
        const element = document.getElementsByTagName('app-site')[0];
        element.scrollIntoView();
      }
    }
  }

  goNext() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview PBX' : 'PBX', 'Next');
    if (!this.isOverview) {
      const url = '/accounts/' + this.account.accountId + '/sites/' + this.site.siteId + '/connect';
      this.router.navigateByUrl(url);
    } else {
      const element = document.getElementsByTagName('app-connect-state')[0];
      element.scrollIntoView();
    }
  }

  infoIconItem(labelName: string): any {
    return this.tooltipSvc.getTooltipItem(labelName);
  }

  isAffectConnectFieldsChanged() {
    if (this.pbxLink && this.freeswitchConfig) {
      const type = this.pbxForm.getRawValue().pbxType;
      const formValue = this.pbxForm.value;
      if (type === pbxTypes.MIVO250) {
        return !(formValue.ipAddress === this.pbxLink.connection_ip_address &&
          formValue.password === this.pbxLink.connection_password &&
          formValue.port === this.pbxLink.connection_port &&
          formValue.trunk_username === this.freeswitchConfig.pbx_trunk_username &&
          formValue.trunk_password === this.freeswitchConfig.pbx_trunk_password &&
          formValue.trunk_group_extension === this.pbxLink.sip_trunk_group);
      } else {
        return !(formValue.ipAddress === this.pbxLink.connection_ip_address &&
          formValue.username === this.pbxLink.connection_username &&
          formValue.password === this.pbxLink.connection_password &&
          formValue.port === this.pbxLink.connection_port &&
          formValue.trunk_username === this.freeswitchConfig.pbx_trunk_username &&
          formValue.trunk_password === this.freeswitchConfig.pbx_trunk_password);
      }
    }
    return false;
  }

  isEditMode() {
    if (this.pbxLink) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.pbxForm && this.pbxForm.dirty) {
      const formValue = this.pbxForm.value;
      const formRawValue = this.pbxForm.getRawValue();
      if (Object.keys(formValue).length !== Object.keys(formRawValue).length) {
        formRawValue.typeDisabled = true;
      }

      if(this.formDataSvc.setSessionData){
        sessionStorage.setItem('pbx-form', JSON.stringify(formRawValue));
      }
    }

    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.accountTagsSubscription) {
      this.accountTagsSubscription.unsubscribe();
    }
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
    if (this.pbxSubcription) {
      this.pbxSubcription.unsubscribe();
    }
    if(this.platformSubscription) {
      this.platformSubscription.unsubscribe();
    }
    if(this.selectedPbxTypeSubscription) {
      this.selectedPbxTypeSubscription.unsubscribe();
    }
  }
}
