import { Injectable } from '@angular/core';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { Account } from '@mitel/cloudlink-sdk/admin';
import { Subject, of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private claims: UserClaims;
  public claimsChanged = new Subject<UserClaims>();

  private company: Account;
  public companyChanged = new Subject<Account>();

  constructor() { }

  getClaims(): UserClaims {
    return this.claims;
  }

  setClaims(claims: UserClaims) {
    this.claims = claims;
    this.claimsChanged.next(this.claims);
  }

  isAssumedRole(): boolean {
    return localStorage.getItem('@mitel/token.original_token') ? true : false;
  }

  isAccountAdmin() {
    return !this.isAssumedRole() && this.getClaims().role === 'ACCOUNT_ADMIN';
  }

  getCompany() {
    return this.company;
  }

  setCompany(company: Account) {
    this.company = company;
    this.companyChanged.next(this.company);
  }
}
