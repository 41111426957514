// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
	text-align: center;
}

.title {
	font-family: MuseoSans-500;
	font-size: 24px;
	color: #15325F;
	letter-spacing: 0.01px;
	margin-top: 30px;
	margin-bottom: 13px;
}

.close-button {
	margin-top: -6px;
	margin-bottom: 16px;
	padding-right: 13px;
}

.content {
	font-family: MuseoSans-300;
	font-size: 12px;
	color: #1C1D1D;
	letter-spacing: 0.01px;
	line-height: 29px;
	margin-bottom: 38px;
}

.description {
	line-height: 1.25;
	margin-bottom: 25px;
}

:host >>> a {
	font-family: MuseoSans-300;
	font-size: 12px;
	color: #00A1E0;
}

.btn-primary {
	width: 160px;
}

.actions {
	position: relative;
	bottom: 20px;
	margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/connect-error/connect-error.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,sBAAsB;CACtB,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;CAChB,mBAAmB;CACnB,mBAAmB;AACpB;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,sBAAsB;CACtB,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,cAAc;AACf;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,gBAAgB;AACjB","sourcesContent":[":host {\n\ttext-align: center;\n}\n\n.title {\n\tfont-family: MuseoSans-500;\n\tfont-size: 24px;\n\tcolor: #15325F;\n\tletter-spacing: 0.01px;\n\tmargin-top: 30px;\n\tmargin-bottom: 13px;\n}\n\n.close-button {\n\tmargin-top: -6px;\n\tmargin-bottom: 16px;\n\tpadding-right: 13px;\n}\n\n.content {\n\tfont-family: MuseoSans-300;\n\tfont-size: 12px;\n\tcolor: #1C1D1D;\n\tletter-spacing: 0.01px;\n\tline-height: 29px;\n\tmargin-bottom: 38px;\n}\n\n.description {\n\tline-height: 1.25;\n\tmargin-bottom: 25px;\n}\n\n:host >>> a {\n\tfont-family: MuseoSans-300;\n\tfont-size: 12px;\n\tcolor: #00A1E0;\n}\n\n.btn-primary {\n\twidth: 160px;\n}\n\n.actions {\n\tposition: relative;\n\tbottom: 20px;\n\tmargin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
