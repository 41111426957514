import { Injectable } from '@angular/core';
import { Admin2Service } from './admin2.service';
import { AuthenticationService } from './authentication.service';
import { AccountService } from './account.service';
import { commonErrorMessages, pbxTypes } from '../shared/constants';
import { CloudTrunking } from '../shared/admin2/cloudTrunking';
import { CloudPbx } from '../shared/admin2/cloudPbx';
import { CloudAppliance } from '../shared/admin2/cloudAppliance';
import { FreeswitchConfig } from '@mitel/cloudlink-sdk/tunnel';


@Injectable()
export class CloudResourcesService {
  cloudTrunking: CloudTrunking;
  cloudPbx: CloudPbx;
  cloudAppliance: CloudAppliance;

  freeswitchConfig: FreeswitchConfig;

  constructor(private adminSvc: Admin2Service,
              private authSvc: AuthenticationService,
              private accountSvc: AccountService) {}

  async setCloudAppliance() {
    const account = this.accountSvc.getAccount();
    if (account) {
      try {
        const response = await this.adminSvc.getCloudAppliances(account.accountId);
        if (response && response.cloudAppliances) {
          this.cloudAppliance = response.cloudAppliances[0];
          await this.updateCloudAppliance();
        } else {
          // create cloud resource
          await this.createCloudAppliance();
        }
      } catch (reason) {
        if (reason.statusCode === 404) {
          // create cloud resource
          this.createCloudAppliance();
        } else if (reason instanceof Error &&
                   reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        } else {
          console.error('error getting cloud appliance', reason);
        }
      }
    }
  }

  async createCloudAppliance() {
    const account = this.accountSvc.getAccount();
    const applianceParams = this.getCloudApplianceParams();
    if (account && applianceParams) {
      try {
        const response = await this.adminSvc.createCloudAppliance(account.accountId, applianceParams);
        console.log('created cloud appliance');
        this.cloudAppliance = response;
      } catch (reason) {
        if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('error creating cloud appliance', res);
          });
        } else if (reason instanceof Error &&
                   reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('error creating cloud appliance', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          console.error('error creating cloud appliance', reason)
        }
      }
    }
  }

  async updateCloudAppliance() {
    const account = this.accountSvc.getAccount();
    const applianceUpdateParams = this.getCloudApplianceParams();
    if (account && this.cloudAppliance && applianceUpdateParams) {
      try {
        const response = await this.adminSvc.updateCloudAppliance(account.accountId, this.cloudAppliance.id, applianceUpdateParams);
        this.cloudAppliance = response;
      } catch (reason) {
        if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('error updating cloud appliance', res);
          });
        } else if (reason instanceof Error &&
                   reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('error updating cloud appliance', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          console.error('error updating cloud appliance', reason);
        }
      }
    }
  }

  private getCloudApplianceParams() {
    const site = this.accountSvc.getSite();
    const networkConfig = this.accountSvc.getNetworkConfig();
    const upgradeSchedule = this.accountSvc.getUpgradeSchedule();

    if (!site) {
      return null;
    }

    let params: any = {
      name: 'freeswitch',
      siteId: site.siteId,
    };

    if (this.cloudAppliance) {
      params = this.cloudAppliance;
    }

    if (networkConfig) {
      params.network = {
        lan1: networkConfig.lan1,
        lan2: networkConfig.lan2 ? networkConfig.lan2 : { ipv4: { mode: 'dhcp' } }
      };
    }

    if (upgradeSchedule) {
      if (<any>upgradeSchedule.host_dayOfWeek && <any>upgradeSchedule.host_dayOfWeek !== '') {
        params.update = {
          host_onBoot: upgradeSchedule.host_onBoot,
          host_hour: '' + upgradeSchedule.host_hour,
          host_minute: upgradeSchedule.host_minute,
          host_dayOfWeek: upgradeSchedule.host_dayOfWeek instanceof Array ?
                          upgradeSchedule.host_dayOfWeek :
                          [upgradeSchedule.host_dayOfWeek],
          container_onBoot: upgradeSchedule.container_onBoot,
          container_hour: '' + upgradeSchedule.container_hour,
          container_minute: upgradeSchedule.container_minute,
          container_dayOfWeek: upgradeSchedule.container_dayOfWeek instanceof Array ?
                               upgradeSchedule.container_dayOfWeek :
                               [upgradeSchedule.container_dayOfWeek],
        };
      } else {
        params.update = {
          'host_onBoot': true,
          'host_hour': '',
          'host_minute': '',
          'host_dayOfWeek': [],
          'container_onBoot': true,
          'container_hour': '',
          'container_minute': '',
          'container_dayOfWeek': []
        }
      }
    }
    return params;
  }


  async setCloudPbx() {
    const account = this.accountSvc.getAccount();
    if (account) {
      try {
        const response = await this.adminSvc.getCloudPbxs(account.accountId);
        console.log('Function: setCloudPbx got response');
        if (response && response.cloudPbxs) {
          this.cloudPbx = response.cloudPbxs[0];
          this.updateCloudPbx();
        } else {
          this.createCloudPbx();
        }
      } catch (reason) {
        if (reason.statusCode === 404) {
          this.createCloudPbx();
        } else if (reason instanceof Error &&
                   reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        } else {
          console.error('failed to get cloud pbx', reason);
        }
      }
    }
  }

  async createCloudPbx() {
    const account = this.accountSvc.getAccount();
    const pbxParams = this.getCloudPbxParams();
    if (account && pbxParams) {
      try {
        const response = await this.adminSvc.createCloudPbx(account.accountId, pbxParams);
        console.log('created cloud pbx');
        this.cloudPbx = response;
      } catch (reason) {
        if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('error creating cloud pbx', res);
          });
        } else if (reason instanceof Error &&
                   reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('error creating cloud pbx', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          console.error('error creating cloud pbx', reason);
        }
      }
    }
  }

  async updateCloudPbx() {
    const account = this.accountSvc.getAccount();
    const pbxUpdateParams = this.getCloudPbxParams();
    if (account && this.cloudPbx && pbxUpdateParams) {
      try {
        const response = await this.adminSvc.updateCloudPbx(account.accountId, this.cloudPbx.id, pbxUpdateParams);
        this.cloudPbx = response;
      } catch (reason) {
        if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('error updating cloud pbx', res);
          });
        } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('error updating cloud pbx', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          console.error('error updating cloud pbx', reason);
        }
      }
    }
  }

  private getCloudPbxParams() {
    const pbxLink = this.accountSvc.getPbxlink();
    const site = this.accountSvc.getSite();

    if (!pbxLink || !site) {
      return null;
    }
    const type = this.getPbxTypeForCloudPbx(pbxLink.type);
    const params: any = {
      name: pbxLink.name,
      siteId: site.siteId,
      type: type,
      pbx: {
        connection_ip_address: String(pbxLink.connection_ip_address),
        connection_port: String(pbxLink.connection_port),
        connection_username: String(pbxLink.connection_username),
        connection_password: String(pbxLink.connection_password),
        snapshot_support: typeof pbxLink.snapshot_support === 'boolean' ? pbxLink.snapshot_support : !!pbxLink.snapshot_support,
        advanced_logging: typeof pbxLink.advanced_logging === 'boolean' ? pbxLink.advanced_logging : !!pbxLink.advanced_logging,
        sip_trunk_group: String(pbxLink.sip_trunk_group),
      }
    };

    return params;
  }

  // Convert PBX Type to be stored on the cloud resource
  private getPbxTypeForCloudPbx(pbxType: string) {
    switch (pbxType) {
      case pbxTypes.MIVO250:
        return 'MiVO250';
      case pbxTypes.MIVO400:
        return 'mvo400';
      case pbxTypes.MIVB:
        return 'mivb';
      case pbxTypes.MIVC:
        return 'mivconnect'
      case pbxTypes.MV5000:
        return 'mv5000';
      case pbxTypes.MXONE:
        return 'mxone';
    }
  }

  async setCloudTrunking(freeswitchConfig: FreeswitchConfig) {
    this.freeswitchConfig = freeswitchConfig;
    const account = this.accountSvc.getAccount();
    if (account) {
      try {
        const response = await this.adminSvc.getCloudTrunkings(account.accountId);
        if (response && response.cloudTrunkings) {
          this.cloudTrunking = response.cloudTrunkings[0];
          this.updateCloudTrunking();
        } else {
          this.createCloudTrunking();
        }
      } catch (reason) {
        if (reason.statusCode === 404) {
          this.createCloudTrunking();
        } else if (reason instanceof Error &&
          reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        } else {
          console.error('failed to get cloud trunking', reason);
        }
      }
    }
  }

  async createCloudTrunking() {
    const account = this.accountSvc.getAccount();
    const trunkingParams = this.getCloudTrunkingParams();
    if (account && trunkingParams) {
      try {
        const response = await this.adminSvc.createCloudTrunking(account.accountId, trunkingParams);
        console.log('created cloud trunking');
        this.cloudTrunking = response;
      } catch (reason) {
        if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('error creating cloud trunking', res);
          });
        } else if (reason instanceof Error &&
                   reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('error creating cloud trunking', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          console.error('error creating cloud trunking', reason);
        }
      }
    }
  }

  async updateCloudTrunking() {
    const account = this.accountSvc.getAccount();
    const trunkingUpdateParams = this.getCloudTrunkingParams();
    if (account && this.cloudTrunking && trunkingUpdateParams) {
      try {
        const response = await this.adminSvc.updateCloudTrunking(account.accountId, this.cloudTrunking.id, trunkingUpdateParams);
        this.cloudTrunking = response;
      } catch (reason) {
        if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('error updating cloud trunking', res);
          });
        } else if (reason instanceof Error &&
                   reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('error updating cloud trunking', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          console.error('error updating cloud trunking', reason);
        }
      }
    }
  }

  getCloudTrunkingParams() {
    const site = this.accountSvc.getSite();

    if (!site || !this.freeswitchConfig) {
      return null;
    }

    const params: any = {
      name: 'freeswitch',
      siteId: site.siteId,
      pbx_trunk_password: this.freeswitchConfig.pbx_trunk_password,
      pbx_trunk_username: this.freeswitchConfig.pbx_trunk_username
    };

    return params;
  }
}
