// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
	width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  margin: auto;
  background: rgba(21, 50, 95, 0.5);
}

:host.hide-spinner {
  display: none;
}

.spinner {
	font-family: MuseoSans-500;
	font-size: 13px;
	color: #FFFFFF;
	text-align: center;
	margin-top: 200px;
}

p {
	margin-top: 17px;
}

.cancel-spinner {
  font-family: MuseoSans-700;
  margin-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/spinner/spinner.component.css"],"names":[],"mappings":"AAAA;CACC,WAAW;EACV,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,aAAa;EACb,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,aAAa;AACf;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB","sourcesContent":[":host {\n\twidth: 100%;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 2000;\n  margin: auto;\n  background: rgba(21, 50, 95, 0.5);\n}\n\n:host.hide-spinner {\n  display: none;\n}\n\n.spinner {\n\tfont-family: MuseoSans-500;\n\tfont-size: 13px;\n\tcolor: #FFFFFF;\n\ttext-align: center;\n\tmargin-top: 200px;\n}\n\np {\n\tmargin-top: 17px;\n}\n\n.cancel-spinner {\n  font-family: MuseoSans-700;\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
