import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
import { ParentCommsService, } from '../services/parent-comms.service';
import { environment } from '../../environments/environment';
  
@Injectable()

export class PolicyGuard {
    
    constructor(private clHeader: ClHeaderComponent,
        private parentCommsService: ParentCommsService) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        // determine if we need to redirect to the accounts app so user accesses gwp through iframe
        console.log("IN POLICY GUARD");
        const iframed = this.parentCommsService.isInsideIframe();
        
        if(iframed) {
            // if GWP is loading in an iframe, we don't need to redirect to the accounts app
            return true;
        } else { 
            this.redirectToAccountsApp();
            return false;
        }
    }

    redirectToAccountsApp() {
        this.clHeader.setTokenCookie().then(token => {
            const paramString = this.getUrlParams();
            const redirectUrl = environment.accountsAppUrl + '?checkToken=true' + paramString;
            this.redirectToUrl(redirectUrl);
        }).catch(err => {
            console.warn("Problem setting token cookie", err);
        });
    }

    redirectToUrl(redirectUrl: string){
        window.location.href = redirectUrl;
    }

    getUrlParams() {
        const params:any = this.clHeader.getInvokedParams();
        // Either the params (tunnel, username, password, showToolbar, etc) will be in a state variable, or not
        // They are in a state variable when coming in from the auth portal or micaccess login
        // They are not in a state variable when redirected from the IP of a reset gateway appliance and user is already logged in
        let urlParams:any = {};
        console.log("Raw urlParams: ", params);
        urlParams = params;
        if(params.code) {
            //we don't want to send a gwp auth code to the accounts app
            delete urlParams["code"];
        }
        if(params.state)
        {
            urlParams =  JSON.parse(decodeURIComponent(params.state));
        }
    
        let paramString = "";
        for(const [key, value] of Object.entries(urlParams)) {
            paramString += "&" + key + "=" + value;
        }

        return paramString;
    }
}