
import {map, takeUntil, mergeMap} from 'rxjs/operators';
import { Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  EventEmitter,
  ViewChild } from '@angular/core';
import { Admin2Service } from '../../services/admin2.service';
import { TooltipService } from '../../services/tooltip.service';
import { AnalyticsService } from '../../services/analytics.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tooltip-content',
  templateUrl: './tooltip-content.component.html',
  styleUrls: ['./tooltip-content.component.css']
})
export class TooltipContentComponent implements OnInit, AfterViewInit {
  @ViewChild('tooltipcontent') tooltipcontent;
	@Input() tooltipTitle: string;
  @Input() tooltipContent: string;
  @Input() helpLink: string;
  @Input() tooltipRef: any;
  @Output() close = new EventEmitter();
  mouseup = new EventEmitter<MouseEvent>();
  mousedown = new EventEmitter<MouseEvent>();
  mousemove = new EventEmitter<MouseEvent>();

  mousedrag: Observable<{top, left}>;

  top: number;
  left: number;

  @HostListener('document:mouseup', ['$event'])
  onMouseup(event: MouseEvent) {
    this.mouseup.emit(event);
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event: MouseEvent) {
    this.mousedown.emit(event);
    return false; // Call preventDefault() on the event
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    this.mousemove.emit(event);
  }

  constructor(private element: ElementRef,
              private cdr: ChangeDetectorRef,
              private admin2Svc: Admin2Service,
              private tooltipSvc: TooltipService,
              private analyticsSvc: AnalyticsService) {
    this.mousedrag = this.mousedown.pipe(map(event => {
      return {
        top: event.clientY - this.top,
        left: event.clientX - this.left,
      };
    }),mergeMap(
      imageOffset => this.mousemove.pipe(map(pos => ({
        top: pos.clientY - imageOffset.top,
        left: pos.clientX - imageOffset.left
      })),takeUntil(this.mouseup),)),);
  }

  ngOnInit() {
    this.mousedrag.subscribe({
      next: pos => {
          this.top = pos.top;
          this.left = pos.left;
      }
    });
  }

  ngAfterViewInit(): void {
    this.analyticsSvc.postAMAEvent('TOOLTIP', this.tooltipTitle, '');
    this.show();
    this.cdr.detectChanges();
    setTimeout(_ => this.tooltipcontent.nativeElement.focus());
  }

  show() {
    if (this.tooltipRef) {
      const p = this.positionElements(this.tooltipRef.nativeElement.querySelector('img'), this.element.nativeElement.children[0]);
      this.top = p.top;
      this.left = p.left;
    }
  }

  onClose(event: Event ) {
    event.preventDefault();
    event.stopPropagation();

    this.tooltipSvc.destroy();
  }

  openHelp() {
    window.open(this.helpLink);
  }

  private positionElements(hostEl: HTMLElement, targetEl: HTMLElement): { top: number, left: number } {
    const hostElPos = this.position(hostEl);
    const targetElWidth = targetEl.offsetWidth;
    const targetElHeight = targetEl.offsetHeight;
    let targetElPos: { top: number, left: number };
    targetElPos = {
      top: hostElPos.top - hostElPos.height / 2,
      left: hostElPos.left + hostElPos.width + 5,
    };

    return targetElPos;
  }

  private position(nativeEl: HTMLElement): { width: number, height: number, top: number, left: number } {
    let offsetParentBCR = { top: 0, left: 0 };
    const elBCR = this.offset(nativeEl);
    const offsetParentEl = this.parentOffsetEl(nativeEl);
    if (offsetParentEl !== window.document) {
        offsetParentBCR = this.offset(offsetParentEl);
        offsetParentBCR.top += offsetParentEl.clientTop - offsetParentEl.scrollTop;
        offsetParentBCR.left += offsetParentEl.clientLeft - offsetParentEl.scrollLeft;
    }

    const boundingClientRect = nativeEl.getBoundingClientRect();
    return {
        width: boundingClientRect.width || nativeEl.offsetWidth,
        height: boundingClientRect.height || nativeEl.offsetHeight,
        top: elBCR.top - offsetParentBCR.top,
        left: elBCR.left - offsetParentBCR.left
    };
  }

  private offset(nativeEl: any): { width: number, height: number, top: number, left: number } {
    const boundingClientRect = nativeEl.getBoundingClientRect();
    return {
        width: boundingClientRect.width || nativeEl.offsetWidth,
        height: boundingClientRect.height || nativeEl.offsetHeight,
        top: boundingClientRect.top + (window.pageYOffset || window.document.documentElement.scrollTop),
        left: boundingClientRect.left + (window.pageXOffset || window.document.documentElement.scrollLeft)
    };
  }

  private parentOffsetEl(nativeEl: HTMLElement): any {
    let offsetParent: any = nativeEl.offsetParent || window.document;
    while (offsetParent && offsetParent !== window.document && this.isStaticPositioned(offsetParent)) {
        offsetParent = offsetParent.offsetParent;
    }
    return offsetParent || window.document;
  }

  private isStaticPositioned(nativeEl: HTMLElement): boolean {
    return (this.getStyle(nativeEl, 'position') || 'static' ) === 'static';
  }

 private getStyle(nativeEl: HTMLElement, cssProp: string): string {
    if ((nativeEl as any).currentStyle) { // IE
        return (nativeEl as any).currentStyle[cssProp];
    }

    if (window.getComputedStyle) {
        return (window.getComputedStyle(nativeEl) as any)[cssProp];
    }

    // finally try and get inline style
    return (nativeEl.style as any)[cssProp];
  }
}


