// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spacer {
	height: 30px;
}

.grey-out >>> .container {
	opacity: 0.5 !important;
	pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/overview/overview.component.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,uBAAuB;CACvB,oBAAoB;AACrB","sourcesContent":[".spacer {\n\theight: 30px;\n}\n\n.grey-out >>> .container {\n\topacity: 0.5 !important;\n\tpointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
