// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  text-align: center;
  margin-bottom: 30px;
}
.close-button {
  margin-top: -6px;
  padding-right: 13px;
}
.content-title {
  margin-bottom: 15px;
  font-family: MuseoSans-500;
  font-size: 24px;
  color: #15325F;
  letter-spacing: 0.01px;
}
.content p {
  margin-bottom: 0;
  font-family: MuseoSans-300;
  font-size: 12px;
  color: #1C1D1D;
  letter-spacing: 0.01px;
  line-height: 1.25;
}
.actions {
  position: relative;
  bottom: 20px;
  margin-top: 30px;
}
button {
  width: 160px;
}
button:first-of-type {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/server-unavailable-modal/server-unavailable-modal.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,0BAA0B;EAC1B,eAAe;EACf,cAAc;EACd,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,eAAe;EACf,cAAc;EACd,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".content {\n  text-align: center;\n  margin-bottom: 30px;\n}\n.close-button {\n  margin-top: -6px;\n  padding-right: 13px;\n}\n.content-title {\n  margin-bottom: 15px;\n  font-family: MuseoSans-500;\n  font-size: 24px;\n  color: #15325F;\n  letter-spacing: 0.01px;\n}\n.content p {\n  margin-bottom: 0;\n  font-family: MuseoSans-300;\n  font-size: 12px;\n  color: #1C1D1D;\n  letter-spacing: 0.01px;\n  line-height: 1.25;\n}\n.actions {\n  position: relative;\n  bottom: 20px;\n  margin-top: 30px;\n}\nbutton {\n  width: 160px;\n}\nbutton:first-of-type {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
