import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-gateway-link-confirmation',
  templateUrl: './gateway-link-confirmation.component.html',
  styleUrls: ['./gateway-link-confirmation.component.css']
})
export class GatewayLinkConfirmationComponent implements OnInit {
	@Input() linking = false;
  @Input() accountName: string;
  @Output() link = new EventEmitter();
	linkInput: string;

  constructor(private activeModal: NgbActiveModal, public translateSvc: TranslateService) { }

  ngOnInit() {
  }

  onClose(event: Event) {
    
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.activeModal.close();
    }, 200);
  }

  onKeypress(event) {
    if (event.keyCode === 13) {
      this.onLink(event);
    }
  }

  onLink(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    this.translateSvc.get("gatewayLinkConfirmation.link").subscribe((res: string) => {
      if (this.linkInput === res)
        this.link.emit();
    });
  }
}
