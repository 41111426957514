import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from '../components/unsaved-changes-modal/unsaved-changes-modal.component';
import { AuthenticationService } from '../services/authentication.service';
import { ErrorPermissionsModalComponent } from '../components/error-permission-modal/error-permission-modal.component';
import { AppService } from './app.service';
import { ParentCommsService } from '../services/parent-comms.service';
import { ClHeaderComponent, CompanySwitcherService } from '@mitel/cloudlink-console-components';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';


@Injectable()
export class FormDataService {
  formsUnsavedChanges = [
    { name: 'account', changes: false },
    { name: 'site', changes: false },
    { name: 'pbx', changes: false },
    { name: 'connect', changes: false },
    { name: 'officelink', changes: false },
    { name: 'advanced', changes: false }
  ];
  setSessionData: boolean = true;
  resetForm = new Subject<boolean>();
  isErrorModelOpen: boolean = false;
  isConnectFieldEdited = false;
  appIsDirty: boolean = false;
  appDirtyStateChanged = new Subject<boolean>();

  constructor(
    private appSvc: AppService,
    private router: Router,
    private modalSvc: NgbModal,
    private authSvc: AuthenticationService,
    private zone: NgZone,
    private location: Location,
    private companySwitcherService: CompanySwitcherService,
    private clheader: ClHeaderComponent,
    private parentCommsService: ParentCommsService) {}

  setCurrentFormDirty(fromStep: string, isDirty: boolean) {
    this.updateFormsUnsavedChanged(fromStep, isDirty);
    if(!this.appIsDirty && isDirty){ //app is changing to dirty
      this.appIsDirty = true;
      this.appDirtyStateChanged.next(true);
    } else if(this.appIsDirty && !isDirty){ //app might be changing to not dirty
        this.setAppDirtyState();
    }
  }

  resetCurrentFormDirty(formName: string) {
    this.updateFormsUnsavedChanged(formName, false);
  }

  private updateFormsUnsavedChanged(formName: String, isDirty: boolean) {
    const index = this.formsUnsavedChanges.findIndex(form => form.name === formName);
    if (index > -1) {
      this.formsUnsavedChanges[index].changes = isDirty;
    }
  }

  resetData() {
    this.formsUnsavedChanges = [
      { name: 'account', changes: false },
      { name: 'site', changes: false },
      { name: 'pbx', changes: false },
      { name: 'connect', changes: false },
      { name: 'officelink', changes: false },
      { name: 'advanced', changes: false }
    ];
  }

  notifyforIframeExit() {
    /* Only called when GWP is being iframed and parent wants to exit GWP.
    If any form has unsaved changes, need to show the navigation confirmation
    If no unsaved changes, tell parent it's ok to exit */

    const hasUnsavedChanges = this.checkSessionData();
  	if ( hasUnsavedChanges) {
  		const modalRef = this.modalSvc.open(UnsavedChangesModalComponent);
	    const contentComponentInstance = modalRef.componentInstance;
      contentComponentInstance.formsUnsavedChanges = this.formsUnsavedChanges;
    } else {
      this.parentCommsService.tellParentToExitGatewayPortal(undefined, false);
    }
  }

  // Use this method to check for unsaved changed before navigating
  // To skip checking for unsaved changes, use revertRoleAndNavigate
  redirectToDashboard(currentPage = '', resetRequired?: boolean, iframeRedirectDestination?: string) {
  	const hasUnsavedChanges = this.checkSessionData();
  	if ( hasUnsavedChanges) {
  		const modalRef = this.modalSvc.open(UnsavedChangesModalComponent);
	    const contentComponentInstance = modalRef.componentInstance;
      contentComponentInstance.formsUnsavedChanges = this.formsUnsavedChanges;
      contentComponentInstance.currentPage = currentPage;
      contentComponentInstance.isAccountPage = resetRequired;
      contentComponentInstance.iframeRedirectDestination = iframeRedirectDestination? iframeRedirectDestination : null;
      //if iframed - onOK of the UnsavedChangesModalComponent will tellParentToExitGatewayPortal
      //if not iframed - onOK will emit continue
      if(!this.parentCommsService.isInsideIframe()) {
        contentComponentInstance.continue.subscribe(() => {
          this.removeAllFormData();
          this.setSessionData = false; //
          if(resetRequired){
            // only the accounts page is listening for this
            // only need to reset the form if we were on the account page (not Overview) and account_admin
            // otherwise the account form will be destroyed - no need to reset it
            this.resetForm.next(true);
          }
          this.revertRoleAndNavigate();
        });
      }
    } else if (this.parentCommsService.isInsideIframe()) {
      // If there are no unsaved changes and the GWP is iframed - we will not go to the dashboard.
      // Instead, send the done integration message back to the parent Accounts app
      this.parentCommsService.tellParentToExitGatewayPortal(iframeRedirectDestination);
    } else {
      this.revertRoleAndNavigate();
    }
  }

  /*
    Note: if you change the original_token to something fake, revertRole will not throw an error.
    The tokens in the broswer will change according to the fake values.
    Also the orignal_token will dissappear - so app no longer thinks it's assumed role.
    The claims will be null though.
  */
  revertRoleAndNavigate() {
    if(this.parentCommsService.isInsideIframe()) {
      this.parentCommsService.tellParentToExitGatewayPortal();
    }
    else if(this.appSvc.isAssumedRole()) {
      this.authSvc.revertRole().then(async token => {
        const claims = await this.authSvc.getUserClaims();
        // set name in header
        this.companySwitcherService.setCurrentAccountById(claims.accountId);
        // If the claims are null we should logout - this is in the partner guard
        this.appSvc.setClaims(claims);
        this.router.navigateByUrl('');
      }).catch(error => {
        console.error('error reverting role', error);
        this.router.navigateByUrl('');
      });
    } else {
      console.log('navigate to dashboard');
      this.router.navigateByUrl('').then(nav => {
        // case of if another tab has navigated to the dashboard already
        // allow this tab to be able to navigate as well
        if (!nav) {
          this.location.go('/');
          this.reload();
        }
      });
    }
  }

  private reload() {
    return this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  checkSessionData() {
    // Check all forms to update formsUnsavedChanges
    this.formsUnsavedChanges[0].changes = this.formsUnsavedChanges[0].changes || this.checkFormData('account');
    this.formsUnsavedChanges[1].changes = this.formsUnsavedChanges[1].changes || this.checkFormData('site');
    this.formsUnsavedChanges[2].changes = this.formsUnsavedChanges[2].changes || this.checkFormData('pbx');
    this.formsUnsavedChanges[3].changes = this.formsUnsavedChanges[3].changes || this.checkFormData('connect');
    // do not include officelink since is it updated immediately
    // this.formsUnsavedChanges[4].changes =  this.checkFormData('officelink');
    this.formsUnsavedChanges[5].changes = this.formsUnsavedChanges[5].changes || this.checkFormData('advanced');
  	return this.formsUnsavedChanges.findIndex(form => form.changes) > -1
  }

  checkFormData(fromStep: string) {
  	let formItem: any;
  	switch (fromStep) {
  		case 'account':
	  		formItem = sessionStorage.getItem('account-form');
		    if (typeof formItem !== 'undefined' && formItem) {
		      return true;
		    } else {
		    	return false;
		    }
  		case 'site':
	  		formItem = sessionStorage.getItem('site-form');
		    if (typeof formItem !== 'undefined' && formItem) {
		      return true;
		    } else {
		    	return false;
		    }
  		case 'pbx':
  			formItem = sessionStorage.getItem('pbx-form');
		    if (typeof formItem !== 'undefined' && formItem) {
		      return true;
		    } else {
		    	return false;
		    }
  		case 'connect':
  			formItem = sessionStorage.getItem('connect-state-form');
		    if (typeof formItem !== 'undefined' && formItem) {
		      return true;
		    } else {
		    	return false;
		    }
  		// case 'officelink':
  		// 	formItem = sessionStorage.getItem('office-connect-form');
		  //   if (typeof formItem !== 'undefined' && formItem) {
		  //     return true;
		  //   } else {
		  //   	return false;
		  //   }
  		case 'advanced':
  			formItem = sessionStorage.getItem('advanced-form');
		    if (typeof formItem !== 'undefined' && formItem) {
		      return true;
		    } else {
		    	return false;
		    }
  		default:
  			break;
  	}
  }

  removeFormData(fromStep: string) {
    this.resetCurrentFormDirty(fromStep);
  	switch (fromStep) {
  		case 'account':
  			sessionStorage.removeItem('account-form');
        sessionStorage.removeItem('account-form-contacts');
        sessionStorage.removeItem('account-form-supportContactIds');
  			break;
  		case 'site':
  			sessionStorage.removeItem('site-form');
  			break;
  		case 'pbx':
  			sessionStorage.removeItem('pbx-form');
  			break;
  		case 'connect':
  			sessionStorage.removeItem('connect-state-form');
  			break;
  		// case 'officelink':
  		// 	sessionStorage.removeItem('office-connect-form');
  		// 	break;
  		case 'advanced':
  			sessionStorage.removeItem('advanced-form');
  			sessionStorage.removeItem('advanced-form-autoUpdate');
  			break;
  		default:
  			break;
  	}
    this.setAppDirtyState();
  }

  removeAllFormData() {
    this.removeFormData('account');
    this.removeFormData('site');
    this.removeFormData('pbx');
    this.removeFormData('connect');
    this.removeFormData('advanced');
  }

  setAppDirtyState(){
    if(this.appIsDirty){
      this.appIsDirty = this.checkSessionData();
      //app changed from being dirty to not being dirty
      if(!this.appIsDirty){
        this.appDirtyStateChanged.next(false);
      }
    }
  }

  // Permission Error handling
  // In what scenario will going back to the dashboard fix the token issue?
  // Should we just tell/force the user to logout instead?
  redirectToDashboardOrLogout(lastRequest?: string) {
    if (!this.isErrorModelOpen) {
      this.isErrorModelOpen = true;
      const modalRef = this.modalSvc.open(ErrorPermissionsModalComponent);
      const contentComponentInstance = modalRef.componentInstance;
      contentComponentInstance.lastRequest = lastRequest;
      contentComponentInstance.continue.subscribe(() => {
        if (this.parentCommsService.isInsideIframe()) {
          this.parentCommsService.tellParentToExitGatewayPortal();
        } else {
          this.revertRoleAndNavigate()
        }
      });
      contentComponentInstance.logout.subscribe(() => {
        this.authSvc.redirectToLogin();
      });
      contentComponentInstance.close.subscribe(() => {
        this.isErrorModelOpen = false;
      });
    }
  }

  redirectToLogout() {
    if (!this.isErrorModelOpen) {
      this.isErrorModelOpen = true;
      const modalRef = this.modalSvc.open(ErrorPermissionsModalComponent);
      const contentComponentInstance = modalRef.componentInstance;
      contentComponentInstance.isFromDashboard = true;

      contentComponentInstance.logout.subscribe(() => {
        this.authSvc.redirectToLogin();
      });
      contentComponentInstance.close.subscribe(() => {
        this.isErrorModelOpen = false;
      });
    }
  }
}
