import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


import { Subscription, zip } from 'rxjs';
import { v4 as uuid } from 'uuid';
import * as Inputmask from 'inputmask';
import * as _ from 'lodash';
import * as jstz from 'jstz';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { AdminService } from '../../services/admin.service';
import { Admin2Service } from '../../services/admin2.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AccountService } from '../../services/account.service';
import { Country, CountryService } from '../../services/country.service';
import { AlertService } from '../../services/alert.service';
import { SpinnerService } from '../../services/spinner.service';
import { CloudResourcesService } from '../../services/cloudResources.service';
import { FormDataService } from '../../services/form-data.service';
import { TooltipService } from '../../services/tooltip.service';
import { AutoUpdateScheduleService } from '../../services/auto-update-schedule.service';
import { PreReqComponent } from '../pre-req/pre-req.component';
import { ConnectErrorComponent } from '../connect-error/connect-error.component';
import { CustomValidators } from '../forms/CustomValidators';
import {
  commonErrorMessages,
  commonMessages,
  DEFAULT_GOOGLE_DNS_SERVERS,
  platformTypes,
  siteMessages,
  toastMsgTypes,
  pbxTypes,
  pbxProducts,
  gatewayProducts
} from '../../shared/constants';
import { TunnelService } from '../../services/tunnel.service';
import { CloudlinkGatewayStatus, NetworkConfig, PutNetworkRequest, PutCloudlinkRequest, Ipv4Config } from '@mitel/cloudlink-sdk/tunnel';
import { Account, Client, Site, SitePostRequest, SitePutRequest } from '@mitel/cloudlink-sdk/admin';
import { FetchInterceptorService } from '../../services/fetch-interceptor.service';
import { TranslateService } from "@ngx-translate/core";
import { ParentCommsService } from '../../services/parent-comms.service';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css']
})

export class SiteComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('zipCode') zipCodeEl: ElementRef;

  siteForm: FormGroup;
  site: Site;
  account: Account;
  client: Client;
  networkConfig: NetworkConfig;
  platformTypes = platformTypes;
  accountTags: any = {};
  modalRef: NgbModalRef;
  subscription: Subscription;
  countries: Country[];
  states: string[];
  cityLabel = 'cityOrTown';
  platform: any;
  isDST = false;
  country;
  protocols = [
    { name: 'DHCP', value: 'dhcp' },
    { name: 'Static', value: 'static'},
  ];
  sessionData: any;

  isOverview = false;
  isSubmitting = false;
  showConnectError = false;
  isStateRequired: boolean;
  goNextPage = false;
  vccDidNumberSelected = false;
  vccDidNumberSelectedChanged = false;

  // tslint:disable-next-line:max-line-length
  dnsPattern: RegExp = /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))(,(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])))*$/;
  showPostalCode: boolean = true;
  postalCodeRequired: boolean;
  defaultSchedule: string = "";
  isIframed: boolean;
  stateOrProvince: string;
  noSite: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private adminSvc: AdminService,
    private admin2Svc: Admin2Service,
    private authSvc: AuthenticationService,
    private countryService: CountryService,
    private accountSvc: AccountService,
    private alertSvc: AlertService,
    private spinnerSvc: SpinnerService,
    private cloudResourcesSvc: CloudResourcesService,
    private formDataSvc: FormDataService,
    private analyticsSvc: AnalyticsService,
    private tunnelSvc: TunnelService,
    private tooltipSvc: TooltipService,
    private autoUpdateSvc: AutoUpdateScheduleService,
    private modalSvc: NgbModal,
    private interceptorService: FetchInterceptorService,
    private ngZone: NgZone,
    private translateSvc: TranslateService,
    private parentCommsService: ParentCommsService
  ) {}

  addMask(event) {
    setTimeout(() => {
      Inputmask({ alias: 'ip' }).mask(event.target.id);
    });
  }

  addVccDidNumberMask(event: any) {
    setTimeout(() => {
      Inputmask({ 'mask': '+9{1,15}', greedy: false }).mask(event.target.id);
    });
  }

  ngOnInit() {
    this.formDataSvc.setSessionData = true;
    this.isIframed = this.parentCommsService.isInsideIframe();
    const formItem = sessionStorage.getItem('site-form');
    if (typeof formItem !== 'undefined' && formItem) {
      this.sessionData = JSON.parse(formItem);
    }
    this.countries = this.countryService.getSupportedCountries();
    this.subscription = this.accountSvc.accountTagsChanged.subscribe(accountTags => {
      this.accountTags = accountTags;
      console.log('account tags change detected');
      if ((this.isErrorTunnel() && !this.showConnectError)) {
        this.showConnectError = true;
        this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_TUNN);
      }
    });

    this.subscription.add(zip(this.accountSvc.accountChanged, this.accountSvc.siteChanged).subscribe(results => {
      [this.account, this.site] = results;

      if (this.site) {
        this.noSite = false;
        this.setFormWithSite();
      } else {
        this.noSite = true;
        this.setFormWithAccount();
        if(!this.networkConfig) {
          this.initializeNetworkPlatformSettings();
        }
      }
    }));
    this.subscription.add(this.accountSvc.networkConfigChanged.subscribe(config => {
      this.networkConfig = config;

      if (config) {
        this.setFormWithNetworkSettings();
        this.cloudResourcesSvc.setCloudAppliance();
      }
      else if((this.site && this.accountTags &&
          this.accountTags['on-board-progress'] &&
          this.accountTags['on-board-progress']['step'] &&
          this.accountTags['on-board-progress']['step'] === 1 &&
          this.accountTags['on-board-progress']['succeeded']) && !this.networkConfig) {
          // when there is a site created by CX, then we have site and at that moment with first step succeeded
          // but no network and platform settings, so need to initialize them
          this.initializeNetworkPlatformSettings();
    }
    }));
    this.subscription.add(this.accountSvc.platformChanged.subscribe(platform => this.platform = platform));

    this.subscription.add(this.translateSvc.onLangChange
      .subscribe(() => this.countries = this.countryService.getSupportedCountries()));
    this.site = this.accountSvc.getSite();
    this.account = this.accountSvc.getAccount();
    this.accountTags = this.accountSvc.getAccountTags();
    if (this.accountTags) {
      if ((this.isErrorTunnel() && !this.showConnectError)) {
        this.showConnectError = true;
        this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_TUNN);
      }
    }

    this.networkConfig = this.accountSvc.getNetworkConfig();
    this.platform = this.accountSvc.getPlatform();

    this.initForm();

    this.subscription.add(this.siteForm.controls['country'].valueChanges.subscribe(newCountry => {
      this.country = newCountry;
      let { states, isRequired, postalCode, postalCodeRequired } = this.countryService.getStatesOrProvincesByCountry(newCountry);
      this.states = states;
      this.isStateRequired = isRequired;
      this.showPostalCode = postalCode;
      this.postalCodeRequired = postalCodeRequired;
      this.cityLabel = this.countryService.getLabelByCountry(newCountry);

      this.siteForm.controls['stateOrProvince'].reset();
      this.siteForm.controls['provinceInput'].reset();
      this.siteForm.controls['zipCode'].reset();

      if (postalCode && this.postalCodeRequired) {
        this.siteForm.controls['zipCode'].setValidators([c => Validators.required(c) || CustomValidators.validateZipCode(c, newCountry.code)]);
      } else {
        this.siteForm.controls['zipCode'].clearValidators();
      }

      if (isRequired) {
        this.siteForm.controls['stateOrProvince'].setValidators([c => Validators.required(c)]);
        this.stateOrProvince = this.siteForm.controls['stateOrProvince'].value;
      } else {
        this.siteForm.controls['stateOrProvince'].clearValidators();
        this.stateOrProvince = null;
      }

      this.siteForm.controls['stateOrProvince'].updateValueAndValidity();
      this.siteForm.controls['zipCode'].updateValueAndValidity();
      // addInputMaskByCountry(this.zipCodeEl, newCountry);
    }));

    this.setFormWithSessionStorage();
    if (this.site) {
      this.setFormWithSite();
      if (this.networkConfig) {
        this.setFormWithNetworkSettings();
        this.cloudResourcesSvc.setCloudAppliance();
      }
    }

    this.route.params.subscribe((params: Params) => {
      this.isOverview = this.route.snapshot.url[0] ? this.route.snapshot.url[0].path === 'overview' : false;
      if ((this.isOverview && this.noSite) || this.noSite) {
        this.setFormWithAccount();
      }
    });
  }

  // If this is the initial configuration then we want to load the initial network
    // settings from the gateway in order to set the current IP address.  Don't care
    // if there are any errors since we just won't display the current address
  initializeNetworkPlatformSettings(){
      console.log("entered in intialization", this.networkConfig, this.site);
        const tunnel = localStorage.getItem('tunnel');
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');
        // Check the values for tunnel, username, password
        if (tunnel && username && password) {
          // Get initial network settings
          this.tunnelSvc.getInitialNetworkSettings(tunnel, username, password)
            .then(response => {
              this.networkConfig = response.result;
              if (this.networkConfig) {
                this.setFormWithNetworkSettings();
                this.accountSvc.setNetworkConfig(this.networkConfig);
              }
            }, reason => {
              console.error('error getting initial network settings', reason);
            }).catch(err => {
              console.error('error getting initial network settings', err);
            });
          this.tunnelSvc.getInitialPlatform(tunnel, username, password)
            .then(response => {
              this.platform = response;
              this.accountSvc.setPlatform(this.platform);
            }, reason => {
              console.error('error getting initial platform', reason);
            }).catch(err => {
              console.error('error getting initial platform', err);
            });
        }
  }

  ngAfterViewInit() {
    // addInputMaskByCountry(this.zipCodeEl, this.country);
  }

  openPreReq() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview Site' : 'Site', 'Open pre-requisites');
    this.modalRef = this.modalSvc.open(PreReqComponent);

    const contentComponentInstance = this.modalRef.componentInstance;
    contentComponentInstance.overview.subscribe(() => {
      this.router.navigateByUrl(`/accounts/${this.account.accountId}/overview`);
    });
  }

  openConnectError() {
    setTimeout(() => this.modalRef = this.modalSvc.open(ConnectErrorComponent));
  }

  getAccountTagParameters(succeeded: boolean, reason?: string): any {
    let tagParams = {};

    if (succeeded) {
      if (this.platform && this.platform.platform &&
        this.platform.capabilities && this.platform.capabilities.embedded_with_pbx) {
        tagParams = { 'on-board-progress': { 'step': 3, 'name': 'pbx', 'succeeded': succeeded } };
        let prodArray = this.getNewProductTagsForSMBC();
        if (prodArray){
          tagParams['products'] = prodArray;
        }
      } else {
        tagParams = { 'on-board-progress': { 'step': 2, 'name': 'site', 'succeeded': succeeded } };
      }
    } else {
      tagParams = { 'on-board-progress': { 'step': 2, 'name': 'site', 'succeeded': succeeded, 'reason': reason } };
    }

    if (!this.accountTags || !this.accountTags['on-board-progress'] || this.accountTags['on-board-progress'].step <= 2) {
      return tagParams;
    }
    return undefined;
  }

  //At this time, SMBCs always  have a 400 for the pbx component.
  getNewProductTagsForSMBC(){
    let prodArray = null;
    if(this.accountTags && this.accountTags['products']) {
      prodArray = this.accountTags['products'];
      // Although unlikely to exist, remove any old PBX types and mobile app products related to CloudLink Gateway
      _.remove(prodArray, function(n) {
        return _.includes(gatewayProducts, n);
      });
      // Add new products for a 400 since that's the pbx type for an SMBC
      prodArray = _.concat(prodArray, pbxProducts[pbxTypes.MIVO400]);
    } else {
      prodArray = pbxProducts[pbxTypes.MIVO400];
    }
    return prodArray;
  }

  async updateAccountTags(succeeded: boolean, reason?: string) {
    const tagParams = this.getAccountTagParameters(succeeded, reason);
    if (tagParams) {
      try {
        const result = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
        console.log('account tag updated');
        this.accountSvc.setAccountTags(result);
        if (succeeded) {
          this.alertSvc.setAlert(toastMsgTypes.INFO, siteMessages.UPDATE_SITE);
        }
        this.isSubmitting = false;
        this.spinnerSvc.hide();
        // Proceed to the next step if the button is Next and succeeded
        if ((this.goNextPage && succeeded) || (succeeded && !this.isSaveMode())) {
          this.cloudResourcesSvc.setCloudAppliance();
          this.goNextPage = false;
          this.goNext(this.site.siteId);
        }
      } catch (reason) {
        if (reason && reason.statusCode === 401) {
          console.error('failed to update account tag', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('failed to update account tag', res);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, commonMessages.ERR_PROGRESS);
          }).catch(err => console.error('failed to update account tag', err));
        } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('failed to update account tag', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          console.error('failed to update account tag', reason);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, commonMessages.ERR_PROGRESS);
        }
        this.isSubmitting = false;
        this.spinnerSvc.hide();
      }
    } else { // No changes to tags
      this.alertSvc.setAlert(toastMsgTypes.INFO, siteMessages.UPDATE_SITE);
      this.isSubmitting = false;
      this.spinnerSvc.hide();
    }
  }

  getCloudParams(response: any, client: any): PutCloudlinkRequest {

    const object: PutCloudlinkRequest = {
      siteId: response.siteId,
      clientId: client ? client.clientId : undefined,
      clientSecret: client ? client.clientSecret : undefined,
      accountId: response.accountId,
      enabled: true,
      tunnel_enabled: true
    };

    if (environment.cloud === 'dev') {
      object.deploymentType = 'dev';
    }

    return object;
  }

  removeTunnelInfoFromStorage() {
    localStorage.removeItem('tunnel');
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    this.parentCommsService.tunnelUsed = true;
  }

  /**
   * Create a new ngrok tunnel in the gateway after a 2 second delay (to give
   * previously generated notifications enough time to reach tunnel microservice)
   *
   * @see https://github.com/mitel-networks/cloudlink-gateway-portal/issues/804
   * @param cloudParams are the initial tunnel parameters.
   */
  createCloudTunnel(cloudParams: PutCloudlinkRequest) {
    setTimeout(async () => {
      this.spinnerSvc.setMessage(siteMessages.CREATE_TUNNEL);
      const tunnel = localStorage.getItem('tunnel');
      const username = localStorage.getItem('username');
      const password = localStorage.getItem('password');
      // Check the values for tunnel, username, password
      if (tunnel && username && password) {
        try {
          await this.tunnelSvc.updateCloudTunnel(tunnel, username, password, cloudParams);
          console.log('successfully sent cloud parameters to create new tunnel');
          this.removeTunnelInfoFromStorage();

          // #1462: Tunnel may not be registered yet,  poll Get Echo until it succeeds
          const interval = setInterval(async () => {
            try {
              const response = await this.tunnelSvc.getEcho();
              if (response.data === 'ECHO..echo..echo') {
                console.log('Echo succesful after tunnel registration');
                clearTimeout(timeout);
                clearInterval(interval);
                await this.setDefaultUpgradeSchedule();
                // Update Network Settings for Non-SMBC
                if (!this.platform || this.platform.capabilities.change_network_settings) {
                  this.updateNetworkSettings();
                } else {
                  this.updateAccountTags(true);
                }
              } else {
                console.warn('unexpected response', response);
              }
            } catch (error) {
              console.log('echo failed, will try again...', error);
            }
          }, 5000); // Check gateway connection every 5 seconds
          const timeout = setTimeout(() => {
            clearInterval(interval);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_TUNN_TOAST);
            this.spinnerSvc.hide();
            this.isSubmitting = false;
          }, 60000); // Stop after 1 min
        } catch (reason) {
          this.spinnerSvc.hide();
          this.openConnectError();
          if (typeof reason === 'string') {
            console.error('failed to create tunnel', reason);
          } else if (reason instanceof Error) {
            console.error('failed to create tunnel', reason.message);
            if (reason.message === commonErrorMessages.AUTH_ERROR) {
              this.authSvc.redirectToLogin();
            }
          } else {
            console.error('failed to create tunnel', JSON.stringify(reason));
          }
          this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_TUNN_TOAST);
          this.isSubmitting = false;
          this.updateAccountTags(false, siteMessages.ERR_TUNN);
        }
      } else {
        this.spinnerSvc.hide();
        console.error('no tunnel credentials in local storage');
        this.openConnectError();
        this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_TUNN);
        this.isSubmitting = false;
        this.updateAccountTags(false, siteMessages.ERR_TUNN);
      }
    }, 7000);
  }

  async setDefaultUpgradeSchedule() {
    if (this.account && this.site) {
      try {
        const schedule = await this.tunnelSvc.getUpgradeSchedule();
        if ((this.platform && this.platform.capabilities && !this.platform.capabilities.update_host) ||
            !<any>schedule.host_dayOfWeek || <any>schedule.host_dayOfWeek === '') {
          const params = this.getDefaultUpdateScheduleParams();
          try {
            await this.tunnelSvc.updateUpgradeSchedule(undefined, params);
            this.accountSvc.setUpgradeSchedule(params);
            this.cloudResourcesSvc.setCloudAppliance();
            console.log('set default upgrade schedule');
          } catch (error) {
            if (typeof error === 'string') {
              console.error('failed to set default upgrade schedule', error);
            } else if (error && error.statusCode === 401) {
              console.error('failed to set default upgrade schedule', JSON.stringify(error));
              this.authSvc.redirectToLogin();
            } else if (error && error.statusCode === 403) {
              console.error('failed to set default upgrade schedule', JSON.stringify(error));
              this.formDataSvc.redirectToDashboardOrLogout();
            } else if (error instanceof Error) {
              console.error('failed to set default upgrade schedule', error.message);
              if (error.message === commonErrorMessages.AUTH_ERROR) {
                this.authSvc.redirectToLogin();
              }
            } else {
              console.error('failed to set default upgrade schedule', JSON.stringify(error));
            }
          }
        }
      } catch (reason) {
        if (typeof reason === 'string') {
          console.error('failed to get upgrade schedule', reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to get upgrade schedule', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to get upgrade schedule', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason instanceof Error) {
          console.error('failed to get upgrade schedule', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to get upgrade schedule', JSON.stringify(reason));
        }
      }
    }
  }

  getDefaultUpdateScheduleParams() {
    let params = {};

    let localTimezone: string;
    if (this.site && this.site.location && this.site.location.timezone) {
      localTimezone = this.site.location.timezone;
    } else {
      localTimezone = jstz.determine().name();
    }

    this.defaultSchedule = this.autoUpdateSvc.getDefaultSchedule();
    const baseTimezone = this.autoUpdateSvc.getBaseTimezone();

    const m = moment(this.defaultSchedule).tz(localTimezone, true);
    const convertedMoment = m.clone().tz(baseTimezone);

    this.isDST = m.isDST();

    const weekdays = this.autoUpdateSvc.getWeekdays();
    const day = [weekdays[convertedMoment.day()].value];
    const time = convertedMoment.hour();

    if (this.platform && this.platform.capabilities && !this.platform.capabilities.update_host) { // !update_host
      params = {
        'container_onBoot': true,
        'container_hour': time,
        'container_minute': '0',
        'container_dayOfWeek': day,
      }
    } else {
      params = {
        'host_onBoot': true,
        'host_hour': time,
        'host_minute': '0',
        'host_dayOfWeek': day,
        'container_onBoot': true,
        'container_hour': time,
        'container_minute': '0',
        'container_dayOfWeek': day,
      }
    }

    return params;
  }

  isFormValid(): boolean {
    let statesValid = true;
    if (this.states) {
      statesValid = this.siteForm.controls['stateOrProvince'].valid;
    }
    // if this.platform is undefined treat it as an appliance
    if ((!this.platform || (this.platform && this.platform.capabilities && this.platform.capabilities.change_network_settings))
      && this.siteForm.controls['port1'].value === 'static') {
      return this.siteForm.valid;
    } else {
      if (!this.countryService.hasPostalCode(this.siteForm.value.country.code)) {
        return statesValid &&
          this.siteForm.controls['name'].valid &&
          this.siteForm.controls['city'].valid &&
          this.siteForm.controls['country'].valid &&
          this.siteForm.controls['vccDidNumber'].valid;
      }

      return statesValid &&
        this.siteForm.controls['name'].valid &&
        this.siteForm.controls['zipCode'].valid &&
        this.siteForm.controls['city'].valid &&
        this.siteForm.controls['country'].valid &&
        this.siteForm.controls['vccDidNumber'].valid;
    }
  }

  validateAllFormFields() {
    let targetName;
    Object.keys(this.siteForm.controls).forEach(field => {
      const control = this.siteForm.get(field);
      if (control instanceof FormControl) {
        if (this.siteForm.controls['port1'].value !== 'static' &&
         (field === 'ipAddress1' || field === 'subnet1' || field === 'gateway1')) {
          control.markAsUntouched();
        } else {
          control.markAsTouched();
          if (!control.valid && !targetName) {
            targetName = field;
          }
        }
      }
    });

    // Scroll to the target control
    if (targetName) {
      const element = document.getElementsByName(`site-${targetName}`)[0];
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  async onSubmit() {
    if (!this.isFormValid()) {
      this.validateAllFormFields();
      return;
    }
    this.alertSvc.clearAlert();
    if (!this.isOverview) {
      window.scrollTo(0, 0);
    }
    this.sessionData = null;
    this.isSubmitting = true;
    //for site created by CX, there is already a site but no gateway and tunnel before initial/first save on this step
    //check tags to find either step > 2 or site step have been successfully completed once
    if (this.site &&
      (this.accountTags &&
       this.accountTags['on-board-progress'] &&
       this.accountTags['on-board-progress']['step'] &&
       (this.accountTags['on-board-progress']['step'] > 2 ||
       (this.accountTags['on-board-progress']['step'] === 2 &&
       this.accountTags['on-board-progress']['succeeded'] )))) {
      this.spinnerSvc.show();
      this.spinnerSvc.setMessage(siteMessages.UPDATING_SITE);
      const updateParams = this.getSiteParameters(false);
      this.goNextPage = false;

      try {
        const response = await this.adminSvc.updateSite(this.account.accountId,
                                                        this.site.siteId,
                                                        updateParams as SitePutRequest);
        console.log('updated site');
        this.formDataSvc.removeFormData('site');
        this.siteForm.markAsPristine();
        this.vccDidNumberSelectedChanged = false;
        this.accountSvc.setSite(response);

        if (this.isErrorTunnel()) {
          console.warn('there was a previous tunnel error, checking tunnel...');
          this.goNextPage = true; // Proceed to the next page if tunnel is succesfully registered
          this.checkTunnelConnection();
        } else if (!this.platform || this.platform.capabilities.change_network_settings) {
          this.updateNetworkSettings();
        } else {
          this.updateAccountTags(true); // Do not update network settings for SMBC
        }
      } catch (reason) {
        this.spinnerSvc.hide();
        this.openConnectError();
        this.isSubmitting = false;
        if (reason && reason.statusCode === 401) {
          console.error('failed to update site', JSON.stringify(reason));
          this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_UPDATE_SITE);
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to update site', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('failed to update site', res);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_UPDATE_SITE);
          }).catch(err => console.error('failed to update site', err));
        } else if (reason instanceof Error) {
          console.error('failed to update site', reason.message);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_UPDATE_SITE);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          let errorMsg = siteMessages.ERR_UPDATE_SITE;
          console.error('failed to update site', reason);
          if (reason && reason.body && reason.body.name === 'ValidationFailure') {
            const errorData = reason.body.data;
            if (errorData && errorData.errors && errorData.errors[0].field === 'vccDidNumber') {
              errorMsg = siteMessages.INVALID_VCC_DID_NUMBER;
            }
          }
          this.alertSvc.setAlert(toastMsgTypes.ERROR, errorMsg);
        }
      }
    } else {
      this.spinnerSvc.show();
      let updateParams, siteParams, response, client;
      let isCreationMode: boolean = false;
      //if site has been already created by CX
      if(this.site){
        this.spinnerSvc.setMessage(siteMessages.UPDATING_SITE);
         updateParams = this.getSiteParameters(false);
      }
      else{
        //if site is being created by GWP
        this.spinnerSvc.setMessage(siteMessages.CREATE_SITE);
        siteParams = this.getSiteParameters(true);
      }
      this.goNextPage = true;

      try {
        if(this.site){
          response = await this.adminSvc.updateSite(this.account.accountId,
                                                        this.site.siteId,
                                                        updateParams as SitePutRequest);
          console.log('updated site');
        }
        else{
          response = await this.adminSvc.createSite(this.account.accountId, siteParams as SitePostRequest);
          console.log('created site');
          isCreationMode = true;
        }

        this.formDataSvc.removeFormData('site');
        this.siteForm.markAsPristine();
        this.vccDidNumberSelectedChanged = false;
        this.site = response;
        this.accountSvc.setSite(response);

        // Check if Gateway has been set up
        this.checkGatewayInfo(response);

        // Create Tunnel
        if(isCreationMode){
          if (response._embedded) {
            client = (<any>response._embedded).client;
          }
          const cloudParams = this.getCloudParams(response, client);
          this.createCloudTunnel(cloudParams);
        }
        else{
          // Check tunnel connection
          this.checkTunnelConnection();
        }
      } catch (reason) {
        this.spinnerSvc.hide();
        this.openConnectError();
        this.isSubmitting = false;
        if (reason && reason.statusCode === 401) {
          console.error('failed to create site', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to create site', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('failed to create site', res);
            const body = JSON.parse(res);
            const errorMsg = body.name ? ('createSite.' + body.name) : commonMessages.ERR_PROGRESS;
            this.alertSvc.setAlert(toastMsgTypes.ERROR, errorMsg);
            this.updateAccountTags(false, errorMsg);
          });
        } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('failed create site', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          let errorMsg = siteMessages.ERR_CREATE_SITE;
          console.error('failed to create site', reason);
          if (reason && reason.body && reason.body.name === 'ValidationFailure') {
            const errorData = reason.body.data;
            if (errorData && errorData.errors && errorData.errors[0].field === 'vccDidNumber') {
              errorMsg = siteMessages.INVALID_VCC_DID_NUMBER;
            }
          }
          this.alertSvc.setAlert(toastMsgTypes.ERROR, errorMsg);
          this.updateAccountTags(false, errorMsg);
        }
      }
    }
  }

  goBack() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview Site' : 'Site', 'Cancel');
    if (this.isEditMode() || this.isIframed) {
      this.formDataSvc.redirectToDashboard(undefined, undefined, 'account');// redirect to accounts page when clicked if iframed
    } else {
      if (!this.isOverview) {
        const url = '/accounts/' + this.account.accountId;
        this.router.navigateByUrl(url);
      } else {
        const element = document.getElementsByTagName('app-account')[0];
        element.scrollIntoView();
      }
    }
  }

  goNext(siteId: string ) {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview Site' : 'Site', 'Next');
    if (!this.platform || (this.platform && this.platform.capabilities && !this.platform.capabilities.embedded_with_pbx)) {
      if (!this.isOverview) {
        const url = '/accounts/' + this.account.accountId + '/sites/' + siteId + '/pbx/new';
        this.router.navigateByUrl(url);
      } else {
        const element = document.getElementsByTagName('app-pbx')[0];
        element.scrollIntoView();
      }
    } else {
      if (!this.isOverview) {
        const url = '/accounts/' + this.account.accountId + '/sites/' + siteId + '/connect';
        this.router.navigateByUrl(url);
      } else {
        const element = document.getElementsByTagName('app-connect-state')[0];
        element.scrollIntoView();
      }
    }
  }

  private getSiteParameters(createSite: boolean): any {
    const input = this.siteForm.value;
    const object: any = {
      name: input.name
    };

    if (createSite) {
      //should not skip creation of the client
      object.skipClientCreation = false;
      object.clientId = uuid();
    }
   	return this.getOtherSiteParameters(object);
  }

  private getOtherSiteParameters(object: any): any {
    const input = this.siteForm.value;
    const location: any = {
      address: {
        country: input.country.code
      }
    };

    if (!_.isEmpty(input.addressLine1)) {
      location.address.street = input.addressLine1;
    }

    if (!_.isEmpty(input.addressLine2)) {
      location.address.street2 = input.addressLine2;
    }

    if (!_.isEmpty(input.city)) {
      location.address.city = input.city;
    }


    if (!_.isEmpty(input.stateOrProvince)) {
      location.address.stateOrProvince = input.stateOrProvince;
    }

    if (!_.isEmpty(input.provinceInput)) {
      location.address.stateOrProvince = input.provinceInput;
    }

    if (_.isEmpty(input.provinceInput) && _.isEmpty(input.stateOrProvince)) {
      location.address.stateOrProvince = null;
    }

    if (!_.isEmpty(input.zipCode)) {
      location.address.zipCode = input.zipCode;
    } else {
      location.address.zipCode = null;
    }

    location.timezone = jstz.determine().name();

    object.location = location;
    // #1373 , vccDidNumber error blocks onboarding, commenting out for now
    // object.vccDidNumber = input.vccDidNumber;

    return object;
  }

  isVccDidNumberSelected() {
    return (this.vccDidNumberSelected ||
            this.siteForm.getRawValue().vccDidNumber !== '');
  }

  onVccDidNumberChanged() {
    this.vccDidNumberSelectedChanged = true;
    if (this.isVccDidNumberSelected()) {
      this.vccDidNumberSelected = false;
      (<FormControl>this.siteForm.controls['vccDidNumber'])
        .setValue('');
      this.siteForm.controls['vccDidNumber'].clearValidators();
    } else {
      this.vccDidNumberSelected = true;
      const site = <any>this.site;
      if (site && site.vccDidNumber) {
        (<FormControl>this.siteForm.controls['vccDidNumber'])
          .setValue(site.vccDidNumber);
      }
      this.siteForm.controls['vccDidNumber'].setValidators([Validators.required]);
    }
    this.siteForm.controls['vccDidNumber'].updateValueAndValidity();
  }

  private initForm() {
    const port1 = this.protocols[0].value;

    const defaultDNS = DEFAULT_GOOGLE_DNS_SERVERS.split(',');

  	this.siteForm = new FormGroup({
  		'name': new FormControl('', Validators.required),
  		'addressLine1': new FormControl(''),
  		'addressLine2': new FormControl(''),
      'city': new FormControl('', Validators.required),
      'stateOrProvince': new FormControl('', [c => Validators.required(c)]),
      'provinceInput': new FormControl(null),
      'zipCode': new FormControl('', [
        c => Validators.required(c) || CustomValidators.validateZipCode(c, {...{...{...this.siteForm}.value}.country}.code)]),
      'country': new FormControl('', Validators.required),
      'vccDidNumber': new FormControl(''),
      'port1': new FormControl(port1),
      'ipAddress1': new FormControl('', [Validators.required, CustomValidators.validateIpAddress]),
      'subnet1': new FormControl('', [Validators.required, CustomValidators.validateSubnet]),
      'gateway1': new FormControl('', [Validators.required, CustomValidators.validateIpAddress]),
      dnsServers: new FormControl(defaultDNS),
      // dnsServers: new FormControl('', [Validators.required, CustomValidators.validateDnsServers]),
    }, CustomValidators.validateNetworkConfig);

    this.onChanges();
  }

  onChanges() {
    this.subscription.add(this.siteForm.valueChanges
      .subscribe(() => this.formDataSvc.setCurrentFormDirty('site', this.siteForm.dirty)));
  }

  private setFormWithAccount() {
    if (this.sessionData) {
      return;
    }
    const account = <any>(this.account);

    if (account && account.location && account.location.address) {
      const address = account.location.address;
      const country = this.countryService.getCountryFromAbbr(address.country);
      if (country) {
        this.states = this.countryService.getStatesOrProvincesByCountry(country).states;
        (<FormControl>this.siteForm.controls['country']).setValue(country);
        // addInputMaskByCountry(this.zipCodeEl, country);
        this.cityLabel = this.countryService.getLabelByCountry(country);
      }  else {
        this.showPostalCode = false;
      }
      if (address.stateOrProvince) {
        let stateOrProvince = this.countryService.getCurrentState(address.stateOrProvince);
        stateOrProvince ?
          (<FormControl>this.siteForm.controls['stateOrProvince']).setValue(stateOrProvince) :
          (<FormControl>this.siteForm.controls['provinceInput']).setValue(address.stateOrProvince);
      }
      (<FormControl>this.siteForm.controls['addressLine1']).setValue(address.street);
      (<FormControl>this.siteForm.controls['addressLine2']).setValue(address.street2);
      (<FormControl>this.siteForm.controls['city']).setValue(address.city);
      (<FormControl>this.siteForm.controls['zipCode']).setValue(address.zipCode);
    }
  }

  private setFormWithSite() {
    if (this.sessionData) {
      return;
    }
    const site = <any>this.site;
    (<FormControl>this.siteForm.controls['name'])
        .setValue(this.site.name);
    if (site.location && site.location.address) {
      const address = site.location.address;
      const country = this.countryService.getCountryFromAbbr(address.country);
      this.cityLabel = this.countryService.getLabelByCountry(country)
      this.states = this.countryService.getStatesOrProvincesByCountry(country).states;
      if (country) {
        (<FormControl>this.siteForm.controls['country']).setValue(country);
        // addInputMaskByCountry(this.zipCodeEl, country);
      }  else {
        (<FormControl>this.siteForm.controls['country']).setValue('');
      }

      if (address.stateOrProvince) {
        this.stateOrProvince = this.countryService.getCurrentState(address.stateOrProvince);

        if (this.stateOrProvince) {
          (<FormControl>this.siteForm.controls['stateOrProvince']).setValue(this.stateOrProvince);
        } else if (this.states && this.states.length === 0) {
          (<FormControl>this.siteForm.controls['provinceInput']).setValue(address.stateOrProvince);
        }
      }
      (<FormControl>this.siteForm.controls['addressLine1']).setValue(address.street);
      (<FormControl>this.siteForm.controls['addressLine2']).setValue(address.street2);
      (<FormControl>this.siteForm.controls['city']).setValue(address.city);
      (<FormControl>this.siteForm.controls['zipCode']).setValue(address.zipCode);
   }

  if (site.vccDidNumber && site.vccDidNumber !== '') {
    (<FormControl>this.siteForm.controls['vccDidNumber'])
      .setValue(site.vccDidNumber);
    this.siteForm.controls['vccDidNumber'].setValidators([Validators.required]);
  }
}

  getNetworkSettingsOptions(): PutNetworkRequest {
    const input = this.siteForm.value;
    const lan1ipv4Obj: Ipv4Config = {
      mode: input.port1
    };

    if (input.port1 === <Ipv4Config.ModeEnum>'static') {
      lan1ipv4Obj.address = this.stripLeadingZeroInIPAddr(input.ipAddress1);
      lan1ipv4Obj.subnet = String(this.subnet2cidr(input.subnet1));
      lan1ipv4Obj.gateway = this.stripLeadingZeroInIPAddr(input.gateway1);
      if (input.dnsServers.length === 0) {
        if (this.networkConfig && this.networkConfig.lan1) {
          lan1ipv4Obj.dns = this.networkConfig.lan1.ipv4.dns ? this.networkConfig.lan1.ipv4.dns : DEFAULT_GOOGLE_DNS_SERVERS;
        } else {
          lan1ipv4Obj.dns = DEFAULT_GOOGLE_DNS_SERVERS;
        }
      } else {
        lan1ipv4Obj.dns = input.dnsServers.join();
      }
      // use Google DNS servers if there is not a custom DNS server configured
    }

    return {
      lan1: {
        ipv4: lan1ipv4Obj
      },
    };
  }

  isNetworkSettingChanged(): boolean {
    if (this.networkConfig && this.networkConfig.lan1) {
      const formValue = this.siteForm.value;
      console.log('isNetworkSettingChanged: determine if network config & form are different');
      if (formValue.port1 === <Ipv4Config.ModeEnum>'static') {
        return !(formValue.port1 === this.networkConfig.lan1.ipv4.mode &&
          formValue.ipAddress1 === this.networkConfig.lan1.ipv4.address &&
          formValue.subnet1 === this.cidr2subnet(Number(this.networkConfig.lan1.ipv4.subnet)) &&
          formValue.gateway1 === this.networkConfig.lan1.ipv4.gateway &&
          formValue.dnsServers.join() === this.networkConfig.lan1.ipv4.dns
        );
      } else {

        return !(formValue.port1 === this.networkConfig.lan1.ipv4.mode);
      }
    } else {
      console.log('isNetworkSettingChanged: network config is not set or lan1 is not set');
      console.log('isNetworkSettingChanged, return true');
      return true;
    }
  }

  async updateNetworkSettings() {
    this.spinnerSvc.setMessage(siteMessages.UPDATE_NETWORK);
    console.log('check isNetworkSettingChanged:', this.isNetworkSettingChanged());
    if (this.account && this.site && this.isNetworkSettingChanged()) {
      const options = this.getNetworkSettingsOptions();
      console.log('Function: updateNetworkSettings, options: ', options);

      if (options) {
        try {
          this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Site' : 'Site', 'Change network settings');
          const result = await this.tunnelSvc.updateNetworkSettings(this.account.accountId, this.site.siteId, options);
          console.log('updated network settings');
          // Set with options since response returns old data
          this.accountSvc.setNetworkConfig(<any>options);
          this.spinnerSvc.setMessage(siteMessages.CONNECTING_GATEWAY);
          const interval = setInterval(async () => {
            if (this.spinnerSvc.since() > 60000) {
              this.spinnerSvc.hide();
              this.isSubmitting = false;
              clearInterval(interval);
              this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_SET_NETWORK_TIMEOUT);
              this.updateAccountTags(true);
              console.error('timed out waiting for gateway to update network settings');
            }
            console.log('get echo...');
            try {
              const response = await this.tunnelSvc.getEcho();
              if (response.data === 'ECHO..echo..echo') {
                console.log('update network settings, get echo successful');
                clearInterval(interval);
                 this.handleNetworkSettingsChange();
              } else {
                console.warn('unexpected response', response);
              }
            } catch (error) {
              console.log('update network settings, echo failed, will try again...', error);
            }
          }, 10000); // Call echo every 10 sec
        } catch (reason) {
          this.spinnerSvc.hide();
          this.isSubmitting = false;
          if (typeof reason === 'string') {
            console.error('error updating network settings', reason);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_SET_NETWORK);
          } else if (reason && reason.statusCode === 401) {
            console.error('error updating network settings', JSON.stringify(reason));
            this.authSvc.redirectToLogin();
          } else if (reason && reason.statusCode === 403) {
            console.error('error updating network settings', JSON.stringify(reason));
            this.formDataSvc.redirectToDashboardOrLogout();
          } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
            console.error('error updating network settings', reason.message);
            this.authSvc.redirectToLogin();
          } else {
            console.error('error updating network settings', JSON.stringify(reason));
            this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_SET_NETWORK);
          }
        }
      }
    } else {
      this.updateAccountTags(true);
    }
  }

  private async handleNetworkSettingsChange() {
    this.spinnerSvc.setMessage(commonMessages.ERR_SERVICE_UNAVAILABLE_RETRY);
    this.interceptorService.skipInterceptions = true;
    const interval = setInterval(async () => {
      if (this.spinnerSvc.since() > 300000) {
        this.spinnerSvc.hide();
        this.isSubmitting = false;
        clearInterval(interval);
        this.interceptorService.skipInterceptions = false;
        this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_SET_NETWORK_TIMEOUT);
        this.updateAccountTags(true);
        console.error('timed out waiting for gateway services to start up');
      }
      console.log('get status...');
      try {
        const response = await this.tunnelSvc.getCloudlinkStatus();
        if (response) {
          console.log('status response', response);
          if (this.areServicesReady(response)) {
            this.interceptorService.skipInterceptions = false;
            clearInterval(interval);
            this.setDefaultUpgradeSchedule();
            setTimeout(() => {
              this.updateAccountTags(true);
            }, 20000); // Add 20 seconds delay after network setting changes
          }
        } else {
          console.warn('unexpected response');
        }
      } catch (error) {
        console.log(' get status services not ready, will try again...', error);
      }
    }, 10000); // Call get status every 10 sec
  }

  private areServicesReady(status: CloudlinkGatewayStatus) {
    if (status.ngrok && status.freeswitch) {
      return true;
    }
    return false;
  }

  private setFormWithSessionStorage() {
    if (this.sessionData) {
      this.states = this.countryService.getStatesOrProvincesByCountry(this.sessionData.country).states;
      // if (this.sessionData.country) {
      //   addInputMaskByCountry(this.zipCodeEl, this.sessionData.country);
      // }

      this.siteForm.patchValue({
        name: this.sessionData.name,
        addressLine1: this.sessionData.addressLine1,
        addressLine2: this.sessionData.addressLine2,
        city: this.sessionData.city,
        country: this.sessionData.country,
        stateOrProvince: this.sessionData.stateOrProvince,
        zipCode: this.sessionData.zipCode,
        vccDidNumber: this.sessionData.vccDidNumber,
        port1: this.sessionData.port1,
        ipAddress1: this.sessionData.ipAddress1,
        subnet1: this.sessionData.subnet1,
        gateway1: this.sessionData.gateway1,
        dnsServers: this.sessionData.dnsServers
      });

      if (this.sessionData.vccDidNumber && this.sessionData.vccDidNumber !== '') {
        this.siteForm.controls['vccDidNumber'].setValidators([Validators.required]);
      }

    }
  }

  private setFormWithNetworkSettings() {
    if (this.sessionData) {
      return;
    }

    if (this.networkConfig && this.networkConfig.lan1) {
      if (this.networkConfig.lan1.ipv4.mode) {
        (<FormControl>this.siteForm.controls['port1'])
          .setValue(this.networkConfig.lan1.ipv4.mode);
      }

      if (this.networkConfig.lan1.ipv4.mode === <Ipv4Config.ModeEnum>'static') {
        console.log('Function: setFormWithNetworkSettings, "SET": ', "SET");
        (<FormControl>this.siteForm.controls['ipAddress1'])
          .setValue(this.networkConfig.lan1.ipv4.address);
        (<FormControl>this.siteForm.controls['subnet1'])
          .setValue(this.networkConfig.lan1.ipv4.subnet.includes('.') ? this.networkConfig.lan1.ipv4.subnet : this.cidr2subnet(Number(this.networkConfig.lan1.ipv4.subnet)));
        (<FormControl>this.siteForm.controls['gateway1'])
          .setValue(this.networkConfig.lan1.ipv4.gateway);
        (<FormControl>this.siteForm.controls['dnsServers'])
          .setValue(this.networkConfig.lan1.ipv4.dns ? this.networkConfig.lan1.ipv4.dns.split(',') : DEFAULT_GOOGLE_DNS_SERVERS.split(','));
      } else {
        (<FormControl>this.siteForm.controls['ipAddress1'])
          .reset();
        (<FormControl>this.siteForm.controls['subnet1'])
          .reset();
        (<FormControl>this.siteForm.controls['gateway1'])
          .reset();
      }
    }
  }

  async checkGatewayInfo(site: Site) {
    this.spinnerSvc.setMessage(siteMessages.CHECK_GATEWAY);
    // Check if the account has a Gateway setup
    try {
      const result = await this.admin2Svc.getGateways(this.account.accountId);
      console.log('gateway already configured');
    } catch (reason) {
      if (reason.statusCode === 404) {
        console.log('gateway not found, begin setting up the gateway');
        this.setGatewayInfo(site);
      } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
        console.error('Reject get gateway', reason.message);
        this.authSvc.redirectToLogin();
      } else {
        this.spinnerSvc.hide();
        this.openConnectError();
        console.error('failed to create gateway', reason);
      }
    }
  }

  async setGatewayInfo(site: Site) {
    this.spinnerSvc.setMessage(siteMessages.SET_GATEWAY);
    const string1 = this.adminSvc.generateRandomPassword(24);
    const string2 = this.adminSvc.generateRandomPassword(24);
    const params = this.getGatewayParameters(site, string1, string2);
    try {
      const result = await this.admin2Svc.createGateway(this.account.accountId, params);
      console.log('gateway created');
    } catch (reason) {
      this.spinnerSvc.hide();
      this.openConnectError();
      if (typeof reason.text === 'function') {
        reason.text().then(res => {
          console.error('error creating gateway', res);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_GATEWAY);
        });
      } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
        console.error('error creating gateway', reason.message);
        this.authSvc.redirectToLogin();
      } else {
        console.error('error creating gateway', reason);
        this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_GATEWAY);
      }
    }
  }

  private getGatewayParameters(site: Site, string1: string, string2: string): any {
    return {
      siteId: site.siteId,
      name: site.name + ' Gateway',
      sipProxy: string1 + '@' + this.account.domain,
      authentication: {
        username: string1,
        password: string2
      },
      secureTrunking: true
    };
  }

  // Check the error state to determine if the tunnel needs to be configured again
  private isErrorTunnel(): boolean {
    if (this.accountTags) {
      if (this.accountTags['on-board-progress']) {
        const progress = this.accountTags['on-board-progress'];
        if (progress.step && progress.step === 2
          && progress.reason
          && (progress.reason === siteMessages.ERR_TUNN_OLD || progress.reason === siteMessages.ERR_TUNN)) {
          return true;
        }
      }
    }
    return false;
  }

  async checkTunnelConnection() {
    this.spinnerSvc.setMessage(siteMessages.CHECK_TUNNEL);
    // 1. Get Client info
    try {
      // regenerate client for this site
      const newClient = await this.createNewClient();
      console.log('created new client');
      const cloudParams = this.getCloudParams(this.site, newClient);
      this.createCloudTunnel(cloudParams);
    } catch (reason) {
      this.spinnerSvc.hide();
      this.isSubmitting = false;
      this.openConnectError();
      if (reason && reason.statusCode === 401) {
        console.error('failed to get client', JSON.stringify(reason));
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('failed to get client', JSON.stringify(reason));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (typeof reason.text === 'function') {
        reason.text().then(res => {
          console.error('failed to get client', res);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_CLIENT);
        }).catch(err => console.error('failed to get client', err));
      } else if (reason instanceof Error) {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_CLIENT);
        console.error('failed to get client', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_CLIENT);
        console.error('failed to get client', reason);
      }
    }
  }


  async createNewClient() {
    try {
      const newClient: Client = await this.adminSvc.createClientByAccountId(this.account.accountId, { name: uuid(), role: 'ACCOUNT_ADMIN' });
      const oldClient: Client = await this.adminSvc.getClientFromAccount(this.account.accountId, this.site.clientId);

      const site = await this.adminSvc.updateSite(this.account.accountId, this.site.siteId, { clientId: newClient.clientId });
      this.accountSvc.setSite(site);

      await this.adminSvc.deleteClientByAccountId(this.account.accountId, oldClient.clientId);
      return newClient;
    } catch (error) {
      console.error('error creating new client', error);
      throw error;
    }
  }

  isEditMode() {
    return !!this.site;
  }

  isSaveMode() {
    const saveMode = this.isEditMode();
    // Display Next if the progress is step otherwise use the value from isEditMode
    if (this.accountTags) {
      if (this.accountTags['on-board-progress']) {
        const progress = this.accountTags['on-board-progress'];
        if (progress.step && progress.step === 2) {
          return false;
        }
      }
    }
    return saveMode;
  }

  infoIconItem(labelName: string): any {
    return this.tooltipSvc.getTooltipItem(labelName);
  }

  keyPress(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  stripLeadingZeroInIPAddr(ip_addr: string): string {
    return ip_addr.split('.').map(Number).join('.');
  }

  subnet2cidr(subnet_mask: string): number {
    if (!CustomValidators.subnetMasks.hasValue(subnet_mask)) {
      throw new Error(`subnet mask ${subnet_mask} is invalid`);
    }
    return CustomValidators.subnetMasks.getKey(subnet_mask);
  }

  cidr2subnet(cidr: number): string {
    if (!CustomValidators.subnetMasks.hasKey(cidr)) {
      throw new Error(`cidr prefix ${cidr} is invalid`);
    }
    return CustomValidators.subnetMasks.getValue(cidr);
  }

  onInvalidDns(dnsValidity: any) {
    const dnsControl = this.siteForm.controls['dnsServers'];
    if (dnsValidity.invalid) {
      switch (dnsValidity.message) {
        case 'validateDnsServers':
          dnsControl.setErrors({ 'validateDnsServers': true });
          break;
        case 'required':
          dnsControl.setErrors({ 'required': true });
          break;
      }
    } else {
      dnsControl.setErrors(null);
    }
  }

  ngOnDestroy() {
    if (this.formDataSvc.setSessionData && ((this.siteForm && this.siteForm.dirty) || this.vccDidNumberSelectedChanged)) {
      sessionStorage.setItem('site-form', JSON.stringify(this.siteForm.value));
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  countryCompare(a: Country, b: Country): boolean {
    return a && b ? (a.code === b.code) : false;
  };

  setAddress(addrObj: any) {
    this.ngZone.run(() => {
      let addr = addrObj;
      if (addr.route) {
        let value = addr.street_number ? `${addr.street_number} ${addr.route}` : addr.route;
        this.siteForm.controls['addressLine1'].patchValue(value);
      }
      if (addr.country) {
        let initialCountry: Country = this.countryService.getSupportedCountries().find(country => country.code === addr.countryCode);
        if (initialCountry) {
          this.siteForm.controls['country'].setValue(initialCountry);
          if (addr.admin_area_l1) {
            let stateObj = this.countryService.getStatesOrProvincesAbbrByCountry(initialCountry);
            if (stateObj['provinceInput']) {
              this.siteForm.controls['provinceInput'].patchValue(addr.admin_area_l2 || addr.admin_area_l1);
            } else {
              this.siteForm.controls['stateOrProvince'].patchValue(stateObj[addr.admin_area_l1]);
            }
          }
          if (addr.postal_code) {
            this.siteForm.controls['zipCode'].patchValue(addr.postal_code);
          }
        } else {
            this.siteForm.controls['country'].setValue('');
            this.siteForm.controls['provinceInput'].patchValue('');
            this.siteForm.controls['stateOrProvince'].patchValue('');
            this.siteForm.controls['zipCode'].patchValue('');
        }

        if (addr.locality) {
          this.siteForm.controls['city'].patchValue(addr.locality);
        }
      } else {
        this.siteForm.controls['country'].patchValue('');
      }
    });

  }
}
