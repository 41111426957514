// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-link {
	position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
}

button {
	text-decoration: none;
}

button:hover {
	text-decoration: underline;
}

.btn-link {
	font-family: MuseoSans-300;
  font-size: 13px;
	color: #00A1E0;
	padding: 0px 10px;
}

.btn-link-emphasis {
	font-family: MuseoSans-700;
}`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;EACjB,QAAQ;EACR,SAAS;EACT,OAAO;EACP,aAAa;EACb,kBAAkB;AACpB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,0BAA0B;EACzB,eAAe;CAChB,cAAc;CACd,iBAAiB;AAClB;;AAEA;CACC,0BAA0B;AAC3B","sourcesContent":[".footer-link {\n\tposition: absolute;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  padding: 1rem;\n  text-align: center;\n}\n\nbutton {\n\ttext-decoration: none;\n}\n\nbutton:hover {\n\ttext-decoration: underline;\n}\n\n.btn-link {\n\tfont-family: MuseoSans-300;\n  font-size: 13px;\n\tcolor: #00A1E0;\n\tpadding: 0px 10px;\n}\n\n.btn-link-emphasis {\n\tfont-family: MuseoSans-700;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
