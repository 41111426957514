import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-server-unavailable-modal',
  templateUrl: './server-unavailable-modal.component.html',
  styleUrls: ['./server-unavailable-modal.component.css']
})
export class ServerUnavailableModalComponent implements OnInit {

  @Output() okPressed = new EventEmitter();

  constructor(private activeModal: NgbActiveModal, private translateSvc: TranslateService) { }

  ngOnInit() {
  }

  onOk() {
    this.okPressed.emit();
  }

  onCancel() {
    this.activeModal.close();
  }
}
