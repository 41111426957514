import { Component, OnInit, Output, Input, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'app-tag-input-item',
  templateUrl: './tag-input-item.component.html',
  styleUrls: ['./tag-input-item.component.css'],
})
export class TagInputItemComponent implements OnInit {
	@Input() text: string;
	@Input() index: number;
	@Output() tagRemoved = new EventEmitter<number>();
  @HostBinding('class.tag-input-item-selected') @Input() selected: boolean;

  constructor() { }

  ngOnInit() {
  }

  removeTag() {
  	this.tagRemoved.emit(this.index);
  }

}
