// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host >>> .alert-custom {
  border-radius: 0px;
  font-family: MuseoSans-300;
  font-size: 12px;
  width: 520px;
  left: 50%;
	transform: translateX(-50%);
	top: 14px;
	margin: 0px;
	margin-bottom: 0px;
	position: fixed;
	z-index: 9999;
	text-align: center;
	overflow-wrap: break-word;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-right: 9px;
	padding-left: 9px;
}

:host >>> .info {
	background-color: #E5F5FC;
	border-color: #00A1E0;
	color: #00A1E0;
}

:host >>> .error {
	background-color: #FDE8E9;
	border-color: #ED1C24;
	color: #ED1C24;
}

span {
	height: 14px;
	margin-top: 3px;
}

img {
	width: 14px;
	height: 14px;
	vertical-align: baseline;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/alert/alert.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,eAAe;EACf,YAAY;EACZ,SAAS;CACV,2BAA2B;CAC3B,SAAS;CACT,WAAW;CACX,kBAAkB;CAClB,eAAe;CACf,aAAa;CACb,kBAAkB;CAClB,yBAAyB;CACzB,gBAAgB;CAChB,mBAAmB;CACnB,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC,yBAAyB;CACzB,qBAAqB;CACrB,cAAc;AACf;;AAEA;CACC,yBAAyB;CACzB,qBAAqB;CACrB,cAAc;AACf;;AAEA;CACC,YAAY;CACZ,eAAe;AAChB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,wBAAwB;AACzB","sourcesContent":[":host >>> .alert-custom {\n  border-radius: 0px;\n  font-family: MuseoSans-300;\n  font-size: 12px;\n  width: 520px;\n  left: 50%;\n\ttransform: translateX(-50%);\n\ttop: 14px;\n\tmargin: 0px;\n\tmargin-bottom: 0px;\n\tposition: fixed;\n\tz-index: 9999;\n\ttext-align: center;\n\toverflow-wrap: break-word;\n\tpadding-top: 4px;\n\tpadding-bottom: 4px;\n\tpadding-right: 9px;\n\tpadding-left: 9px;\n}\n\n:host >>> .info {\n\tbackground-color: #E5F5FC;\n\tborder-color: #00A1E0;\n\tcolor: #00A1E0;\n}\n\n:host >>> .error {\n\tbackground-color: #FDE8E9;\n\tborder-color: #ED1C24;\n\tcolor: #ED1C24;\n}\n\nspan {\n\theight: 14px;\n\tmargin-top: 3px;\n}\n\nimg {\n\twidth: 14px;\n\theight: 14px;\n\tvertical-align: baseline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
