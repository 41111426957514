import { Directive,
         HostListener,
         HostBinding,
         OnDestroy,
         Input,
         ElementRef,
         ComponentRef,
         ViewContainerRef,
         ComponentFactoryResolver,
         ComponentFactory, 
         OnInit,
         OnChanges} from '@angular/core';
import { TooltipContentComponent } from '../components/tooltip-content/tooltip-content.component';
import { TooltipService } from '../services/tooltip.service';
import { Admin2Service } from '../services/admin2.service';

@Directive({ selector: '[appTooltip]' })
export class TooltipDirective implements OnChanges {

  @Input() appTooltip: any = {};

  private tooltip: ComponentRef<TooltipContentComponent>;
  isOpen: boolean;

  constructor(private elementRef: ElementRef,
              private viewContainerRef: ViewContainerRef,
              private resolver: ComponentFactoryResolver,
              private tooltipSvc: TooltipService,
              private admin2Svc: Admin2Service) {}

  ngOnChanges(changes) {
    if (changes.appTooltip && this.isOpen) {
      this.tooltip.instance.tooltipTitle = this.appTooltip.title;
      this.tooltip.instance.tooltipContent = this.appTooltip.content;
      this.tooltip.instance.helpLink = this.appTooltip.help;
    }
  }

  @HostListener('window:keyup.enter', ['$event'])
  @HostListener('click', ['$event'])
  show(event) {
    let src = event && event.target ? event.target.src : undefined;
    let classList = event && event.target ? event.target.classList : undefined;
    if (src && src.includes('info_serif.svg') || classList && classList.contains('info')) {
      this.tooltipSvc.destroy();
      this.isOpen = true;
      const factory = this.resolver.resolveComponentFactory(TooltipContentComponent);
      this.tooltip = this.viewContainerRef.createComponent(factory);
      this.tooltip.instance.tooltipRef = this.elementRef;
      this.tooltip.instance.tooltipTitle = this.appTooltip.title;
      this.tooltip.instance.tooltipContent = this.appTooltip.content;
      this.tooltip.instance.helpLink = this.appTooltip.help;
      this.tooltip.instance.close.subscribe(() => {
        this.tooltipSvc.destroy();
        this.isOpen = false;
      });
      
      this.tooltipSvc.addTooltip(this.tooltip);
    }
  }

}
