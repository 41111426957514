import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-users-list-modal',
  templateUrl: './users-list-modal.component.html',
  styleUrls: ['./users-list-modal.component.css']
})
export class UsersListModalComponent implements OnInit {
	reverse = false;
	order = 'name';
  users: any[];
  applicationName: string;

  types = {
    NOEMAIL: 'NOEMAIL',
    DUPEMAIL: 'DUPEMAIL'
  };
  type = this.types.NOEMAIL;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  	if (this.users && this.users.length > 0) {
      this.users.forEach(user => {
        if (!user.name) {
          user.name = `${user.firstname} ${user.lastname}`;
        }
      });
  	}
  }

  onClose() {
  	this.activeModal.close();
  }

  reverseOrder() {;
  	this.reverse = !this.reverse;
  }

}
