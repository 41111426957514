import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MessageType } from '../shared/constants';
import { AdvancedFormComponent } from './../components/advanced-form/advanced-form.component';

@Injectable({
  providedIn: 'root'
})
export class DeactivateAdvancedGuardService {

  constructor(private router: Router) { }

  canDeactivate(component: AdvancedFormComponent,
    route: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot, 
    nextState?: RouterStateSnapshot): boolean {
    if(component.isIframedSupport){
      if(nextState && nextState.url.indexOf('message') === -1){ //user is being redirected from Advanced page to another page different that message 
        console.log("Redirecting to No Support Page - Updated Status"); //Advanced page is enable but the status was updated after navigating to this page
        this.router.navigate(['accounts', component.account.accountId, 'message', MessageType.NO_SUPPORT_UPDATED_STATUS]);
        return false;
      }
    }
    return true;
  }
}
