import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  connectStates,
  toastMsgTypes,
  connectMessages,
  resyncMessages,
  commonErrorMessages,
  commonMessages,
  platformTypes,
  pbxTypes,
  applicationNames,
  pbxProducts,
  gatewayProducts} from '../../shared/constants';
import { Subscription } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { AdminService } from '../../services/admin.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ProgressService } from '../../services/progress.service';
import { AlertService } from '../../services/alert.service';
import { EchoService } from '../../services/echo.service';
import { CloudResourcesService } from '../../services/cloudResources.service';
import { ResyncService } from '../../services/resync/';
import { SpinnerService } from '../../services/spinner.service';
import { FormDataService } from '../../services/form-data.service';
import { UsersListModalComponent } from '../users-list-modal/users-list-modal.component';
import { TunnelService } from '../../services/tunnel.service';
import { Pbxlink, PbxUser, PbxTrunk, PbxSyncStatus, CloudlinkGatewayStatus} from '@mitel/cloudlink-sdk/tunnel';
import { Utils, Odata } from '@mitel/cloudlink-sdk';
import { Account, Site} from '@mitel/cloudlink-sdk/admin';
import { UnexpectedModalComponent } from "../unexpected-modal/unexpected-modal.component";
import * as _ from 'lodash';
import { AppService } from '../../services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { PatchRequestError } from '../../shared/resync/helper';
import { LicensesService } from '../../services/licenses.service';
import { SyncService } from '../../services/sync.service';
import { ParentCommsService } from '../..//services/parent-comms.service';

@Component({
  selector: 'app-connect-state',
  templateUrl: './connect-state.component.html',
  styleUrls: ['./connect-state.component.css'],
})
export class ConnectStateComponent implements OnInit, OnDestroy {
	state: string;
	result: any = {
    users: '-',
    trunks: '-',
    huntGroups: '-',
    phantoms: '-',
    actualNumberOfTrunks: '-',
  };
  allPBXUsers: PbxUser[];
  usersWithoutEmail: PbxUser[];
  usersWithDupEmail: PbxUser[];
	connectStates = connectStates;

  account: Account;
  site: Site;
  pbxLink: Pbxlink;
  accountTags: any = {};
  accountTagsSubscription: Subscription;
  accountSubscription: Subscription;
  siteSubscription: Subscription;
  pbxSubscription: Subscription;
  cloudStatusSubscription: Subscription;

  isOverview = false;
  pbxSyncing = false;
  pbxConnecting = false;
  isSubmitting = false;

  syncMins: number;
  sessionData: any;
  connectFailMessage = '';
  MAX_QUERY_NUM = 50;
  isGettingPbxInfo = false;

  getPbxInfoTimeout = false;

  platform: any;
  platformSubscription: Subscription;
  platformTypes = platformTypes;
  pbxTypes = pbxTypes;

  didNumber: string;
  applicationName: string;
  status : CloudlinkGatewayStatus;
  continueCount = true;

  launchedFromSystemInventory: boolean = false;
  componentIdFromSystemInventory: string = undefined;
  showSyncScheduleWithSyncButton: boolean = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminSvc: AdminService,
              private admin2Svc: AdminService,
              private authSvc: AuthenticationService,
              private accountSvc: AccountService,
              private progressSvc: ProgressService,
              private alertSvc: AlertService,
              private cloudResourcesSvc: CloudResourcesService,
              private resyncService: ResyncService,
              private spinnerSvc: SpinnerService,
              private echoSvc: EchoService,
              private formDataSvc: FormDataService,
              private analyticsSvc: AnalyticsService,
              private tunnelSvc: TunnelService,
              private syncSvc: SyncService,
              private appSvc: AppService,
              private modalSvc: NgbModal,
              private translateSvc: TranslateService,
              private licensesService : LicensesService,
              private parentCommsService: ParentCommsService) { }

  ngOnInit() {
    this.launchedFromSystemInventory = this.parentCommsService.launchedFromSystemInventory;
    this.componentIdFromSystemInventory = this.parentCommsService.componentId;
    this.formDataSvc.setSessionData = true;
    const formItem = sessionStorage.getItem('connect-state-form');
    if (typeof formItem !== 'undefined' && formItem) {
      this.sessionData = JSON.parse(formItem);
    }
    this.accountTagsSubscription = this.accountSvc.accountTagsChanged
                                    .subscribe(accountTags => {
                                      this.accountTags = accountTags;
                                      this.echoSvc.getEcho();
                                      this.setConnectState();
                                    });
    this.accountSubscription = this.accountSvc.accountChanged
                                .subscribe(account => {
                                  this.account = account;
                                  this.echoSvc.getEcho();
                                  this.getPbxInfo();
                                  this.getPbxSyncSchedule();

                                });
    this.siteSubscription = this.accountSvc.siteChanged
                              .subscribe(site => {
                                this.site = site;
                                this.echoSvc.getEcho();
                                this.getPbxInfo();
                                this.getPbxSyncSchedule();

                              });
    this.pbxSubscription = this.accountSvc.pbxChanged
                            .subscribe(pbx => {
                              this.pbxLink = pbx;
                              this.applicationName = this.accountSvc.getApplicationName();
                              this.getPbxInfo();
                            });
    this.platformSubscription = this.accountSvc.platformChanged.subscribe(platform => {
      this.platform = platform
      this.shouldSyncScheduleShow();
    });

    this.cloudStatusSubscription = this.accountSvc.cloudlinkStatusChanged.subscribe(status => this.status = status);

    this.account = this.accountSvc.getAccount();
    this.site = this.accountSvc.getSite();
    this.pbxLink = this.accountSvc.getPbxlink();
    this.applicationName = this.accountSvc.getApplicationName();
    this.accountTags = this.accountSvc.getAccountTags();
    this.platform = this.accountSvc.getPlatform();
    this.shouldSyncScheduleShow();

    this.echoSvc.setGatewayConnect('connect');
    this.setConnectState();

    this.route.params
      .subscribe(
        (params: Params) => {
          this.isOverview = this.route.snapshot.url[0].path === 'overview';
      });
  }

  shouldSyncScheduleShow(){
    if(!this.platform || this.platform.platform === platformTypes.APPLIANCE){
      this.showSyncScheduleWithSyncButton = true;
    }
    else{
      this.showSyncScheduleWithSyncButton = false;
    }
  }

  setConnectState() {
    if(this.launchedFromSystemInventory){
      this.state = this.connectStates.CONNECT_RESULT;
      this.getPbxInfo();
    }
    else if (this.accountTags && this.accountTags['on-board-progress']) {
      const progressConnectIdx = this.progressSvc.getProgressIdx('connect');
      const lastProgressIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress'].name);
      if (lastProgressIdx < progressConnectIdx - 1 ||
          (lastProgressIdx === progressConnectIdx - 1 && !this.accountTags['on-board-progress'].succeeded)) {
        this.state = this.connectStates.CANNOT_CONNECT;
      } else if (lastProgressIdx === progressConnectIdx - 1) {
        this.state = this.connectStates.CAN_CONNECT;
      } else if (lastProgressIdx === progressConnectIdx) {
        if (!this.accountTags['on-board-progress'].succeeded) {
          this.setConnectFailMessage();
          this.state = this.connectStates.CONNECT_FAILED;
        } else {
          this.state = this.connectStates.CONNECT_RESULT;
          this.getPbxInfo();
          this.getPbxSyncSchedule();
        }
      } else {
        this.state = this.connectStates.CONNECT_RESULT;
        this.getPbxInfo();
        this.getPbxSyncSchedule();
      }
    }
  }

  async setCloudlinkStatus() {
    if (this.account && (this.site || this.launchedFromSystemInventory)) {
      try {
        console.log('Function: , from connect-state: ');
        const status: CloudlinkGatewayStatus = await this.tunnelSvc.getCloudlinkStatus(this.componentIdFromSystemInventory);
        this.accountSvc.setCloudlinkStatus(status);
      } catch (error) {
        if (typeof error === 'string') {
          console.error('failed to get cloudlink status', error);
        } else if (error && error.statusCode === 401) {
          console.error('failed to get cloudlink status', JSON.stringify(error));
          this.authSvc.redirectToLogin();
        } else if (typeof error.text === 'function') {
          error.text().then(res => {
            console.error('failed to get cloudlink status', res);
          }).catch(err => console.error('failed to get cloudlink status', err));
        } else if (error instanceof Error) {
          console.error('failed to get cloudlink status', error.message);
          if (error.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to get cloudlink status', error);
        }
      }
    }
  }

  setConnectFailMessage() {
    if (this.accountTags && this.accountTags['on-board-progress'] &&
        !this.accountTags['on-board-progress'].succeeded) {
      if (this.accountTags['on-board-progress'].reason &&
          this.accountTags['on-board-progress'].reason.indexOf('ERR_PBX_INV_PW') !== -1) {
        this.connectFailMessage = connectMessages.INV_PSWD;
      } else {
        this.connectFailMessage = connectMessages.NO_CONNECTION;
      }
    }
  }

  async connect() {
    if(this.launchedFromSystemInventory){
      //don't allow this operation if launched from system inventory
      return;
    }
    this.pbxConnecting = true;
    // Retrieve pbxLinks again to check connect_error
    try {
      this.pbxLink = await this.tunnelSvc.getFirstPbxlink();
      if(this.pbxLink){
        console.log('connect ... ');
        this.accountSvc.setPbxlink(this.pbxLink);
      }
    } catch (error) {
      if (typeof error === 'string') {
        console.error('failed to get pbxlink', error);
      } else if (error && error.statusCode === 401) {
        console.error('failed to get pbxlink', JSON.stringify(error));
        this.authSvc.redirectToLogin();
      } else if (error && error.statusCode === 403) {
        console.error('failed to get pbxlink', JSON.stringify(error));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (error && error.statusCode === 404) {
        console.error('failed to get pbxlink', JSON.stringify(error));
        this.alertSvc.setAlert(toastMsgTypes.ERROR, connectMessages.ERROR_SYNC);
      } else if (error instanceof Error) {
        console.error('failed to get pbxlink', error.message);
        if (error.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to get pbxlink', JSON.stringify(error));
      }
      return;
    }
    console.log('connect ');

    if (this.pbxLink && (this.pbxLink.state === 'init' ||
       (this.pbxLink['connect_error'] && this.pbxLink['connect_error'] !== ''))) {
      const statusMsg = this.echoSvc.getStatusMessageFromConnectError(this.pbxLink['connect_error']);
      this.connectFailMessage = statusMsg;
      const tagParams = { 'gateway-connection': { connected: true, pbxConnected: false, reason: statusMsg } };
      this.updateAccountTags(this.account.accountId, tagParams);
      this.state = this.connectStates.CONNECT_FAILED;
      this.pbxConnecting = false;
      this.alertSvc.setAlert(toastMsgTypes.ERROR, statusMsg);
    } else {
      try {
        await this.updateNameSyncPolicy();
        // check if platform exists
        if (!this.platform) {
          this.platform = await this.getPlatform();
        }

        // Jump to sync for SMBC platform
        if (this.platform && this.platform.capabilities
          && this.platform.capabilities.embedded_with_pbx) {
          this.cloudResourcesSvc.setCloudPbx();
          this.pbxConnecting = false;
          this.startPbxSyncing();
          return;
        } else {
          await this.tunnelSvc.startPbxSync(this.account.accountId, this.site.siteId);
          setTimeout(() => {
            this.getPbxSyncStatus();
            this.getPbxSyncSchedule();
          }, 1000);
        }
      } catch (reason) {
        if (reason && reason.statusCode === 401) {
          console.error('failed to start PBX sync', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to start PBX sync', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason && reason.body && reason.body.message) {
          console.error('failed to start PBX sync', JSON.stringify(reason));
          let errMsg;
            const srvErrMsg = reason.body.message;
            if ((srvErrMsg === commonErrorMessages.SRV_ERR_PBX_LOGIN) || (srvErrMsg.startsWith("pbxLogin error") && srvErrMsg.endsWith("timeout"))) {
              errMsg = commonMessages.ERR_PBX_LOGIN;
              this.connectFailMessage = connectMessages.NO_CONNECTION;
            } else if (srvErrMsg === commonErrorMessages.SRV_ERR_INV_PW) {
              errMsg = commonMessages.ERR_PBX_INV_PW;
              this.connectFailMessage = connectMessages.INV_PSWD;
            } else {
              errMsg = srvErrMsg;
            }
            this.updateAccountTagsWithProgress(false, errMsg);
        } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('failed to start PBX sync', reason.message);
          this.authSvc.redirectToLogin();
        } else if (typeof reason === 'string') {
          console.error('failed to start PBX sync', reason);
          this.updateAccountTagsWithProgress(false, reason);
          this.connectFailMessage = connectMessages.NO_CONNECTION;
        } else {
          console.error('failed to start PBX sync', reason);
          this.connectFailMessage = connectMessages.NO_CONNECTION;
          this.updateAccountTagsWithProgress(false, connectMessages.ERROR_SYNC);
        }
        this.state = this.connectStates.CONNECT_FAILED;
        this.pbxConnecting = false;
      }
    }
  }

  async getPlatform() {
    //only called from connect() if not launched from system inventory
    try {
      this.platform = await this.tunnelSvc.getPlatform();
      this.accountSvc.setPlatform(this.platform);
      return this.platform;
    } catch (reason) {
      if (typeof reason === 'string') {
        console.error('failed to get platform', reason);
      } else if (reason instanceof Error) {
        console.error('failed to get platform', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.log('failed to get platform', JSON.stringify(reason));
      }
      return;
    }
  }

  updateNameSyncPolicy() {
    if(this.pbxLink && this.pbxLink.type === pbxTypes.MIVO400) {
        let dataChanges = {
          policy: <any> {
            overwriteVerifiedNamesFromIntegrations: true
          }
        }
       return this.adminSvc.updateAccount(this.account.accountId, dataChanges).then(account => {
          this.accountSvc.setAccount(account);
        },reason => {
           if (reason && reason.statusCode === 401) {
              this.authSvc.redirectToLogin();
          } else if (reason && reason.statusCode === 403) {
              this.formDataSvc.redirectToDashboardOrLogout();
          }
          console.error('failed to update account', reason);
        })
        .catch(error => console.error('failed to update account', error));
    }
  }

  getAccountTagParameters(succeeded: boolean, reason?: string): any {
    let tagParams = {};
    if (succeeded) {
      if (this.accountTags && this.accountTags['process-complete'] === true) {
        tagParams = { 'on-board-progress': { 'step': 5, 'name': 'officelink', 'succeeded': succeeded } };
      } else {
        tagParams = { 'on-board-progress': { 'step': 4, 'name': 'connect', 'succeeded': succeeded } };
      }
      tagParams['email-warning'] = true;
      tagParams['gsm-warning'] = true;
    } else {
      tagParams = { 'on-board-progress': { 'step': 4, 'name': 'connect', 'succeeded': succeeded, 'reason': reason } };
    }

    if (!this.accountTags || !this.accountTags['on-board-progress'] || this.accountTags['on-board-progress'].step <= 4) {
      return tagParams;
    } else if (succeeded) {
      tagParams = { 'email-warning': true };
      tagParams['gsm-warning'] = true;
      return tagParams;
    }
    return undefined;
  }

  updateAccountTagsWithProgress(success: boolean, reason?: string) {
    // Toast with Error message
    if (!success && reason) {
      this.alertSvc.setAlert(toastMsgTypes.ERROR, reason);
    }
    if(!this.launchedFromSystemInventory){
      // Add Progress to Account tags
      const tagParams = this.getAccountTagParameters(success, reason);
      if (tagParams) {
        this.updateAccountTags(this.account.accountId, tagParams);
      }
    }
  }

  async startPbxSyncing() {
    //should never be called when launched from system inventory
    if(this.launchedFromSystemInventory){ //just for safety
      return Promise.resolve();
    }
    this.spinnerSvc.show();
    this.spinnerSvc.setMessage(connectMessages.PBX_SYNC);
    this.pbxSyncing = true;
    try {
      // If gateway products are not present in the tags for whatever reason (CLSD-1626), 
      // update the tags and add policy if mivo400
      if (this.pbxLink && this.pbxLink.type) {
        let hasCorrectProductTags = pbxProducts[this.pbxLink.type].every(val => this.accountTags?.products?.includes(val))
        if (!hasCorrectProductTags) {
          await this.updateProductTagsIfRequired(this.pbxLink.type);
          await this.updateNameSyncPolicy();
        }
      }
      await this.tunnelSvc.startPbxSync(this.account.accountId, this.site.siteId);
      setTimeout(() => {
        this.getPbxSyncStatus();
      }, 1000);
    } catch (reason) {
      if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
        console.error('failed to start PBX sync', reason.message);
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 401) {
        console.error('failed to start PBX sync', reason);
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('failed to start PBX sync', JSON.stringify(reason));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else {
        console.error('failed to start PBX sync', reason);
        if (reason && reason.body && reason.body.message) {
          const srvErrMsg = reason.body.message;
          let errMsg;
          if (srvErrMsg === commonErrorMessages.SRV_ERR_PBX_LOGIN) {
            errMsg = commonMessages.ERR_PBX_LOGIN;
          } else if (srvErrMsg === commonErrorMessages.SRV_ERR_INV_PW) {
            errMsg = commonMessages.ERR_PBX_INV_PW;
          } else if(reason.body.name && this.translationExists('toasts.startPbxSync.' + reason.body.name)){
            errMsg = 'startPbxSync.' + reason.body.name;
          } else {
            errMsg = connectMessages.ERROR_SYNC;
          }
          this.alertSvc.setAlert(toastMsgTypes.ERROR, errMsg);
        } else {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, connectMessages.ERROR_SYNC);
        }
      }
      this.pbxSyncing = false;
      this.spinnerSvc.hide();
    }
  }

  startResync() {
    //should never be called when launched from system inventory
    if(this.launchedFromSystemInventory){ //just for safety
      return ;
    }
    this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Connect' : 'Connect', 'Start re-sync');
    this.spinnerSvc.setMessage(connectMessages.PBX_RESYNC);
    console.log('waiting for sync to complete');
    setTimeout(async () => {
      try {
        await this.resyncService.startResyncForAccount(this.account.accountId, this.site.siteId);
        setTimeout(async() => {
          await this.checkGSMConfig();
          this.spinnerSvc.hide();
          this.alertSvc.setAlert(toastMsgTypes.INFO, resyncMessages.SYNC_COMPLETE);
        }, 12000); // Added 10 more seconds to wait for webhook notifications
      } catch (reason) {
        if (!(reason instanceof PatchRequestError)) {
          this.spinnerSvc.hide();
        }
        if (reason instanceof PatchRequestError) {
          setTimeout(async() => {
            await this.checkGSMConfig();
            this.spinnerSvc.hide();
            this.alertSvc.setAlert(toastMsgTypes.INFO, resyncMessages.SYNC_COMPLETE_WITH_FAILURES);
          }, 12000); // Added 10 more seconds to wait for webhook notifications
        }
        else if (typeof reason === 'string') {
          console.error('resync failed: ', reason);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('resync failed: ', reason);
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('resync failed: ', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason && reason.statusCode === 409 && reason.body && reason.body.name) {
          console.error('resync failed: ', JSON.stringify(reason));
          // Many possible reasons for a 409 and UserAlreadyExists.
          // Only dealing with email conflict now.
          // pbxUserId conflict is also a possible UserAlreadyExists error
          if(reason.body.name === 'UserAlreadyExists' && reason.body.message && reason.body.message === "Email address not unique in account" && reason.body.error_description){
            // Email address not unique in account: <email>
            let descriptionParts = reason.body.error_description.split(': ');
            let email = descriptionParts.length === 2? descriptionParts[1] : undefined;
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'startResyncForAccount.EmailConflict', email);
          } else if(this.translationExists('toasts.startResyncForAccount.' + reason.body.name)){
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'startResyncForAccount.' + reason.body.name);
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.ERROR_SYNC);
          }
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('resync failed: ', res);
            const body = JSON.parse(res);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'startResyncForAccount.' + body.name);
          });
        } else if (reason instanceof Error) {
          console.error('resync failed: ', reason.message);
          if (reason.message === commonErrorMessages.TYPE_ERROR) {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.TYPE_ERROR);
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.ERROR_SYNC);
          }
        } else {
          console.error('resync failed: ', reason);
          if (reason && reason.body && reason.body.name) {
            console.log(this.translationExists('toasts.startResyncForAccount.' + reason.body.name));
            if(this.translationExists('toasts.startResyncForAccount.' + reason.body.name)){
              this.alertSvc.setAlert(toastMsgTypes.ERROR, 'startResyncForAccount.' + reason.body.name);
            } else {
              this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.ERROR_SYNC);
            }
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.ERROR_SYNC);
          }
        }
      }
    }, 5000);
  }

  translationExists(key: string){
    let translation = this.translateSvc.instant(key); // returns the key if no translation is found
    return translation === key? false : true;
  };

  async checkGSMConfig() {
    this.didNumber = null;
    if(!this.launchedFromSystemInventory){
      try {
        let site = await this.adminSvc.getFirstSite(this.account.accountId);
        if(site){
          this.didNumber = site.vccDidNumber;
          if(!(_.isEqual(site, this.site))) {
            this.accountSvc.setSite(site);
          }
        }
      } catch (error) {
        if (typeof error  === 'string') {
            console.error('failed to get sites', error);
        } else if (error && error.statusCode === 401) {
            console.error('failed to get sites', error);
            this.authSvc.redirectToLogin();
        } else if (error && error.statusCode === 403) {
          console.error('failed to get sites', JSON.stringify(error));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (typeof error.text === 'function') {
            error.text().then(res => console.error('failed to get sites', res));
        } else if (error instanceof Error) {
            console.error('failed to get sites', error.message);
            if (error.message === commonErrorMessages.AUTH_ERROR) {
                this.authSvc.redirectToLogin();
            }
        } else {
            console.error('failed to get sites', error);
        }
      }
    }
  }

  getPbxTypeFromTags() {
    const accountTags = this.accountSvc.getAccountTags();
    let productTags = [];
    let pbxType = null;
    if (accountTags && accountTags["products"]) {
      productTags = accountTags["products"];
      for (let key in pbxProducts) {
        let value = pbxProducts[key][0];
        if (productTags.includes(value)) {
          pbxType = key;
          break;
        }
      }
    }
    return pbxType;
  }

  async updateProductTagsIfRequired(type: string) {
    let pbxTypeFromTags = this.getPbxTypeFromTags();

    if (type !== pbxTypeFromTags) {
      try {
        let prodArray;
        if (this.accountTags && this.accountTags['products']) {
          prodArray = this.accountTags['products'];
          // Remove any old PBX types and mobile app products related to CloudLink Gateway
          _.remove(prodArray, function (n) {
            return _.includes(gatewayProducts, n);
          });
          // Add new products based on the PBX Type
          prodArray = _.concat(prodArray, pbxProducts[type]);
        } else {
          prodArray = pbxProducts[type];
        }
        const newTags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, { products: prodArray })
        this.accountSvc.setAccountTags(newTags);
      } catch (err) {
        console.error('failed to update account products', err);
      }
    }
  }

  closeGSMConfigWarning() {
    if(this.launchedFromSystemInventory){
      //doesn't make sense to place this tag on the account since there could be many pbxs
      return;
    }
    // Issue 517 in J-SDK. Updating a tag to boolean false actually sets tag to empty object
    // Temporary workaround is to use string 'false'
    const tagParams = { 'gsm-warning': 'false' };
    this.updateAccountTags(this.account.accountId, tagParams);
  }

  shouldShowGSMConfigWarning(): boolean {
    if (this.pbxLink && (this.pbxLink.type === pbxTypes.MIVO400 || this.pbxLink.type === pbxTypes.MIVC) &&
      (this.didNumber === '' || !this.didNumber) &&
      this.accountTags &&  this.accountTags['gsm-warning'] !== false && this.accountTags['gsm-warning'] !== 'false') {
      // the above line can be cleaned up to just the bool false when issue 517 is J-SDK is fixed
      return true;
    }
    return false;
  }

  //Can't find where this method is being called
  async startResyncForSMBC() {
    if(this.launchedFromSystemInventory){
      return Promise.resolve();
    }
    if (!this.pbxConnecting) {
      this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Connect' : 'Connect', 'Start re-sync');
      this.spinnerSvc.show();
      this.spinnerSvc.setMessage(connectMessages.PBX_RESYNC);
    }
    console.log('waiting for sync to complete');

    try {
      await this.resyncService.startResyncForAccount(this.account.accountId, this.site.siteId);
      setTimeout(() => {
        if (this.pbxConnecting) {
          this.state = this.connectStates.CONNECT_SUCCESS;
          setTimeout(() => {
            this.state = this.connectStates.CONNECT_RESULT;
            this.isGettingPbxInfo = false;
            this.getPbxInfo();
            this.pbxConnecting = false;
            this.updateAccountTagsWithProgress(true);
          }, 4000);
        } else {
          this.spinnerSvc.hide();
          this.alertSvc.setAlert(toastMsgTypes.INFO, resyncMessages.SYNC_COMPLETE);
          this.isGettingPbxInfo = false;
          this.getPbxInfo();
        }
      }, 12000); // Added 10 more seconds to wait for webhook notifications
    } catch (reason) {
      if (this.pbxConnecting) {
        console.warn('PBX sync failed');
        this.state = this.connectStates.CONNECT_FAILED;
        this.pbxConnecting = false;
        this.updateAccountTagsWithProgress(false, connectMessages.ERROR_RESYNC);
      } else {
        this.spinnerSvc.hide();
        if (typeof reason === 'string') {
          console.error('resync failed: ', reason);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('resync failed: ', reason);
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('resync failed: ', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason && reason.statusCode === 409 && reason.body && reason.body.name) {
          console.error('resync failed: ', reason);
          // Many possible reasons for a 409 and UserAlreadyExists.
          // Only dealing with email conflict now.
          // pbxUserId conflict is also a possible UserAlreadyExists error
          if(reason.body.name === 'UserAlreadyExists' && reason.body.message && reason.body.message === "Email address not unique in account" && reason.body.error_description){
            // Email address not unique in account: <email>
            let descriptionParts = reason.body.error_description.split(': ');
            let email = descriptionParts.length === 2? descriptionParts[1] : undefined;
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'startResyncForAccount.EmailConflict', email);
          } else if(this.translationExists('toasts.startResyncForAccount.' + reason.body.name)){
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'startResyncForAccount.' + reason.body.name);
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.ERROR_SYNC);
          }
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('resync failed: ', res);
            const body = JSON.parse(res);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, 'startResyncForAccount.' + body.name);
          });
        } else if (reason instanceof Error) {
          console.error('resync failed: ', reason.message);
          if (reason.message === commonErrorMessages.TYPE_ERROR) {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.TYPE_ERROR);
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.ERROR_SYNC);
          }
        } else {
          console.error('resync failed: ', reason);
          if (reason && reason.body && reason.body.name) {
            if(this.translationExists('toasts.startResyncForAccount.' + reason.body.name)){
              this.alertSvc.setAlert(toastMsgTypes.ERROR, 'startResyncForAccount.' + reason.body.name);
            } else {
              this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.ERROR_SYNC);
            }
          } else {
            this.alertSvc.setAlert(toastMsgTypes.ERROR, resyncMessages.ERROR_SYNC);
          }
        }
      }
    }
  }

  resetResult() {
    this.result.users = 0;
    this.result.trunks = 0;
    this.result.huntGroups = 0;
    this.result.phantoms = 0;
    this.result.actualNumberOfTrunks = 0;
    this.allPBXUsers = [];
    this.usersWithoutEmail = [];
    this.usersWithDupEmail = [];
  }

  async getPbxSyncStatus() {
    //should never be called when launched from system inventory
    if(this.launchedFromSystemInventory){ //for safety
      return Promise.resolve();
    }
    if (this.account && this.site &&
       (this.state === this.connectStates.CONNECT_RESULT || this.pbxConnecting || this.pbxSyncing)) {
      try {
        const statusCollection = await this.tunnelSvc.getPbxSyncStatus(this.account.accountId, this.site.siteId);
        const status = Utils.getItemsFromCollection<PbxSyncStatus>(statusCollection);
        if (status && status[0]) {
          if (status[0].status === 'complete') {
            this.alertSvc.clearAlert();
            if (this.pbxConnecting) {
              this.state = this.connectStates.CONNECT_SUCCESS;
              this.startResync();
              setTimeout(() => {
                this.state = this.connectStates.CONNECT_RESULT;
                this.isGettingPbxInfo = false;
                this.getPbxInfo();
                this.pbxConnecting = false;
                this.updateAccountTagsWithProgress(true);
                this.setCloudTrunking();
                if(this.pbxLink && this.pbxLink.type && this.pbxLink.type === pbxTypes.MIVB) {
                  this.syncSvc.syncForMIVB(this.account.accountId, this.site.siteId);
                }
              }, 17000); // Added 13 more seconds to match time taken for resync
            }
            if (this.pbxSyncing) {
              this.state = this.connectStates.CONNECT_RESULT;
              this.isGettingPbxInfo = false;
              // stop counting pbxUser is it is the middle of users recursive calls
              this.continueCount = false;
              setTimeout(() => {
                this.continueCount = true;
                this.getPbxInfo();
                this.pbxSyncing = false;
                this.updateAccountTagsWithProgress(true);
                this.startResync();
                if(this.pbxLink && this.pbxLink.type && this.pbxLink.type === pbxTypes.MIVB) {
                  this.syncSvc.syncForMIVB(this.account.accountId, this.site.siteId);
                }
              }, 2000);
            }
          } else if (status[0].status === 'failed') {
            console.warn('PBX sync failed');
            const srvErrMsg = status[0].message;
            let errMsg;
            if (srvErrMsg === commonErrorMessages.SRV_ERR_PBX_LOGIN) {
              errMsg = commonMessages.ERR_PBX_LOGIN;
              this.connectFailMessage = connectMessages.NO_CONNECTION;
            } else if (srvErrMsg === commonErrorMessages.SRV_ERR_INV_PW) {
              errMsg = commonMessages.ERR_PBX_INV_PW;
              this.connectFailMessage = connectMessages.INV_PSWD;
            } else {
              errMsg = srvErrMsg;
              this.connectFailMessage = connectMessages.NO_CONNECTION;
            }
            if (this.pbxConnecting || this.pbxSyncing) {
              this.state = this.connectStates.CONNECT_FAILED;
              this.spinnerSvc.hide();
              this.pbxSyncing = false;
              this.pbxConnecting = false;
              this.updateAccountTagsWithProgress(false, errMsg);
            }
          } else if (status[0].status === 'syncing') {
            setTimeout(() => {
              this.getPbxSyncStatus(); // Get Sync status again
            }, 1000);
          }
        }
      } catch (reason) {
        this.spinnerSvc.hide();
        this.pbxConnecting = false;
        this.pbxSyncing = false;
        if (typeof reason === 'string') {
          console.error('failed to get PBX sync status', reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('failed to get PBX sync status', JSON.stringify(reason));
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to get PBX sync status', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason instanceof Error) {
          console.error('failed to get PBX sync status', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else if (reason && reason.statusCode === 406){
          const modalRef = this.modalSvc.open(UnexpectedModalComponent);
          const contentComponentInstance = modalRef.componentInstance;
          contentComponentInstance.advanced.subscribe(() => {
            this.router.navigateByUrl(`accounts/${this.account.accountId}/sites/${this.site.siteId}/advanced`)
              .then(()=>{
                modalRef.close()
              });
          });
          console.error('failed to get PBX sync status', 'Unexpected response body received.');
        } else {
            console.error('failed to get PBX sync status', JSON.stringify(reason))
        }
      }
    }
  }

  async setCloudTrunking() {
    //should never be called when launched from system inventory
    if(this.launchedFromSystemInventory){
      return Promise.resolve();
    }
    try {
      const response = await this.tunnelSvc.getFreeswitchConfig(this.account.accountId, this.site.siteId);
      console.log('fetching freeswitch config', response);
      this.cloudResourcesSvc.setCloudTrunking(response);
    } catch (reason) {
      if (typeof reason === 'string') {
        console.error('rej to get freeswitch config:', reason);
      } else if (reason && reason.statusCode === 401) {
        console.error('rej to get freeswitch config:', JSON.stringify(reason));
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        console.error('rej to get freeswitch config:', JSON.stringify(reason));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (reason instanceof Error) {
        console.error('rej to get freeswitch config:', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('rej to get freeswitch config:', JSON.stringify(reason));
      }
    }
  }

  async getPbxSyncSchedule() {
    //schedule details are not required when launched from system inventory
    if(this.launchedFromSystemInventory){
      return Promise.resolve();
    }
    if (this.sessionData) {
      this.syncMins = this.sessionData;
    } else {
      if (this.account && this.site && this.state) {
        try {
          const schedule = await this.tunnelSvc.getPbxSyncSchedule(this.account.accountId, this.site.siteId);
          if (schedule.count === 0 || Object.keys(schedule).length === 0) {
            this.setDefaultSyncSchedule();
          } else {
            const scheduleItems = Utils.getItemsFromCollection(schedule);
            if (scheduleItems[0]) {
              this.syncMins = scheduleItems[0].eachMinutes;
            }
          }
        } catch (reason) {
          if (typeof reason === 'string') {
            console.error('failed to get PBX sync schedule', reason);
          } else if (reason && reason.statusCode === 401) {
            console.error('failed to get PBX sync schedule', JSON.stringify(reason));
            this.authSvc.redirectToLogin();
          } else if (reason && reason.statusCode === 403) {
            console.error('failed to get PBX sync schedule', JSON.stringify(reason));
            this.formDataSvc.redirectToDashboardOrLogout();
          } else if (reason instanceof Error) {
            console.error('failed to get PBX sync schedule', reason.message);
            if (reason.message === commonErrorMessages.AUTH_ERROR) {
              this.authSvc.redirectToLogin();
            }
          } else {
            console.error('failed to get PBX sync schedule', JSON.stringify(reason));
          }
        }
      }
    }
  }

  async setDefaultSyncSchedule() {
    const defaultSyncMinutes = 20;
    const syncScheduleItem = { eachMinutes: defaultSyncMinutes };
    try {
      const response = await this.tunnelSvc.createPbxSyncSchedule(this.account.accountId, this.site.siteId, syncScheduleItem);
      if (response) {
        this.syncMins = defaultSyncMinutes;
        console.log('set default sync schedule to ', defaultSyncMinutes);
      }
    } catch (reason) {
      console.error('failed to set default PBX sync schedule', reason);
    }
  }

  pbxTypeSupportsPhantoms(): boolean {
    if(!this.pbxLink || !this.pbxLink.type){
      return false
    }
    else{
      switch (this.pbxLink.type) {
        case pbxTypes.MIVO400:
        case pbxTypes.MIVC:
        case pbxTypes.MIVB:
        case pbxTypes.OSV:
        case pbxTypes.OS4K:
          return false;
        
        default:
          return true;
      }
    }
  };

  getPbxInfo() {
    let okToGetPbxInfo = false;
    if(this.pbxLink && this.account && (!this.isGettingPbxInfo || this.getPbxInfoTimeout)){
      if(this.parentCommsService.launchedFromSystemInventory){
        okToGetPbxInfo = true;
      }
      else if(this.site && this.state === this.connectStates.CONNECT_RESULT){
        okToGetPbxInfo = true;
      }
    }
    if (okToGetPbxInfo) {
      this.isGettingPbxInfo = true;
      this.getPbxInfoTimeout = false;
      this.resetResult();
      const topFilter: Odata = {
        $Top: this.MAX_QUERY_NUM
      };
      this.getPbxUsers(topFilter);
      this.getPbxTrunks(topFilter);
      this.getPbxHuntgroups(topFilter);
      this.getPbxPhantoms(topFilter);
      this.setLicenceInUse();
      this.didNumber = this.site?.vccDidNumber? this.site.vccDidNumber : null;

      this.setCloudlinkStatus();

    }
  }

  getPbxUsers(odata?: Odata) {
    this.tunnelSvc.getPbxUsers(this.pbxLink._id, this.componentIdFromSystemInventory, odata).then(u => {
      const pbxUsers = Utils.getItemsFromCollection<PbxUser>(u);
      const nextUsers = Utils.getOdataNext(u);

      if (pbxUsers && pbxUsers.length > 0) {
        this.allPBXUsers = this.allPBXUsers.concat(pbxUsers);
      }
      if (typeof u.count === 'number') {
        this.result.users += u.count;
      }
      if (nextUsers && nextUsers.$Skip && this.continueCount) { //recursively call this method until there are no more pbxUsers to get
        this.getPbxUsers(nextUsers);
      } else if ( this.allPBXUsers.length > 0 ) { //do this only if there are not more pbxUsers to get
        const noEmailUsers = this.allPBXUsers.filter(u => {
          return u.email === '' || !u.email;
        });
        this.usersWithoutEmail = this.usersWithoutEmail.concat(noEmailUsers);
        this.usersWithDupEmail = this.getDupEmailUsers(this.allPBXUsers);
      }
     }, reason => {
      if (reason.statusCode === 504) {
        this.getPbxInfoTimeout = true;
      } else if (reason && reason.statusCode === 401) {
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        this.formDataSvc.redirectToDashboardOrLogout();
      }
      console.error('failed to get PBX users', reason);
     }).catch(error => console.error('failed to get PBX users', error));
  }

  getDupEmailUsers(users: PbxUser[]): PbxUser[] {
    const object = {};
    let result = [];
    let email = "";

    users.forEach(item => {
      email = item.email.toLowerCase();
      if (email && !object[email]) {
        object[email] = 0;
      }
      if(email) {
        object[email] += 1;
      }
    });

    for (const prop in object) {
      if (object[prop] >= 2) {
        const tempUsers = users.filter(u => {
          return u.email.toLowerCase() === prop;
        });
        result = result.concat(tempUsers);
      }
    }

    return result;
  }

  getPbxTrunks(odata?: Odata) {
    this.tunnelSvc.getPbxTrunks(this.pbxLink._id, this.componentIdFromSystemInventory, odata).then(t => {
      const pbxTrunks = Utils.getItemsFromCollection<PbxTrunk>(t);
      const nextTrunks = Utils.getOdataNext(t);
      if (typeof t.count === 'number') {
        this.result.trunks += t.count;
      }
      if (nextTrunks && nextTrunks.$Skip) {
        this.getPbxTrunks(nextTrunks);
      }
    }, reason => {
      if (reason.status === 504) {
        this.getPbxInfoTimeout = true;
      } else if (reason && reason.statusCode === 401) {
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        this.formDataSvc.redirectToDashboardOrLogout();
      }
      console.error('failed to get PBX trunks', reason);
    }).catch(error => console.error('failed to get PBX trunks', error));
  }

  getPbxHuntgroups(odata?: Odata) {
    this.tunnelSvc.getPbxHuntgroups(this.pbxLink._id, this.componentIdFromSystemInventory, odata).then(hg => {
      const pbxHuntGroups = Utils.getItemsFromCollection(hg);
      const nextHuntGroups = Utils.getOdataNext(hg);
      if (typeof hg.count === 'number') {
        this.result.huntGroups += hg.count;
      }
      if (nextHuntGroups && nextHuntGroups.$Skip) {
        this.getPbxHuntgroups(nextHuntGroups);
      }
    }, reason => {
      if (reason.status === 504) {
        this.getPbxInfoTimeout = true;
      } else if (reason && reason.statusCode === 401) {
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        this.formDataSvc.redirectToDashboardOrLogout();
      }
      console.error('failed to get PBX hunt groups', reason);
    }).catch(error => console.error('failed to get PBX hunt groups', error));
  }

  getPbxPhantoms(odata?: Odata) {
    if(!this.pbxTypeSupportsPhantoms()){
      return;
    }
    this.tunnelSvc.getPbxPhantoms(this.pbxLink._id, this.componentIdFromSystemInventory, odata).then(p => {
      const phantoms = Utils.getItemsFromCollection(p);
      const nextPhantoms = Utils.getOdataNext(p);
      if (typeof p.count === 'number') {
        this.result.phantoms += p.count;
      }
      if (nextPhantoms && nextPhantoms.$Skip) {
        this.getPbxPhantoms(nextPhantoms);
      }
    }, reason => {
      if (reason.status === 504) {
        this.getPbxInfoTimeout = true;
      } else if (reason && reason.statusCode === 401) {
        this.authSvc.redirectToLogin();
      } else if (reason && reason.statusCode === 403) {
        this.formDataSvc.redirectToDashboardOrLogout();
      }
      console.error('failed to get PBX phantoms', reason);
    }).catch(error => console.error('failed to get PBX phantoms', error));
  }

  setLicenceInUse() {
    if (this.pbxLink && this.pbxLink.license_in_use) {
      const numTrunks = parseInt(this.pbxLink.license_in_use, 10);
      this.result.actualNumberOfTrunks = isNaN(numTrunks) ? 0 : numTrunks;
    }
  }

  goBack() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview Connect' : 'Connect', 'Cancel');
    if(this.launchedFromSystemInventory){
      //No back button shows when launched from System Inventory.
      //But just in case this method is called, exit iframe
      this.formDataSvc.revertRoleAndNavigate();
    } else if (this.isEditMode()) {
      this.formDataSvc.redirectToDashboard(undefined, undefined, 'account');// redirect to accounts page when clicked if iframed
    } else if (this.platform && this.platform.capabilities &&  this.platform.capabilities.embedded_with_pbx) {
      if (!this.isOverview) {
        const backUrl = '/accounts/' + this.account.accountId + '/sites/' + this.site.siteId;
        this.router.navigateByUrl(backUrl);
      } else {
        const element = document.getElementsByTagName('app-site')[0];
        element.scrollIntoView();
      }
    } else {
      if (!this.isOverview) {
        let pbxId = null;
        const pbx = this.accountSvc.getPbxlink();
        if (pbx) {
          pbxId = pbx._id;
        }

        const backUrl = '/accounts/' + this.account.accountId + '/sites/' + this.site.siteId + '/pbx/' + pbxId;
        this.router.navigateByUrl(backUrl);
      } else {
        const element = document.getElementsByTagName('app-pbx')[0];
        element.scrollIntoView();
      }
    }
  }

  async onSubmit() {
    this.alertSvc.clearAlert();
    if (!this.isOverview) {
      window.scrollTo(0, 0);
    }

    this.sessionData = null;
    //onSubmit should not be called when launched from system inventory
    if(this.launchedFromSystemInventory){
      return Promise.resolve();
    }
    this.isSubmitting = true;
    if (this.formDataSvc.isConnectFieldEdited) {
      const syncScheduleItem = { eachMinutes: +this.syncMins };

      try {
        await this.tunnelSvc.createPbxSyncSchedule(this.account.accountId, this.site.siteId, syncScheduleItem);
        this.formDataSvc.removeFormData('connect');
        this.formDataSvc.isConnectFieldEdited = false;
        this.alertSvc.setAlert(toastMsgTypes.INFO, connectMessages.UPDATE_SYNC_SCHEDULE);
        this.goNext();
        this.isSubmitting = false;
      } catch (reason) {
        this.isSubmitting = false;
        if (reason && reason.statusCode === 401) {
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
          console.error('failed to createPbxSyncSchedule', JSON.stringify(reason));
          this.formDataSvc.redirectToDashboardOrLogout();
        } else if (reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('failed to update PBX sync schedule', reason.message);
          this.authSvc.redirectToLogin();
        } else if (reason && reason.body && reason.body.name) {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, 'createPbxSyncSchedule.' + reason.body.name);
        } else {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, connectMessages.ERR_SYNC_SCHEDULE);
        }
        console.error('failed to update PBX sync schedule', reason);
      }
    } else {
      this.goNext();
      this.isSubmitting = false;
    }
  }

  goNext() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', this.isOverview ? 'Overview Connect' : 'Connect', 'Next');
    if(this.launchedFromSystemInventory){
      //goNext should not be called when launched from System Inventory. 
      return;
    }
    else if (!this.isOverview) {
      let url = '/accounts/' + this.account.accountId + '/sites/' + this.site.siteId +  '/officelink';

        this.router.navigateByUrl(url);
    } else {
        const element = document.getElementsByTagName('app-office-connect')[0];
        element.scrollIntoView();
    }
  }

  goAdvanced() {
    if (!this.isOverview) {
      const url = '/accounts/' + this.account.accountId + '/sites/' + this.site.siteId + '/advanced';
      this.router.navigateByUrl(url);
    } else {
      const element = document.getElementsByTagName('app-advanced-form')[0];
      element.scrollIntoView();
    }
  }

  async updateAccountTags(accountId: string, tagParams: any) {
    try {
      const tags = await this.adminSvc.tryUpdateAccountTags(accountId, tagParams);
      this.accountTags = tags;
      this.accountSvc.setAccountTags(tags);
    } catch (error) {
      if (typeof error === 'string') {
        console.error('failed to update account tag', error);
      } else if (error && error.statusCode === 401) {
        console.error('failed to update account tag', JSON.stringify(error));
        this.authSvc.redirectToLogin();
      } else if (error && error.statusCode === 403) {
        console.error('failed to update account tag', JSON.stringify(error));
        this.formDataSvc.redirectToDashboardOrLogout();
      } else if (typeof error.text === 'function') {
        error.text().then(res => {
          console.error('failed to update account tag', res);
        }).catch(err => console.error('failed to update account tag', err));
      } else if (error instanceof Error) {
        console.error('failed to update account tag', error.message);
        if (error.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to update account tag', error);
      }
    }
  }

  closeNoEmailWarning() {
    if(this.launchedFromSystemInventory){
      return;
    }
    this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Connect' : 'Connect', 'Close no email warning');
    // Issue 517 in J-SDK. Updating a tag to boolean false actually sets tag to empty object
    // Temporary workaround is to use string 'false'
    const tagParams = { 'email-warning': 'false' };
    this.updateAccountTags(this.account.accountId, tagParams);
  }

  shouldShowEmailWarning(): boolean {
    if (((this.usersWithoutEmail && this.usersWithoutEmail.length > 0) ||
      (this.usersWithDupEmail && this.usersWithDupEmail.length > 0)) &&
      this.accountTags && this.accountTags['email-warning'] !== false && this.accountTags['email-warning'] !== 'false' ) {
        // the above line can be cleaned up to just the bool false when issue 517 is J-SDK is fixed
        return true;
    }
    return false;
  }

  shouldShowNoEmailAndDuplicateWarning(): boolean {
    if (((this.usersWithoutEmail && this.usersWithoutEmail.length > 0) &&
      (this.usersWithDupEmail && this.usersWithDupEmail.length > 0)) &&
      this.accountTags && this.accountTags['email-warning'] !== false && this.accountTags['email-warning'] !== 'false') {
      // the above line can be cleaned up to just the bool false when issue 517 is J-SDK is fixed
      return true;
    }
    return false;
  }

  shouldShowNoEmailWarning(): boolean {
    if (((this.usersWithoutEmail && this.usersWithoutEmail.length > 0) &&
      (this.usersWithDupEmail && this.usersWithDupEmail.length === 0)) &&
      this.accountTags && this.accountTags['email-warning'] !== false && this.accountTags['email-warning'] !== 'false') {
      // the above line can be cleaned up to just the bool false when issue 517 is J-SDK is fixed
      return true;
    }
    return false;
  }

  shouldShowDuplicateEmailWarning(): boolean {
    if (((this.usersWithoutEmail && this.usersWithoutEmail.length === 0) &&
      (this.usersWithDupEmail && this.usersWithDupEmail.length > 0)) &&
      this.accountTags && this.accountTags['email-warning'] !== false && this.accountTags['email-warning'] !== 'false') {
      // the above line can be cleaned up to just the bool false when issue 517 is J-SDK is fixed
      return true;
    }
    return false;
  }

  async openUsersWithoutEmailList() {
    this.analyticsSvc.postAMAEvent('EVENT_CLICK', this.isOverview ? 'Overview Connect' : 'Connect', 'View users without email');
    const modalRef = this.modalSvc.open(UsersListModalComponent);
    const contentComponentInstance = modalRef.componentInstance;
    contentComponentInstance.type = 'NOEMAIL';
    contentComponentInstance.users = this.usersWithoutEmail;
    if(await this.licensesService.shouldBeDisplayedMitelOne()){
      contentComponentInstance.applicationName = applicationNames.MONE;
    }
    else
      contentComponentInstance.applicationName = this.applicationName;
  }

  openUsersWithDupEmailList() {
    const modalRef = this.modalSvc.open(UsersListModalComponent);
    const contentComponentInstance = modalRef.componentInstance;
    contentComponentInstance.type = 'DUPEMAIL';
    contentComponentInstance.users = this.usersWithDupEmail;
  }

  isEditMode() {
    if (this.accountTags && this.accountTags['on-board-progress']) {
      const progressConnectIdx = this.progressSvc.getProgressIdx('connect');
      const lastProgressIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress'].name);

      if (progressConnectIdx < lastProgressIdx) {
        return true;
      } else if (progressConnectIdx === lastProgressIdx && this.accountTags['on-board-progress'].succeeded) {
        return true;
      }
    }

    return false;
  }

  keyUp(event: any) {
    const pattern = /[0-9]/;
    const inputChar = event.key;

    // Firefox fix blocking special keys, #1345
    const editSpecialKeys = '$Backspace$Delete$';
    const specialKeys = '$Home$Tab$Left$ArrowLeft$Right$ArrowRight$End$';
    const testKey = '$' + event.key + '$';
    const isEditSpecialKey = editSpecialKeys.indexOf(testKey) >= 0
    const isSpecialKey = specialKeys.indexOf(testKey) >= 0;

    if (pattern.test(inputChar) || isEditSpecialKey) {
      this.formDataSvc.isConnectFieldEdited = true;
    } else if (!isSpecialKey) {
      event.preventDefault();
      this.formDataSvc.isConnectFieldEdited = false;
    }
    this.formDataSvc.setCurrentFormDirty('connect', this.formDataSvc.isConnectFieldEdited);
  }

  ngOnDestroy() {
    if (this.formDataSvc.setSessionData && this.formDataSvc.isConnectFieldEdited) {
      sessionStorage.setItem('connect-state-form', JSON.stringify(this.syncMins));
    }
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.accountTagsSubscription) {
      this.accountTagsSubscription.unsubscribe();
    }
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
    if (this.pbxSubscription) {
      this.pbxSubscription.unsubscribe();
    }
    if (this.platformSubscription) {
      this.platformSubscription.unsubscribe();
    }
    if(this.cloudStatusSubscription) {
      this.cloudStatusSubscription.unsubscribe();
    }
  }

}
