import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { Config, Utils, Odata, TunnelService as CloudTunnel } from '@mitel/cloudlink-sdk';
import {
  PostPbxlinkRequest, PutPbxlinkRequest, PutMaintenanceRequest, PutCloudlinkRequest,
  PutNetworkRequest, PutSettingsUpdateRequest,
  PostFreeswitchRequest,
  PutLicenseRequest, TrunkGroupLicense,
  GetLog, FreeswitchTrace, PutFreeswitchTraceRequest,
  NetworkSettings, LogsStatus, PbxSyncStatus
} from '@mitel/cloudlink-sdk/tunnel';
import { environment } from '../../environments/environment';
import { pbxSelectOptions, pbxTypes } from '../shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class TunnelService {
    private readonly tunnel: CloudTunnel;

    constructor(private http: HttpClient, private authSvc: AuthenticationService) {
        Config.cloud = environment.cloud;
        Config.requestTimeoutMilliseconds = 30000; // timeout for 30 seconds
        this.tunnel = new CloudTunnel();
    }

    public getPbxlinks(accountId: string, siteId: string, options?: any): Promise<any> {
        return this.tunnel.getPbxlinks({ accountId, siteId, options });
    }

    public getPbxlink(accountId: string, siteId: string, pbxlinkId: string, options?: any) {
        return this.tunnel.getPbxlink({ accountId, siteId, pbxlinkId, options });
    }

    public createPbxlink(accountId: string, siteId: string, body: PostPbxlinkRequest, options?: any) {
        return this.tunnel.createPbxlink({ accountId, siteId, body, options });
    }

    public updatePbxlink(accountId: string, siteId: string, pbxlinkId: string, body: PutPbxlinkRequest, options?: any): Promise<any> {
        return this.tunnel.updatePbxlink({ accountId, siteId, pbxlinkId, body, options });
    }

    public deletePbxlink(accountId: string, siteId: string, pbxlinkId: string, options?: any) {
        return this.tunnel.deletePbxlink({ accountId, siteId, pbxlinkId, options });
    }

    // tslint:disable-next-line:max-line-length
    public updatePbxlinkLicense(accountId: string, siteId: string, pbxlinkId: string, trunkGroup: string, body: PutLicenseRequest): Promise<TrunkGroupLicense> {
        return this.tunnel.updatePbxlinkLicense({ accountId, siteId, pbxlinkId, trunkGroup, body });
    }


    public updateCloudTunnel(tunnelId: string, username: string, password: string, body: PutCloudlinkRequest) {
        const encodeCreds = btoa(username + ':' + password);
        const headers = { 'authorization': 'Basic ' + encodeCreds };
        const options = {
            headers: headers
        };
        return this.tunnel.updateCloudTunnel({ tunnelId, body, options });
    }

    public async getMBG(accountId: string, siteId: string) {
        const requestParams = await this.getMBGRequestParams(accountId, siteId);

        return firstValueFrom(this.http.get(requestParams.path, requestParams.httpOptions));
    }

    public async updateMBGRequest(accountId: string, siteId: string, params: any) {
        const requestParams = await this.getMBGRequestParams(accountId, siteId, params);

        return firstValueFrom(this.http.put(requestParams.path, requestParams.body, requestParams.httpOptions));
    }

    public async deleteMBGRequest(accountId: string, siteId: string) {
        const requestParams = await this.getMBGRequestParams(accountId, siteId);

        return firstValueFrom(this.http.delete(requestParams.path, requestParams.httpOptions));
    }

    public async createMBGRequest(accountId: string, siteId: string, params: any) {
        const requestParams = await this.getMBGRequestParams(accountId, siteId, params);

        return firstValueFrom(this.http.post(requestParams.path, requestParams.body, requestParams.httpOptions));
    }

    public getPbxSyncStatus(accountId: string, siteId: string): Promise<any> {
        return this.tunnel.getPbxSyncStatus({ accountId, siteId }).then(response=>{
          console.log('Class: TunnelService, Function: response, response: '
          , response);
          return new Promise((resolve, reject) => {
            const status = Utils.getItemsFromCollection<PbxSyncStatus>(response);
            console.log('Class: TunnelService, Function: status, status: '
            , status);
            if (status.length === 0)
                reject({statusCode: 406});
            resolve(response)
          })
        });
    }

    public startPbxSync(accountId: string, siteId: string) {
        return this.tunnel.startPbxSync({ accountId, siteId });

    }

    public getPbxSyncSchedule(accountId: string, siteId: string) {
        return this.tunnel.getPbxSyncSchedule({ accountId, siteId });
    }

    public createPbxSyncSchedule(accountId: string, siteId: string, body: any) {
        return this.tunnel.createPbxSyncSchedule({ accountId, siteId, body });
    }

    public updateUpgradeSchedule(accountId: string,
        siteId: string, body: PutSettingsUpdateRequest): Promise<any> {
        return this.tunnel.updateUpgradeSchedule({ accountId, siteId, body }).then(response => {
          console.log('Class: TunnelService, Function: , : ', response);
        });
    }

    public getUpgradeSchedule(accountId: string, siteId: string): Promise<any> {
        return this.tunnel.getUpgradeSchedule({ accountId, siteId });
    }

    public sendUpgradeRequest(accountId: string, siteId: string): Promise<any> {
        const body: PutMaintenanceRequest = {
            update_now: true
        }
        return this.tunnel.sendMaintenanceRequest({ accountId, siteId, body });
    }

    public sendRebootRequest(accountId: string, siteId: string): Promise<any> {
        const body: PutMaintenanceRequest = {
            reboot: true
        }
        return this.tunnel.sendMaintenanceRequest({ accountId, siteId, body });
    }

    public sendRestartRequest(accountId: string, siteId: string): Promise<any> {
      const body: any = {
        application_restart: true
      }
      return this.tunnel.sendMaintenanceRequest({ accountId, siteId, body });
  }

    public sendFactoryResetRequest(accountId: string, siteId: string): Promise<any> {
        const body: PutMaintenanceRequest = {
            factory_reset: true
        }
        return this.tunnel.sendMaintenanceRequest({ accountId, siteId, body });
    }

    public getEcho(accountId: string, siteId: string, options?: any): Promise<any> {
        return this.tunnel.getEcho({ accountId, siteId, options });
    }

    public getCloudlinkStatus(accountId: string, siteId: string, options?: any): Promise<any> {
        return this.tunnel.getCloudlinkStatus({ accountId, siteId, options });
    }

    public getPbxUsers(accountId: string, siteId: string, pbxId: string, odata?: Odata, options?: any): Promise<any> {
        if (odata) {
            return this.tunnel.getPbxUsers({ accountId, siteId, pbxId, $Top: odata.$Top, $Skip: odata.$Skip, options });
        } else {
            return this.tunnel.getPbxUsers({ accountId, siteId, pbxId, options });
        }
    }

    public async getPbxContacts(accountId: string, siteId: string, pbxId: string, odata?: Odata): Promise<any> {
        let baseUrl = `${environment.tunnelUrl}/premise/accounts/${accountId}/sites/${siteId}/pbxs/${pbxId}/contacts`;
        const token = (await this.authSvc.getToken()).access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                'content-type': 'application/json',
                'authorization': `Bearer ${token}`
            })
        };

        if (odata?.$Top !== undefined) {
            baseUrl += `?$top=${odata.$Top}`;
        }
        if (odata?.$Skip !== undefined) {
            if (odata?.$Top !== undefined) {
                baseUrl += '&';
            } else {
                baseUrl += '?'
            }
            baseUrl += `$skip=${odata.$Skip}`;
        }

        return await firstValueFrom(this.http.get(baseUrl, httpOptions));
    }

    public getPbxTrunks(accountId: string, siteId: string, pbxId: string, odata?: Odata, options?: any): Promise<any> {
        if (odata) {
            return this.tunnel.getPbxTrunks({ accountId, siteId, pbxId, $Top: odata.$Top, $Skip: odata.$Skip, options });
        } else {
            return this.tunnel.getPbxTrunks({ accountId, siteId, pbxId, options });
        }
    }

    public getPbxHuntgroups(accountId: string, siteId: string, pbxId: string, odata?: Odata, options?: any): Promise<any> {
        if (odata) {
            return this.tunnel.getPbxHuntgroups({ accountId, siteId, pbxId, $Top: odata.$Top, $Skip: odata.$Skip, options });
        } else {
            return this.tunnel.getPbxHuntgroups({ accountId, siteId, pbxId, options });
        }
    }

    public getPbxPhantoms(accountId: string, siteId: string, pbxId: string, odata?: Odata, options?: any): Promise<any> {
        if (odata) {
            return this.tunnel.getPbxPhantoms({ accountId, siteId, pbxId, $Top: odata.$Top, $Skip: odata.$Skip, options });
        } else {
            return this.tunnel.getPbxPhantoms({ accountId, siteId, pbxId, options });
        }
    }

    public getNetworkSettings(accountId: string, siteId: string): Promise<any> {
        return this.tunnel.getNetworkSettings({ accountId, siteId });
    }

    public updateNetworkSettings(accountId: string, siteId: string, body: PutNetworkRequest): Promise<any> {
        return this.tunnel.updateNetworkSettings({ accountId, siteId, body });
    }

    public getSystemVersion(accountId: string, siteId: string, options?: any) {
        return this.tunnel.getSystemVersion({ accountId, siteId, options });
    }

    public createFreeswitchConfig(accountId: string, siteId: string, body: PostFreeswitchRequest) {
        return this.tunnel.createFreeswitchConfig({ accountId, siteId, body });
    }

    public getFreeswitchConfig(accountId: string, siteId: string) {
        return this.tunnel.getFreeswitchConfig({ accountId, siteId });
    }

    public sendFreeswitchTraceRequest(accountId: string, siteId: string,
        body: PutFreeswitchTraceRequest): Promise<FreeswitchTrace> {
        return this.tunnel.sendFreeswitchTraceRequest({ accountId, siteId, body });
    }

    public createLogRequest(accountId: string, siteId: string): Promise<Response> {
        return this.tunnel.createLogRequest({ accountId, siteId });
    }

    public getLogCreationStatus(accountId: string, siteId: string): Promise<LogsStatus> {
        return this.tunnel.getLogCreationStatus({ accountId, siteId });
    }

    public getLogRequest(accountId: string, siteId: string, expires?: number): Promise<GetLog> {
        return this.tunnel.getLogRequest({ accountId, siteId, expires });
    }

    public getLogUploadStatus(accountId: string, siteId: string, logId: string): Promise<LogsStatus> {
        return this.tunnel.getLogUploadStatus({ accountId, siteId, logId });
    }

    public getInitialNetworkSettings(tunnelId: string, username: string, password: string): Promise<NetworkSettings> {
        const encodeCreds = btoa(username + ':' + password);
        const headers = { 'authorization': 'Basic ' + encodeCreds };
        const options = {
            headers: headers
        };
        return this.tunnel.getInitialNetworkSettings({ tunnelId, options });
    }

    public getPlatform(accountId: string, siteId: string, options?: any): Promise<any> {
        return this.tunnel.getPlatform({accountId, siteId, options});
    }

    public getInitialPlatform(tunnelId: string, username: string, password: string): Promise<any> {
       const encodeCreds = btoa(username + ':' + password);
       const headers = { 'authorization': 'Basic ' + encodeCreds };
       const options = {
           headers: headers
       };
       return this.tunnel.getInitialPlatform({ tunnelId, options });
    }

  public async getSupportedPbxTypes(platform?: any ) {
      if (platform && platform.virtualized) {
        return pbxSelectOptions.filter(v => v.value !== pbxTypes.MIVO250);
      } else if( platform && platform.platform ==="smbc" ) {
        return pbxSelectOptions.filter(v => v.value === pbxTypes.MIVO400);
      } else {
        return pbxSelectOptions;
      }
  }

    public async getMBGRequestParams(accountId: string, siteId: string, params?: any) {
        const path = `${environment.tunnelUrl}/premise/accounts/${accountId}/sites/${siteId}/settings/mbg`;

        let body;
        if (params) {
            body = {
                ipAddressHostname: params.mbg_ip_address,
                adminPassword: params.mbg_password
            }
        }

        const token = await this.authSvc.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                'content-type': 'application/json',
                'authorization': `Bearer ${token.access_token}`
            })
        };

        return {
            path,
            body,
            httpOptions
        }
    }

    public async installX509Certificates(accountId: string, siteId: string, body: any, options?: any) {
      const requestParams = await this.getCertificateParams(accountId, siteId, body);

      return firstValueFrom(this.http.put(requestParams.path, requestParams.body, requestParams.httpOptions));
  }

  public async getCertificateParam(accountId: string, siteId: string) {
    const path = `${environment.tunnelUrl}/premise/accounts/${accountId}/sites/${siteId}/system/x509cert/caroot`;

    let body;
    const token = await this.authSvc.getToken();
    const httpOptions = {
        headers: new HttpHeaders({
            'content-type': 'application/json',
            'authorization': `Bearer ${token.access_token}`
        })
    };

    return {
        path,
        body,
        httpOptions
    }
}

  public async getInstallX509Certificates(accountId: string, siteId: string) {
    const requestParams = await this.getCertificateParam(accountId, siteId);

    return firstValueFrom(this.http.get(requestParams.path, requestParams.httpOptions));
}

    public async getCertificateParams(accountId: string, siteId: string,  params: any ) {
      const path = `${environment.tunnelUrl}/premise/accounts/${accountId}/sites/${siteId}/system/x509cert/caroot`;

      let body = {
          cert: params
      }
      const token = await this.authSvc.getToken();
      const httpOptions = {
          headers: new HttpHeaders({
              'content-type': 'application/json',
              'authorization': `Bearer ${token.access_token}`
          })
      };

      return {
          path,
          body,
          httpOptions
      }
  }
  
    public async getMiCollabConfiguration(accountId: string, siteId: string) {
        const requestParams = await this.getMiCollabRequestParams(accountId, siteId);

        return firstValueFrom(this.http.get(requestParams.path, requestParams.httpOptions));
    }

    public async createMiCollabRequest(accountId: string, siteId: string, params: any) {
        const requestParams = await this.getMiCollabRequestParams(accountId, siteId, params);

        return firstValueFrom(this.http.post(requestParams.path, requestParams.body, requestParams.httpOptions));
    }

    public async updateMiCollabRequest(accountId: string, siteId: string, params: any) {
        const requestParams = await this.getMiCollabRequestParams(accountId, siteId, params);

        return firstValueFrom(this.http.put(requestParams.path, requestParams.body, requestParams.httpOptions));
    }

    public async deleteMiCollabRequest(accountId: string, siteId: string) {
        const requestParams = await this.getMiCollabRequestParams(accountId, siteId);

        return firstValueFrom(this.http.delete(requestParams.path, requestParams.httpOptions));
    }

    public async getMiCollabRequestParams(accountId: string, siteId: string, params?: any) {
        const path = `${environment.tunnelUrl}/premise/accounts/${accountId}/sites/${siteId}/settings/micollab`;

        let body;
        if (params) {
            body = {
                ipAddressHostname: params.miCollab_ip_address,
                adminPassword: params.miCollab_password
            }
        }

        const token = await this.authSvc.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                'content-type': 'application/json',
                'authorization': `Bearer ${token.access_token}`
            })
        };

        return {
            path,
            body,
            httpOptions
        }
    }
    
}
