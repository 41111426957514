import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from '../../services/admin.service';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import { Account, User } from '@mitel/cloudlink-sdk/admin';
import { FormDataService } from '../../services/form-data.service';
import { environment } from '../../../environments/environment';
import { AppService } from '../../../app/services/app.service';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { ParentCommsService } from '../../services/parent-comms.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
  public user: User;
  cloud: string = environment.cloud || '';
  company: Account;
  statusMsg;
  subscriptions: Subscription;
  isIframedSupport: boolean;

  constructor(private router: Router,
    private appSvc: AppService,
    private clHeader: ClHeaderComponent,
    private adminSvc: AdminService,
    private authSvc: AuthenticationService,
    private formDataSvc: FormDataService,
    private userSvc: UserService,
    private parentCommsService: ParentCommsService) {
  }

 async ngOnInit() {
    this.subscriptions = this.userSvc.userChanged.subscribe(
      (user: User) => {
        this.user = user;
        // If coming from miaccess, if you assume role into an account, then close tab without logout then decide to go through 
        // miaccess portal, original token will stay because it only goes with reverting role and log out. So on log in, if 
        // original token is in localstorage, remove it to prevent lockout
        if (this.user.createdBy === 'Mitel Connect' && 
            this.user.role === 'PARTNER_ADMIN' &&
            this.appSvc.isAssumedRole()) {
          localStorage.removeItem('@mitel/token.original_token');
        }
    });
    this.subscriptions.add(this.appSvc.claimsChanged.subscribe(claims => this.handleUserClaims(claims)));
    this.isIframedSupport = this.parentCommsService.isIframedSupportPage;
  }

  async handleUserClaims(claims) {
    this.adminSvc.getMyUser().then(user => {
      this.user = user;
      this.userSvc.setUser(user);
    }, reason => {
      if (reason && reason.statusCode === 401) {
        console.error('failed to get user', JSON.stringify(reason.body));
      } else if (typeof reason.text === 'function') {
        reason.text().then(res => console.error('failed to get my user', res));
      }  else if (reason instanceof Error) {
        console.error('failed to get logged in user', reason.message);
      } else {
        console.error('failed to get my user', reason);
      }
    }).catch(error => console.error('There has been an error', error))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
