import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
              private analyticsSvc: AnalyticsService,
              private translateSvc: TranslateService,) { }

  ngOnInit() {
  }

  openLegal() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', 'Links', 'Legal');
    window.open(this.translateSvc.instant('footer.legal'));
  }

  goToFeedback() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', 'Links', 'Feedback');
    window.open(this.translateSvc.instant('footer.feedback'));
  }

  goToSupport() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', 'Links', 'Support');
    window.open(this.translateSvc.instant('footer.help'));
  }

  goToStatus() {
    this.analyticsSvc.postAMAEvent('NAV_CLICK', 'Links', 'Status');
    window.open(this.translateSvc.instant('footer.status'));
  }

}
