import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { FormAlertType, MessageType } from './../../shared/constants';

@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss']
})
export class MessageFormComponent implements OnInit {
  message: string;
  messageType: string;

  constructor(private route: ActivatedRoute,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    let type = this.route.snapshot.paramMap.get('type');
    if(type === MessageType.NO_SUPPORT || type === MessageType.NO_SUPPORT_UPDATED_STATUS){
      this.messageType = FormAlertType.ERROR;
      this.message = this.translateService.instant('constants.messages.NO_SUPPORT_AVAILABLE');
    }
  }

}
