import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { stepStatus } from '../shared/constants';


export class ProgressItem {
    public step: number;
    public name: string;
    public status: string;
    public next: string;
}

@Injectable()
export class ProgressService {

    private currentStep =  0;

    private progressMap = [
        {'step': 0, 'name': 'new', 'status': stepStatus.FINISH_BUILD_CUSTOMER, 'next': 'account'},
        {'step': 1, 'name': 'account', 'status': stepStatus.CONFIG_GATEWAY, 'next': 'site'},
        {'step': 2, 'name': 'site', 'status': stepStatus.ADD_PBX, 'next': 'pbx'},
        {'step': 3, 'name': 'pbx', 'status': stepStatus.CONNECT_PBX, 'next': 'connect'},
        // tslint:disable-next-line:max-line-length
        {'step': 4, 'name': 'connect', 'status': stepStatus.CONFIG_OFFICELINK, 'next': 'officelink' },  // skip to 5, if Mitel OfficeConnect not selected
        {'step': 5, 'name': 'officelink', 'status': stepStatus.OVERVIEW, 'next': 'overview'},
        {'step': 5, 'name': 'officeconnect', 'status': stepStatus.OVERVIEW, 'next': 'overview'}, // Backwards compatibility of OfficeConnect
        {'step': 6, 'name': 'advanced', 'status': stepStatus.OVERVIEW, 'next': 'overview'},
        {'step': 7, 'name': 'overview', 'status': stepStatus.OVERVIEW, 'next': 'overview'}
    ];

    constructor() {}

    public getProgressMap(): any {
        return this.progressMap;
    };

    public getProgressItem(name: string): ProgressItem {
        const progressObj = _.find(this.progressMap, {'name': name});
        if (progressObj) {
            return progressObj;
        } else {
            return this.progressMap[0];
        }
    }

    public getPrevProgressItem(name: string): ProgressItem {
        const currentProgressObj = _.find(this.progressMap, {'name': name});

        if (currentProgressObj) {
            const prevProgressObj = _.find(this.progressMap, {'step': currentProgressObj.step - 1});

            if (prevProgressObj) {
                return prevProgressObj;
            } else {
                return this.progressMap[0];
            }
        }
    }

    public getNextProgressItem(name: string): ProgressItem {
        const currentProgressObj = _.find(this.progressMap, {'name': name});

        if (currentProgressObj) {
            const nextProgressObj = _.find(this.progressMap, {'step': currentProgressObj.step + 1});

            if (nextProgressObj) {
                return nextProgressObj;
            } else {
                return this.progressMap[this.progressMap.length - 1];
            }
        }
    }

    public getProgressIdx(name: string): number {
        const idx = _.findIndex(this.progressMap, {'name': name});
        return idx;
    }

    public getDefaultProgressItem(): ProgressItem {
        return this.progressMap[0];
    }

    public getNextUrl(name: string, accountId?: string, siteId?: string, pbxId?: string): string {

        const progressObj = this.getProgressItem(name);
        let url;
        switch (progressObj.next) {
            case ('account'):
                url = '/accounts/' + accountId;
                break;
            case ('site'):
                url = '/accounts/' + accountId + '/sites/';
                if (siteId) {
                    url += siteId;
                }else {
                    url += 'new';
                }
                break;
            case('pbx'):
                if (pbxId) {
                    url = '/accounts/' + accountId + '/sites/' + siteId + '/pbx/' + pbxId;
                } else {
                    url = '/accounts/' + accountId + '/sites/' + siteId + '/pbx/new';
                }
                break;
            case('connect'):
                url = '/accounts/' + accountId + '/sites/' + siteId + '/connect';
                break;
            case('officeconnect'):
                url = '/accounts/' + accountId + '/sites/' + siteId + '/officelink';
                break;
            case('officelink'):
                url = '/accounts/' + accountId + '/sites/' + siteId + '/officelink';
                break;
            case('advanced'):
                url = '/accounts/' + accountId + '/sites/' + siteId + '/advanced';
                break;
            case('overview'):
                url = '/accounts/' + accountId + '/overview';
                break;
            default:
                url = '/accounts/new';
                break;
        }
        return url;
    }
}
