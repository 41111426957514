import { Injectable } from '@angular/core';
import { OrdersService } from './orders.service';
import { mitelOneLicenses } from '../shared/constants';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class LicensesService {

  constructor(
    private accountSvc: AccountService,
    private ordersService: OrdersService
  ) { }

  async shouldBeDisplayedMitelOne() : Promise<boolean> {
    let tags = this.accountSvc.getAccountTags();

    if(tags && tags.products && tags.products.length > 0 && tags.products.includes("MitelOne")) {
      if(tags.products.includes("MiVoice Office 400")) {
        return true;
      }
      else {
        return false;
      }
    }

    return false;
  }


  async getAvailableLicensesDetails() {
    return this.ordersService.getAvailableLicenses().then( availableLicenses => {
      let licenseInfo = {};
        for( let license in availableLicenses) {
          if (mitelOneLicenses.includes(license)) {
            licenseInfo[license] = availableLicenses[license];
          }
        }
        return licenseInfo;
      })
      .catch(error => {
        console.log("There was a problem fetching license information", error);
        return {};
      });
  }



}
