import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-unexpected-modal',
  templateUrl: './unexpected-modal.component.html',
  styleUrls: ['./unexpected-modal.component.css']
})
export class UnexpectedModalComponent implements OnInit {

  @Output() advanced = new EventEmitter();

  constructor(private activeModal: NgbActiveModal, private translateSvc: TranslateService) { }

  ngOnInit() {}

  onCancel() {
    this.activeModal.close();
  }

  onAdvanced() {
    this.advanced.emit()
  }
}
