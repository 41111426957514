import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ParentCommsService } from '../services/parent-comms.service';
import { UserClaims } from '@mitel/cloudlink-sdk';
  
  
@Injectable()
export class iFrameGuard {
    claims: UserClaims;
    
    constructor(private parentCommsService: ParentCommsService) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        /* 
        Should not show the Partner Dashboard if inside an iframe.
        When being iframed, we are coming from the Accounts App. 
        A Partner_Admin in the Accounts app has already assumed role to Account_Admin to see the Gateway Portal in an iframe
        */
        console.log("iFrame Guard: ", !this.parentCommsService.isInsideIframe());
        return !this.parentCommsService.isInsideIframe();
    }
}