// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
	text-align: center;
	margin-top: 10px;
}

.title {
	font-family: MuseoSans-500;
	font-size: 24px;
	color: #15325F;
	letter-spacing: 0.01px;
	margin-bottom: 10px;
}

.description {
	font-family: MuseoSans-300;
	font-size: 12px;
	color: #1C1D1D;
	letter-spacing: 0.01px;
	line-height: 1.25;
}

.description:last-of-type {
	margin-bottom: 36px;
}

.description span {
	font-family: MuseoSans-500;
}

button {
	width: 200px;
	margin-top: 4px;
	margin-bottom: 36px;
}

button:last-of-type {
	margin-top: -16px;
}

a {
	font-family: MuseoSans-300;
	font-size: 12px;
	color: #00A1E0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pbx-connection/pbx-connection.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,sBAAsB;CACtB,mBAAmB;AACpB;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,sBAAsB;CACtB,iBAAiB;AAClB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,YAAY;CACZ,eAAe;CACf,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,cAAc;AACf","sourcesContent":[".content {\n\ttext-align: center;\n\tmargin-top: 10px;\n}\n\n.title {\n\tfont-family: MuseoSans-500;\n\tfont-size: 24px;\n\tcolor: #15325F;\n\tletter-spacing: 0.01px;\n\tmargin-bottom: 10px;\n}\n\n.description {\n\tfont-family: MuseoSans-300;\n\tfont-size: 12px;\n\tcolor: #1C1D1D;\n\tletter-spacing: 0.01px;\n\tline-height: 1.25;\n}\n\n.description:last-of-type {\n\tmargin-bottom: 36px;\n}\n\n.description span {\n\tfont-family: MuseoSans-500;\n}\n\nbutton {\n\twidth: 200px;\n\tmargin-top: 4px;\n\tmargin-bottom: 36px;\n}\n\nbutton:last-of-type {\n\tmargin-top: -16px;\n}\n\na {\n\tfont-family: MuseoSans-300;\n\tfont-size: 12px;\n\tcolor: #00A1E0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
