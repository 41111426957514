import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionUpdateService {

  constructor() { }

  isAllVersionsUpdated(versionRes: any, platform) {
      for(var key in versionRes) {
        if(versionRes[key] && versionRes[key]['name'] && versionRes[key].name !== '-'){
          if(versionRes[key].local_version !== '' &&
            versionRes[key].local_version !== '(unknown)' &&
            versionRes[key].local_version !== '-' &&
            versionRes[key].local_version === versionRes[key].cloud_version){
              continue;
          }
          else{
            return false;
          }
        }
      }
      if (platform && platform.capabilities && platform.capabilities.update_host) {
        if(versionRes.patch_level !== '' &&
        versionRes.patch_level !== '(unknown)' &&
        versionRes.patch_level !== '-' &&
        versionRes.patch_level === versionRes.cloud_patch_level){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        return true;
      }
  }
}
