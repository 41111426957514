import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User, Partner, Account } from '@mitel/cloudlink-sdk/admin';

@Injectable()
export class UserService {
	userChanged = new Subject<User>();
  private user: User;
  private partner: Partner;
  partnerChanged = new Subject<Partner>();
  private partnerAccount: Account;
  partnerAccountChanged = new Subject<Account>();

  constructor() {}

  setUser(user: User) {
    this.user = user;
    this.userChanged.next(this.user);
  }

  getUser() {
    return this.user;
  }

  setPartner(partner: Partner) {
    this.partner = partner;
    this.partnerChanged.next(this.partner);
  }

  getPartner() {
    return this.partner;
  }
  setPartnerAccount(account: Account) {
    this.partnerAccount = account;
    this.partnerAccountChanged.next(this.partnerAccount);
  }

  getPartnerAccount() {
    return this.partnerAccount;
  }
}
