import { supportedCountries, excludedCountries} from '../shared/countries';
import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import * as iso from 'iso-3166-2';


export interface Country {
  name: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private translateService: TranslateService
  ) {}


  getSupportedCountries(): Country[] {
    let countries: Country[] = [];
    Object.keys(iso.data).forEach((code) => {
      if (excludedCountries.find(country => country.code === code)) {
        return;
      }
      const countryName = this.translateService.instant('countries.' + code);
      countries.push({
        name: countryName,
        code
      })
    });

    return countries.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  getLabelByCountry(country?: Country) {
    if (!country) {
      return 'cityOrTown'
    }
    let currentCountry = supportedCountries
      .filter(item => item.country.toLowerCase() === country.name.toLowerCase() || item.code.toLowerCase() === country.code.toLowerCase())
      .shift();
    if (currentCountry) {
      return currentCountry.cityLabel
    } else {
      return 'cityOrTown'
    }
  }

  getStatesOrProvincesByCountry(country: Country) {
    if (!country) {
      return {states: null};
    }
    let currentCountry = supportedCountries
      .filter(item => item.code.toUpperCase() === country.code.toUpperCase() || item.country.toLowerCase() === country.name.toLowerCase())
      .shift();
    let result: string[];
    if (currentCountry && currentCountry.states) {
      result = [];
      currentCountry.states.forEach( state => {
        result.push(state.name);
      });
    }


    let isRequired = currentCountry ? currentCountry.required : false;
    let postalCode = currentCountry ? currentCountry.postalCode : true;
    let postalCodeRequired = currentCountry ? currentCountry.postalCodeRequired : false;
    return { states: result ? result.sort() : null, isRequired, postalCode, postalCodeRequired };
  }

  getStatesOrProvincesAbbrByCountry(country: Country) {
    let result: object = {};
    if (!country) {
      return {};
    }

    let countryObject = supportedCountries
      .filter(item => item.code.toUpperCase() === country.code.toUpperCase() || item.country.toLowerCase() === country.name.toLowerCase())
      .shift();
    if (countryObject && countryObject.states && countryObject.states.length > 0) {
      countryObject.states.forEach((state) => {
        result[state.abbreviation ? state.abbreviation : state.name] = state.name
      })
    } else if (countryObject && countryObject.states && countryObject.states.length === 0) {
      return {provinceInput: true}
    }
    return result;
  }

  getCountryAbbr(country: string): string {
    let browserLang = this.translateService.getBrowserLang();
		if(browserLang == 'en'){
			const culturalLang = this.translateService.getBrowserCultureLang();
			browserLang = culturalLang.match(/en-GB/)? culturalLang : 'en';
		  }
    let countryFromModel = supportedCountries
      .find(item =>
        (browserLang !== this.translateService.currentLang) ?
          item.country.toLowerCase() === country.toLowerCase() :
          this.translateService.instant('countries.' + item.code).toLowerCase() === country.toLowerCase());
    return countryFromModel ? countryFromModel.code : undefined;
  }

  getCountryFromAbbr(abbr: string): Country {
    return this.getSupportedCountries().find(item => item.code.toUpperCase() === abbr.toUpperCase());
  }

  getCurrentState(stateFromResponse: string) {
    let currentCountry = supportedCountries
      .find((record) => record.states &&
        record.states
          .findIndex(state =>
            (state.name && state.name.toLowerCase() === stateFromResponse.toLowerCase()) ||
            (state.abbreviation && state.abbreviation.toLowerCase() === stateFromResponse.toLowerCase())
          ) >= 0);
    if (!currentCountry) {
      return null;
    }
    let currentState = currentCountry.states.find(state =>
      state.name.toLowerCase() === stateFromResponse.toLowerCase() ||
      state.abbreviation.toLowerCase() === stateFromResponse.toLowerCase());
    return currentState ? currentState.name : null;
  }

  hasPostalCode(chosenCountryCode: string): boolean {
    const countryChosen  = supportedCountries.find(country => country.code === chosenCountryCode);
    return (countryChosen && countryChosen.postalCode) ? true : false;
  }
}
