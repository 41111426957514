import { Routes, RouterModule, mapToCanActivate, mapToCanDeactivate } from '@angular/router';

import { AuthGuard } from './services/auth-guard.service';
import { PolicyGuard } from './services/policy-guard.service';
import { HomeComponent } from './pages/home/home.component';
import { SiteComponent } from './components/site/site.component';
import { PbxComponent } from './components/pbx/pbx.component';
import { AdvancedFormComponent } from './components/advanced-form/advanced-form.component';
import { ConnectStateComponent } from './components/connect-state/connect-state.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { OverviewComponent } from './components/overview/overview.component';
import { GatewayConnectionComponent } from './components/gateway-connection/gateway-connection.component';
import { PbxConnectionComponent } from './components/pbx-connection/pbx-connection.component';
import { MessageFormComponent } from './components/message-form/message-form.component';
import { DeactivateAdvancedGuardService } from './services/deactivate-advanced-guard.service';
import { OfficeConnectComponent } from './components/office-connect/office-connect.component';

const appRoutes: Routes = [
    { path: '', canActivate: mapToCanActivate([AuthGuard]), children: [
        { path: '', canActivate: mapToCanActivate([PolicyGuard]), component: HomeComponent, children: [
            { path: 'accounts/:id', component: NavbarComponent, children: [
                { path: 'sites/new', component: SiteComponent },
                { path: 'sites/:id', component: SiteComponent },
                { path: 'sites/:id/pbx/new', component: PbxComponent },
                { path: 'sites/:id/pbx/:id', component: PbxComponent },
                { path: 'sites/:id/connect', component: ConnectStateComponent},
                { path: 'sites/:id/officelink', component: OfficeConnectComponent },
                { 
                    path: 'sites/:id/advanced', 
                    component: AdvancedFormComponent,
                    canDeactivate: mapToCanDeactivate([DeactivateAdvancedGuardService])
                 },
                { path: 'overview', component: OverviewComponent },
                { path: 'gateway-connection-error', component: GatewayConnectionComponent },
                { path: 'pbx-connection-error', component: PbxConnectionComponent },
                { path: 'message/:type', component: MessageFormComponent }
            ] },
        ] },
    ] },
    { path: '**', redirectTo: '' }
];

export const appRouting = RouterModule.forRoot(appRoutes, { useHash: true });
