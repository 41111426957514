import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import {
  Partner,
  PartnerCollection,
  PartnerPostRequest,
  PartnerPutRequest,
  Account,
  AccountCollection,
  AccountPostRequest,
  AccountPutRequest,
  Group,
  GroupCollection,
  GroupPutRequest,
  Site,
  SitePostRequest,
  SitePutRequest,
  Client,
  ClientCollection,
  ClientPostRequest,
  ClientPutRequest,
  User,
  UserCollection,
  UserPostRequest,
  UserPutRequest,
  UserAccessCodePostRequest,
  UserAccessCodePutRequest,
  UserWelcomeEmailRequest,
  ContactCollection,
  Tag,
  SiteCollection,
  GroupPostRequest,
  MemberCollection
} from '@mitel/cloudlink-sdk/admin';
import { Config, Odata,  AdminService as CloudAdmin } from '@mitel/cloudlink-sdk';
import {AccountService} from './account.service';
import * as _ from 'lodash';

@Injectable()
export class AdminService {
    private readonly api: CloudAdmin;

    constructor(private accountService: AccountService) {
        console.log('connecting to', environment.cloud);
        Config.cloud = environment.cloud;
        Config.requestTimeoutMilliseconds = 30000; // timeout for 30 seconds
        if (environment.stage === 'stage') {
            Config.stage = 'stage';
            console.log('stage: ', environment.stage);
        }
        this.api = new CloudAdmin();
    }

    public getPartners(odata?: Odata, options?: any): Promise<PartnerCollection> {
        if (odata) {
            return this.api.getPartners({ $Top: odata.$Top, $Skip: odata.$Skip, $SkipToken: odata.$SkipToken, options });
        } else {
            return this.api.getPartners({ options });
        }
    }

    public getPartner(partnerId: string, options?: any): Promise<Partner> {
        return this.api.getPartner({ partnerId, options });
    }

    public createPartner(body: PartnerPostRequest, options?: any): Promise<Partner> {
        return this.api.createPartner({body, options});
    }

    public updatePartner(partnerId: string, body: PartnerPutRequest, options?: any): Promise<any> {
        return this.api.updatePartner({partnerId, body, options});
    }

    public deletePartner(partnerId: string): Promise<any> {
        return this.api.deletePartner({partnerId});
    }

    public getAccounts(odata?: Odata, options?: any): Promise<AccountCollection> {
        if (odata) {
            return this.api.getAccounts({
                $Expand: odata.$Expand, $Skip: odata.$Skip,
                $Top: odata.$Top, $SkipToken: odata.$SkipToken, options
            });
        } else {
            return this.api.getAccounts({ options });
        }
    }

    public getAccount(accountId: string): Promise<Account> {
        return this.api.getAccount({accountId});
    }

    public createAccount(body: AccountPostRequest): Promise<Account> {
        return this.api.createAccount({ body });
    }

    public updateAccount(accountId: string, body: AccountPutRequest): Promise<Account> {
        return this.api.updateAccount({accountId, body});
    }

    public deleteAccount(accountId: string): Promise<any> {
        return this.api.deleteAccount({accountId});
    }

    public getSites(accountId: string, odata?: Odata, options?: any): Promise<SiteCollection> {
        if (odata) {
            return this.api.getSites({ accountId, $Top: odata.$Top, $Skip: odata.$Skip,
                 $Expand: odata.$Expand, $SkipToken: odata.$SkipToken, options });
        } else {
            return this.api.getSites({ accountId, options });
        }
    }

    public getSite(accountId: string, siteId: string): Promise<Site> {
        return this.api.getSite({accountId, siteId});
    }

    public createSite(accountId, body: SitePostRequest): Promise<Site> {
        return this.api.createSite({accountId, body});
    }

    public updateSite(accountId: string, siteId: string, body: SitePutRequest): Promise<Site> {
        return this.api.updateSite({accountId, siteId, body});
    }

    public deleteSite(accountId: string, siteId: string): Promise<any> {
        return this.api.deleteSite({accountId, siteId});
    }

    public getClientsFromAccount(accountId: string, odata?: Odata, options?: any): Promise<ClientCollection> {
        if (odata) {
            return this.api.getClientsByAccountId({
                accountId, $Top: odata.$Top, $Skip: odata.$Skip,
                $SkipToken: odata.$SkipToken, $Filter: odata.$Filter, options
            });
        } else {
            return this.api.getClientsByAccountId({ accountId, options });
        }
    }

    public getClients($Filter?: string, $Skip?: number, $Top?: number, $SkipToken?: string, options?: any): Promise<ClientCollection> {
        return this.api.getClients({ $Top, $Skip, $Filter, $SkipToken, options });
    }

    public getClientFromAccount(accountId: string, clientId: string): Promise<Client> {
        return this.api.getClientByAccountId({ accountId, clientId });
    }

    public getClient(clientId: string): Promise<Client> {
        return this.api.getClient({ clientId });
    }

    public createClient(body: ClientPostRequest): Promise<Client> {
        return this.api.createClient({ body });
    }

    public createClientByAccountId(accountId: string, body: ClientPostRequest): Promise<Client> {
        return this.api.createClientByAccountId({accountId, body});
    }

    public deleteClientByAccountId(accountId: string, clientId: string): Promise<any> {
        return this.api.deleteClientByAccountId({accountId, clientId});
    }


    public updateClient(clientId: string, body: ClientPutRequest): Promise<Client> {
        return this.api.updateClient({ clientId, body });
    }

    public deleteClient(clientId: string): Promise<any> {
        return this.api.deleteClient({clientId});
    }

    public createUser(body: UserPostRequest): Promise<User> {
        return this.api.createUser({body});
    }

    public createUserForAccount(accountId: string, body: UserPostRequest): Promise<User> {
        return this.api.createUserByAccountId({accountId, body});
    }

    public createAccessCode(userId: string, body: UserAccessCodePostRequest): Promise<any> {
        return this.api.sendUserAccessCode({ userId, body });
    }

    public confirmAccessCode(userId: string, body: UserAccessCodePutRequest): Promise<User> {
        return this.api.verifyUserAccessCode({ userId, body });
    }

    public getUsersFromAccount(accountId: string, odata?: Odata, options?: any): Promise<UserCollection> {
        if (odata) {
            return this.api.getUsersByAccountId({
                accountId, $Skip: odata.$Skip, $Top: odata.$Top, $SkipToken: odata.$SkipToken,
                $Filter: odata.$Filter, $Search: odata.$Search, $Expand: odata.$Expand, options
            });
        } else {
            return this.api.getUsersByAccountId({ accountId, options });
        }
    }

    public getUserFromAccount(accountId: string, userId: string): Promise<User> {
        return this.api.getUserByAccountId({accountId, userId});
    }

    public getUsers(odata?: Odata, options?: any): Promise<UserCollection> {
        if (odata) {
            return this.api.getUsers({
                $Skip: odata.$Skip, $Top: odata.$Top, $SkipToken: odata.$SkipToken,
                $Filter: odata.$Filter, $Search: odata.$Search, $Expand: odata.$Expand, options
            });
        } else {
            return this.api.getUsers({ options });
        }
    }

    public getUser(userId: string): Promise<User> {
        return this.api.getUser({ userId });
    }

    public getUserByAccountId(accountId: string, userId: string): Promise<User> {
        return this.api.getUserByAccountId({ accountId, userId })
    }

    public getContactsByAccountId(accountId: string, odata?: Odata): Promise<ContactCollection> {
        if (odata) {
            return this.api.getAccountContacts({ accountId, $Filter: odata.$Filter, $Skip: odata.$Skip,
                $Top: odata.$Top, $Search: odata.$Search, $SkipToken: odata.$SkipToken });
        } else {
            return this.api.getAccountContacts({ accountId });
        }
    }

    public getMyUser(): Promise<User> {
        // Use 'me' keyword as the userId
        return this.getUser('me');
    }

    public updateUser(userId: string, body: UserPutRequest): Promise<User> {
        return this.api.updateUser({ userId, body });
    }

    public updateUserFromAccount(accountId: string, userId: string, body: UserPutRequest): Promise<User> {
        return this.api.updateUserByAccountId({ accountId, userId, body });
    }

    public deleteUser(userId: string): Promise<any> {
        return this.api.deleteUser({ userId });
    }

    public deleteUserFromAccount(accountId: string, userId: string): Promise<any> {
        return this.api.deleteUserByAccountId({ accountId, userId });
    }

    public uploadUserPhoto(userId: string, fileType: string, file: any): Promise<User> {
        return this.api.updateUserPhotoWithFileType({ userId: userId, body: file, fileType: fileType});
    }

    public uploadUserPhotoFromAccount(accountId: string, userId: string, fileType: string, file: any): Promise<User> {
        return this.api.updateUserPhotoByAccountIdWithFileType({ accountId: accountId, userId: userId, body: file, fileType: fileType });
    }

    public getUserTags(userId: string): Promise<any> {
        return this.api.getUserTags({ userId });
    }

    public getUserTag(userId: string, tagId: string): Promise<any> {
        return this.api.getUserTag({ userId, tagId });
    }

    public createUserTag(userId: string, body: any): Promise<any> {
        return this.api.createUserTag({ userId, body });
    }

    public getUserTagsFromAccount(accountId: string, userId: string): Promise<any> {
        return this.api.getUserTagsByAccountId({accountId, userId});
    }

    public getUserTagFromAccount(accountId: string, userId: string, tagId: string): Promise<any> {
        return this.api.getUserTagByAccountId({accountId, userId, tagId});
    }

    public createUserTagFromAccount(accountId: string, userId: string, body: any): Promise<any> {
        return this.api.createUserTagByAccountId({accountId, userId, body});
    }

    public getAccountTags(accountId: string): Promise<any> {
        return this.api.getAccountTags({accountId});
    }

    public getAccountTag(accountId: string, tagId: string): Promise<any> {
        return this.api.getAccountTag({accountId, tagId});
    }

    public createAccountTag(accountId: string, body: any): Promise<Tag> {
        return this.api.createAccountTag({accountId, body});
    }

  /**
   * Updates existing tags and creates new ones if they don't exist.
   * Please use createAccountTags directly if know for certain all tags are non existent
   * @param accountId
   * @param body
   */
  public async tryUpdateAccountTags(accountId: string, body: any): Promise<any> {
        const existingTags = this.accountService.getAccountTags() || {};
        const updatedTags = {};
        let newTags;
        for (const updatedTagId of Object.keys(body)) {
          if (existingTags[updatedTagId] !== undefined) {
            if (!_.isEqual(existingTags[updatedTagId], body[updatedTagId])) {
              const tagValue = typeof(body[updatedTagId]) === 'string' ? JSON.stringify(body[updatedTagId]) : body[updatedTagId];
              const response = await this.updateAccountTag(accountId, updatedTagId, tagValue);
              delete response._links; // removes the _links property
              updatedTags[updatedTagId] = response;
            }
          } else {
              if (!newTags) {
                newTags = {};
              }
              newTags[updatedTagId] = body[updatedTagId];
            }
        }
        if (newTags) {
          return await this.createAccountTag(accountId, newTags);
        }
        return {...existingTags, ...updatedTags};
    }

    public updateAccountTag(accountId: string, tagId: string, body: any): Promise<Tag> {
        return this.api.updateAccountTag({accountId, tagId, body});
    }

    public deleteAccountTag(accountId: string, tagId): Promise<any> {
        return this.api.deleteAccountTag({accountId, tagId});
    }

    public startResync(): Promise<any> {
        return this.api.startResync({});
    }

    public startResyncForAccount(accountId: string): Promise<any> {
        return this.api.startResyncByAccountId({accountId});
    }

    public getGroups(odata?: Odata, options?: any): Promise<GroupCollection> {
        if (odata) {
            return this.api.getGroups({ $Expand: odata.$Expand, $Filter: odata.$Filter, $Skip: odata.$Skip,
                $Top: odata.$Top, $Search: odata.$Search, options });
        } else {
            return this.api.getGroups({ options });
        }
    }

    public getGroupsFromAccount(accountId: string, odata?: Odata, options?: any): Promise<GroupCollection> {
        if (odata) {
            return this.api.getGroupsByAccountId({
                accountId, $Skip: odata.$Skip, $Top: odata.$Top,
                $SkipToken: odata.$SkipToken, $Filter: odata.$Filter,
                $Search: odata.$Search, $Expand: odata.$Expand, options
            });
        } else {
            return this.api.getGroupsByAccountId({ accountId, options });
        }
    }

    public getPhantomGroups(odata?: Odata, options?: any): Promise<GroupCollection> {
        if (odata) {
            return this.api.getGroups({
                $Filter: `type eq 'PARK' and subType eq 'PHANTOM'`, $Expand: odata.$Expand,
                $Skip: odata.$Skip, $Top: odata.$Top, options
            });
        } else {
            return this.api.getGroups({ $Filter: `type eq 'PARK' and subType eq 'PHANTOM'`, options });
        }
    }

    public createGroupByAccount(accountId: string, body: GroupPostRequest, options?: any): Promise<Group> {
        return this.api.createGroupByAccountId({ accountId, body, options });
    }

    public getGroupFromAccount(accountId: string, groupId: string, options?: any): Promise<Group> {
        return this.api.getGroupByAccountId({ accountId, groupId, options });
    }

    public deleteGroupFromAccount(accountId: string, groupId: string, options?: any): Promise<Group> {
        return this.api.deleteGroupByAccountId({ accountId, groupId, options });
    }

    public updateGroupFromAccount(accountId: string, groupId: string, body: GroupPutRequest): Promise<Group> {
        return this.api.updateGroupByAccountId({ accountId, groupId, body });
    }

    public updateGroup(groupId: string, body: GroupPutRequest): Promise<Group> {
        return this.api.updateGroup({groupId, body});
    }

    public getGroupsWithTagId(tagId: string, odata?: Odata, options?: any): Promise<GroupCollection> {
        if (odata) {
            return this.api.getGroupsWithTag({
                tagId, $Skip: odata.$Skip, $Top: odata.$Top, $SkipToken: odata.$SkipToken,
                $Filter: odata.$Filter, $Search: odata.$Filter, options
            });
        } else {
            return this.api.getGroupsWithTag({ tagId, options });
        }
    }

    public getGroupsWithTagIdFromAccount(accountId: string, tagId: string, odata?: Odata, options?: any): Promise<GroupCollection> {
        if (odata) {
            // TODO: remove when odata for getGroupsWithTagIdFromAccount is supported in cloudlink-sdk/admin
            options = options || {};
            const queryParameter = {} as any;

            if (odata.$Skip !== undefined) {
                queryParameter['$skip'] = odata.$Skip;
            }
            if (odata.$Top !== undefined) {
                queryParameter['$top'] = odata.$Top;
            }
            if (odata.$Filter !== undefined) {
                queryParameter['$filter'] = odata.$Filter;
            }
            if (odata.$Search !== undefined) {
                queryParameter['$search'] = odata.$Search;
            }
            if (odata.$SkipToken !== undefined) {
                queryParameter['$skipToken'] = odata.$SkipToken;
            }

            options.query = Object.assign({}, queryParameter, options.query);
            return this.api.getGroupsWithTagByAccountId({ accountId, tagId, options });
        } else {
            return this.api.getGroupsWithTagByAccountId({ accountId, tagId, options });
        }
    }

    public createGroupTag(groupId: string, body: any): Promise<Tag> {
        return this.api.createGroupTag({groupId, body});
    }

    public createGroupTagFromAccount(accountId: string, groupId: string, body: any): Promise<Tag> {
        return this.api.createGroupTagByAccountId({accountId, groupId, body});
    }

    public updateGroupTag(groupId: string, tagId: string, body: any): Promise<Tag> {
        return this.api.updateGroupTag({groupId, tagId, body});
    }

    public updateGroupTagFromAccount(accountId: string, groupId: string, tagId: string, body: any): Promise<Tag> {
        return this.api.updateGroupTagByAccountId({accountId, groupId, tagId, body});
    }

    public deleteGroupTag(groupId: string, tagId: string): Promise<any> {
        return this.api.deleteGroupTag({groupId, tagId});
    }

    public deleteGroupTagFromAccount(accountId: string, groupId: string, tagId: string): Promise<any> {
        return this.api.deleteGroupTagByAccountId({accountId, groupId, tagId});
    }

    public generateRandomPassword(length: number): string {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
        let pass = '';
        for (let x = 0; x < length; x++) {
            const i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        return pass;
    }

  public sendWelcomeEmailToUser(accountId: string, userId: string, body: UserWelcomeEmailRequest): Promise<void> {
    return this.api.sendWelcomeToUserByAccountId({accountId, userId, body});
  }

  public sendWelcomeEmail(accountId: string, email: string, body: UserWelcomeEmailRequest): Promise<void> {
    return this.api.sendWelcomeByAccountId({accountId, email, body});
  }

  public getGroupMembers(accountId: string, groupId: string, options?: any): Promise<MemberCollection> {
    return this.api.getGroupMembersByAccountId({accountId, groupId, options});
  }

}
