// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
	display: inline-block;
	background: #E5F5FC;
	padding-left: 10px;
	padding-right: 5px;
	border-radius: 90px;
	margin-right:10px;
	height: 20px;
	font-family: MuseoSans-300;
	font-size: 13px;
	color: #404141;
	line-height: 1.6;
}

:host.tag-input-item-selected {
	color: white;
	background: #0d8bff;
}

.tag-input-remove {
	cursor: pointer;
	display: inline-block;
	padding-left: 15px;
	padding-right: 3px;
	height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/tag-input-item/tag-input-item.component.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,mBAAmB;CACnB,kBAAkB;CAClB,kBAAkB;CAClB,mBAAmB;CACnB,iBAAiB;CACjB,YAAY;CACZ,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,YAAY;CACZ,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,qBAAqB;CACrB,kBAAkB;CAClB,kBAAkB;CAClB,YAAY;AACb","sourcesContent":[":host {\n\tdisplay: inline-block;\n\tbackground: #E5F5FC;\n\tpadding-left: 10px;\n\tpadding-right: 5px;\n\tborder-radius: 90px;\n\tmargin-right:10px;\n\theight: 20px;\n\tfont-family: MuseoSans-300;\n\tfont-size: 13px;\n\tcolor: #404141;\n\tline-height: 1.6;\n}\n\n:host.tag-input-item-selected {\n\tcolor: white;\n\tbackground: #0d8bff;\n}\n\n.tag-input-remove {\n\tcursor: pointer;\n\tdisplay: inline-block;\n\tpadding-left: 15px;\n\tpadding-right: 3px;\n\theight: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
