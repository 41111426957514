import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, filterString: string, propName: string): any {
    if (!value || value.length === 0) {
    	return value;
    }

    if (!filterString || filterString === '') {
    	return value;
    }

    if (!propName || propName === '') {
        return value;
    }

    const resultArray = [];
    for (const item of value) {
    	if (item[propName].toLowerCase().indexOf(filterString.toLowerCase()) !== -1) {
    		resultArray.push(item);
    	}
    }
    return resultArray;
  }

}
