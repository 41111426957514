import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { map } from 'rxjs/operators';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';


@Injectable()
export class AuthGuard {
  constructor(private appSvs: AppService, private clHeader: ClHeaderComponent) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const claims = this.appSvs.getClaims();
    if(claims){
      if (claims.role === 'PARTNER_ADMIN' || claims.role === 'ACCOUNT_ADMIN') {
          console.log("AUTH GUARD says: ", true);
          return true;
      } else {
          this.clHeader.logoutUser();
          return false;
      }
    } else {
      return this.appSvs.claimsChanged.asObservable().pipe(map( claims => {
        if (claims.role === 'PARTNER_ADMIN' || claims.role === 'ACCOUNT_ADMIN') {
          console.log("AUTH GUARD says: ", true);
          return true;
        } else {
          this.clHeader.logoutUser();
          return false;
        }
      }));
    }
  }
}
