import { Injectable, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TooltipService {
  component: any;

  constructor(private translateSvc: TranslateService) {}

  addTooltip(component: any) {
    this.component = component;
  }

  getTooltipItem(labelName: string) {
    const translateObject = this.translateSvc.instant(labelName);
    const item = {
      name: translateObject.name ? translateObject.name() : '',
      title: translateObject.title ? translateObject.title() : '',
      content: translateObject.content ? translateObject.content() : '',
      help: translateObject.help ? translateObject.help() : ''
    };

    return item;
  }

  destroy() {
    if (this.component) {
      this.component.destroy();
    }
  }
}
