import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  HostBinding,
  ViewChild,
  Input,
  Output,
  forwardRef,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { tldExists } from 'tldjs';
import * as _ from 'lodash';
import * as freemail from 'freemail-webpack';
import { EMAIL_REGEXP, SUPPORTEMAIL_REGEXP } from '../../shared/constants';

const TAG_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagInputComponent),
  multi: true
}

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  providers: [TAG_INPUT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./tag-input.component.css']
})
export class TagInputComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {
  @ViewChild('tagInputField') tagInputField;
	@Input() placeholder = 'Add a tag';
	@Input() inputArray: string[];
  @Input() tagType = 'email';
	@Input() delimiterCode = '188';
	@Input() addOnBlur = true;
	@Input() addOnEnter = true;
	@Input() addOnPaste = true;
	@Input() allowedTagsPattern: RegExp = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
  @Output() invalidTag = new EventEmitter<any>();
	@HostBinding('class.tag-input-focus') isFocused;
  @HostBinding('class.touched') isTouched;
  @HostBinding('class.item-invalid') isItemInvalid;

	tagsList: string[];
	inputValue = '';
	delimiter: number;
	selectedTag: number;
  invalidInputValue = '';

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
  	if (this.inputArray) {
  		this.tagsList = this.inputArray;
  	}
    this.delimiter = parseInt(this.delimiterCode, 10);
  }

  ngAfterViewInit() {
    // If the user passes an undefined variable to ngModel this will warn
    // and set the value to an empty array
    if (!this.tagsList) {
      this.tagsList = [];
      this.cdr.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputArray && changes.inputArray.currentValue) {
      this.tagsList = this.inputArray;

      if (this.tagsList.length < 1) {
        this.invalidTag.emit({ invalid: true, message: 'required'})
      }
    }
  }

  onClick() {
    this.isFocused = true;
    setTimeout(_ => this.tagInputField.nativeElement.focus());
  }

  inputChanged(event) {
    const key = event.keyCode;
    switch (key) {
      case 8: // Backspace
        this._handleBackspace();
        break;
      case 13: // Enter
        this._addTag(this.inputValue);
        event.preventDefault();
        break;
      case 32: // Space
        this._addTag(this.inputValue);
        event.preventDefault();
        break;

      case this.delimiter:
        this._addTag(this.inputValue);
        event.preventDefault();
        break;

      default:
        this._resetInvalidInput();
        this._resetSelected();
        break;
    }
  }

  inputBlurred() {
    this._resetInvalidInput();
    this._addTag(this.inputValue);
    this.isFocused = false;
    this.isTouched = true;
    this.validate()
  }

  private validate(){
    if (this.tagsList.length < 1) {
      this.invalidTag.emit({ invalid: true, message: 'required'})
    }
  }

  inputFocused() {
    this.isFocused = true;
  }

  inputPaste(event) {
    const clipboardData = event.clipboardData || (event.originalEvent && event.originalEvent.clipboardData);
    const pastedString = clipboardData.getData('text/plain');
    const tags = this._splitString(pastedString);
    this._addTags(tags);
    setTimeout(() => this.inputValue = '', 3000);
  }

  private _splitString(tagString: string) {
    tagString = tagString.trim();
    const tags = tagString.split(String.fromCharCode(this.delimiter));
    return tags.filter((tag) => !!tag);
  }

  private _isTagValid(tagString: string) {
    return this.allowedTagsPattern.test(tagString) && tldExists(tagString);
  }

  private _isFreemail(tagString: string) {
    return tagString && freemail.isFree(`smith@${tagString}`);
  }

  private _addTag(tag: string) {
    let tagValidity = { invalid: false, message: null, tag: tag };
    if (tag === '' && this.invalidInputValue === '' && !(this.tagType === 'dns' && this.tagsList.length == 0)) {
      this.isItemInvalid = false;
      this.invalidTag.emit(tagValidity);
      return;
    }

    if (this.tagType === 'domain') {
      if (this._isTagValid(tag) && !this._isFreemail(tag)) {
        this.updateTagLists(false, tagValidity);
      } else {
        if (this._isFreemail(tag)) {
          tagValidity = { invalid: true, message: 'freemail', tag: tag };
        } else {
          tagValidity = { invalid: true, message: 'invalidFormat', tag: tag };
        }
        this.updateTagLists(true, tagValidity);
      }
    } else if (this.tagType === 'email') {
      if (EMAIL_REGEXP.test(tag)) {
        this.updateTagLists(false, tagValidity);
      } else {
        tagValidity = { invalid: true, message: 'invalidFormat', tag: tag };
        this.updateTagLists(true, tagValidity);
      }
    } else if (this.tagType === 'supportEmail') {
      if (EMAIL_REGEXP.test(tag)) {
        //Check to see if tag is a forbidden email
        if (!SUPPORTEMAIL_REGEXP.test(tag)) {
          this.updateTagLists(false, tagValidity);
        } else {
          tagValidity = { invalid: true, message: 'forbiddenSupportEmail', tag: tag };
          this.updateTagLists(true, tagValidity);
        }
      } else {
        tagValidity = { invalid: true, message: 'invalidFormat', tag: tag };
        this.updateTagLists(true, tagValidity);
      }
    } else if (this.tagType === 'dns') {
      const isInvalid = !this.allowedTagsPattern.test(tag);
      if (isInvalid) {
        this.updateTagLists(isInvalid, { invalid: isInvalid, message: 'validateDnsServers', tag: tag });
      } else {
        this.updateTagLists(isInvalid, tagValidity);
      }
      if (this.tagsList.length == 0) {
        this.updateTagLists(true, { invalid: true, message: 'required', tag: tag });
      }
    }
  }

  private updateTagLists(invalid: boolean, tagValidity: any) {
    if (invalid) {
      this.invalidInputValue = tagValidity.tag;
      this.isItemInvalid = true;
      this.invalidTag.emit(tagValidity);
      this._resetSelected();
      this._resetInput();
    } else {
      this.tagsList = _.uniq(this.tagsList.concat(tagValidity.tag));
      this.isItemInvalid = false;
      this.invalidTag.emit(tagValidity);
      this._resetSelected();
      this._resetInput();
      this.onChange(this.tagsList);
    }
  }

  private _addTags(tags: string[]) {
    const validTags = tags.filter((tag) => this._isTagValid(tag));
    this.tagsList = this.tagsList.concat(_.uniq(validTags));
    this._resetSelected();
    this._resetInput();
    this.onChange(this.tagsList);
  }

  private _removeTag(tagIndexToRemove) {
    this.tagsList.splice(tagIndexToRemove, 1);
    this._resetSelected();
    console.log('Function: _removeTag, this.tagsList.length: ', this.tagsList.length);
    if (this.tagsList.length == 0) {
      this.updateTagLists(true, { invalid: true, message: 'required', tag: null });
    }
    this.onChange(this.tagsList);
  }

  private _handleBackspace() {
    this.validate();
    if (this.invalidInputValue !== '') {
      this._resetInvalidInput();
    } else if (!this.inputValue.length && this.tagsList.length) {
      if (!this.isBlank(this.selectedTag)) {
        this._removeTag(this.selectedTag);
      } else {
        this.selectedTag = this.tagsList.length - 1;
      }
    }
  }

  private isBlank(obj: any) {
  	return obj === undefined || obj === null;
  }

  private _resetSelected() {
    this.selectedTag = null;
  }

  private _resetInput() {
    this.inputValue = '';
  }

  private _resetInvalidInput() {
    this.invalidInputValue = '';
  }

  /** Implemented as part of ControlValueAccessor. */
  onChange: (value) => any = () => { };

  onTouched: () => any = () => { };

  writeValue(value: any) { }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }



}
