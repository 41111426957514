export enum Language {
	en = 'en',
  'en-GB' ='en-GB',
	de = 'de',
	fr = 'fr',
	es = 'es',
	it = 'it',
	nl = 'nl',
	pt = 'pt'
}
