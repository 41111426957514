import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { FormAlertType, MessageType } from './../../shared/constants';

@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss']
})
export class MessageFormComponent implements OnInit {
  message: string;
  messageType: string;

  constructor(private route: ActivatedRoute,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    let type = this.route.snapshot.paramMap.get('type');
    if(type === MessageType.NO_SUPPORT || type === MessageType.NO_SUPPORT_UPDATED_STATUS){
      this.messageType = FormAlertType.ERROR;
      this.message = this.translateService.instant('constants.messages.NO_SUPPORT_AVAILABLE');
    }
    else if(type === MessageType.NO_SUPPORT_MISSING_COMPONENT_ID){
      this.messageType = FormAlertType.ERROR;
      this.message = this.translateService.instant('constants.messages.NO_SUPPORT_MISSING_COMPONENT_ID');
    }
    else if(type === MessageType.NO_SUPPORT_FAILURE_TO_RETRIEVE_SYSTEM_DETAILS){
      this.messageType = FormAlertType.ERROR;
      this.message = this.translateService.instant('constants.messages.NO_SUPPORT_FAILURE_TO_RETRIEVE_SYSTEM_DETAILS');
    }
    else if(type === MessageType.FAILED_TO_GET_ACCOUNT){
      this.messageType = FormAlertType.ERROR;
      this.message = this.translateService.instant('constants.messages.FAILED_TO_GET_ACCOUNT');
    }
    else if(type === MessageType.FACTORY_RESET_REQUEST_INITIATED){
      this.messageType = FormAlertType.SUCCESS;
      this.message = this.translateService.instant('constants.messages.FACTORY_RESET_REQUEST_INITIATED');
    }
    else if(type === MessageType.REBOOT_REQUEST_INITIATED){
      this.messageType = FormAlertType.SUCCESS;
      this.message = this.translateService.instant('constants.messages.REBOOT_REQUEST_INITIATED');
    }
    else if(type === MessageType.RESTART_REQUEST_INITIATED){
      this.messageType = FormAlertType.SUCCESS;
      this.message = this.translateService.instant('constants.messages.RESTART_REQUEST_INITIATED');
    }
    else if(type === MessageType.UPGRADE_REQUEST_INITIATED){
      this.messageType = FormAlertType.SUCCESS;
      this.message = this.translateService.instant('constants.messages.UPGRADE_REQUEST_INITIATED');
    }
    else if(type === MessageType.GATEWAY_CONNECTION_ISSUE){
      this.messageType = FormAlertType.ERROR;
      this.message = this.translateService.instant('constants.messages.GATEWAY_CONNECTION_ISSUE');
    }
    else if(type === MessageType.PBX_CONNECTION_ISSUE){
      this.messageType = FormAlertType.ERROR;
      this.message = this.translateService.instant('constants.messages.PBX_CONNECTION_ISSUE');
    }
  }

}
