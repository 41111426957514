import { Injectable } from '@angular/core';
import { Config, AuthenticationService as CloudAuth, Token, Claims, UserClaims } from '@mitel/cloudlink-sdk';
import { AssumeRoleTokenPostRequest, PolicyTestRequest, PolicyTestResponse } from '@mitel/cloudlink-sdk/authentication';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
import { ParentCommsService } from '../services/parent-comms.service';

@Injectable()
export class AuthenticationService {
    private readonly auth: CloudAuth;

    constructor(private router: Router, private clHeader: ClHeaderComponent, private parentCommsService: ParentCommsService) {
        Config.cloud = environment.cloud;
        this.auth = Config.authentication;
    }

    public login(username: string, password: string, accountId?: string): Promise<Token> {
        localStorage.removeItem('@mitel/token.original_token');
        return this.auth.login({ user: { username, password, accountId } });
    }

    public logout(): Promise<void> {
        localStorage.removeItem('@mitel/token.original_token');
        return this.auth.logout();
    }

    public loggedInUser(): Promise<string> {
        const notLoggedIn = 'Not logged in';
        return this.getUserClaims()
            .then(claims => claims ? claims.name : notLoggedIn)
            .catch(error => {
                console.log('error loggedInUser', error);
                return notLoggedIn;
            });
    }

    public getUserClaims(): Promise<UserClaims> {
           return this.auth.getToken()
           .then(token => token ? this.auth.whoAmI(token).then(claims => claims as UserClaims) : null)
           .catch(error => {
               console.log('error getUserClaims', error);
               console.log(error.body);
               return null;
           });
    }

    public getPartnerClaims(): Promise<Claims> {
        let original_token = localStorage.getItem('@mitel/token.original_token');

        if (!original_token) {
            return Promise.resolve(null);
        }

        return this.auth.whoAmI(JSON.parse(original_token));
    }

    public ssoLogin(token: Token): Promise<Token> {
        localStorage.removeItem('@mitel/token.original_token');
        return this.auth.setToken(token);
    }

    public redirectToLogin(message?: string) {
        if(this.parentCommsService.isInsideIframe()){
            this.clHeader.logoutOnly();
            this.parentCommsService.tellParentToRedirectToLogin();  
        } else {
            this.clHeader.logoutUser();
        }
    }

    public assumeRole(body: AssumeRoleTokenPostRequest): Promise<Token> {
        return this.auth.assumeRole(body);
    }

    public revertRole(): Promise<Token> {
        return this.auth.revertRole();
    }

    public createAssumeRoleToken(body: AssumeRoleTokenPostRequest, options?: any): Promise<Token> {
        return this.auth.createTokenWithAssumedRole({ body, options });
    }

    public getToken(): Promise<Token> {
        return this.auth.getToken();
    }

    private getAuthOptions(token: Token) {
        const options = {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${token.access_token}`
            }
        }
        return options;
    }

    public getAssumedRoleToken(body: AssumeRoleTokenPostRequest): Promise<Token> {
        return this.auth.getToken().then(token => {
            const authHeader = this.getAuthOptions(token);
            return this.createAssumeRoleToken(body, authHeader)
        })
    }

    testPolicy(request: PolicyTestRequest): Promise<PolicyTestResponse> {
        return this.auth.testPolicy(request);
    }

    private getAssumedRoleOptions(token: Token) {
        const headers = { 'authorization': 'Bearer ' + token.access_token };
        const options = {
          headers: headers
        };
  
        return options;
    }

    public getAssumeRoleParams(accountId: string) {
        let params;
        if (accountId) {
            params = { accountId: accountId, role: 'ACCOUNT_ADMIN' };
        }
        return params;
    }
  
    public async getAssumeRoleHeader(accountId) {
        const assumeParams = this.getAssumeRoleParams(accountId);
        if (assumeParams) {
          const token = await this.getAssumedRoleToken(assumeParams);
          return this.getAssumedRoleOptions(token);
        } else {
          return undefined;
        }
    }
}
