import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {BiDirectionalMap} from 'bi-directional-map/dist';
import { isPostalCode } from 'validator';

const Netmask = require('netmask').Netmask

export class CustomValidators {

  static subnetMasks = new BiDirectionalMap<number, string>([
    [32, '255.255.255.255'],
    [31, '255.255.255.254'],
    [30, '255.255.255.252'],
    [29, '255.255.255.248'],
    [28, '255.255.255.240'],
    [27, '255.255.255.224'],
    [26, '255.255.255.192'],
    [25, '255.255.255.128'],
    [24, '255.255.255.0'],
    [23, '255.255.254.0'],
    [22, '255.255.252.0'],
    [21, '255.255.248.0'],
    [20, '255.255.240.0'],
    [19, '255.255.224.0'],
    [18, '255.255.192.0'],
    [17, '255.255.128.0'],
    [16, '255.255.0.0'],
    [15, '255.254.0.0'],
    [14, '255.252.0.0'],
    [13, '255.248.0.0'],
    [12, '255.240.0.0'],
    [11, '255.224.0.0'],
    [10, '255.192.0.0'],
    [9, '255.128.0.0'],
    [8, '255.0.0.0'],
    [7, '254.0.0.0'],
    [6, '252.0.0.0'],
    [5, '248.0.0.0'],
    [4, '240.0.0.0'],
    [3, '224.0.0.0'],
    [2, '192.0.0.0'],
    [1, '128.0.0.0'],
    [0, '0.0.0.0']
  ]);

  /**
   * sample from http://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html
   */
  static validateEmail(c: FormControl) {
    // tslint:disable-next-line:max-line-length
    const EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (!c.value || c.value === '') {
      return null;
    }

    return EMAIL_REGEXP.test(c.value) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }

  static forbiddenEmail(c: FormControl) {
    const FORBIDDENEMAIL_REGEXP = /cloudlink-support@mitel.com/i;

    if (!c.value || c.value === '') {
      return null;
    }

    return FORBIDDENEMAIL_REGEXP.test(c.value) ?  {
      forbiddenEmail: {
        valid: false
      }
    } : null ;
  }

  static validateE164Number(c: FormControl) {
    const E164_REGEXP = /^\+{1}[1-9]\d{1,14}$/g;

    return E164_REGEXP.test(c.value) ? null : {
      validateE164: {
        valid: false
      }
    };
  }

  static validateOptionalE164Number(c: FormControl) {
    const E164_REGEXP = /^$|\+{1}[1-9]\d{1,14}$/g

    return E164_REGEXP.test(c.value) ? null : {
      validateE164: {
        valid: false
      }
    };
  }

  static validateZipCode(c: AbstractControl, countryCode= 'any'): {[s: string]: boolean} {
    const invalid = {'IsNotValidZipCode': true};

    if (!c.value) {
      return invalid;
    }

    const threeDigit = /^\d{3}$/;
    const fourDigit = /^\d{4}$/;
    const fiveDigit = /^\d{5}$/;
    const sixDigit = /^\d{6}$/;
    const patterns = {
      SA: /(^\d{5}-{1}\d{4}$)|(^\d{5}$)/, // NNNNNN or NNNNN-NNNN
      AR: /^([a-zA-Z]{1}\d{4}[a-zA-Z]{3}|[a-zA-Z]{1}\d{4}|\d{4})$/, // NNNN or LNNNN or LNNNNLLL
      LB: /(^\d{5}$)|(^\d{4}\s{0,1}\d{4}$)/, // NNNN NNNN or NNNNNNNN or NNNNN
      CL: /^(\d{7})|[0-9]{3}[-][0-9]{4}$/, // NNNNNNN (NNN-NNNN)
      MU: /^([1-9]|A|R)\d{4,4}$/i, // NNNNN or ANNNN or RNNNN
      TF: fiveDigit,
      BR: /^\d{5}-\d{3}$/,
      NZ: fourDigit,
      CV: fourDigit,
      CO: sixDigit,
      CY: fourDigit,
      EG: fiveDigit,
      SV: fourDigit,
      GP: /^97(0|1)\d{2}$/,
      JO: fiveDigit,
      MG: threeDigit,
      MQ: /^972\d{2}$/,
      RS: fiveDigit,
      SG: sixDigit,
    };

    let valid = false;
    try {
      if (countryCode === 'SA') { // SA is recognized by library, but need to include another valid option for a postalCode
        valid = patterns.SA.test(c.value);
      } else {
        valid = isPostalCode(c.value, countryCode);
      }
    } catch (err) { // countryCode not recognized by library

      if (countryCode in patterns) {
        valid = patterns[countryCode].test(c.value);
      } else {
        valid = isPostalCode(c.value, 'any');
      }
    }
    return valid ? null : invalid;
  }

  static validateDottecDecimal(value: string): boolean {
    // tslint:disable-next-line:max-line-length
    const IP_REGEXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const disallowed_IP = ['0.0.0.0', '127.0.0.1', '255.255.255.255'];
    return IP_REGEXP.test(value) && disallowed_IP.indexOf(value) === -1;
  }

  static validateIpAddress(c: FormControl) {
    if (!c.value) {
      return null;
    }
    return CustomValidators.validateDottecDecimal(c.value) ? null : {
      validateIpAddress: {
        valid: false
      }
    };
  }

  static validateFQDN(value: string): boolean {
    const FQDN_REGEXP = /^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)$/;
    return FQDN_REGEXP.test(value);
  }

  static validateIpAddressOrFQDN(c: FormControl){
    if (!c.value) {
      return null;
    }
    if(CustomValidators.validateDottecDecimal(c.value)){
      return null;
    } else if(CustomValidators.validateFQDN(c.value)){
      return null;
    } else{
      return {
        validateIpAddressOrFQDN: { valid: false }
      }
    }
  }

  static validateSubnet(c: FormControl) {
    if (!c.value) {
      return null;
    }
    return CustomValidators.subnetMasks.hasValue(c.value) ? null : {
      validateSubnet: {
        valid: false
      }
    };
  }

  static validateDnsServers(c: FormControl) {
    // tslint:disable-next-line:max-line-length
    const IP_REGEXP = /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))(,(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])).(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])))*$/;

    if (!c.value) {
      return null;
    }

    return IP_REGEXP.test(c.value) ? null : {
      validateDnsServers: {
        valid: false
      }
    };
  }

  static validateDigit(c: FormControl) {
    if (!c.value) {
      return null;
    }
    const DIGIT_REGEXP = /^\d+$/;

    return DIGIT_REGEXP.test(c.value) ? null : {
      nonDigit: {
        valid: false
      }
    };
  }

  static validateString(c: FormControl) {
    if (!c.value) {
      return null;
    }
    const DIGIT_REGEXP = /^\d+((;\d+)*)?$/;

    return DIGIT_REGEXP.test(c.value) ? null : {
      nonValidString: {
        valid: false
      }
    };
  }

  static validateMaxLength(maxLength: number) {
    return (c: FormControl) => {
      if (c.value && c.value.length > maxLength) {
        return {
          maxLength: {
            valid: false
          }
        };
      }
      return null;
    };
  }

  static validateCharacters(c: FormControl) {
    const CHAR_REGEXP = /^[a-zA-Z0-9~!\$\&*\(\)\?\/;',+=._-]+$/;

    if (c.value) {
      return CHAR_REGEXP.test(c.value) ? null : {
        invalidChar: {
          valid: false
        }
      };
    }
    return null;
  }

  static validatePasswordChars(c: FormControl) {
    if (c.value && c.value.indexOf('|') > -1) {
      return {
        invalidChar: {
          valid: false
        }
      };
    }
    return null;
  }

  static validateGateway(ip: string, subnet: string, gateway: string): boolean {

    if (!CustomValidators.subnetMasks.hasValue(subnet)) {
      return true;
    }

    try {
      const block = new Netmask(`${ip}/${CustomValidators.subnetMasks.getKey(subnet)}`);
      return block.contains(gateway)
    }catch (error) {
        // ignore
    }
  }

  static validateNetworkConfig(g: FormGroup) {
    const ip = g.get('ipAddress1') ? g.get('ipAddress1').value : null;
    const subnet = g.get('subnet1') ? g.get('subnet1').value : null;
    const gateway = g.get('gateway1') ? g.get('gateway1').value : null;

    if (!ip || !subnet || !gateway) {
      return null;
    }

    // tslint:disable-next-line:max-line-length
    if (!CustomValidators.validateDottecDecimal(ip) || !CustomValidators.validateDottecDecimal(subnet) || !CustomValidators.validateDottecDecimal(gateway)) {
      return null;
    }

    if (ip === gateway) {
      return {
        ipSameAsGateway: {
          valid: false
        }
      };
    }

    return CustomValidators.validateGateway(ip, subnet, gateway) ? null : {
      invalidGateway: {
        valid: false
      }
    };
  }
}
