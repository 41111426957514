import { Injectable } from '@angular/core';
import { Admin2Service } from './admin2.service';
import { detect } from 'detect-browser';
import * as AWS from 'aws-sdk';
import * as AMA from 'aws-sdk-mobile-analytics';

@Injectable()
export class AnalyticsService {

    private myClient: any;

    constructor(private admin2Svc: Admin2Service) {}

    /**
     * Method to initialize the Amazon Mobile Analytics client.
     */

    initClient() {
        if ( !this.myClient ) {
            // get the cognito creds and appID
            this.admin2Svc.getAnalyticsConfigurationData('ba8ad84c-88e3-4283-8f68-0a68bd686206').then(response => {
                if (response && response.analytics &&
                    response.analytics.cognitoIdentity !== 'none' &&
                    response.analytics.appId !== 'none') {

                    AWS.config.region = 'us-east-1';
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: response.analytics.cognitoIdentity
                    });

                    const options = {
                        appId : response.analytics.appId,
                        appTitle: 'portal',
                        appVersionName: '1.0.0.2',
                        // logger: console // Remove this line to turn off log messages
                    };

                    this.myClient = new AMA.Manager(options);

                    const browser = detect();
                    if (browser) {
                        this.myClient.recordEvent('AppStart',
                            {'BROWSER_TYPE': browser.name,
                            'BROWSER_VERSION': browser.name + ' ' + browser.version});
                    }
                }
            }, reason => {
                console.log('Unable to get analytics configuration');
            }).catch(err => {
                console.log('Unable to get analytics configuration ' + err);
            });
        }
    }

    /**
     * Method to post an event to Amazon Mobile Analytics
     * See https://github.com/mitel-networks/cloudlink-gateway-portal/wiki for details
     * on the events currently reported
     *
     * @param eventName The name of the event to submit for analytics (i.e. EVENT_CLICK)
     * @param eventAttribute The attribute being reported for the given event (i.e. Advanced)
     * @param eventVal  The value of the attribute (i.e. Factory Default)
     */
    postAMAEvent(eventName: string, eventAttribute: string, eventVal: string) {
        if (this.myClient) {
            const attributes = {};
            attributes [ eventAttribute ] = eventVal;
            this.myClient.recordEvent(eventName, attributes);
        }
    }
}
