import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Account, User, Group, Contact } from '@mitel/cloudlink-sdk/admin'
import { PbxUser, PbxHuntgroup, PbxPhantom } from '@mitel/cloudlink-sdk/tunnel';
import { Utils, Odata } from '@mitel/cloudlink-sdk';
import { AdminService } from '../admin.service';
import { AccountService } from '../../services/account.service';
import { AuthenticationService } from '../authentication.service';
import { FormDataService } from '../form-data.service';
import { TunnelService } from '../../services/tunnel.service';
import { SpinnerService } from '../../services/spinner.service';
import { GroupsResyncHelper, PatchRequestError, UsersResyncHelper, ContactsResyncHelper, PbxContact } from '../../shared/resync/helper';
import { connectMessages } from '../../shared/constants';
import { environment } from '../../../environments/environment';

@Injectable()
export class ResyncService {
    private usersResyncHelper: UsersResyncHelper;
    private groupsResyncHelper: GroupsResyncHelper;
    private contactsResyncHelper: ContactsResyncHelper;
    accountTags: any = {};
    MAX_QUERY_NUM = 50;
    pbxUsers: PbxUser[] = [];
    cloudlinkUsers: User[] = [];
    pbxHuntGroups: PbxHuntgroup[] = [];
    adminHuntGroups: Group[] = [];
    pbxPhantoms: PbxPhantom[] = [];
    adminPhantoms: Group[] = [];
    failures: number = 0;
    pbxContacts: PbxContact[] = [];
    cloudlinkContacts: Contact[] = [];
    private tunnelUrlBase: string = environment.tunnelUrl;

    constructor(
        private adminService: AdminService,
        private authSvc: AuthenticationService,
        private spinnerSvc: SpinnerService,
        private tunnelSvc: TunnelService,
        private accountSvc: AccountService,
        private formDataSvc: FormDataService,
        private http: HttpClient
    ) { }

    public async startResyncForAccount(accountId: string, siteId?: string): Promise<any> {
        this.accountTags = this.accountSvc.getAccountTags();
        if (this.accountTags['on-board-progress']?.succeeded && this.accountTags['process-complete']) {
            await this.resync(accountId, siteId);
            const failures = this.failures;
            this.resetResyncInfo();
            if (failures > 0) {
                throw new PatchRequestError('ResyncService.startResyncForAccount(): some of the updates failed.', failures);
            }
        } else {
            console.log('Resync not started because onboarding is not complete yet.');
        }
    }

    private async resync(accountId: string, siteId?: string) {
        this.usersResyncHelper = new UsersResyncHelper(this.spinnerSvc, this.authSvc, this.http);
        this.groupsResyncHelper = new GroupsResyncHelper(this.adminService, this.authSvc, this.formDataSvc, this.spinnerSvc, this.http);
        this.contactsResyncHelper = new ContactsResyncHelper(this.spinnerSvc, this.authSvc, this.http);
        const token = (await this.authSvc.getToken()).access_token;

        try {
            const account = await this.adminService.getAccount(accountId);
            if (!account) {
                throw new Error(`Account ${accountId} not found.`);
            }
            accountId = account.accountId;

            if (siteId) {
                await this.pbxSyncSite(token, account, siteId);
            } else {
                const sites = _.keyBy((await this.adminService.getSites(accountId))?._embedded?.items, 'siteId');
                if (_.keys(sites).length > 0) {
                    for (const site of _.keys(sites)) {
                        await this.pbxSyncSite(token, account, site);
                    }
                } else {
                    throw new Error('Site not found.');
                }
            }
        } catch (error) {
            console.error('ResyncService.resync() Error => ', error);
            if (!!error?.statusCode || error instanceof PatchRequestError) {
                throw error;
            } else {
                throw new Error('Failed to sync customer, try again later.');
            }
        }
    }

    private async pbxSyncSite(token: string, account: Account, siteId: string) {

        console.log('ResyncService.resync() siteId =>', siteId);
        this.spinnerSvc.setMessage(connectMessages.SYNC_USERS);
        const pbxId = '1';
        const topFilter: Odata = {
            $Top: this.MAX_QUERY_NUM
        };
        const cloudlinkUsersFilter: Odata = {
            $SkipToken: '',
            $Filter: `siteId eq \'${siteId}\' or siteId eq null or siteId eq \'\'`
        };
        const contactsFilter: Odata = {
            $SkipToken: '',
            $Filter: 'contactType eq \'PBX\''
        };

        // sync users
        try {
            this.spinnerSvc.setMessage(connectMessages.GET_PBX_USERS);
            await this.getPbxUsers(account, siteId, pbxId, topFilter);
            console.log('ResyncService.pbxSyncSite() number of pbxUsers =>', this.pbxUsers.length);

            this.spinnerSvc.setMessage(connectMessages.GET_CLOUDLINK_USERS);
            await this.getCloudlinkUsers(account, cloudlinkUsersFilter);
            console.log('ResyncService.pbxSyncSite() number of cloudlinkUsers =>', this.cloudlinkUsers.length);

            const updatesMade = await this.usersResyncHelper.syncUsers(account, siteId, this.pbxUsers, this.cloudlinkUsers);

            if (updatesMade) {
                // after updating the users go and get the updated list of users for use in groups resync
                this.cloudlinkUsers = [];
                await this.getCloudlinkUsers(account, cloudlinkUsersFilter);
            }
        } catch (error) {
            if (error instanceof PatchRequestError) {
                this.failures += error.failureCount;
            } else {
                throw error;
            }
        }

        // sync hunt groups
        try {
            this.spinnerSvc.setMessage(connectMessages.SYNC_HUNT_GROUPS);
            await this.getPbxHuntgroups(account, siteId, pbxId, topFilter);
            console.log('ResyncService.pbxSyncSite() number of pbxHuntGroups =>', this.pbxHuntGroups.length);
            const adminHuntGroups: Group[] = await this.groupsResyncHelper.getAllHuntGroupsBySiteIdOrNoSite(account.accountId, siteId);
            console.log('ResyncService.pbxSyncSite() number of adminHuntGroups =>', adminHuntGroups.length);

            await this.groupsResyncHelper.syncHuntGroups(account, siteId, this.pbxHuntGroups, adminHuntGroups, this.cloudlinkUsers);
        } catch (error) {
            if (error instanceof PatchRequestError) {
                this.failures += error.failureCount;
            } else {
                throw error;
            }
        }

        // sync phantoms
        try {
            this.spinnerSvc.setMessage(connectMessages.SYNC_PHANTOMS);
            await this.getPbxPhantoms(account, siteId, pbxId, topFilter);
            console.log('ResyncService.pbxSyncSite() number of pbxPhantoms =>', this.pbxPhantoms.length);
            const adminPhantoms = await this.groupsResyncHelper.getAllPhantomsBySiteIdOrNoSite(account.accountId, siteId);
            console.log('ResyncService.pbxSyncSite() number of adminPhantoms =>', adminPhantoms.length);

            await this.groupsResyncHelper.syncHuntGroups(account, siteId, this.pbxPhantoms, adminPhantoms, this.cloudlinkUsers, 'PHANTOM');
            this.spinnerSvc.setMessage(connectMessages.PBX_RESYNC);
        } catch (error) {
            if (error instanceof PatchRequestError) {
                this.failures += error.failureCount;
            } else {
                throw error;
            }
        }

        // sync contacts
        try {
            this.spinnerSvc.setMessage(connectMessages.GET_PBX_CONTACTS);
            await this.getPbxContacts(account, siteId, pbxId);
            console.log('ResyncService.pbxSyncSite() number of pbxContacts =>', this.pbxContacts.length);

            this.spinnerSvc.setMessage(connectMessages.GET_CLOUDLINK_CONTACTS);
            await this.getCloudlinkContacts(account, contactsFilter);
            console.log('ResyncService.pbxSyncSite() number of cloudlinkContacts =>', this.cloudlinkContacts.length);

            await this.contactsResyncHelper.syncContacts(account, this.pbxContacts, this.cloudlinkContacts);
            this.spinnerSvc.setMessage(connectMessages.PBX_RESYNC);
        } catch (error) {
            if (error instanceof PatchRequestError) {
                this.failures += error.failureCount;
            } else {
                throw error;
            }
        }
    }

    private async getCloudlinkUsers(account, odata?: Odata) {
        await this.adminService.getUsersFromAccount(account.accountId, odata)
            .then(async u => {
                const cloudlinkUsers = Utils.getItemsFromCollection<User>(u);
                const nextUsers = Utils.getOdataNext(u);

                if (cloudlinkUsers && cloudlinkUsers.length > 0) {
                    this.cloudlinkUsers = this.cloudlinkUsers.concat(cloudlinkUsers);
                }
                if (nextUsers && nextUsers.$SkipToken) { //recursively call this method until there are no more cloudlinkUsers to get
                    await this.getCloudlinkUsers(account, nextUsers);
                }
            }, reason => {
                this.handleError(reason);
                console.error('failed to get cloudlink users: ', reason);
                throw reason;
            })
            .catch((error) => {
                console.error('failed to get cloudlink users: ', error);
                throw error;
            });

    }

    private async getPbxUsers(account, siteId, pbxId, odata?: Odata) {
        await this.tunnelSvc.getPbxUsers(account.accountId, siteId, pbxId, odata)
            .then(async u => {
                const pbxUsers = Utils.getItemsFromCollection<PbxUser>(u);
                const nextUsers = Utils.getOdataNext(u);

                if (pbxUsers && pbxUsers.length > 0) {
                    this.pbxUsers = this.pbxUsers.concat(pbxUsers);
                }
                if (nextUsers && nextUsers.$Skip) { //recursively call this method until there are no more pbxUsers to get
                    await this.getPbxUsers(account, siteId, pbxId, nextUsers);
                }
            }, reason => {
                this.handleError(reason);
                console.error('failed to get PBX users', reason);
                throw reason;
            })
            .catch((error) => {
                console.error('failed to get PBX users', error);
                throw error;
            });
    }

    private async getPbxHuntgroups(account, siteId, pbxId, odata?: Odata) {
        await this.tunnelSvc.getPbxHuntgroups(account.accountId, siteId, pbxId, odata).then(async hg => {
            const pbxHuntGroups = Utils.getItemsFromCollection(hg);
            const nextHuntGroups = Utils.getOdataNext(hg);
            if (pbxHuntGroups && pbxHuntGroups.length > 0) {
                this.pbxHuntGroups = this.pbxHuntGroups.concat(pbxHuntGroups);
            }
            if (nextHuntGroups && nextHuntGroups.$Skip) { //recursively call this method until there are no more pbxHuntGroups to get
                await this.getPbxHuntgroups(account, siteId, pbxId, nextHuntGroups);
            }
        }, reason => {
            this.handleError(reason);
            console.error('failed to get PBX hunt groups', reason);
            throw reason;
        }).catch((error) => {
            console.error('failed to get PBX hunt groups', error);
            throw error;
        });
    }

    private async getPbxPhantoms(account, siteId, pbxId, odata?: Odata) {
        await this.tunnelSvc.getPbxPhantoms(account.accountId, siteId, pbxId, odata).then(async p => {
            const phantoms = Utils.getItemsFromCollection(p);
            const nextPhantoms = Utils.getOdataNext(p);
            if (phantoms && phantoms.length > 0) {
                this.pbxPhantoms = this.pbxPhantoms.concat(phantoms);
            }
            if (nextPhantoms && nextPhantoms.$Skip) { //recursively call this method until there are no more pbxPhantoms to get
                await this.getPbxPhantoms(account, siteId, pbxId, nextPhantoms);
            }
        }, reason => {
            this.handleError(reason);
            console.error('failed to get PBX phantoms', reason);
            throw reason;
        }).catch((error) => {
            console.error('failed to get PBX phantoms', error);
            throw error;
        });
    }

    private async getCloudlinkContacts(account, odata?: Odata) {
        await this.adminService.getContactsByAccountId(account.accountId, odata)
            .then(async c => {
                const cloudlinkContacts = Utils.getItemsFromCollection<Contact>(c);
                const nextContacts = Utils.getOdataNext(c);

                if (cloudlinkContacts && cloudlinkContacts.length > 0) {
                    this.cloudlinkContacts = this.cloudlinkContacts.concat(cloudlinkContacts);
                }
                if (nextContacts && nextContacts.$SkipToken) { //recursively call this method until there are no more cloudlinkContacts to get
                    await this.getCloudlinkContacts(account, nextContacts);
                }
            }, reason => {
                this.handleError(reason);
                console.error('failed to get cloudlink contacts: ', reason);
                throw reason;
            })
            .catch((error) => {
                console.error('failed to get cloudlink contacts: ', error);
                throw error;
            });

    }

    async getPbxContacts(account, siteId, pbxId, odata?: Odata) {
        await this.tunnelSvc.getPbxContacts(account.accountId, siteId, pbxId, odata)
            .then(async c => {
                const pbxContacts = Utils.getItemsFromCollection<PbxContact>(c);
                const nextContacts = Utils.getOdataNext(c);

                if (pbxContacts && pbxContacts.length > 0) {
                    this.pbxContacts = this.pbxContacts.concat(pbxContacts);
                }
                if (nextContacts && nextContacts.$Skip) { //recursively call this method until there are no more pbxContact to get
                    await this.getPbxContacts(account, siteId, pbxId, nextContacts);
                }
            }, reason => {
                this.handleError(reason);
                console.error('failed to get PBX contacts', reason);
                throw reason;
            })
            .catch((error) => {
                console.error('failed to get PBX contacts', error);
                throw error;
            });
    }

    private handleError(reason) {
        if (reason && reason.statusCode === 401) {
            this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
            this.formDataSvc.redirectToDashboardOrLogout();
        }
    }

    private resetResyncInfo() {
        this.pbxUsers = [];
        this.cloudlinkUsers = [];
        this.pbxHuntGroups = [];
        this.pbxPhantoms = [];
        this.failures = 0;
        this.groupsResyncHelper.resetGroups();
        this.pbxContacts = [];
        this.cloudlinkContacts = [];
    }
}