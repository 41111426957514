import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FetchInterceptorService } from "./services/fetch-interceptor.service";
import { UserClaims, Account, User } from '@mitel/cloudlink-sdk';
import { AppService } from './services/app.service';
import { FormDataService } from './services/form-data.service';
import { environment } from '../environments/environment';
import { ClHeaderComponent, CompanySwitcherService } from '@mitel/cloudlink-console-components';
import { AuthenticationService } from './services/authentication.service';
import { ParentCommsService } from './services/parent-comms.service';
import { NavigationService } from './services/navigation.service';
import { SpinnerService } from './services/spinner.service';
import { AnalyticsService } from './services/analytics.service';
import { FeatureAvailabilityService } from './services/feature-availability.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'app';
  company: Account;
  claims: UserClaims;
  urlParams:any = {};
  environmentA = environment;
  cloud: string = environment.cloud || '';
  user: User;
  appDirtySubscription: Subscription = new Subscription();

  constructor(
    private formDataSvc: FormDataService,
    private translateSvc: TranslateService,
    private fetchInterceptor: FetchInterceptorService,
    private appSvc: AppService,
    private clHeader: ClHeaderComponent,
    private authSvc: AuthenticationService,
    private parentCommsService: ParentCommsService,
    private navigationService: NavigationService,
    private spinnerSvc: SpinnerService,
    private analyticsSvc: AnalyticsService,
    private companySwitcherSvc: CompanySwitcherService,
    private featureAvailabilityService: FeatureAvailabilityService
  ) {}

  @HostListener('window:beforeunload')
  beforeunload() {
    // An unload event is fired when browser window closes or a new page load is requested.
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/unload_event

    if (this.parentCommsService.isInsideIframe()) {
      // parent is unloading e.g close the browser tab
      console.log('parent is unloading');
      this.clHeader.logoutOnly();
    }
  }

  @HostListener('window:message', ['$event'])
	onMessage(event) {
    console.log("GWP WAS SENT A MESSAGE: ", event);
    if (event && event.data && event.data.type == "CL_LANGCHANGE" && event.data.data) {
      this.setLanguage(event.data.data);
    } else if (event && event.data === 'CL_NOTIFYANDEXIT') {
      /*
      Need to check if there are unsaved changes on any forms.
      If so, show a pop up to ask user to confirm they want to exit the GWP without saving.
      If there are no changes to save, just tell the parent to exit the GWP.
      */
      this.formDataSvc.notifyforIframeExit();
    } else if (this.parentCommsService.isInsideIframe())
      if(event && event.data == "CL_UNLOAD") {
        // To prepare for the next time the app loads, it is important to make sure there are no tokens left in local storage from this user
        this.clHeader.logoutOnly();
      }else if(event && event.data && event.data.type == "CL_FEATURE_FLAGS"){
        //Received feature flags from parent
        this.featureAvailabilityService.initFeatureFlagsFromParent(event.data.flags);
      }
  }

  ngOnInit(): void {
    console.log("GWP is initializing");
    this.clHeader.setClientId(environment.clientId);
    this.fetchInterceptor.initInterceptor();

    this.translateSvc.addLangs(['en', 'en-GB', 'fr', 'de', 'es', 'it', 'nl', 'pt']);
    this.translateSvc.setDefaultLang('en');
    let browserLang = this.translateSvc.getBrowserLang();
    if(browserLang == 'en'){
			browserLang = this.translateSvc.getBrowserCultureLang();
		}
    console.log('TS browser lang: ', browserLang);
    this.setLanguage(browserLang);

    if(this.parentCommsService.isInsideIframe()){
      this.appDirtySubscription = this.formDataSvc.appDirtyStateChanged.subscribe(state => {
        console.log("GWP: Alerting parent of change in dirty state: ", state);
        this.parentCommsService.tellParentIfUnsavedChangesExist(state);
      });
      this.parentCommsService.tellParentToSendFeatureFlags();
    }
  }

  handleInvokingUrl( url: string )
	{
    if(this.claims){
      this.getUrlParams(this.clHeader.getInvokedParams());
      this.setTunnelParamsInLocalStorage();
      sessionStorage.clear();

      if(this.urlParams.lang && this.urlParams.lang !=="") {
        this.setLanguage(this.urlParams.lang);
      }

      if(this.urlParams.accountid && this.urlParams.accountid !=="" && this.parentCommsService.isInsideIframe()){
        if(this.urlParams.iframedsupport){
          this.parentCommsService.isIframedSupportPage = true;
          document.body.style.backgroundColor = '#F5F5F6'; //match style with accounts app
        }

        /*
        If iframed - we are coming from the Account edit page/Gateway Logs of the Accounts apps.
          Account edit page -> The user already knows the account details
            So take them to the most relevant page in the Gateway Portal - determined by tags/errors/onboarding
            This is similar to when a Partner_Admin clicks the status of an account (on the Partner Dashboard page) and is taken
            to the relevant page.
          Gateway Support -> display the relevant data from the Advanced page
        */
        this.spinnerSvc.setMessage('loading');
        this.spinnerSvc.show();
        this.navigationService.getTagsandNavigate(this.urlParams.accountid);
      }
    }
	}

  setLanguage(lang: string){
    this.translateSvc.use(lang.match(/en|en-GB|fr|de|es|it|nl|pt/) ? lang : 'en');
  }

  getUrlParams(params) {
    this.urlParams = params;
    if(params.state)
    {
      this.urlParams =  JSON.parse(decodeURIComponent(params.state));
    }
    console.log('Url Parameters', this.urlParams);
  }

  setTunnelParamsInLocalStorage() {
    if (this.urlParams) {
      if (this.urlParams.tunnel) {
        let tunnelId = decodeURIComponent(this.urlParams.tunnel);
        const index = tunnelId.indexOf('https://');
        if (index === 0) {
          tunnelId = tunnelId.substring(8);
        }
        localStorage.setItem('tunnel', tunnelId);
      }

      if (this.urlParams.username) {
        localStorage.setItem('username', this.urlParams.username);
      }

      if (this.urlParams.password) {
        localStorage.setItem('password', this.urlParams.password);
      }
    }
  }

  handleUserClaims(claims: UserClaims) {
    this.claims = claims;
    this.appSvc.setClaims(claims);
    this.analyticsSvc.initClient();
    this.companySwitcherSvc.setCurrentAccountById(claims.accountId);
  }

  onCompanyUpdated(company: Account) {
    this.company = company;
    this.appSvc.setCompany(company);
  }

  async goToDashboard() {
    const partnerClaims = await this.authSvc.getPartnerClaims();
    // only go to dashboard if their account came through partner
    if (partnerClaims) {
      this.formDataSvc.redirectToDashboard();
    }
  }

  ngOnDestroy() {
    this.fetchInterceptor.deinitInterceptor();
    if(this.appDirtySubscription)
    {
      this.appDirtySubscription.unsubscribe();
    }
  }
}
