// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 4px;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border: 1px solid #BFBFC0;
  height: auto;
}

:host.tag-input-focus {
	border: 1px solid #00A1E0;
}

.tag-input-field {
  box-shadow: none;
  border: 0;
}

:host.item-invalid {
  border: 1px solid red;
}

span {
  font-family: MuseoSans-300;
  font-size: 13px;
  color: #ED1C24;
  margin-left: 4px;
}

input {
	height: 20px;
  width: 250px;
	padding-left: 4px;
	margin-bottom: 0px;
}

input:focus {
	outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/tag-input/tag-input.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,0BAA0B;EAC1B,yBAAyB;EACzB,YAAY;AACd;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;CACC,YAAY;EACX,YAAY;CACb,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,aAAa;AACd","sourcesContent":[":host {\n  display: block;\n  padding: 4px;\n  border-radius: 0px;\n  -moz-border-radius: 0px;\n  -webkit-border-radius: 0px;\n  border: 1px solid #BFBFC0;\n  height: auto;\n}\n\n:host.tag-input-focus {\n\tborder: 1px solid #00A1E0;\n}\n\n.tag-input-field {\n  box-shadow: none;\n  border: 0;\n}\n\n:host.item-invalid {\n  border: 1px solid red;\n}\n\nspan {\n  font-family: MuseoSans-300;\n  font-size: 13px;\n  color: #ED1C24;\n  margin-left: 4px;\n}\n\ninput {\n\theight: 20px;\n  width: 250px;\n\tpadding-left: 4px;\n\tmargin-bottom: 0px;\n}\n\ninput:focus {\n\toutline: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
