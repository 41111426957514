import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-gateway-network-configuration',
  templateUrl: './gateway-network-configuration.component.html',
  styleUrls: ['./gateway-network-configuration.component.css']
})
export class GatewayNetworkConfigurationComponent implements OnInit {
  @Input() existingNetworkConfig;
  @Input() currentNetworkConfig;
  @Input() selectableNetworkConfig: boolean = false;
	existingNetworkConfigSelected: boolean;
  currentNetworkConfigSelected: boolean;
  selectedNetworkConfig;
  isDisabled:boolean;
  existingNetworkConfigDns;
  currentNetworkConfigDns;
  canClose = false;

  constructor(
    private activeModal: NgbActiveModal,
    public translateSvc: TranslateService
    ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.canClose = true;
    }, 2000);
    if(this.selectableNetworkConfig) {
      this.isDisabled = false;
      this.existingNetworkConfigSelected = false;
      this.currentNetworkConfigSelected = false;
    }
    else {
      this.isDisabled = true;
      this.existingNetworkConfigSelected = false;
      this.currentNetworkConfigSelected = true;
      this.selectedNetworkConfig = this.currentNetworkConfig;
    }
    if(this.existingNetworkConfig && this.existingNetworkConfig.dns)
      this.existingNetworkConfigDns = this.existingNetworkConfig.dns.replace(/,/g, ', ');
    if(this.currentNetworkConfig && this.currentNetworkConfig.dns)
      this.currentNetworkConfigDns = this.currentNetworkConfig.dns.replace(/,/g, ', ');
  }



  onClose(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    
    if (this.canClose) {
      setTimeout(() => {
        this.activeModal.close();
      }, 200);
     
    }
  }

  onSelect(event: Event, config){
    event.preventDefault();
    event.stopPropagation();
    if(config == this.existingNetworkConfig) {
      this.existingNetworkConfigSelected = true;
      this.currentNetworkConfigSelected = false;
      this.selectedNetworkConfig = this.existingNetworkConfig;
    }
    else {
      this.currentNetworkConfigSelected = true;
      this.existingNetworkConfigSelected = false;
      this.selectedNetworkConfig = this.currentNetworkConfig;
    }
  }

  onContinue(event: Event){
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => { 
      this.activeModal.close(this.selectedNetworkConfig);
    }, 300);
  }

}
