import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../services/account.service';
import { AdminService } from '../../services/admin.service';
import { Admin2Service } from '../../services/admin2.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CloudResourcesService } from '../../services/cloudResources.service';
import { EchoService } from '../../services/echo.service';
import { ResyncService } from '../../services/resync/';
import { SpinnerService } from '../../services/spinner.service';
import { AlertService } from '../../services/alert.service';
import { TooltipService } from '../../services/tooltip.service';
import { AutoUpdateScheduleService } from '../../services/auto-update-schedule.service';
import { GatewayLinkConfirmationComponent } from '../gateway-link-confirmation/gateway-link-confirmation.component';
import { ConnectErrorComponent } from '../connect-error/connect-error.component';
import { environment } from '../../../environments/environment';
import { Utils } from '@mitel/cloudlink-sdk';
import {
  Pbxlink, PostPbxlinkRequest,
  PostFreeswitchRequest, PutNetworkRequest, PbxSyncStatus, PutCloudlinkRequest, Ipv4Config
} from '@mitel/cloudlink-sdk/tunnel';
import { TunnelService } from '../../services/tunnel.service';
import { Account, Site, Client } from '@mitel/cloudlink-sdk/admin';
import {
  toastMsgTypes,
  siteMessages,
  commonMessages,
  commonErrorMessages,
  gatewayConnectStateMessages,
  pbxMessages,
  connectMessages,
  resyncMessages,
  linkingSteps,
  gatewayConnectState,
  pbxStatus,
  pbxTypes } from '../../shared/constants';
import * as _ from 'lodash';
import * as jstz from 'jstz';
import * as moment from 'moment-timezone';
import { UnexpectedModalComponent } from "../unexpected-modal/unexpected-modal.component";
import { v4 as uuid } from 'uuid';
import { CloudTrunking } from '../../shared/admin2/cloudTrunking';
import { CloudPbx } from '../../shared/admin2/cloudPbx';
import { CloudAppliance } from '../../shared/admin2/cloudAppliance';
import { ParentCommsService } from '../../services/parent-comms.service';
import { GatewayNetworkConfigurationComponent } from '../gateway-network-configuration/gateway-network-configuration.component';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-gateway-connection',
  templateUrl: './gateway-connection.component.html',
  styleUrls: ['./gateway-connection.component.css']
})
export class GatewayConnectionComponent implements OnInit, OnDestroy {
	account: Account;
	accountSubscription: Subscription;
  site: Site;
  siteSubscription: Subscription;
  pbxLink: Pbxlink;
  pbxSubscription: Subscription;
	accountTags = {};
  accountTagsSubscription: Subscription;
	gatewayConnectState = gatewayConnectState;
	state: string;
  existingNetworkConfig;
  currentNetworkConfig;
  selectedNetworkConfig;
  currentConfig;

  retrying = false;
  retryFailed = false;

  cloudTrunking: CloudTrunking;
  cloudPbx: CloudPbx;
  cloudAppliance: CloudAppliance;

  interval: any;
  timeout: any;
  ECONNREFUSED = 'ECONNREFUSED';
  ERR_CONNECT_MEDIA = 'connection failed to cloud proxy';

  linkingFailed = false;
  linkingToStep: string;
  firstCheckBoxSelected = false;
  secondCheckBoxSelected = false;
  tunnelParametersExists = false;

  platform: any;

  constructor(private accountSvc: AccountService,
              private adminSvc: AdminService,
              private admin2Svc: Admin2Service,
              private authSvc: AuthenticationService,
  						private modalSvc: NgbModal,
              private echoSvc: EchoService,
              private cloudResourcesSvc: CloudResourcesService,
              private resyncService: ResyncService,
              private spinnerSvc: SpinnerService,
              private alertSvc: AlertService,
              private tunnelSvc: TunnelService,
              private tooltipSvc: TooltipService,
              private router: Router,
              private translateSvc: TranslateService,
              private autoUpdateSvc: AutoUpdateScheduleService,
              private parentCommsService: ParentCommsService,
              private syncSvc: SyncService) { }

  ngOnInit() {
    if(this.parentCommsService.launchedFromSystemInventory){
      return;
    }
  	this.accountSubscription = this.accountSvc.accountChanged
  									.subscribe(account => this.account = account);
  	this.siteSubscription = this.accountSvc.siteChanged
  													.subscribe(site => this.site = site);
    this.pbxSubscription = this.accountSvc.pbxChanged
                              .subscribe(pbx => this.pbxLink = pbx);
  	this.accountTagsSubscription = this.accountSvc.accountTagsChanged
                                    .subscribe(accountTags => {
                                        this.accountTags = accountTags;
                                        this.setState();
                                    });
    this.account = this.accountSvc.getAccount();
    this.site = this.accountSvc.getSite();
    this.pbxLink = this.accountSvc.getPbxlink();
    this.accountTags = this.accountSvc.getAccountTags();
    this.setState();
    this.getCurrentNetworkPlatformSettings();
    this.getCloudAppliance();
  }

  setState() {
  	if (this.accountTags && this.accountTags['gateway-connection']) {
  		const gatewayConnected = this.accountTags['gateway-connection'].connected;
  		if (gatewayConnected) {
        if (this.linkingFailed) {
          this.state = gatewayConnectState.LINK_FAIL;
        } else {
          this.state = gatewayConnectState.RETRY_SUCCESS;
        }
  		} else if (this.retryFailed) {
  			this.state = gatewayConnectState.RETRY_FAIL;
  		} else {
  			this.state = gatewayConnectState.CONNECT_FAIL;
  		}
  	}
    this.retryFailed = false;
  }

  retryGetEcho(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.account && this.site && this.accountTags) {
      this.retrying = true;
      this.echoSvc.getEcho(this.retrying).then(async success => {
        if (success) {
          console.log('working', success);
          this.retryFailed = false;
          await this.getPlatform();
        } else {
          this.spinnerSvc.hide();
          this.retrying = false;
          this.retryFailed = true;
        }
      });
    }
  }

  async getPlatform() {
    try {
      this.platform = await this.tunnelSvc.getPlatform();
      this.accountSvc.setPlatform(this.platform);
    } catch (reason) {
      if (typeof reason === 'string') {
        console.error('failed to get platform', reason);
      } else if (reason instanceof Error) {
        console.error('failed to get platform', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.log('failed to get platform', JSON.stringify(reason));
      }
    }
  }

  async getEcho() {
    this.spinnerSvc.setMessage(gatewayConnectStateMessages.CHECKING_CONNECT);

    try {
      const result = await this.tunnelSvc.getEcho();
      if (result.data === 'ECHO..echo..echo') {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        sessionStorage.setItem('gateway-connection', JSON.stringify(true));
        await this.updateAccountTagsWithGatewayConnection(true);
        await this.updateAccountTagsWithPbxStatus(pbxStatus.UNKNOWN);
        await this.getPlatform();
        if (!this.platform || (this.platform.capabilities && this.platform.capabilities.change_network_settings)) {
          // Update Network Settings
          this.updateNetworkSettings();
        } else {
          await this.setCloudAppliance();
          await this.setPbxlink();
          await this.setUpgradeSchedule(); 
         
          if(this.accountTags && this.accountTags['pbx-status'] && this.accountTags['pbx-status']['status'] === pbxStatus.MISSING) {
            this.alertSvc.setAlert(toastMsgTypes.INFO, gatewayConnectStateMessages.ERR_PBX_MISSING_LINKS);
            this.redirectToStep('pbx');
            this.spinnerSvc.hide();
          } else {
            this.startPbxSync();
          }
        }
      }
    } catch (reason) {
      if (typeof reason === 'string') {
        console.warn('echo failed', reason);
      } else if (reason && reason.statusCode === 401) {
        console.warn('echo failed', JSON.stringify(reason));
      } else if (typeof reason.text === 'function') {
        reason.text().then(res => {
          console.warn('echo failed', res);
        }).catch(err => {
          console.warn('echo failed', err);
        });
      } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
        console.error('echo failed', reason.message);
        this.authSvc.redirectToLogin();
      } else {
        console.warn('echo failed', JSON.stringify(reason));
      }
    }
  }

  async setCloudAppliance() {
      if (this.cloudAppliance &&
        this.cloudAppliance.network &&
        this.cloudAppliance.network.lan1 &&
        this.cloudAppliance.network.lan1.ipv4 &&
        _.isEqual(this.selectedNetworkConfig,this.cloudAppliance.network.lan1.ipv4)) {
        this.cloudAppliance = this.cloudAppliance;
      }
      else{
        try {
          this.accountSvc.setNetworkConfig(this.currentConfig);
          await this.cloudResourcesSvc.setCloudAppliance();
            //fetching updated cloud appliance
            await this.getCloudAppliance();
        } catch (error) {
          console.warn('cloudAppliance not updated', error);
          this.cloudAppliance = undefined;
        }
      }
  }

  async getCloudAppliance() {
    try {
      // Get cloud appliance
      const response = await this.admin2Svc.getCloudAppliances(this.account.accountId);
      if (response && response.cloudAppliances) {
        this.cloudAppliance = response.cloudAppliances[0];
      }
      else{
        this.cloudAppliance = undefined;
      }
      console.log('cloudAppliance', this.cloudAppliance);
    } catch (error) {
      console.warn('cloudAppliance not found', error);
      this.cloudAppliance = undefined;
    }
  }

  getCurrentNetworkPlatformSettings(){
    console.log("entered in initialization", this.currentNetworkConfig);
      const tunnel = localStorage.getItem('tunnel');
      const username = localStorage.getItem('username');
      const password = localStorage.getItem('password');
      // Check the values for tunnel, username, password
      if (tunnel && username && password) {
        // Get initial network settings
        this.tunnelSvc.getInitialNetworkSettings(tunnel, username, password)
          .then(response => {
            if(response && response.result && response.result.lan1 && response.result.lan1.ipv4){
              this.currentNetworkConfig = response.result.lan1.ipv4;
              this.currentConfig = response.result;
              this.accountSvc.setNetworkConfig(this.currentConfig);
              console.log("network details", response.result);
            }
          }, reason => {
            console.error('error getting initial network settings', reason);
          }).catch(err => {
            console.error('error getting initial network settings', err);
          });

          this.tunnelSvc.getInitialPlatform(tunnel, username, password)
            .then(response => {
              this.platform = response;
              this.accountSvc.setPlatform(this.platform);
            }, reason => {
              console.error('error getting initial platform', reason);
            }).catch(err => {
              console.error('error getting initial platform', err);
            });
      }
  }

  async setPbxlink() {
    try {
      this.pbxLink = await this.tunnelSvc.getFirstPbxlink();
      if(this.pbxLink){
        this.accountSvc.setPbxlink(this.pbxLink);
      } else {
        const tagParams = { 
          'on-board-progress': { 'step': 3, 'name': 'pbx', 'succeeded': false, reason: pbxMessages.MISSING_PBX_LINK },
          'pbx-status': { status: pbxStatus.MISSING }
         };
        await this.updateAccountTagWithTagParams(tagParams);
      }
    } catch (error) {
      console.warn('pbxlink not found', error);
    }
  }

  selectableNetworkConfiguration(existingConfig, currentConfig){
    if(this.platform && this.platform.capabilities && !this.platform.capabilities.change_network_settings){
      console.log("change_network_settings is ", this.platform.capabilities.change_network_settings);
      return false;
    }
    else if(!existingConfig)
      return false;
    else if(existingConfig.mode !== currentConfig.mode)
      return true;
    else if(existingConfig.mode === <Ipv4Config.ModeEnum>'static' &&
            existingConfig.address !== currentConfig.address)
      return true;
    else
      return false;
  }

  async openNetworkConfig(event) {
    event.preventDefault();
    event.stopPropagation();

    if(this.cloudAppliance && this.cloudAppliance.network && this.cloudAppliance.network.lan1 && this.cloudAppliance.network.lan1.ipv4){
      this.existingNetworkConfig = this.cloudAppliance.network.lan1.ipv4;
      console.log("appliance details", this.cloudAppliance);
    }

    //network configuration dialog doesn't open if currentNetworkConfig is missing
    if(!this.currentNetworkConfig){
      this.alertSvc.setAlert(toastMsgTypes.ERROR, gatewayConnectStateMessages.ERR_RELINK);
      return;
    }

    console.log("current network details", this.currentNetworkConfig);
    let selectableNetworkConfig = this.selectableNetworkConfiguration(this.existingNetworkConfig, this.currentNetworkConfig);
    const modalRef = this.modalSvc.open(GatewayNetworkConfigurationComponent);
  	const contentComponentInstance = modalRef.componentInstance;
  	contentComponentInstance.existingNetworkConfig = this.existingNetworkConfig;
    contentComponentInstance.currentNetworkConfig = this.currentNetworkConfig;
    contentComponentInstance.selectableNetworkConfig = selectableNetworkConfig;
    modalRef.result.then((networkConfig) => {
      console.log("response data", networkConfig);
      this.selectedNetworkConfig = networkConfig;
      if(this.selectedNetworkConfig) {
        setTimeout(() => {
          this.linkToGateway();
        }, 100);
      }
    }, (reason) => {
      // on dismiss
    });
  }

  linkToGateway() {
  	const modalRef = this.modalSvc.open(GatewayLinkConfirmationComponent);

  	const contentComponentInstance = modalRef.componentInstance;
  	contentComponentInstance.accountName = this.account ? this.account.name : null;
  	contentComponentInstance.link.subscribe(async () => {
  		contentComponentInstance.linking = true;
  		modalRef.close();
      // Link to a new gateway
      this.account = this.accountSvc.getAccount();
      this.site = this.accountSvc.getSite();
      // Get Client info
      this.spinnerSvc.show();
      this.spinnerSvc.setMessage(siteMessages.CHECK_TUNNEL);
      try {
        // regenerate client for this site
        const newClient = await this.createNewClient();
        console.log('created new client');
        const cloudParams = this.getCloudParams(this.site, newClient);
        this.createCloudTunnel(cloudParams);
      } catch (reason) {
        this.spinnerSvc.hide();
        this.openConnectError();
        if (reason && reason.statusCode === 401) {
          console.error('failed to get client', reason);
          this.authSvc.redirectToLogin();
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('failed to get client', res);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_CLIENT);
          }).catch(err => console.error('failed to get client', err));
        } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('failed to get client', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_CLIENT);
          console.error('failed to get client', reason);
        }
      }
  	});
  }

  infoIconItem(labelName: string): any {
    return this.tooltipSvc.getTooltipItem(labelName);
  }

  async createCloudTunnel(cloudParams: PutCloudlinkRequest) {
    // Create tunnel
    this.spinnerSvc.setMessage(siteMessages.CREATE_TUNNEL);
    const tunnel = localStorage.getItem('tunnel');
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    if (tunnel && username && password) {
      try {
        await this.tunnelSvc.updateCloudTunnel(tunnel, username, password, cloudParams);
        console.log('successfully sent cloud parameters to create new tunnel');
        this.removeTunnelInfoFromStorage();
        // Update account tag tunnel-creds
        const tagParams = { 'tunnel-creds': true };
        const tags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
        this.accountSvc.setAccountTags(tags);
        // Check gateway connection
        this.interval = setInterval(() => {
          this.getEcho();
        }, 5000); // Check gateway connection every 5 seconds
        this.timeout = setTimeout(() => {
          clearInterval(this.interval);
          this.spinnerSvc.hide();
          this.alertSvc.setAlert(toastMsgTypes.ERROR, gatewayConnectStateMessages.ERR_CONNECT_NEW_GATEWAY);
        }, 60000); // Stop after 1 min
      } catch (reason) {
        this.spinnerSvc.hide();
        this.openConnectError();
        if (reason && reason.statusCode === 401) {
          console.error('failed to create tunnel', reason);
          this.authSvc.redirectToLogin();
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('failed to create tunnel', res);
          });
        } else if (reason instanceof Error) {
          console.error('failed to create tunnel', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to create tunnel', reason);
        }
        this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_TUNN_TOAST);
      }
    } else {
      this.spinnerSvc.hide();
      this.openConnectError();
      this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_TUNN);
    }
  }

  getCloudParams(site: any, client?: any): PutCloudlinkRequest {
    const object: PutCloudlinkRequest = {
      siteId: site.siteId,
      clientId: client ? client.clientId : undefined,
      clientSecret: client ? client.clientSecret : undefined,
      accountId: site.accountId,
      enabled: true,
      tunnel_enabled: true
    };

    if (environment.cloud === 'dev') {
      object.deploymentType = 'dev';
    }

    return object;
  }

  removeTunnelInfoFromStorage() {
    localStorage.removeItem('tunnel');
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    this.parentCommsService.tunnelUsed = true;
  }

  openConnectError() {
    setTimeout(() => this.modalSvc.open(ConnectErrorComponent));
  }

  async updateNetworkSettings() {
    this.linkingToStep = linkingSteps.STEP_2;
    this.spinnerSvc.show(); // reset spinnerSince in spinnerSvc
    this.spinnerSvc.setMessage(siteMessages.UPDATE_NETWORK);

    try {
      // Get cloud appliance
      await this.setCloudAppliance();
        console.log("this is the updated appliance", this.cloudAppliance);
        if (this.cloudAppliance && this.cloudAppliance['network']) {
          // Update network config
          const networkParams = this.getNetworkSettingsOptions();
          await this.tunnelSvc.updateNetworkSettings(this.account.accountId, this.site.siteId, networkParams);
          this.accountSvc.setNetworkConfig(<any>networkParams);
          const interval = setInterval(async () => {
            if (this.spinnerSvc.since() > 60000) {
              clearInterval(interval);
              this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_SET_NETWORK_TIMEOUT);
              this.updateAccountTag(false, 2, 'site', siteMessages.ERR_SET_NETWORK_TIMEOUT);
              console.error('timed out waiting for gateway to update network settings');
            }
            console.log('get echo...');
            try {
              const response = await this.tunnelSvc.getEcho();
              if (response.data === 'ECHO..echo..echo') {
                clearInterval(interval);
                this.setUpgradeSchedule();
                setTimeout(() => {
                  // Create PBX
                  this.createPbxlink();
                }, 20000); // Add 20 seconds delay after network setting changes
              } else {
                console.warn('unexpected response', response);
              }
            } catch (error) {
              console.log('echo failed, will try again...', error);
            }
          }, 10000); // Call echo every 10 sec
        } else {
          this.updateAccountTag(true, 2, 'site');
        }
    } catch (reason) {
       if (reason && reason.statusCode === 401) {
        console.error('failed to update network settings', reason);
        this.authSvc.redirectToLogin();
      } else if (typeof reason.text === 'function') {
        reason.text().then(res => {
          console.error('failed to update network settings', res);
        });
      } else if (reason instanceof Error) {
        console.error('failed to update network settings', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to update network settings', reason);
      }
      this.alertSvc.setAlert(toastMsgTypes.ERROR, siteMessages.ERR_SET_NETWORK);
      this.updateAccountTag(false, 2, 'site', siteMessages.ERR_SET_NETWORK);
    }
  }

  getNetworkSettingsOptions(): PutNetworkRequest {
    const defaultLan2ipv4Obj: Ipv4Config = {
      mode: <Ipv4Config.ModeEnum>'dhcp'
    };

    const options: PutNetworkRequest = {
      lan1: this.cloudAppliance['network']['lan1'],
      lan2: this.cloudAppliance['network']['lan2'] ?
        this.cloudAppliance['network']['lan2'] : { ipv4: defaultLan2ipv4Obj }
    };

    return options;
  }

  async createPbxlink() {
    this.linkingToStep = linkingSteps.STEP_3;
    this.spinnerSvc.show();
    this.spinnerSvc.setMessage(pbxMessages.CONFIG_PBX);

    try {
      // Get cloud pbx
      const response = await this.admin2Svc.getCloudPbxs(this.account.accountId);
      if (response && response.cloudPbxs) {
        this.cloudPbx = response.cloudPbxs[0];
      }
      console.log('cloudPbx', this.cloudPbx);

      if (this.cloudPbx && this.cloudPbx.pbx) {
        // Create Pbx
        const pbxParams = this.getPbxParams();
        const pbxResponse = await this.tunnelSvc.createPbxlink(this.account.accountId, this.site.siteId, pbxParams);
        this.accountSvc.setPbxlink(pbxResponse);
        this.updateAccountTagsWithPbxStatus(pbxStatus.UP);
        this.configureFreeswitch();
      } else {
        this.updateAccountTag(true, 2, 'site');
      }
    } catch (reason) {
      this.accountSvc.setPbxlink(null);
      if (reason && reason.statusCode === 401) {
        this.spinnerSvc.hide();
        console.error('failed to create PBX link', reason);
        this.authSvc.redirectToLogin();
      } else if (typeof reason.text === 'function') {
        reason.text().then(res => {
          console.error('failed to create PBX link', res);
          let errorMsg: string;
          let alertPrefix;
          if (reason.status === 500) {
            errorMsg = pbxMessages.ERR_UPDATE_SERVER;
            alertPrefix = pbxMessages.ERR_CREATE_PBX;
          } else if (reason.status === 504) {
            errorMsg = pbxMessages.ERR_UPDATE_TIMEOUT;
            alertPrefix = pbxMessages.ERR_CREATE_PBX;
          } else {
            errorMsg = JSON.parse(res).message;
          }
          this.alertSvc.setAlert(toastMsgTypes.ERROR, errorMsg, undefined, alertPrefix);
          this.updateAccountTag(false, 3, 'pbx', errorMsg);
        });
      } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
        this.spinnerSvc.hide();
        console.error('failed to create PBX link', reason.message);
        this.authSvc.redirectToLogin();
      } else {
        console.error('failed to create PBX link', reason);
        let errorMsg: string;
        let alertPrefix;
        if (reason && reason.statusCode) {
          if (reason.statusCode === 500) {
            errorMsg = pbxMessages.ERR_UPDATE_SERVER;
            alertPrefix = pbxMessages.ERR_CREATE_PBX;
          } else if (reason.statusCode === 504) {
            errorMsg = pbxMessages.ERR_UPDATE_TIMEOUT;
            alertPrefix = pbxMessages.ERR_CREATE_PBX;
          }else if (reason.statusCode === 404) {
            this.spinnerSvc.hide();
            this.alertSvc.setAlert(toastMsgTypes.INFO, gatewayConnectStateMessages.SUCCESS_RELINK);
            this.redirectToStep('pbx');
          } else if (reason.body && reason.body.message) {
            errorMsg = reason.body.message;
          } else {
            errorMsg = pbxMessages.ERR_CREATE_PBX;
          }
        } else {
          errorMsg = pbxMessages.ERR_CREATE_PBX;
        }
        if(errorMsg) {
          this.alertSvc.setAlert(toastMsgTypes.ERROR, errorMsg, undefined, alertPrefix);
          this.updateAccountTag(false, 3, 'pbx', errorMsg);
        }
      }
    }
  }

  getPbxParams(): PostPbxlinkRequest {
    // convert type to pbxTypes
    const type = this.getPbxTypeFromCloudPbx(this.cloudPbx.type);
    const object: any = {
      name: this.cloudPbx.name,
      type: type,
      connection_ip_address: this.cloudPbx.pbx['connection_ip_address'],
      connection_port: this.cloudPbx.pbx['connection_port'],
      connection_username: this.cloudPbx.pbx['connection_username'],
      connection_password: this.cloudPbx.pbx['connection_password'],
      snapshot_support: this.cloudPbx.pbx['snapshot_support'],
      advanced_logging: this.cloudPbx.pbx['advanced_logging'],
      sip_trunk_group: this.cloudPbx.pbx['sip_trunk_group'],
      license_number: 0
    };

    return object;
  }

    // Convert PBX Type to be from Cloud Pbx
    private getPbxTypeFromCloudPbx(cloudType: string) {
      switch (cloudType) {
        case 'MiVO250':
          return pbxTypes.MIVO250;
        default:
          return cloudType;
      }
    }

  async configureFreeswitch() {
    this.linkingToStep = linkingSteps.STEP_4;
    this.spinnerSvc.show();
    this.spinnerSvc.setMessage(pbxMessages.CONFIG_FREESWITCH);

    try {
      // Get cloud trunking
      const response = await this.admin2Svc.getCloudTrunkings(this.account.accountId);
      if (response && response.cloudTrunkings) {
        this.cloudTrunking = response.cloudTrunkings[0];
      }

      if (this.cloudTrunking) {
        console.log('cloudTrunking', this.cloudTrunking);
        // Configure freeswitch
        this.sendFreeswitchConfigRequest();
      } else {
        this.updateAccountTag(true, 2, 'site');
      }
    } catch (reason) {
      let relinkStep = false;
      if (typeof reason.text === 'function') {
        reason.text().then(res => {
          console.error('failed to get cloud trunking', res);
        });
      } else if (reason instanceof Error) {
        console.error('failed to get cloud trunking', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else if (reason.statusCode === 404) {
          this.spinnerSvc.hide();
          relinkStep = true;
          this.alertSvc.setAlert(toastMsgTypes.INFO, gatewayConnectStateMessages.SUCCESS_RELINK);
          this.redirectToStep('pbx');
          return;
      } else {
        console.error('failed to get cloud trunking', reason);
      }
      if(!relinkStep){
        this.alertSvc.setAlert(toastMsgTypes.ERROR, pbxMessages.ERR_SIP_TRUNK_CONFIG_TOAST);
        this.updateAccountTag(false, 3, 'pbx', pbxMessages.ERR_SIP_TRUNK_CONFIG);
      }
    }
  }

  async sendFreeswitchConfigRequest(retry: boolean = false) {
    this.spinnerSvc.setMessage(pbxMessages.CONFIG_FREESWITCH);

    const freeswitchParams = this.getFreeswitchParams();
    try {
      await this.tunnelSvc.createFreeswitchConfig(this.account.accountId, this.site.siteId, freeswitchParams);
      if (retry) {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
      }
      console.log('configured freeswitch');
      setTimeout(async () => {
        if (this.cloudPbx && this.cloudPbx.type === 'MiVO250') {
          try {
            // tslint:disable-next-line:max-line-length
            const response = await this.tunnelSvc.updatePbxlinkLicense(this.account.accountId, this.site.siteId, this.pbxLink._id, this.pbxLink.sip_trunk_group, {
              license_number: '100'
            });
            if (response.rc) {
              console.error('updatePbxlinkLicense failed', response);
              throw pbxMessages.ERR_TRUNK_LICENSE;
            }
          } catch (error) {
            console.error('updatePbxlinkLicense failed', error);
            throw pbxMessages.ERR_TRUNK_LICENSE;
          }
        }
        this.connectPbx();
      }, 5000);
    } catch (rej) {
      let errorMsg = '';
      if (rej && rej.statusCode === 401) {
        console.error('failed to set freeswitch config', JSON.stringify(rej));
        this.authSvc.redirectToLogin();
      } else if (rej && rej.body) {
        console.error('failed to set freeswitch config', JSON.stringify(rej));
        errorMsg = rej.body.message;
        if (rej.status === 500 && errorMsg.indexOf(this.ECONNREFUSED) > -1) {
          if (!retry) {
            this.interval = setInterval(() => {
              this.sendFreeswitchConfigRequest(true);
            }, 5000); // retry every 5 seconds
            this.timeout = setTimeout(() => {
              clearInterval(this.interval);
              this.alertSvc.setAlert(toastMsgTypes.ERROR, errorMsg);
              this.updateAccountTag(false, 3, 'pbx', pbxMessages.ERR_SIP_TRUNK_CONFIG);
            }, 120000); // show error message if > 2mins
          }
          return;
        } else if (errorMsg.indexOf(this.ERR_CONNECT_MEDIA) > -1) {
          errorMsg = pbxMessages.ERR_CONNECT_MEDIA;
        } else {
          if(rej.body.name){
            errorMsg = 'createFreeswitchConfig.' + rej.body.name;
          }
          else{
            errorMsg = 'createFreeswitchConfig.TimeoutError';
          }
        }
      } else if (typeof rej === 'string') {
        console.error('failed to set freeswitch config', rej);
        errorMsg = rej;
      } else if (rej instanceof Error && rej.message === commonErrorMessages.AUTH_ERROR) {
        console.error('failed to set freeswitch config', rej.message);
        this.authSvc.redirectToLogin();
      } else {
        console.error('failed to set freeswitch config', rej);
        errorMsg = pbxMessages.ERR_SIP_TRUNK_CONFIG_TOAST;
      }
      if (retry) {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
      }
      this.alertSvc.setAlert(toastMsgTypes.ERROR, errorMsg);
      this.updateAccountTag(false, 3, 'pbx', pbxMessages.ERR_SIP_TRUNK_CONFIG);
    }
  }

  getFreeswitchParams(): PostFreeswitchRequest {
    const object = {
      pbx_trunk_username: this.cloudTrunking['pbx_trunk_username'],
      pbx_trunk_password: this.cloudTrunking['pbx_trunk_password']
    };

    return object;
  }

  async connectPbx() {
    this.linkingToStep = linkingSteps.STEP_5;
    this.spinnerSvc.show();
    this.spinnerSvc.setMessage(connectMessages.CONNECT_PBX);
    // Retrieve pbxLinks again to check connect_error
    try {
      this.pbxLink = await this.tunnelSvc.getFirstPbxlink();
      if(this.pbxLink){
        this.accountSvc.setPbxlink(this.pbxLink);
      }
    } catch (error) {
      console.error('cannot get pbx link', error);
      let errMsg = '';
      if (error.statusCode === 401) {
        this.authSvc.redirectToLogin();
      } else if (error.statusCode === 503) {
        errMsg = pbxMessages.CANNOT_CONNECT_PBX;
      } else if (error.statusCode === 504) {
        errMsg = pbxMessages.SYSTEM_DATA_TIMEOUT;
      } else if (typeof error === 'string') {
        errMsg = (error && error !== '') ? error : 'common.unknown';
      } else if (error instanceof Error) {
        if (error.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        } else if (error.message === commonErrorMessages.TYPE_ERROR) {
          errMsg = pbxMessages.ERR_GET_PBX;
        } else {
          errMsg = (error.message && error.message !== '') ?
                    error.message : 'common.unknown';
        }
      } else {
        errMsg = (error.body && error.body.message && error.body.message !== '') ?
          error.body.message : 'common.unknown';
      }
      this.alertSvc.setAlert(toastMsgTypes.ERROR, errMsg);
      this.updateAccountTagsWithPbxStatus(pbxStatus.DOWN, errMsg);
      this.spinnerSvc.hide();
      this.router.navigateByUrl(`/accounts/${this.account.accountId}/sites/${this.site.siteId}/connect`);
      return;
    }

    if (this.pbxLink && (this.pbxLink.state === 'init' ||
       (this.pbxLink['connect_error'] && this.pbxLink['connect_error'] !== ''))) {
      const statusMsg = this.echoSvc.getStatusMessageFromConnectError(this.pbxLink['connect_error']);
      this.updateAccountTagsWithGatewayConnection(true, false, statusMsg);
      this.updateAccountTag(false, 3, 'pbx', statusMsg);
      this.alertSvc.setAlert(toastMsgTypes.ERROR, statusMsg);
    } else {
      await this.startPbxSync();
    }
  }

  async startPbxSync() {
    console.log('start pbx sync');
    try {
      await this.tunnelSvc.startPbxSync(this.account.accountId, this.site.siteId).then((res)=>{
        console.log('Class: GatewayConnectionComponent, Function: res, : '
        , res);
      });
      setTimeout(() => {
        this.getPbxSyncStatus();
      }, 1000);
    } catch (reason) {
      if (typeof reason === 'string') {
        console.error('failed to start PBX sync', reason);
        this.updateAccountTag(false, 4, 'connect', reason);
      } else if (reason && reason.statusCode === 401) {
        console.error('failed to start PBX sync', JSON.stringify(reason));
        this.spinnerSvc.hide();
        this.authSvc.redirectToLogin();
      } else if (reason instanceof Error) {
        console.error('failed to start PBX sync', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.spinnerSvc.hide();
          this.authSvc.redirectToLogin();
        } else {
          this.updateAccountTag(false, 4, 'connect', reason.message);
        }
      } else {
        let errMsg;
        if (reason && reason.body && reason.body.message) {
          const srvErrMsg = reason.body.message;
          if (srvErrMsg === commonErrorMessages.SRV_ERR_PBX_LOGIN) {
            errMsg = commonMessages.ERR_PBX_LOGIN;
          } else if (srvErrMsg === commonErrorMessages.SRV_ERR_INV_PW) {
            errMsg = commonMessages.ERR_PBX_INV_PW;
          } else {
            errMsg = srvErrMsg;
          }
        } else {
          errMsg = connectMessages.ERROR_SYNC;
        }
        console.error('failed to start PBX sync', JSON.stringify(reason));
        this.updateAccountTag(false, 4, 'connect', errMsg);
      }
      this.alertSvc.setAlert(toastMsgTypes.ERROR, connectMessages.ERROR_SYNC);
    }
  }

  async getPbxSyncStatus() {
    try {
      const statusCollection = await this.tunnelSvc.getPbxSyncStatus(this.account.accountId, this.site.siteId);
      console.log('Class: GatewayConnectionComponent, Function: getPbxSyncStatus, statusCollection: '
      , statusCollection);
      const status = Utils.getItemsFromCollection<PbxSyncStatus>(statusCollection);
      if (status && status[0]) {
        if (status[0].status === 'complete') {
          this.startResync();
          if(this.pbxLink && this.pbxLink.type && this.pbxLink.type === pbxTypes.MIVB) {
            this.syncSvc.syncForMIVB(this.account.accountId, this.site.siteId);
          }
        } else if (status[0].status === 'failed') {
          console.warn('PBX sync failed');
          const srvErrMsg = status[0].message;
          let errMsg;
          if (srvErrMsg === commonErrorMessages.SRV_ERR_PBX_LOGIN) {
            errMsg = commonMessages.ERR_PBX_LOGIN;
          } else if (srvErrMsg === commonErrorMessages.SRV_ERR_INV_PW) {
            errMsg = commonMessages.ERR_PBX_INV_PW;
          } else {
            errMsg = srvErrMsg;
          }
          this.updateAccountTag(false, 4, 'connect', errMsg);
        } else if (status[0].status === 'syncing') {
          setTimeout(() => {
            this.getPbxSyncStatus(); // Get Sync status again
          }, 1000);
        }
      }
    } catch (reason) {
      if (typeof reason === 'string') {
        console.error('failed to get PBX sync status', reason);
      } else if (reason && reason.statusCode === 401) {
        console.error('failed to get PBX sync status', JSON.stringify(reason));
        this.authSvc.redirectToLogin();
      } else if (reason instanceof Error) {
        console.error('failed to get PBX sync status', reason.message);
        if (reason.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else if (reason && reason.statusCode === 406){
        const modalRef = this.modalSvc.open(UnexpectedModalComponent);
        const contentComponentInstance = modalRef.componentInstance;
        contentComponentInstance.advanced.subscribe(() => {
          this.router.navigateByUrl(`accounts/${this.account.accountId}/sites/${this.site.siteId}/advanced`)
            .then(()=>{
              modalRef.close()
            });
        });
        console.error('failed to get PBX sync status', 'Unexpected response body received.');
      } else {
        console.error('failed to get PBX sync status', JSON.stringify(reason));
      }
      this.alertSvc.setAlert(toastMsgTypes.ERROR, connectMessages.ERROR_SYNC);
      this.updateAccountTag(false, 4, 'connect', connectMessages.ERROR_SYNC);
    }
  }

  startResync() {
    this.spinnerSvc.show();
    this.spinnerSvc.setMessage(connectMessages.PBX_RESYNC);
    console.log('waiting for sync to complete');
    setTimeout(async () => {
      try {
        await this.resyncService.startResyncForAccount(this.account.accountId, this.site.siteId);
        setTimeout(() => {
           this.updateAccountTag(true, 4, 'connect');
        }, 12000); // Added 10 more seconds to wait for webhook notifications
      } catch (reason) {
        let failedMessage = {message: resyncMessages.ERROR_SYNC, data: null};
        if (typeof reason === 'string') {
          console.error('resync failed: ', reason);
          this.updateAccountTag(false, 4, 'connect', reason);
        } else if (reason && reason.statusCode === 401) {
          console.error('resync failed: ', reason);
          this.spinnerSvc.hide();
          this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 409 && reason.body && reason.body.name) {
          console.error('resync failed: ', JSON.stringify(reason));
          let errorMsg;
          // Many possible reasons for a 409 and UserAlreadyExists.
          // Only dealing with email conflict now.
          // pbxUserId conflict is also a possible UserAlreadyExists error
          if(reason.body.name === 'UserAlreadyExists' && reason.body.message && reason.body.message === "Email address not unique in account" && reason.body.error_description){
            // Email address not unique in account: <email>
            let descriptionParts = reason.body.error_description.split(': ');
            let email = descriptionParts.length === 2? descriptionParts[1] : undefined;
            failedMessage.data = email;
            failedMessage.message = 'startResyncForAccount.EmailConflict';
            errorMsg = 'startResyncForAccount.EmailConflict';
          } else if(this.translationExists('toasts.startResyncForAccount.' + reason.body.name)){
            failedMessage.message = 'startResyncForAccount.' + reason.body.name;
            errorMsg = 'startResyncForAccount.' + reason.body.name;
          } else {
            errorMsg = resyncMessages.ERROR_SYNC;
          }
          this.updateAccountTag(false, 4, 'connect', errorMsg);

        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('resync failed: ', res);
            const body = JSON.parse(res);
            this.updateAccountTag(false, 4, 'connect', 'startResyncForAccount.' + body.name);
          });
        } else if (reason instanceof Error) {
          console.error('resync failed: ', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.spinnerSvc.hide();
            this.authSvc.redirectToLogin();
          } else {
            this.updateAccountTag(false, 4, 'connect', reason.message);
          }
        } else {
          console.error('resync failed: ', reason);
          let errorMsg;
          if (reason && reason.body && reason.body.name) {
            if(this.translationExists('toasts.startResyncForAccount.' + reason.body.name)){
              failedMessage.message = 'startResyncForAccount.' + reason.body.name;
              errorMsg = 'startResyncForAccount.' + reason.body.name;
            } else {
              errorMsg = resyncMessages.ERROR_SYNC;
            }
          } else {
            errorMsg = resyncMessages.ERROR_SYNC;
          }
          this.updateAccountTag(false, 4, 'connect', errorMsg);
        }
        this.alertSvc.setAlert(toastMsgTypes.ERROR, failedMessage.message, failedMessage.data);
      }
    }, 5000);
  }

  translationExists(key: string){
    let translation = this.translateSvc.instant(key);
    return translation === key? false : true;
  };

  async setUpgradeSchedule() {
    const params = this.getDefaultUpdateScheduleParams();
    try {
      await this.tunnelSvc.updateUpgradeSchedule(undefined, params);
      this.accountSvc.setUpgradeSchedule(params);
      console.log('set upgrade schedule');
    } catch (error) {
      if (typeof error === 'string') {
        console.error('failed to set upgrade schedule', error);
      } else if (error && error.statusCode === 401) {
        console.error('failed to set upgrade schedule', JSON.stringify(error));
        this.authSvc.redirectToLogin();
      } else if (error instanceof Error) {
        console.error('failed to set upgrade schedule', error.message);
        if (error.message === commonErrorMessages.AUTH_ERROR) {
          this.authSvc.redirectToLogin();
        }
      } else {
        console.error('failed to set upgrade schedule', JSON.stringify(error));
      }
    }
  }

  getDefaultUpdateScheduleParams() {
    let params = {};

    if (this.cloudAppliance && this.cloudAppliance['update'] && this.cloudAppliance['update']['host_dayOfWeek'] &&
        this.cloudAppliance['update']['host_dayOfWeek'].length >= 1) {
      params = this.cloudAppliance['update'];
    } else {
      let localTimezone: string;
      if (this.site && this.site.location && this.site.location.timezone) {
        localTimezone = this.site.location.timezone;
      } else {
        localTimezone = jstz.determine().name();
      }

      const defaultSchedule = this.autoUpdateSvc.getDefaultSchedule();
      const baseTimezone = this.autoUpdateSvc.getBaseTimezone();

      const m = moment.tz(defaultSchedule, localTimezone);
      const convertedMoment = m.clone().tz(baseTimezone);
      const weekdays = this.autoUpdateSvc.getWeekdays();
      const day = [weekdays[convertedMoment.day()].value];
      const time = convertedMoment.hour();

      if (this.platform && this.platform.capabilities && !this.platform.capabilities.update_host) {
        params = {
          'container_onBoot': true,
          'container_hour': time,
          'container_minute': '0',
          'container_dayOfWeek': day,
        }
      } else {
        params = {
          'host_onBoot': true,
          'host_hour': time,
          'host_minute': '0',
          'host_dayOfWeek': day,
          'container_onBoot': true,
          'container_hour': time,
          'container_minute': '0',
          'container_dayOfWeek': day,
        }
      }
    }

    return params;
  }

  async updateAccountTagsWithPbxStatus(status: string, reason?: string) {
    const tagParams = {};
    if (status === pbxStatus.DOWN) {
      tagParams['pbx-status'] = { status: status, reason: reason };
    } else if (status === pbxStatus.UP || status === pbxStatus.UNKNOWN || status === pbxStatus.MISSING) {
      tagParams['pbx-status'] = { status: status };
    }

    await this.updateAccountTagWithTagParams(tagParams);
  }

  async updateAccountTagsWithGatewayConnection(connected: boolean, pbxConnected?: boolean, reason?: string) {
    const tagParams = {};
    if (connected) {
      if (pbxConnected === true) {
        tagParams['gateway-connection'] = { connected: connected, pbxConnected: pbxConnected };
      } else if (pbxConnected === false) {
        tagParams['gateway-connection'] = { connected: connected, pbxConnected: pbxConnected, reason: reason };
      } else {
        tagParams['gateway-connection'] = { connected: connected };
      }
    } else {
      tagParams['gateway-connection'] = { connected: connected, reason: reason };
    }

    await this.updateAccountTagWithTagParams(tagParams);
  }

  async updateAccountTagWithTagParams(tagParams: any) {
    if (tagParams) {
      try {
        const tags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
        this.accountTags = tags;
        this.accountSvc.setAccountTags(this.accountTags);
      } catch (error) {
        if (error && error.status === 401) {
          console.error('failed to update account tag', error);
          this.authSvc.redirectToLogin();
        } else if (typeof error.text === 'function') {
          error.text().then(res => {
            console.error('failed to update account tag', res);
          }).catch(err => console.error('failed to update account tag', err));
        } else if (error instanceof Error) {
          console.error('failed to update account tag', error.message);
          if (error.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to update account tag', error);
        }
      }
    }
  }

  async updateAccountTag(succeeded: boolean, step: number, name: string, reason?: string) {
    const tagParams = this.getAccountTagParameters(succeeded, step, name, reason);
    if (tagParams) {
      try {
        const accountTags = await this.adminSvc.tryUpdateAccountTags(this.account.accountId, tagParams);
        if (accountTags && accountTags['on-board-progress'] &&
            accountTags['on-board-progress'].succeeded) {
          this.linkingFailed = false;
          const name = accountTags['on-board-progress'].name;
          this.redirectToStep(name);
        } else {
          this.linkingFailed = true;
        }
        this.spinnerSvc.hide();
        this.accountSvc.setAccountTags(accountTags);
      } catch (reason) {
        this.spinnerSvc.hide();
        if (reason && reason.statusCode === 401) {
          console.error('failed to update account tag', reason);
          this.authSvc.redirectToLogin();
        } else if (typeof reason.text === 'function') {
          reason.text().then(res => {
            console.error('failed to update account tag', res);
            this.alertSvc.setAlert(toastMsgTypes.ERROR, commonMessages.ERR_PROGRESS);
          }).catch(err => console.error('failed to update account tag', err));
        } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
          console.error('failed to update account tag', reason.message);
          this.authSvc.redirectToLogin();
        } else {
          console.error('failed to update account tag', reason);
          this.alertSvc.setAlert(toastMsgTypes.ERROR, commonMessages.ERR_PROGRESS);
        }
      }
    }
  }

  getAccountTagParameters(succeeded: boolean, step: number, name: string, reason?: string): any {
    let tagParams = {};

    if (succeeded) {
      tagParams = { 'on-board-progress': { 'step': step, 'name': name, 'succeeded': succeeded } };
    } else {
      tagParams = { 'on-board-progress': { 'step': step, 'name': name, 'succeeded': succeeded, 'reason': reason } };
    }

    return tagParams;
  }


  async createNewClient() {
    try {
      const newClient: Client = await this.adminSvc.createClientByAccountId(this.account.accountId, { name: uuid(), role: 'ACCOUNT_ADMIN' })
      const oldClient: Client = await this.adminSvc.getClientFromAccount(this.account.accountId, this.site.clientId);

      const site = await this.adminSvc.updateSite(this.account.accountId, this.site.siteId, { clientId: newClient.clientId });
      await this.adminSvc.deleteClientByAccountId(this.account.accountId, oldClient.clientId);

      return newClient;
    } catch (error) {
      console.error('error creating new client', error);
      throw error;
    }
  }

  retryLink() {
    switch (this.linkingToStep) {
      case linkingSteps.STEP_2:
        this.updateNetworkSettings();
        break;
      case linkingSteps.STEP_3:
        this.createPbxlink();
        break;
      case linkingSteps.STEP_4:
        this.configureFreeswitch();
        break;
      case linkingSteps.STEP_5:
        this.connectPbx();
        break;
      default:
        break;
    }
  }

  startOver() {
    if (this.accountTags && this.accountTags['on-board-progress']) {
      const name = this.accountTags['on-board-progress'].name;
      this.redirectToStep(name);
    }
  }

  redirectToStep(name: string) {
    switch (name) {
      case 'site':
        this.router.navigateByUrl(`/accounts/${this.account.accountId}/sites/${this.site.siteId}`)
        break;
      case 'pbx':
        const pbxId = this.accountSvc.getPbxlink() ? this.accountSvc.getPbxlink()._id : 'new';
        this.router.navigateByUrl(`/accounts/${this.account.accountId}/sites/${this.site.siteId}/pbx/${pbxId}`);
        break;
      case 'connect':
        this.router.navigateByUrl(`/accounts/${this.account.accountId}/sites/${this.site.siteId}/connect`);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
  	if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.accountTagsSubscription) {
      this.accountTagsSubscription.unsubscribe();
    }
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
    if (this.pbxSubscription) {
      this.pbxSubscription.unsubscribe();
    }
  }

  onSelectCheckbox(event: Event, checkboxNumber: number) {
    event.preventDefault();
    event.stopPropagation();

    switch (checkboxNumber) {
      case 1: {
        this.firstCheckBoxSelected = !this.firstCheckBoxSelected;
        break;
      }
      case 2: {
        this.secondCheckBoxSelected = !this.secondCheckBoxSelected;
        break;
      }
    }
  }

  disableRelink(){
    const tunnel = localStorage.getItem('tunnel');
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    // Check the values for tunnel, username, password
    if (tunnel && username && password)
        this.tunnelParametersExists = true;
    return !this.secondCheckBoxSelected || !this.firstCheckBoxSelected || !this.tunnelParametersExists;
  }
}
