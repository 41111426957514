import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class SpinnerService {
    private showSpinner = false;
    private message: string;
    private spinningSince: number;
    showSpinnerChanged = new Subject<any>();
    messageChanged = new Subject<string>();

    constructor() {

    }

    setMessage(message: string) {
        this.message = message;
        this.messageChanged.next(this.message);
    }

    getMessage() {
        return this.message;
    }

    getShowSpinner() {
        return this.showSpinner;
    }

    show() {
        this.showSpinner = true;
        this.showSpinnerChanged.next(this.showSpinner);
        this.spinningSince = (new Date).getTime();
    }

    since(): number {
        if (!this.spinningSince) {
            return 0;
        } else {
            return (new Date).getTime() - this.spinningSince;
        }
    }

    hide() {
        this.spinningSince = 0;
        this.showSpinner = false;
        this.showSpinnerChanged.next(this.showSpinner);
    }
}
