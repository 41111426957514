import { Injectable } from '@angular/core';
import { FeatureFlags } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class FeatureAvailabilityService {
  featureFlags: any = {
  };

  initFeatureFlagsFromParent(featureFlags: any[]) {
    console.log('Received feature flags from parent ', featureFlags);
    if (featureFlags) {
    }
  }
}
