import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'sortby'
})
export class SortbyPipe implements PipeTransform {

  transform(value: any, propName: string, reverse?: boolean): any {
    if (!value || value.length === 0) {
    	return value;
    }

    if (!propName || propName === '') {
    	return value;
    }

    const collection = _.clone(value);

    const empty = _.remove(collection, (object: any) => {
      return !object[propName];
    });

    const resultArray = collection.sort((a: any, b: any): number => {
         return a[propName].toLowerCase().localeCompare(b[propName].toLowerCase());
    });

    if (reverse) {
      resultArray.reverse();
      return empty.concat(resultArray);
    } else {
      return resultArray.concat(empty);
    }
  }

}
