import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { ProgressService } from '../../services/progress.service';
import { platformTypes } from '../../shared/constants';
import { ParentCommsService } from '../../services/parent-comms.service';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit, OnDestroy {
	accountTags: any = {};
	accountTagsSubscription: Subscription;

  platform: any;
  platformSubscription: Subscription;
  platformTypes = platformTypes;
  isIframed: boolean = false;

  constructor(
    private accountSvc: AccountService,
    private parentCommsService: ParentCommsService,
    private progressSvc: ProgressService
  ) { }

  ngOnInit() {
  	this.accountTags = this.accountSvc.getAccountTags();
  	this.accountTagsSubscription = this.accountSvc.accountTagsChanged
  																	.subscribe(tags => this.accountTags = tags);
    this.platform = this.accountSvc.getPlatform();
    this.isIframed = this.parentCommsService.isInsideIframe();
    this.platformSubscription = this.accountSvc.platformChanged.subscribe(platform => this.platform = platform);
  }

  isGreyedOut(stepName: string) {
  	if ((!this.accountTags || !this.accountTags['on-board-progress']) && stepName === 'account') {
        return false;
    }

    // Disable connect form when pbx has connect_error
    if (stepName === 'connect') {
        const pbxLink = this.accountSvc.getPbxlink();
        if (pbxLink && pbxLink['connect_error'] && pbxLink['connect_error'] !== '') {
          return true;
        }
    }

    if (stepName === 'site' && this.accountTags && this.accountTags['tunnel-creds'] === false) {
        return true;
    }

    if (this.accountTags && this.accountTags['on-board-progress']) {
        const lastStepIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress']['name']);
        const stepNameIdx = this.progressSvc.getProgressIdx(stepName);

        if (stepName === 'advanced') {
            const siteStepIdx = this.progressSvc.getProgressIdx('site');
            if (this.accountTags['on-board-progress']['succeeded']) {
                return siteStepIdx > lastStepIdx;
            } else {
                return siteStepIdx >= lastStepIdx;
            }
        } else if (this.accountTags['on-board-progress']['name'] === 'connect' && this.accountTags['on-board-progress']['succeeded']) {
            return false;
        } else if (this.accountTags['on-board-progress']['succeeded'] === false) {
            return stepNameIdx > lastStepIdx;
        } else {
            return stepNameIdx > lastStepIdx + 1;
        }
    }

    return true;
  }

  ngOnDestroy() {
  	if (this.accountTagsSubscription) {
  		this.accountTagsSubscription.unsubscribe();
  	}
    if (this.platformSubscription) {
      this.platformSubscription.unsubscribe();
    }
  }

}
