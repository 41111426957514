import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-checkmark',
  templateUrl: './checkmark.component.html'
})
export class CheckmarkComponent implements OnInit {
  public lottieConfigLoading: Object;
  public lottieConfigCheck: Object;
  private anim: any;
  public isTimeUp = false;

  constructor() {
    this.lottieConfigLoading = {
      path: 'assets/animations/loading.json',
      autoplay: true,
      loop: true,
    };

    this.lottieConfigCheck = {
      path: 'assets/animations/check.json',
      autoplay: true,
      loop: true,
    };

    setTimeout(() => {
      this.isTimeUp = true;
    }, 15050);

    setTimeout(() => {
      this.anim.pause();
    }, 15850);
  }

  ngOnInit() {}

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  onDestroy() {
    this.anim.stop();
  }
}
