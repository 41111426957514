import * as _ from 'lodash';
import * as validator from 'validator';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Account, User, Group, Member, Contact, ContactDetail } from '@mitel/cloudlink-sdk/admin';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../../environments/environment';
import { FormDataService } from '../../services/form-data.service';
import { Odata } from '@mitel/cloudlink-sdk';
import { AdminService } from '../../services/admin.service';
import { SpinnerService } from '../../services/spinner.service';
import { PbxUser } from '@mitel/cloudlink-sdk/tunnel'
import { connectMessages } from '../../shared/constants';
import { Utils } from '@mitel/cloudlink-sdk';

export enum SourceType {
    PBX = 'PBX',
    APP = 'APP'
};

export enum PbxType {
    MiVoice250 = 'MiVoice250',
    mvo400 = 'mvo400',
    mivb = 'mivb',
    mxone = 'mxone',
    mv5000 = 'mv5000'
};

export enum GroupType {
    APP = 'APP'
};

export enum opType {
    ADD = 'add',
    DELETE = 'delete',
    UPDATE = 'update'
};

export interface PbxContact {
    _id: string,
    pbx_id?: string,
    name?: string,
    number?: string,
    email?: string,
    pbx_object_id?: string,
    firstname?: string,
    lastname?: string,
    department?: string,
    location?: string
};

const MAX_BATCH_SIZE = 50;

export class ResyncBaseHelper {

    protected getPath(basePath: string, type: opType, id: string, subPath?: string): string {
        if (type === opType.ADD && !subPath) {
            return basePath;
        } else {
            return subPath ? `${basePath}/${id}/${subPath}` : `${basePath}/${id}`;
        }
    }

    protected getOperationsBatches(basePath: string, type: opType, batch: Array<any>, id: string, subPath?: string): Array<Array<any>> {
        const opBatches = [];
        let operations = [];
        batch.forEach((item) => {

            const operation: any = {
                op: type,
            };
            if (basePath === '/users') {
                operation.id = item[id];
                operation.body = item;
            } else {
                // a group add might have members attached to it and is handled separately
                // we will add new groups one PATCH request at a time since they now include all the members
                if (basePath == '/groups' && type === opType.ADD && !subPath) {
                    // first operation will be the group add
                    operation.path = this.getPath(basePath, type, item[id]);
                    const groupOnly = _.omit(item, 'member');
                    operation.value = groupOnly;
                    operations.push(operation);

                    // now add all the members
                    if (item.member) {
                        for (const member of item.member) {
                            operations.push({
                                op: type,
                                path: '/groups/$results.ops[0].groupId/members',
                                value: member,
                            });
                        }
                    }
                    opBatches.push(operations);
                    operations = [];
                    return;
                } else {
                    operation.path = this.getPath(basePath, type, item[id], subPath);
                    operation.value = item;
                }
            }

            operations.push(operation);
            if (operations.length === MAX_BATCH_SIZE) {
                opBatches.push(operations);
                operations = [];
            }
        });

        if (operations.length > 0) {
            opBatches.push(operations);
            operations = [];
        }

        return opBatches;
    }
}

export class UsersResyncHelper extends ResyncBaseHelper {
    private adminUrlBase: string = environment.adminUrl;

    constructor(
        private spinnerSvc: SpinnerService,
        private authSvc: AuthenticationService,
        private http: HttpClient
    ) { 
        super();
     }

    public async syncUsers(account: Account, siteId: string, pbxUsers: PbxUser[], cloudlinkUsers: any[]): Promise<boolean> {
        // compare using both pbxUserId and extension in case either has been modified
        const usersByPbxUserId = _.keyBy(cloudlinkUsers, 'pbxUserId');
        const usersByExtension = _.keyBy(cloudlinkUsers, 'extension');
        const missingPbxUsers: any[] = [];
        const usersToUpdate: any[] = [];
        let updatesMade = false;

        if (pbxUsers) {
            for (const pbxUser of pbxUsers) {
                if (pbxUser.extension) {
                    // create comparisons based on extension and pbxUserId
                    const matchingCloudlinkUserByExtension = _.get(usersByExtension, pbxUser.extension);
                    const matchingCloudlinkUserByPbxUserId = _.get(usersByPbxUserId, pbxUser._id);

                    if (!matchingCloudlinkUserByExtension && !matchingCloudlinkUserByPbxUserId) {
                        // no matches so this is a new user
                        missingPbxUsers.push(this.convertPbxUserToUser(pbxUser, siteId));
                    } else {
                        // if we found a match on extension and pbxUserId
                        if (matchingCloudlinkUserByExtension && matchingCloudlinkUserByPbxUserId) {
                            // If the matchingCloudlinkUserByExtension.userId matches the matchingCloudlinkUserByPbxUserId.userId then it's certainly
                            // the same user and we can compare the differences and remove the entry from the usersByExtension list.
                            // If it so happens that the userIds are different (unexpected), we still want to make an attempt to update the user by
                            // comparing the pbxUser and the matching user by pbxUserId, at worst the individual update fails and we will have a log
                            // that indicates the error and the whole resync will not stop
                            const diff = this.getUserDifference(account, pbxUser, matchingCloudlinkUserByPbxUserId, siteId);
                            if (diff) {
                                usersToUpdate.push(diff);
                            }
                        } else if (matchingCloudlinkUserByExtension && !matchingCloudlinkUserByPbxUserId) {
                            // we found a match by extension but different pbxUserId (relink), update the pbxUserId
                            const diff = this.getUserDifference(account, pbxUser, matchingCloudlinkUserByExtension, siteId);
                            if (diff) {
                                usersToUpdate.push(diff);
                            }
                        } else {
                            // the only remaining combination is (!matchingCloudlinkUserByExtension && matchingCloudlinkUserByPbxUserId)
                            // we did not find a match by extension but found the same pbxUserId, update the extension
                            const diff = this.getUserDifference(account, pbxUser, matchingCloudlinkUserByPbxUserId, siteId);
                            // remove the entry for the old extension from the list of usersByExtension so it doesn't remove
                            // the extension that we are changing for the user
                            _.unset(usersByExtension, matchingCloudlinkUserByPbxUserId.extension);
                            if (diff) {
                                usersToUpdate.push(diff);
                            }
                        }
                    }
                    _.unset(usersByExtension, pbxUser.extension);
                }
            }
        }
        _.unset(usersByExtension, undefined);
        _.unset(usersByExtension, '');
        console.log('UsersResyncHelper.syncUsers() number of cloudlink Users with extension that should be removed =>', _.size(usersByExtension));
        console.log('UsersResyncHelper.syncUsers() number of pbx Users that needed to be inserted =>', missingPbxUsers.length);
        console.log('UsersResyncHelper.syncUsers() number of pbx Users that needed to be updated =>', usersToUpdate.length);
        try {
            if (usersToUpdate.length > 0) {
                this.spinnerSvc.setMessage(connectMessages.UPDATE_USERS);
                await this.sendUserPatchRequest(account.accountId, opType.UPDATE, usersToUpdate);
                updatesMade = true;
            }
            if (missingPbxUsers.length > 0) {
                this.spinnerSvc.setMessage(connectMessages.ADD_USERS);
                await this.sendUserPatchRequest(account.accountId, opType.ADD, missingPbxUsers);
                updatesMade = true;
            }
            if (_.size(usersByExtension) > 0) {
                this.spinnerSvc.setMessage(connectMessages.REMOVE_USERS);
                const usersToRemoveExtensionFrom = this.removeExtensionFromUser(usersByExtension);
                await this.sendUserPatchRequest(account.accountId, opType.UPDATE, usersToRemoveExtensionFrom);
                updatesMade = true;
            }
        } catch (e) {
            console.log('UsersResyncHelper.syncUsers() operation failed ');
            throw e;
        }

        return updatesMade;
    }

    private convertPbxUserToUser(pbxUser: PbxUser, siteId: string): User {
        const user = _.merge( pbxUser, {
            source: SourceType[SourceType.PBX],
            pbxUserId: pbxUser._id,
            extensionVerified: true,
            siteId,
        });
        delete user.pbx_id;
        delete user._id;
        return user;
    }

    private removeExtensionFromUser(usersByExtension: any): any[] {
        const users: User[] = _.map(usersByExtension, (user: User) => user);
        const usersToBeRemoved: any[] = [];
        for (const previous of users) {
            if (!_.isEmpty(previous.extension)) {
                let user: User = _.cloneDeep(previous);
                user.extension = '';
                user.pbxUserId = '';
                user.extensionVerified = false;
                user.source = SourceType[SourceType.APP];
                usersToBeRemoved.push(user);
            }
        }
        return usersToBeRemoved;
    }

    /**
     * This method is intended to update the cloudlink user with the PBX user data that might have
     * changed and is now being collected in the resync.  I have changed the logic slightly to now
     * only do a partial update of the cloudlink user and only update the fields which the PBX has some
     * control over -> email, pbxUserId, extension (and extensionVerified since the update is coming from the pbx this should always
     * be true), and name if the user has not onboarded yet.
     * 
     * @param account The account of the user to update
     * @param pbxUser The user's information from the PBX
     * @param previous The user's cloudlink information
     */
    private getUserDifference(account: any, pbxUser: any, previous: User, siteId: string): any {
        let difference: User;
        // update the email if the previous user didn't have one or if it is different
        // also we should clear the email address if the PBX email has been cleared and the
        // user has not registered yet
        if ((pbxUser.email && (!previous.email || pbxUser.email.toLocaleLowerCase() !== previous.email.toLocaleLowerCase())) ||
            (!pbxUser.email && previous.email && !previous.emailVerified)) {
            difference = <User>{};
            difference.email = pbxUser.email;
            if (difference.email && !validator.default.isEmail(difference.email)) {
                console.warn('ignored invalid email address', difference);
                return undefined;
            }
        }
        // Populate the pbxUserId field 
        if (_.has(pbxUser, '_id')) {
            if (!_.has(previous, 'pbxUserId') || previous.pbxUserId !== pbxUser._id) {
                if (!difference) {
                    difference = <User>{};
                }
                difference.pbxUserId = pbxUser._id;
            }
        }

        // update of names for verified users depends on account policy flag
        if (pbxUser.name && pbxUser.name !== previous.name && (!previous.emailVerified || account.policy?.overwriteVerifiedNamesFromIntegrations)) {
            if (!difference) {
                difference = <User>{};
            }
            difference.name = pbxUser.name;
        }

        if (pbxUser.extension && pbxUser.extension !== previous.extension) {
            if (!difference) {
                difference = <User>{};
            }
            difference.extension = pbxUser.extension;
        }

        if (!previous.siteId) {
            difference.siteId = siteId;
        }

        if (difference) {
            difference.uniqueUserId = previous.uniqueUserId;
            difference.userId = previous.userId;
            difference.source = SourceType.PBX;
            difference.extensionVerified = true;
            console.log('UsersResyncHelper.updateUserDifference() difference =>', difference);
        }
        return difference;
    }

    public async sendUserPatchRequest(accountId: string, type: opType, users: Array<User>) {
        if (users.length > 0) {
            const usersUrl = `${this.adminUrlBase}/accounts/${accountId}/users`;
            const token = (await this.authSvc.getToken()).access_token;
            const headers = {
                'Authorization': token,
            };
            const batches = this.getOperationsBatches('/users', type, users, 'userId');
            let failureCount = 0;

            for await (const ops of batches) {
                const body = {
                    'operations': ops,
                }
                await firstValueFrom(this.http.patch(usersUrl, body, { headers }))
                    .then((res) => {
                        console.log('UsersResyncHelper.sendUserPatchRequest() - operation: ', type, ' response: ', res);
                        failureCount += this.handleUserResponse(res);
                    })
                    .catch((e) => {
                        throw new Error(`UsersResyncHelper.sendUserPatchRequest() failed - operation: ${type} error: ${JSON.stringify(e)}`);
                    });
            }

            console.log('UsersResyncHelper.sendUserPatchRequest() complete - failures: ', failureCount);
            if (failureCount > 0) {
                throw new PatchRequestError('UsersResyncHelper.sendUserPatchRequest(): some of the updates failed.', failureCount, type, 'USER');
            }
        }
    }

    private handleUserResponse(response: any): number {
        const ops = response?.operations;
        let failures = 0;
        if (ops && ops.length > 0) {
            ops.forEach((op) => {
                if (op.statusCode > 399) {
                    failures += 1;
                    console.log('UsersResyncHelper.sendUserPatchRequest() operation failed - error: ', op);
                }
            });
        }
        return failures;
    }
}

export class GroupsResyncHelper extends ResyncBaseHelper {
    private huntGroups: Group[] = [];
    private phantomGroups: Group[] = [];
    private getGroupsCompleted: boolean = false;
    private adminGroups: Group[] = [];
    private adminUrlBase: string = environment.adminUrl;

    constructor(
        private adminService: AdminService,
        private authSvc: AuthenticationService,
        private formDataSvc: FormDataService,
        private spinnerSvc: SpinnerService,
        private http: HttpClient
    ) {
        super();
     }

    public async syncHuntGroups(account: Account, siteId: string, pbxGroups: any[], adminGroups: any[], cloudlinkUsers: any[], subType?: string) {
        const groupsByExtension = _.keyBy(adminGroups, 'dialableNumber');
        const missingPbxGroups: any[] = [];
        const groupsToUpdate: any[] = [];
        const groupMembersToAdd: any[] = [];
        const groupMembersToRemove: any[] = [];

        if (pbxGroups) {
            for (const pbxGroup of pbxGroups) {
                if (pbxGroup.extension) {
                    const matchingAdminGroup = _.get(groupsByExtension, pbxGroup.extension);
                    if (matchingAdminGroup) {
                        const result = this.getGroupAndMemberDifference(pbxGroup, matchingAdminGroup, cloudlinkUsers);
                        if (result.updatedGroup) {
                            groupsToUpdate.push(result.updatedGroup);
                        }
                        if (result.newMembers.length > 0) {
                            groupMembersToAdd.push(...result.newMembers);
                        }
                        if (result.deletedMembers.length > 0) {
                            groupMembersToRemove.push(...result.deletedMembers);
                        }
                    } else {
                        missingPbxGroups.push(pbxGroup);
                    }
                    _.unset(groupsByExtension, pbxGroup.extension);
                }
            }
        }
        _.unset(groupsByExtension, undefined);
        console.log(`Number of admin ${subType || ''}HuntGroups with extension that should be removed =>`, _.size(groupsByExtension));
        console.log(`Number of pbx ${subType || ''}HuntGroups that need to be inserted =>`, missingPbxGroups.length);
        console.log(`Number of pbx ${subType || ''}HuntGroups that need to be updated =>`, groupsToUpdate.length );
        console.log(`Number of pbx ${subType || ''}HuntGroups members to add =>`, groupMembersToAdd.length );
        console.log(`Number of pbx ${subType || ''}HuntGroups members to remove =>`, groupMembersToRemove.length);

        try {
            this.spinnerSvc.setMessage(connectMessages.UPDATE_HUNT_GROUPS);
            await this.sendGroupPatchRequest(account.accountId, opType.UPDATE, groupsToUpdate);
            await this.sendGroupMemberPatchRequest(account.accountId, opType.DELETE, groupMembersToRemove);
            await this.sendGroupMemberPatchRequest(account.accountId, opType.ADD, groupMembersToAdd);
            this.spinnerSvc.setMessage(connectMessages.REMOVE_HUNT_GROUPS);
            await this.removeExtraGroups(account.accountId, groupsByExtension);
            this.spinnerSvc.setMessage(connectMessages.ADD_HUNT_GROUPS);
            await this.addMissingPbxGroups(account, siteId, missingPbxGroups, cloudlinkUsers, subType);
        } catch (e) {
            console.log('GroupsResyncHelper.syncHuntGroups() operation failed');
            throw e;
        }
    }

    public async getAllHuntGroupsBySiteIdOrNoSite(accountId: string, siteId: string): Promise<Group[]> {
        if (!this.getGroupsCompleted) {
            await this.getAllGroupsWithMembers(accountId);
        }
        const resultGroups: Group[] = [];
        for (const huntGroup of this.huntGroups) {
            if (huntGroup.siteId === siteId || !huntGroup.siteId) {
                resultGroups.push(huntGroup);
            }
        }
        return resultGroups;
    }

    public async getAllPhantomsBySiteIdOrNoSite(accountId: string, siteId: string): Promise<Group[]> {
        const resultGroups: Group[] = [];
        for (const phantomGroup of this.phantomGroups) {
            if (phantomGroup.siteId === siteId || !phantomGroup.siteId) {
                resultGroups.push(phantomGroup);
            }
        }
        return resultGroups;
    }

    private async getAllGroupsWithMembers(accountId: string): Promise<void> {
        const odataFilter: Odata = {
            $SkipToken: ''
        };

        await this.getAdminGroupsWithAllMembers(accountId, odataFilter);
        for (const group of this.adminGroups) {
            if (group.type === 'APP' && group.subType !== 'PHANTOM') {
                this.huntGroups.push(group);
            } else if (group.subType === 'PHANTOM') {
                this.phantomGroups.push(group);
            }
        }
        this.getGroupsCompleted = true;
    }

    private async getAllMembersForAdminGroups(accountId: string, adminGroups: any[]) {
        // if there isn't a next link in the embedded members _links then we found all the members already
        if (adminGroups) {
            for (const adminGroup of adminGroups) {
                if (adminGroup._embedded?.members?._links?.next) {
                    // get all the members of which the MiVO400 only allows 16 and admin micro returns up to $top 50
                    const adminMembers = await this.adminService.getGroupMembers(accountId, adminGroup.groupId);
                    adminGroup.member = adminMembers?._embedded?.items;
                    delete adminGroup._embedded;
                } else {
                    adminGroup.member = adminGroup._embedded?.members?._embedded?.items;
                    // don't need the embedded members any more
                    delete adminGroup._embedded;
                }
            }
        }
    }

    private async getAdminGroupsWithAllMembers(accountId, odata?: Odata) {
        await this.adminService.getGroupsFromAccount(accountId, odata)
            .then(async u => {
                const adminGroups = Utils.getItemsFromCollection<Group>(u);
                await this.getAllMembersForAdminGroups(accountId, adminGroups);
                const nextGroups = Utils.getOdataNext(u);

                if (adminGroups && adminGroups.length > 0) {
                    this.adminGroups = this.adminGroups.concat(adminGroups);
                }
                if (nextGroups && nextGroups.$SkipToken) {
                    //recursively call this method until there are no more adminGroups to get
                    await this.getAdminGroupsWithAllMembers(accountId, nextGroups);
                }
            }, reason => {
                this.handleError(reason);
                console.error('failed to get admin groups', reason);
                throw reason;
            })
            .catch((error) => {
                console.error('failed to get admin groups', error);
                throw error;
            });
    }

    private handleError(reason) {
        if (reason && reason.statusCode === 401) {
            this.authSvc.redirectToLogin();
        } else if (reason && reason.statusCode === 403) {
            this.formDataSvc.redirectToDashboardOrLogout();
        }
    }

    private getGroupAndMemberDifference(pbxGroup: any, matchingAdminGroup: Group, cloudlinkUsers: any): any {
        const previousGroup: Group = _.cloneDeep(matchingAdminGroup);
        let updatedGroup: Group;
        if (pbxGroup.username && pbxGroup.username !== matchingAdminGroup.name) {
            updatedGroup = _.cloneDeep(previousGroup);
            updatedGroup.name = pbxGroup.username;
        }
        if (pbxGroup.group_type && pbxGroup.group_type !== matchingAdminGroup.subType) {
            if (!updatedGroup) {
                updatedGroup = _.cloneDeep(previousGroup);
            }
            updatedGroup.subType = pbxGroup.group_type;
        }
        if (pbxGroup.pbx_type && pbxGroup.pbx_type !== matchingAdminGroup.pbxType) {
            if (!updatedGroup) {
                updatedGroup = _.cloneDeep(previousGroup);
            }
            updatedGroup.pbxType = PbxType[pbxGroup.pbx_type] || matchingAdminGroup.pbxType;
        }

        let newMembers = [];
        let deletedMembers = [];
        let adminMembersById = {};
        // if there are members then key them by memberId (userId)
        if (_.size(matchingAdminGroup.member) > 0) {
            adminMembersById = _.keyBy(matchingAdminGroup.member, 'memberId');
        }
        const cloudlinkUsersByExtension = _.keyBy(cloudlinkUsers, 'extension');

        // loop through the pbxMembers (by extension) and find any new members
        if (pbxGroup.members) {
            for (const pbxMember of pbxGroup.members) {

                const matchingCloudlinkUser = _.get(cloudlinkUsersByExtension, pbxMember);
                if (matchingCloudlinkUser) {
                    // found a matching user, now see if the user is in the admin group
                    const matchingCloudlinkMember = _.get(adminMembersById, matchingCloudlinkUser.userId);
                    if (!matchingCloudlinkMember) {
                        // couldn't find it in the admin group so it needs to be added
                        const member = {
                            groupId: matchingAdminGroup.groupId,
                            memberId: matchingCloudlinkUser.userId,
                        }
                        newMembers.push(member);
                    }
                    _.unset(adminMembersById, matchingCloudlinkUser.userId);
                }
            }
        }

        // find any left over admin group members, those need to be removed
        const extraAdminMembers: Member[] = _.map(adminMembersById, (member: Member) => member);
        for (const extraAdminMember of extraAdminMembers) {
            const member = {
                groupId: matchingAdminGroup.groupId,
                memberId: extraAdminMember.memberId,
            };
            deletedMembers.push(member);
        }

        return { updatedGroup, newMembers, deletedMembers };
    }

    private async addMissingPbxGroups(account: Account, siteId: string, pbxGroups: any, cloudlinkUsers: any, subType?: string) {
        const groupsToAdd: any[] = [];

        if (pbxGroups) {
            for (const pbxGroup of pbxGroups) {
                console.log('GroupsResyncHelper.addMissingPbxGroups() pbxGroup =>', pbxGroup);

                const group = {
                    groupId: pbxGroup._id,
                    accountId: account.accountId,
                    name: pbxGroup.username || '',
                    siteId: siteId,
                    dialableNumber: pbxGroup.extension,
                    type: GroupType.APP,
                    pbxType: (pbxGroup.pbx_type && PbxType[pbxGroup.pbx_type]) ? PbxType[pbxGroup.pbx_type] : PbxType[PbxType.MiVoice250],
                    subType: subType || pbxGroup.group_type || 'UCD',
                }
                if (pbxGroup.members) {
                    await this.addGroupMembers(group, pbxGroup.members, cloudlinkUsers);
                }
                groupsToAdd.push(group);
            }
        }
        try {
            await this.sendGroupPatchRequest(account.accountId, opType.ADD, groupsToAdd);
        } catch (err) {
            throw err;
        }
    }

    private async addGroupMembers(group: Group, pbxGroupMembers: any[], cloudlinkUsers: any[]) {
        group.member = [];
        const cloudlinkUsersByExtension = _.keyBy(cloudlinkUsers, 'extension');
        // loop through member list and get the associated user by extension
        if (pbxGroupMembers) {
            for (const pbxMember of pbxGroupMembers) {
                const matchingCloudlinkUser = _.get(cloudlinkUsersByExtension, pbxMember);
                if (matchingCloudlinkUser) {
                    const member = {
                        memberId: matchingCloudlinkUser.userId,
                    };
                    group.member.push(member)
                } else {
                    console.log('Unable to find matching cloudlink user for ' + pbxMember);
                }
            }
        }
    }

    private async removeExtraGroups(accountId: string, groupsByExtension: any) {
        // only remove groups that have a pbxType, meaning they came from the PBX
        try {
            const groups: Group[] = _.filter(groupsByExtension, 'pbxType');
            await this.sendGroupPatchRequest(accountId, opType.DELETE, groups);
        } catch (e) {
            throw e;
        }
    }

    public async sendGroupPatchRequest(accountId: string, type: opType, groups: Array<Group>) {
        if (groups.length > 0) {
            const groupsUrl = `${this.adminUrlBase}/accounts/${accountId}/groups`;
            const token = (await this.authSvc.getToken()).access_token;
            const headers = {
                'Authorization': token,
            };
            const batches = this.getOperationsBatches('/groups', type, groups, 'groupId');
            let failureCount = 0;

            for await (const ops of batches) {
                const body = {
                    'ops': ops,
                    'continueOnError': true
                };
                await firstValueFrom(this.http.patch(groupsUrl, body, { headers }))
                    .then((res) => {
                        console.log('GroupsResyncHelper.sendGroupPatchRequest() - operation: ', type, ' response: ', res);
                        failureCount += this.handleGroupResponse(res);
                    })
                    .catch((e) => {
                        throw new Error(`GroupsResyncHelper.sendGroupPatchRequest() failed - operation: ${type} error: ${JSON.stringify(e)}`);
                    });
            }

            console.log('GroupsResyncHelper.sendGroupPatchRequest() complete - failures: ', failureCount);
            if (failureCount > 0) {
                throw new PatchRequestError('GroupsResyncHelper.sendGroupPatchRequest(): some of the updates failed.', failureCount, type, 'GROUP');
            }
        }
    }

    public async sendGroupMemberPatchRequest(accountId: string, type: opType, members: Array<any>) {
        if (members.length > 0) {
            const groupsUrl = `${this.adminUrlBase}/accounts/${accountId}/groups`;
            const token = (await this.authSvc.getToken()).access_token;
            const headers = {
                'Authorization': token,
            };
            const batches = this.getOperationsBatches('/groups', type, members, 'groupId', 'members');
            let failureCount = 0;

            for await (const ops of batches) {
                const body = {
                    'ops': ops,
                    'continueOnError': true
                };
                await firstValueFrom(this.http.patch(groupsUrl, body, { headers }))
                    .then((res) => {
                        console.log('GroupsResyncHelper.sendGroupMemberPatchRequest() - operation: ', type, ' response: ', res);
                        failureCount += this.handleGroupResponse(res);
                    })
                    .catch((e) => {
                        throw new Error(`GroupsResyncHelper.sendGroupMemberPatchRequest() failed - operation: ${type} error: ${JSON.stringify(e)}`);
                    });
            }

            console.log('GroupsResyncHelper.sendGroupMemberPatchRequest() complete - failures: ', failureCount);
            if (failureCount > 0) {
                throw new PatchRequestError('GroupsResyncHelper.sendGroupMemberPatchRequest(): some of the updates failed.', failureCount, type, 'GROUP');
            }
        }
    }

    private handleGroupResponse(response: any): number {
        const operations = response?.ops;
        let failures = 0;
        if (operations && operations.length > 0) {
            operations.forEach((op) => {
                if (op.statusCode > 399) {
                    failures += 1;
                    console.log('GroupsResyncHelper.sendGroupPatchRequest() operation failed - error: ', op);
                }
            });
        }
        return failures;
    }

    public resetGroups() {
        this.huntGroups = [];
        this.phantomGroups = [];
        this.getGroupsCompleted = false;
        this.adminGroups = [];
    }
}

export class PatchRequestError extends Error {
    public body: string;
    public failureCount: number;
    public type: opType;
    public syncType: 'USER' | 'GROUP' | 'CONTACT';

    constructor(body: string, count: number, type?: opType, syncType?: 'USER' | 'GROUP' | 'CONTACT', ...params) {
        super(...params);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PatchRequestError);
        }
        Object.setPrototypeOf(this, PatchRequestError.prototype);

        this.failureCount = count;
        this.body = body;
        this.type = type;
        this.syncType = syncType;
    }
}

export class ContactsResyncHelper extends ResyncBaseHelper {
    private adminUrlBase: string = environment.adminUrl;

    constructor(
        private spinnerSvc: SpinnerService,
        private authSvc: AuthenticationService,
        private http: HttpClient
    ) { 
        super();
    }

    public async syncContacts(account: Account, pbxContacts: PbxContact[], cloudlinkContacts: Contact[]) {

        const missingPbxContacts: any[] = [];
        const contactsToUpdate: any[] = [];

        // walk the list of pbx Contacts
        if (pbxContacts) {
            for (const pbxContact of pbxContacts) {
                if (pbxContact._id) {
                    // find the index of the matching cloudlink Contact
                    const matchingCloudlinkContact = _.findIndex(cloudlinkContacts, cloudlinkContact => cloudlinkContact.contactId === pbxContact._id);
                    if (matchingCloudlinkContact === -1) {
                        // not found so create the contact in Cloudlink
                        const storeableContact: Contact = this.createStoreableContact(account.accountId, pbxContact);
                        missingPbxContacts.push(storeableContact);
                    } else {
                        // found a match, see if there are any differences to update in Cloudlink
                        const diffContact: Contact = this.getContactDifference(pbxContact, cloudlinkContacts[matchingCloudlinkContact]);
                        if (!_.isEmpty(diffContact)) {
                            contactsToUpdate.push(diffContact);
                        }
                    }
                    // remove the entry from the cloudlinkContacts array, if any
                    _.pullAt(cloudlinkContacts, [matchingCloudlinkContact]);
                }
            }
        }

        console.log('ContactsResyncHelper.syncContacts() number of cloudlink contacts that should be removed =>', cloudlinkContacts.length);
        console.log('ContactsResyncHelper.syncContacts() number of pbx contacts that need to be inserted =>', missingPbxContacts.length);
        console.log('ContactsResyncHelper.syncContacts() number of pbx contacts that need to be updated =>', contactsToUpdate.length);
        try {
            this.spinnerSvc.setMessage(connectMessages.UPDATE_CONTACTS);
            await this.sendContactPatchRequest(account.accountId, opType.UPDATE, contactsToUpdate);
            this.spinnerSvc.setMessage(connectMessages.ADD_CONTACTS);
            await this.sendContactPatchRequest(account.accountId, opType.ADD, missingPbxContacts);
            this.spinnerSvc.setMessage(connectMessages.REMOVE_CONTACTS);
            await this.sendContactPatchRequest(account.accountId, opType.DELETE, cloudlinkContacts);
        } catch (e) {
            console.log('ContactsResyncHelper.syncContacts() operation failed');
            throw e;
        }
    }

    private createStoreableContact(accountId: string, pbxContact: PbxContact): Contact {
        let contact: Contact = {
            accountId,
            contactId: pbxContact._id,
            contactType: 'PBX',
            name: pbxContact.name,
            email: pbxContact.email,
            contactDetails: []
        };

        if (pbxContact.number) {
            contact.contactDetails.push({type: 'phone', description: 'number', value: pbxContact.number});
        }
        if (pbxContact.department) {
            contact.contactDetails.push({type: 'other', description: 'department', value: pbxContact.department});
        }
        if (pbxContact.location) {
            contact.contactDetails.push({type: 'other', description: 'location', value: pbxContact.location});
        }
        return contact;
    }

    /**
     * This method is intended to update the cloudlink contact with the PBX contact data that might have
     * changed and is now being collected in the resync.
     * 
     * @param pbxContact The contact information from the PBX
     * @param previous The contact's cloudlink information
     */
    private getContactDifference(pbxContact: PbxContact, previous: Contact): Contact {
        let difference: Contact = <Contact>{};

        if (pbxContact.name !== previous.name) {
            difference.name = pbxContact.name;
        }

        if (pbxContact.email && (!previous.email || pbxContact.email.toLocaleLowerCase() !== previous.email.toLocaleLowerCase())) {
            difference.email = pbxContact.email.toLocaleLowerCase();
        }

        // compare contactsDetails all together since they need to be sent as a whole array
        if (pbxContact.number || pbxContact.department || pbxContact.location) {
            const numberEntry: ContactDetail = {type: 'phone', description: 'number', value: pbxContact.number};
            const departmentEntry: ContactDetail = {type: 'other', description: 'department', value: pbxContact.department};
            const locationEntry: ContactDetail = {type: 'other', description: 'location', value: pbxContact.location};

            if ((pbxContact.number && _.findIndex(previous.contactDetails, detail => _.isEqual(detail, numberEntry)) === -1) ||
            (pbxContact.department && _.findIndex(previous.contactDetails, detail => _.isEqual(detail, departmentEntry)) === -1) ||
            (pbxContact.location && _.findIndex(previous.contactDetails, detail => _.isEqual(detail, locationEntry)) === -1)) {
                difference.contactDetails = [];
                pbxContact.number ? difference.contactDetails.push(numberEntry) : undefined;
                pbxContact.department ? difference.contactDetails.push(departmentEntry) : undefined;
                pbxContact.location ? difference.contactDetails.push(locationEntry) : undefined;
            }
        }

        if (!_.isEmpty(difference)) {
            difference.contactId = previous.contactId;
            console.log('ContactsResyncHelper.updateContactDifference() difference =>', difference);
        }
        return difference;
    }

    public async sendContactPatchRequest(accountId: string, type: opType, contacts: Array<Contact>) {

        if (contacts.length > 0) {
            const contactsUrl = `${this.adminUrlBase}/accounts/${accountId}/contacts`;
            const token = (await this.authSvc.getToken()).access_token;
            const headers = {
                'Authorization': token,
            };
            const batches = this.getOperationsBatches('/contacts', type, contacts, 'contactId');
            let failureCount = 0;

            for await (const ops of batches) {
                const body = {
                    'ops': ops,
                    'continueOnError': true
                };
                await firstValueFrom(this.http.patch(contactsUrl, body, {headers}))
                    .then(res => {
                        console.log('ContactsResyncHelper.sendContactPatchRequest() - operation: ', type, ' response: ', res);

                        (<any>res)?.ops.forEach(op => {
                            if (op.statusCode > 399) {
                                failureCount += 1;
                                console.log('ContactsResyncHelper.sendContactPatchRequest() operation failed - error: ', op);
                            }
                        });
                    })
                    .catch((e) => {
                        throw new Error(`ContactsResyncHelper.sendContactPatchRequest() failed - operation: ${type} error: ${e}`);
                    });
            }

            console.log('ContactsResyncHelper.sendContactPatchRequest() complete - failures: ', failureCount);
            if (failureCount > 0) {
                throw new PatchRequestError('ContactsResyncHelper.sendContactPatchRequest(): some of the updates failed.', failureCount, type, 'CONTACT');
            }
        }
    }

}
