// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #F5F5F6 !important;
}

.container {
  padding: 20px;
}

.alert {
  margin: 15px 0;
  border-radius: 4px;
}
.alert.alert-danger {
  border: solid 1px #eb3530;
  background-color: #FDE8E9;
}
.alert.alert-success {
  border: solid 1px #18a966;
  background-color: #E6FFCE;
}

.alert-message {
  font-size: 14px;
  font-weight: 400;
}
.alert-message.alert-danger {
  color: #eb3530;
  background-color: #FDE8E9;
}
.alert-message.alert-success {
  color: #18a966;
  background-color: #E6FFCE;
}`, "",{"version":3,"sources":["webpack://./src/app/components/message-form/message-form.component.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;AADF;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,cAAA;EACA,kBAAA;AADF;AAGE;EACE,yBAAA;EACA,yBCFgB;ADCpB;AAIE;EACE,yBAAA;EACA,yBCRiB;ADMrB;;AAMA;EACE,eAAA;EACA,gBCViB;ADOnB;AAKE;EACE,cCrBK;EDsBL,yBCjBgB;ADcpB;AAME;EACE,cC3BM;ED4BN,yBCvBiB;ADmBrB","sourcesContent":["@import 'variables';\n\nbody {\n  background-color: #F5F5F6 !important;\n}\n\n.container {\n  padding: 20px;\n}\n\n.alert {\n  margin: 15px 0;\n  border-radius: 4px;\n  \n  &.alert-danger {\n    border: solid 1px $danger;\n    background-color: $danger-background;\n  }\n  \n  &.alert-success {\n    border: solid 1px $success;\n    background-color: $success-background;\n  }\n}\n\n.alert-message {\n  font-size: 14px;\n  font-weight: $font-weight-bold;\n\n  &.alert-danger {\n    color: $danger;\n    background-color: $danger-background;\n  }\n  \n  &.alert-success {\n    color: $success;\n    background-color: $success-background;\n  }\n}\n","$font-family: 'Open Sans', serif;\n\n// Colors\n$body-background: #F5F5F6;\n$app-background: #e7eaef;\n$white: #ffffff;\n$black: #000000;\n\n$success: #18a966;\n$danger: #eb3530;\n$warning: #f7981c;\n$primary: #0073d0;\n\n$success-background: #E6FFCE;\n$danger-background: #FDE8E9;\n\n$font-weight-normal: 300;\n$font-weight-bold: 400;\n$font-weight-bolder: 600;"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
