import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Account, Site, Contact } from '@mitel/cloudlink-sdk/admin';
import { Pbxlink, NetworkConfig, UpdateSettings, CloudlinkGatewayStatus } from '@mitel/cloudlink-sdk/tunnel';
import {pbxTypes, applicationNames, applicationNamesLong} from '../shared/constants';
@Injectable()
export class AccountService {
    supportContactChanged = new Subject<Contact[]>();
    accountChanged = new Subject<Account>();
    accountTagsChanged = new Subject<any>();
    siteChanged = new Subject<Site>();
    pbxChanged = new Subject<Pbxlink>();
    networkConfigChanged = new Subject<NetworkConfig>();
    upgradeScheduleChanged = new Subject<UpdateSettings>();
    selectedPbxTypeChanged = new Subject<string>();
    platformChanged = new Subject<any>();
    cloudlinkStatusChanged = new Subject<CloudlinkGatewayStatus>();

    private account: Account;
    private accountTags: any;
    private site: Site;
    private pbxlink: Pbxlink;
    private networkConfig: NetworkConfig;
    private upgradeSchedule: UpdateSettings;
    private selectedPbxType: string;
    private cloudlinkStatus: CloudlinkGatewayStatus;

    private platform: any;
    numberOfPBXUsers: number;
    constructor() { }

    setAccount(account: Account) {
        this.account = account;
        this.accountChanged.next(this.account);
    }

    getAccount() {
        return this.account;
    }

    setAccountTags(tags: any) {
        this.accountTags = tags;
        this.accountTagsChanged.next(this.accountTags);
    }

    getAccountTags() {
        return this.accountTags;
    }

    setSite(site: Site) {
        this.site = site;
        this.siteChanged.next(this.site);
    }

    getSite() {
        return this.site;
    }

    setPbxlink(pbxlink: Pbxlink) {
        this.pbxlink = pbxlink;
        this.pbxChanged.next(this.pbxlink);
    }

    getPbxlink() {
        return this.pbxlink;
    }

    setNumberOfPBXUsers(num: number) {
        this.numberOfPBXUsers = num;
    }

    getNumberOfPBXUsers() {
        return this.numberOfPBXUsers;
    }

    setNetworkConfig(config: NetworkConfig) {
        this.networkConfig = config;
        this.networkConfigChanged.next(this.networkConfig);
    }

    getNetworkConfig() {
        return this.networkConfig;
    }

    setUpgradeSchedule(schedule: UpdateSettings) {
        this.upgradeSchedule = schedule;
        this.upgradeScheduleChanged.next(this.upgradeSchedule);
    }

    getUpgradeSchedule() {
        return this.upgradeSchedule;
    }

    setSelectedPbxType(selectedPbxType: string) {
        this.selectedPbxType = selectedPbxType;
        this.selectedPbxTypeChanged.next(this.selectedPbxType);
    }

    getSelectedPbxType() {
        return this.selectedPbxType;
    }

    getApplicationName(preReqPbxType ?: String) {
        let type;
        if(preReqPbxType) {
          type = preReqPbxType;
        } else if (this.pbxlink) {
            type = this.pbxlink.type;
        } else if (this.selectedPbxType) {
            type = this.selectedPbxType;
        }
        switch (type) {
            case pbxTypes.MIVO250:
                return applicationNames.OFFICELINK;
            case pbxTypes.MIVB:
            case pbxTypes.MIVO400:
            case pbxTypes.MIVC:
            case pbxTypes.MXONE:
            case pbxTypes.MV5000:
                return applicationNames.MOMA;
            default:
                return applicationNames.OFFICELINK;
        }
    }

  getApplicationNameLong() {
    let type;
    if (this.pbxlink) {
      type = this.pbxlink.type;
    } else if (this.selectedPbxType) {
      type = this.selectedPbxType;
    }
    switch (type) {
      case pbxTypes.MIVO250:
        return applicationNamesLong.OFFICELINK;
      case pbxTypes.MIVB:
      case pbxTypes.MIVO400:
      case pbxTypes.MIVC:
        return applicationNamesLong.MOMA;
      default:
        return applicationNamesLong.OFFICELINK;
    }
  }

    setPlatform(platform: any) {
        this.platform = platform;
        this.platformChanged.next(this.platform);
    }

    getPlatform() {
        return this.platform;
    }

    setCloudlinkStatus(status: CloudlinkGatewayStatus) {
        this.cloudlinkStatus = status;
        this.cloudlinkStatusChanged.next(this.cloudlinkStatus);
    }

    getCloudlinkStatus() {
        return this.cloudlinkStatus;
    }
}
