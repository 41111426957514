import { Injectable, InjectionToken, Inject } from '@angular/core';

export const WindowToken = new InjectionToken('Window');
export function windowProvider() {return window;}

@Injectable({
    providedIn: 'root'
})
export class ParentCommsService {
    tunnelUsed: boolean = false;
    private isIframedSupport: boolean = false;

    constructor(@Inject(WindowToken) private window: Window){};

    get isIframedSupportPage(): boolean {
        return this.isIframedSupport;
    }

    set isIframedSupportPage(newValue: boolean) {
        this.isIframedSupport = newValue
    }
    
    tellParentToExitGatewayPortal(redirectDestination?: string, insideIframe: boolean = true) {
        console.log('Sending done integration message to parent');
        this.window.parent.postMessage(
            {
                type: "CL_DONEINTEGRATION",
                tunnelUsed: this.tunnelUsed,
                redirectDestination: redirectDestination? redirectDestination : null,
                fromInsideIframe: insideIframe
            },
            '*'
        );
    }
    
    tellParentToRedirectToLogin() {
        this.window.parent.postMessage("CL_REDIRECT_LOGIN", '*');
    }

    isInsideIframe() {
        console.log("isIframed: ",  this.window.location !== this.window.parent.location);
        return this.window.location !== this.window.parent.location;
    }

    tellParentIfUnsavedChangesExist(state: boolean){
        this.window.parent.postMessage(
            {
                type: "CL_UNSAVEDCHANGES",
                unsavedChanges: state
            },
            '*'
        );
    }

  tellParentToSendFeatureFlags() {
    if (this.isInsideIframe()) {
      window.parent.postMessage("CL_GET_FEATURE_FLAGS", '*');
      return true;
    }
    return false;
  }

}