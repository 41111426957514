import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {
	alert = {
		type: null,
		message: null,
	};
	alertSubscription: Subscription;

  constructor(private alertSvc: AlertService) { }

  ngOnInit() {
  	this.alert = this.alertSvc.getAlert();
  	this.alertSubscription = this.alertSvc.alertChanged
  												.subscribe(alert => {
  													this.alert = alert;
  												});
  }

  setClass() {
  	if (this.alert) {
  		return this.alert.type;
  	}
  }

	onClose() {
		this.alert.type = null;
		this.alert.message = null;
	}

	ngOnDestroy() {
		if (this.alertSubscription) {
			this.alertSubscription.unsubscribe();
		}
	}
}
