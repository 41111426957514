// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-tooltip {
	position: absolute;
}

.app-tooltip:focus {
	outline: 0;
}

.container {
	width: 320px;
	border: 1px solid #BFBFC0;
	border-bottom: 4px solid #BFBFC0;
	position: relative;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 12px;
	z-index: 1000;
	background-color: #FFF;
	cursor: move
}

.container:before {
  content: "";
  position: absolute;
  top: 15px;
  right: 98%;
  height: 8px;
  width: 8px;
  background: #FFF;
  transform: rotate(45deg) translate(-50%);
  border-bottom: 1px solid #BFBFC0;
  border-left: inherit;
  box-shadow: inherit;
}

span > img {
	width: 14px;
}

.title {
	font-family: MuseoSans-500;
	font-size: 13px;
	color: #404141;
	margin-bottom: 10px;
	text-align: left;
}

.close-button {
	margin-top: -6px;
	padding-right: 13px;
}

.content {
	font-family: MuseoSans-100;
	font-size: 11px;
	color: #404141;
	line-height: 1.5;
	text-align: left;
}

.spacer {
	height: 18px;
}
.help-link {
	cursor: pointer;
	font-size: 12px;
	font-weight: bold;
	float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/components/tooltip-content/tooltip-content.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,YAAY;CACZ,yBAAyB;CACzB,gCAAgC;CAChC,kBAAkB;CAClB,gBAAgB;CAChB,kBAAkB;CAClB,mBAAmB;CACnB,oBAAoB;CACpB,aAAa;CACb,sBAAsB;CACtB;AACD;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;EACX,UAAU;EACV,gBAAgB;EAChB,wCAAwC;EACxC,gCAAgC;EAChC,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb;AACA;CACC,eAAe;CACf,eAAe;CACf,iBAAiB;CACjB,YAAY;AACb","sourcesContent":[".app-tooltip {\n\tposition: absolute;\n}\n\n.app-tooltip:focus {\n\toutline: 0;\n}\n\n.container {\n\twidth: 320px;\n\tborder: 1px solid #BFBFC0;\n\tborder-bottom: 4px solid #BFBFC0;\n\tposition: relative;\n\tpadding-top: 8px;\n\tpadding-left: 10px;\n\tpadding-right: 10px;\n\tpadding-bottom: 12px;\n\tz-index: 1000;\n\tbackground-color: #FFF;\n\tcursor: move\n}\n\n.container:before {\n  content: \"\";\n  position: absolute;\n  top: 15px;\n  right: 98%;\n  height: 8px;\n  width: 8px;\n  background: #FFF;\n  transform: rotate(45deg) translate(-50%);\n  border-bottom: 1px solid #BFBFC0;\n  border-left: inherit;\n  box-shadow: inherit;\n}\n\nspan > img {\n\twidth: 14px;\n}\n\n.title {\n\tfont-family: MuseoSans-500;\n\tfont-size: 13px;\n\tcolor: #404141;\n\tmargin-bottom: 10px;\n\ttext-align: left;\n}\n\n.close-button {\n\tmargin-top: -6px;\n\tpadding-right: 13px;\n}\n\n.content {\n\tfont-family: MuseoSans-100;\n\tfont-size: 11px;\n\tcolor: #404141;\n\tline-height: 1.5;\n\ttext-align: left;\n}\n\n.spacer {\n\theight: 18px;\n}\n.help-link {\n\tcursor: pointer;\n\tfont-size: 12px;\n\tfont-weight: bold;\n\tfloat: right;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
