import * as moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class AutoUpdateScheduleService {

    private frequencies = ['Daily', 'Weekly'];
    private weekdays = [
        { value: 'Sun', option: 'Sunday' },
        { value: 'Mon', option: 'Monday' },
        { value: 'Tue', option: 'Tuesday' },
        { value: 'Wed', option: 'Wednesday' },
        { value: 'Thu', option: 'Thursday' },
        { value: 'Fri', option: 'Friday' },
        { value: 'Sat', option: 'Saturday' },
    ];
    private timeOptions = [
        { value: '0', option: '00:00' },
        { value: '1', option: '01:00' },
        { value: '2', option: '02:00' },
        { value: '3', option: '03:00' },
        { value: '4', option: '04:00' },
        { value: '5', option: '05:00' },
        { value: '6', option: '06:00' },
        { value: '7', option: '07:00' },
        { value: '8', option: '08:00' },
        { value: '9', option: '09:00' },
        { value: '10', option: '10:00' },
        { value: '11', option: '11:00' },
        { value: '12', option: '12:00' },
        { value: '13', option: '13:00' },
        { value: '14', option: '14:00' },
        { value: '15', option: '15:00' },
        { value: '16', option: '16:00' },
        { value: '17', option: '17:00' },
        { value: '18', option: '18:00' },
        { value: '19', option: '19:00' },
        { value: '20', option: '20:00' },
        { value: '21', option: '21:00' },
        { value: '22', option: '22:00' },
        { value: '23', option: '23:00' },
    ];
    private dayToDate = [];
    private baseTimezone = 'GMT';
    private defaultSchedule;
    constructor() {
        this.setDefaultSchedule();
    }

    private setDefaultSchedule() {
        // Find the nearest Sunday after today.
        let sunday = 7; // 7 is the last index of the days ie. 1 means Monday, 2 means Tuesday, etc. (THERE IS NO 0 INDEX)
        let nearestSunday;

        nearestSunday = moment().isoWeekday(sunday);

        // Unlike days, months are indexed: so January is 0, February is 1, etc.
        this.defaultSchedule = nearestSunday.year() + '-' + ("0" + (nearestSunday.month() + 1)).substr(-2) + '-' + ("0" + nearestSunday.date()).substr(-2) + ' 02:00';

        // Populate dayToDate array for the rest of the week
        for (let i = 0; i < 7; i++) {
            this.dayToDate.push({
                day: this.weekdays[i].value,
                date: nearestSunday.year() + '-' + ("0" + (nearestSunday.month() + 1)).substr(-2) + '-' + ("0" + nearestSunday.date()).substr(-2)
            });
            nearestSunday.add(1, 'd');
        }
    }

    getFrequencies() {
        return this.frequencies;
    }

    getWeekdays() {
        return this.weekdays;
    }

    getTimeOptions() {
        return this.timeOptions;
    }

    getDate(day: string) {
        const theDate = this.dayToDate.find(d => {
            return d.day === day;
        });
        return theDate.date;
    }

    getTime(time: string) {
        const timeStr = time.toString();
        const theTime = this.timeOptions.find(t => {
            return t.value === timeStr;
        });

        return theTime.option;
    }

    getBaseTimezone() {
        return this.baseTimezone;
    }

    getDefaultSchedule() {
        return this.defaultSchedule;
    }
}
