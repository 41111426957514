import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { TooltipService } from '../../services/tooltip.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-deactivate-confirmation',
  templateUrl: './deactivate-confirmation.component.html',
  styleUrls: ['./deactivate-confirmation.component.css']
})
export class DeactivateConfirmationComponent implements OnInit {
	@Input() title: string;
  @Input() deactivating = false;
	@Output() deactivate = new EventEmitter();
  deactivateInput: string;

  constructor(
    private activeModal: NgbActiveModal,
    private tooltipService: TooltipService,
    private translateSvc: TranslateService
  ) { }

  ngOnInit() {}

  onClose() {
  	this.activeModal.close();
  }

  onKeypress(event) {
    if (event.keyCode === 13) {
      this.onDeactivate();
    }
  }

  onDeactivate() {
    this.translateSvc.get("deactivateConfirmation.deactivate").subscribe((res: string) => {
      if (this.deactivateInput === res)
        this.deactivate.emit();
    });
  }

  infoIconItem(labelName: string): any {
    return this.tooltipService.getTooltipItem(labelName);
  }
}
