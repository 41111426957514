import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-connect-error',
  templateUrl: './connect-error.component.html',
  styleUrls: ['./connect-error.component.css']
})
export class ConnectErrorComponent  {
  @Output() continue = new EventEmitter();
  constructor(private activeModal: NgbActiveModal) { }


  onClose() {
    this.activeModal.close();
  }

  onContinue() {
    this.continue.emit();
    this.onClose();
  }

}
