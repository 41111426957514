import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ParentCommsService } from '../../services/parent-comms.service';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-error-permission-modal',
    templateUrl: './error-permission-modal.component.html',
    styleUrls: ['./error-permission-modal.component.css']
})
export class ErrorPermissionsModalComponent implements OnInit {
    @Input() lastRequest: any;
    @Output() continue = new EventEmitter();
    @Output() logout = new EventEmitter();
    @Output() close = new EventEmitter();
    
    isIframed: boolean;
    isAccountAdmin: boolean;
    isFromDashboard: boolean;
    
    constructor(
        private activeModal: NgbActiveModal, 
        private translateSvc: TranslateService, 
        private parentCommsService: ParentCommsService,
        private appService: AppService) { }

    ngOnInit() {
        console.log('error dialog for ', this.lastRequest);
        this.isAccountAdmin = this.appService.isAccountAdmin();
        this.isIframed = this.parentCommsService.isInsideIframe();
    }

    onOk() {
        this.continue.emit();
        this.onClose();
    }

    onLogout() {
        this.logout.emit();
        this.onClose();
    }

    onClose() {
        this.close.emit();
        this.activeModal.close();
    }

}
