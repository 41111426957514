import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-system-options-confirmation',
  templateUrl: './system-options-confirmation.component.html',
  styleUrls: ['./system-options-confirmation.component.css']
})
export class SystemOptionsConfirmationComponent implements OnInit {
	@Input() title: string;
	@Input() keyword: string;
	@Input()  customerName: string;
	@Input()  customerAddress: string;
	@Output() confirm = new EventEmitter();
	placeholder: string;
  keywordInput: string;

  constructor(private activeModal: NgbActiveModal, private translateSvc: TranslateService) { }

  ngOnInit() {
  	this.placeholder = `type '${this.keyword}'`;
  }

  onClose() {
  	this.activeModal.close();
  }

  onKeypress(event) {
    if (event.keyCode === 13) {
      this.onConfirm();
    }
  }

  onConfirm() {
    this.translateSvc.get('systemOptionsConfirmation.' + this.keyword).subscribe((res: string) => {
      if (this.keywordInput === res)
        this.confirm.emit();
    });
  }
}
