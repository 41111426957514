import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

type mivbSync = {
  _links: {
    self: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class MivbProvisioningService {

  constructor(private http: HttpClient, private authSvc: AuthenticationService) { }

  public async startSyncForMivb(accountId: string, siteId: string) {
    const requestParams = await this.getSyncParam(accountId, siteId);

    return firstValueFrom(this.http.post(requestParams.path, requestParams.body, requestParams.httpOptions)) as Promise<mivbSync>;
  }

  public async getSyncParam(accountId: string, siteId: string) {
    const path = `${environment.mivbProvisioningUrl}/accounts/${accountId}/sites/${siteId}/sync`;

    let body;
    const token = await this.authSvc.getToken();
    const httpOptions = {
        headers: new HttpHeaders({
            'content-type': 'application/json',
            'authorization': `Bearer ${token.access_token}`
        })
    };

    return {
        path,
        body,
        httpOptions
    }
  }
}
