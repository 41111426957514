export interface Countries {
  country: string;
  code: string;
  cityLabel?: string;
  states?: States[];
  required?: boolean;
  postalCode?: boolean;
  postalCodeRequired?: boolean;
}

export interface States {
  name: string;
  abbreviation?: string;
}

export const supportedCountries: Countries[] = [
  {
    country: 'United Arab Emirates',
    code: 'AE',
    cityLabel: 'cityOrTown',
    postalCode: false,
    required: true,
    states: [
      {
        name: 'Abu Dhabi',
        abbreviation: 'AZ'
      },
      {
        name: 'Ajman',
        abbreviation: 'AJ'
      },
      {
        name: 'Fujairah',
        abbreviation: 'FU'
      },
      {
        name: 'Dubai',
        abbreviation: 'DU'
      },
      {
        name: 'Ras al Khaimah',
        abbreviation: 'RK'
      },
      {
        name: 'Sharjah',
        abbreviation: 'SH'
      },
      {
        name: 'Umm al Quwain',
        abbreviation: 'UQ'
      },
    ]
  },
  {
    country: 'Brazil',
    code: 'BR',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true,
    required: true,
    states: [
      {
        name: 'Acre',
        abbreviation: 'AC'
      },
      {
        name: 'Alagoas',
        abbreviation: 'AL'
      },
      {
        name: 'Amazonas',
        abbreviation: 'AM'
      },
      {
        name: 'Amapá',
        abbreviation: 'AP'
      },
      {
        name: 'Bahia',
        abbreviation: 'BA'
      },
      {
        name: 'Ceará',
        abbreviation: 'CE'
      },
      {
        name: 'Distrito Federal',
        abbreviation: 'DF'
      },
      {
        name: 'Espírito Santo',
        abbreviation: 'ES'
      },
      {
        name: 'Goiás',
        abbreviation: 'GO'
      },
      {
        name: 'Maranhão',
        abbreviation: 'MA'
      },
      {
        name: 'Minas Gerais',
        abbreviation: 'MG'
      },
      {
        name: 'Mato Grosso do Sul',
        abbreviation: 'MS'
      },
      {
        name: 'Mato Grosso',
        abbreviation: 'MT'
      },
      {
        name: 'Pará',
        abbreviation: 'PA'
      },
      {
        name: 'Paraíba',
        abbreviation: 'PB'
      },
      {
        name: 'Pernambuco',
        abbreviation: 'PE'
      },
      {
        name: 'Piauí',
        abbreviation: 'PI'
      },
      {
        name: 'Paraná',
        abbreviation: 'PR'
      },
      {
        name: 'Rio de Janeiro',
        abbreviation: 'RJ'
      },
      {
        name: 'Rio Grande do Norte',
        abbreviation: 'RN'
      },
      {
        name: 'Rondônia',
        abbreviation: 'RO'
      },
      {
        name: 'Roraima',
        abbreviation: 'RR'
      },
      {
        name: 'Rio Grande do Sul',
        abbreviation: 'RS'
      },
      {
        name: 'Sergipe',
        abbreviation: ''
      },
      {
        name: 'São Paulo',
        abbreviation: 'SP'
      },
      {
        name: 'Tocantins',
        abbreviation: 'TO'
      }
    ]
  },
  {
    country: 'Mexico',
    code: 'MX',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true,
    required: true,
    states: [
      {
        name: 'Aguascalientes',
        abbreviation: 'AG'
      },
      {
        name: 'Baja California Norte',
        abbreviation: 'BC'
      },
      {
        name: 'Baja California Sur',
        abbreviation: 'BS'
      },
      {
        name: 'Chihuahua',
        abbreviation: 'CH'
      },
      {
        name: 'Colima',
        abbreviation: 'CL'
      },
      {
        name: 'Campeche',
        abbreviation: 'CM'
      },
      {
        name: 'Chiapas',
        abbreviation: 'CS'
      },
      {
        name: 'CDMX',
        abbreviation: 'CDMX'
      },
      {
        name: 'Durango',
        abbreviation: 'DG'
      },
      {
        name: 'Guerrero',
        abbreviation: 'GR'
      },
      {
        name: 'Guanajuato',
        abbreviation: 'GT'
      },
      {
        name: 'Hidalgo',
        abbreviation: 'HG'
      },
      {
        name: 'Jalisco',
        abbreviation: 'JA'
      },
      {
        name: 'México',
        abbreviation: 'EM'
      },
      {
        name: 'Michoacan',
        abbreviation: 'MI'
      },
      {
        name: 'Nayarit',
        abbreviation: 'NA'
      },
      {
        name: 'Nuevo Leon',
        abbreviation: 'NL'
      },
      {
        name: 'Oaxaca',
        abbreviation: 'OA'
      },
      {
        name: 'Puebla',
        abbreviation: 'PU'
      },
      {
        name: 'Quintana Roo',
        abbreviation: 'QR'
      },
      {
        name: 'Queretaro',
        abbreviation: 'QT'
      },
      {
        name: 'Sinaloa',
        abbreviation: 'SI'
      },
      {
        name: 'San Luis Potosi',
        abbreviation: 'SL'
      },
      {
        name: 'Sonora',
        abbreviation: 'SO'
      },
      {
        name: 'Tabasco',
        abbreviation: 'TB'
      },
      {
        name: 'Tlaxcala',
        abbreviation: 'TL'
      },
      {
        name: 'Tamaulipas',
        abbreviation: 'TM'
      },
      {
        name: 'Veracruz',
        abbreviation: 'VE'
      },
      {
        name: 'Yucatan',
        abbreviation: 'YU'
      },
      {
        name: 'Zacatecas',
        abbreviation: 'ZA'
      },
    ]
  },
  {
    country: 'Egypt',
    code: 'EG',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Alexandria',
        abbreviation: 'ALX'
      },
      {
        name: 'Aswan',
        abbreviation: 'ASN'
      },
      {
        name: 'Asyut',
        abbreviation: 'AST'
      },
      {
        name: 'Beheira',
        abbreviation: 'BH'
      },
      {
        name: 'Beni Suef',
        abbreviation: 'BNS'
      },
      {
        name: 'Cairo',
        abbreviation: 'C'
      },
      {
        name: 'Dakahlia',
        abbreviation: 'DK'
      },
      {
        name: 'Damietta',
        abbreviation: 'DT'
      },
      {
        name: 'Faiyum',
        abbreviation: 'FYM'
      },
      {
        name: 'Gharbia',
        abbreviation: 'GH'
      },
      {
        name: 'Giza',
        abbreviation: 'GZ'
      },
      {
        name: 'Ismailia',
        abbreviation: 'IS'
      },
      {
        name: 'Kafr El Sheikh',
        abbreviation: 'KFS'
      },
      {
        name: 'Luxor',
        abbreviation: 'LX'
      },
      {
        name: 'Matruh',
        abbreviation: 'MT'
      },
      {
        name: 'Minya',
        abbreviation: 'MN'
      },
      {
        name: 'Monufia',
        abbreviation: 'MNF'
      },
      {
        name: 'New Valley',
        abbreviation: 'WAD'
      },
      {
        name: 'North Sinai',
        abbreviation: 'SIN'
      },
      {
        name: 'Port Said',
        abbreviation: 'PTS'
      },
      {
        name: 'Qalyubia',
        abbreviation: 'KB'
      },
      {
        name: 'Qena',
        abbreviation: 'KN'
      },
      {
        name: 'Red Sea',
        abbreviation: 'BA'
      },
      {
        name: 'Sharqia',
        abbreviation: 'SHR'
      },
      {
        name: 'Sohag',
        abbreviation: 'SHG'
      },
      {
        name: 'South Sinai',
        abbreviation: 'JS'
      },
      {
        name: 'Suez',
        abbreviation: 'SUZ'
      },
    ]
  }, 
  {
    country: 'India',
    code: 'IN',
    cityLabel: 'cityOrTown',
    required: true,
    postalCode: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Andaman and Nicobar Islands',
        abbreviation: 'AN'
      },
      {
        name: 'Andhra Pradesh',
        abbreviation: 'AP'
      },
      {
        name: 'Arunachal Pradesh',
        abbreviation: 'AR'
      },
      {
        name: 'Assam',
        abbreviation: 'AS'
      },
      {
        name: 'Bihar',
        abbreviation: 'BR'
      },
      {
        name: 'Chandigarh',
        abbreviation: 'CH'
      },
      {
        name: 'Chhattisgarh',
        abbreviation: 'CG'
      },
      {
        name: 'Delhi',
        abbreviation: 'DL'
      },
      {
        name: 'Dadra and Nagar Haveli and Daman and Diu',
        abbreviation: 'DH'
      },
      {
        name: 'Goa',
        abbreviation: 'GA'
      },
      {
        name: 'Gujarat',
        abbreviation: 'GJ'
      },
      {
        name: 'Haryana',
        abbreviation: 'HR'
      },
      {
        name: 'Himachal Pradesh',
        abbreviation: 'HP'
      },
      {
        name: 'Jammu and Kashmir',
        abbreviation: 'JK'
      },
      {
        name: 'Jharkhand',
        abbreviation: 'JH'
      },
      {
        name: 'Karnataka',
        abbreviation: 'KA'
      },
      {
        name: 'Kerala',
        abbreviation: 'KL'
      },
      {
        name: 'Ladakh',
        abbreviation: 'LA'
      },
      {
        name: 'Lakshadweep',
        abbreviation: 'LD'
      },
      {
        name: 'Madhya Pradesh',
        abbreviation: 'MP'
      },
      {
        name: 'Maharashtra',
        abbreviation: 'MH'
      },
      {
        name: 'Manipur',
        abbreviation: 'MN'
      },
      {
        name: 'Meghalaya',
        abbreviation: 'ML'
      },
      {
        name: 'Mizoram',
        abbreviation: 'MZ'
      },
      {
        name: 'Nagaland',
        abbreviation: 'NL'
      },
      {
        name: 'Odisha',
        abbreviation: 'OR'
      },
      {
        name: 'Puducherry',
        abbreviation: 'PY'
      },
      {
        name: 'Punjab',
        abbreviation: 'PB'
      },
      {
        name: 'Rajasthan',
        abbreviation: 'RJ'
      },
      {
        name: 'Sikkim',
        abbreviation: 'SK'
      },
      {
        name: 'Tamil Nadu',
        abbreviation: 'TN'
      },
      {
        name: 'Telangana',
        abbreviation: 'TS'
      },
      {
        name: 'Tripura',
        abbreviation: 'TR'
      },
      {
        name: 'Uttar Pradesh',
        abbreviation: 'UP'
      },
      {
        name: 'Uttarakhand',
        abbreviation: 'UK'
      },
      {
        name: 'West Bengal',
        abbreviation: 'WB'
      }
    ]
  },
  {
    country: 'Israel',
    code: 'IL',
    cityLabel: 'cityOrTown',
    required: true,
    postalCode: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'HaDarom',
        abbreviation: 'South District'
      },
      {
        name: 'HaMerkaz',
        abbreviation: 'Center District'
      },
      {
        name: 'HaTsafon',
        abbreviation: 'North District'
      },
      {
        name: 'H̱efa',
        abbreviation: 'Haifa District'
      },
      {
        name: 'Tel Aviv',
        abbreviation: 'Tel Aviv District'
      },
      {
        name: 'Yerushalayim',
        abbreviation: 'Jerusalem District'
      }
    ]
  },
  {
    country: 'Jordan',
    code: 'JO',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Ajlun',
        abbreviation: 'AJ'
      },
      {
        name: 'Amman',
        abbreviation: 'AM'
      },
      {
        name: 'Aqaba',
        abbreviation: 'AQ'
      },
      {
        name: 'Balqa',
        abbreviation: 'BA'
      },
      {
        name: 'Irbid',
        abbreviation: 'IR'
      },
      {
        name: 'Jarash',
        abbreviation: 'JA'
      },
      {
        name: 'Karak',
        abbreviation: 'KA'
      },
      {
        name: 'Ma`an',
        abbreviation: 'MN'
      },
      {
        name: 'Madaba',
        abbreviation: 'MD'
      },
      {
        name: 'Mafraq',
        abbreviation: 'MA'
      },
      {
        name: 'Tafilah',
        abbreviation: 'AT'
      },
      {
        name: 'Zarqa',
        abbreviation: 'AZ'
      },
    ]
  },
  {
    country: 'Curaçao',
    code: 'CW',
    cityLabel: 'town',
    postalCodeRequired: false,
    postalCode: false
  },
  {
    country: 'Madagascar',
    code: 'MG',
    cityLabel: 'town',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Antananarivo',
        abbreviation: 'T'
      },
      {
        name: 'Antsiranana',
        abbreviation: 'D'
      },
      {
        name: 'Fianarantsoa',
        abbreviation: 'F'
      },
      {
        name: 'Mahajanga',
        abbreviation: 'M'
      },
      {
        name: 'Toamasina',
        abbreviation: 'A'
      },
      {
        name: 'Toliara',
        abbreviation: 'U'
      },
    ]
  },
  {
    country: 'Colombia',
    code: 'CO',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Amazonas',
        abbreviation: 'AMA'
      },
      {
        name: 'Antioquia',
        abbreviation: 'ANT'
      },
      {
        name: 'Arauca',
        abbreviation: 'ARA'
      },
      {
        name: 'Atlántico',
        abbreviation: 'ATL'
      },
      {
        name: 'Bolívar',
        abbreviation: 'BOL'
      },
      {
        name: 'Boyacá',
        abbreviation: 'BOY'
      },
      {
        name: 'Caldas',
        abbreviation: 'CAL'
      },
      {
        name: 'Caquetá',
        abbreviation: 'CAQ'
      },
      {
        name: 'Cauca',
        abbreviation: 'CAU'
      },
      {
        name: 'Casanare',
        abbreviation: 'CAS'
      },
      {
        name: 'Cesar',
        abbreviation: 'CES'
      },
      {
        name: 'Chocó',
        abbreviation: 'CHO'
      },
      {
        name: 'Córdoba',
        abbreviation: 'COR'
      },
      {
        name: 'Cundinamarca',
        abbreviation: 'CUN'
      },
      {
        name: 'Guainía',
        abbreviation: 'GUA'
      },
      {
        name: 'Guaviare',
        abbreviation: 'GUV'
      },
      {
        name: 'Huila',
        abbreviation: 'HUI'
      },
      {
        name: 'La Guajira',
        abbreviation: 'LAG'
      },
      {
        name: 'Magdalena',
        abbreviation: 'MAG'
      },
      {
        name: 'Meta',
        abbreviation: 'MET'
      },
      {
        name: 'Nariño',
        abbreviation: 'NAR'
      },
      {
        name: 'Norte de Santander',
        abbreviation: 'NSA'
      },
      {
        name: 'Putumayo',
        abbreviation: 'PUT'
      },
      {
        name: 'Quindío',
        abbreviation: 'GUI'
      },
      {
        name: 'Risaralda',
        abbreviation: 'RIS'
      },
      {
        name: 'San Andrés y Providencia',
        abbreviation: 'SAP'
      },
      {
        name: 'Santander',
        abbreviation: 'SAN'
      },
      {
        name: 'Sucre',
        abbreviation: 'SUC'
      },
      {
        name: 'Tolima',
        abbreviation: 'TOL'
      },
      {
        name: 'Valle del Cauca',
        abbreviation: 'VAC'
      },
      {
        name: 'Vaupés',
        abbreviation: 'VAU'
      },
      {
        name: 'Vichada',
        abbreviation: 'VID'
      },
      {
        name: 'Bogotá',
        abbreviation: 'Bogotá'
      }
    ]
  },
  {
    country: 'El Salvador',
    code: 'SV',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Ahuachapán',
        abbreviation: 'AH'
      },
      {
        name: 'Cabañas',
        abbreviation: 'CA'
      },
      {
        name: 'Chalatenango',
        abbreviation: 'CH'
      },
      {
        name: 'Cuscatlán',
        abbreviation: 'CU'
      },
      {
        name: 'La Liberdad',
        abbreviation: 'LI'
      },
      {
        name: 'La Paz',
        abbreviation: 'PA'
      },
      {
        name: 'La Unión',
        abbreviation: 'UN'
      },
      {
        name: 'Morazán',
        abbreviation: 'MO'
      },
      {
        name: 'San Miguel',
        abbreviation: 'SM'
      },
      {
        name: 'San Salvador',
        abbreviation: 'SS'
      },
      {
        name: 'San Vicente',
        abbreviation: 'SV'
      },
      {
        name: 'Santa Ana',
        abbreviation: 'SA'
      },
      {
        name: 'Sonsonate',
        abbreviation: 'SO'
      },
      {
        name: 'Usulatán',
        abbreviation: 'US'
      }
    ]
  },
  {
    country: 'Martinique',
    code: 'MQ',
    cityLabel: 'town',
    postalCode: true,
    postalCodeRequired: true,
  },
  {
    country: 'Guadeloupe',
    code: 'GP',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Singapore',
    code: 'SG',
    cityLabel: 'city', // It is a city-state
    postalCodeRequired: true,
    postalCode: true,
  },
  {
    country: 'Bosnia and Herzegovina',
    code: 'BA',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true,
  },
  {
    country: 'Réunion',
    code: 'RE',
    cityLabel: 'town',
    postalCode: true,
    postalCodeRequired: true,
  },
  {
    country: 'Kuwait',
    code: 'KW',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true
  },
  {
    country: 'Chile',
    code: 'CL',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Aisén del General Carlos Ibañez del Campo',
        abbreviation: 'Aisén del General Carlos Ibañez del Campo'
      },
      {
        name: 'Antofagasta',
        abbreviation: 'Antofagasta'
      },
      {
        name: 'Arica y Parinacota',
        abbreviation: 'Arica y Parinacota'
      },
      {
        name: 'Atacama',
        abbreviation: 'Atacama'
      },
      {
        name: 'Biobío',
        abbreviation: 'Biobío'
      },
      {
        name: 'Coquimbo',
        abbreviation: 'Coquimbo'
      },
      {
        name: 'La Araucanía',
        abbreviation: 'La Araucanía'
      },
      {
        name: 'Libertador General Bernardo O\'Higgins',
        abbreviation: 'LI'
      },
      {
        name: 'Los Lagos',
        abbreviation: 'Los Lagos'
      },
      {
        name: 'Los Ríos',
        abbreviation: 'Los Ríos'
      },
      {
        name: 'Magallanes',
        abbreviation: 'Magallanes'
      },
      {
        name: 'Maule',
        abbreviation: 'Maule'
      },
      {
        name: 'Región Metropolitana',
        abbreviation: 'Región Metropolitana'
      },
      {
        name: 'Tarapacá',
        abbreviation: 'Tarapacá'
      },
      {
        name: 'Valparaíso',
        abbreviation: 'Valparaíso'
      },
      {
        name: 'Ñuble',
        abbreviation: 'Ñuble'
      },
    ]
  },
  {
    country: 'Argentina',
    code: 'AR',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Provincia de Buenos Aires',
        abbreviation: 'Provincia de Buenos Aires'
      },
      {
        name: 'Catamarca',
        abbreviation: 'Catamarca'
      },
      {
        name: 'Chaco',
        abbreviation: 'Chaco'
      },
      {
        name: 'Chubut',
        abbreviation: 'Chubut'
      },
      {
        name: 'Ciudad Autónoma de Buenos Aires',
        abbreviation: 'Ciudad Autónoma de Buenos Aires'
      },
      {
        name: 'Corrientes',
        abbreviation: 'Corrientes'
      },
      {
        name: 'Córdoba',
        abbreviation: 'Córdoba'
      },
      {
        name: 'Entre Ríos',
        abbreviation: 'Entre Ríos'
      },
      {
        name: 'Formosa',
        abbreviation: 'Formosa'
      },
      {
        name: 'Jujuy',
        abbreviation: 'Jujuy'
      },
      {
        name: 'La Pampa',
        abbreviation: 'La Pampa'
      },
      {
        name: 'La Rioja',
        abbreviation: 'La Rioja'
      },
      {
        name: 'Mendoza',
        abbreviation: 'Mendoza'
      },
      {
        name: 'Misiones',
        abbreviation: 'Misiones'
      },
      {
        name: 'Neuquén',
        abbreviation: 'Neuquén'
      },
      {
        name: 'Río Negro',
        abbreviation: 'Río Negro'
      },
      {
        name: 'Salta',
        abbreviation: 'Salta'
      },
      {
        name: 'San Juan',
        abbreviation: 'San Juan'
      },
      {
        name: 'San Luis',
        abbreviation: 'San Luis'
      },
      {
        name: 'Santa Cruz',
        abbreviation: 'Santa Cruz'
      },
      {
        name: 'Santa Fe',
        abbreviation: 'Santa Fe'
      },
      {
        name: 'Santiago del Estero',
        abbreviation: 'Santiago del Estero'
      },
      {
        name: 'Tierra del Fuego',
        abbreviation: 'Tierra del Fuego'
      },
      {
        name: 'Tucumán',
        abbreviation: 'Tucumán'
      }
    ]
  },
  {
    country: 'Tanzania',
    code: 'TZ',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Arusha Region',
        abbreviation: 'Arusha Region'
      },
      {
        name: 'Dar es Salaam Region',
        abbreviation: 'Dar es Salaam Region'
      },
      {
        name: 'Dodoma Region',
        abbreviation: 'Dodoma Region'
      },
      {
        name: 'Geita Region',
        abbreviation: 'Geita Region'
      },
      {
        name: 'Iringa Region',
        abbreviation: 'Iringa Region'
      },
      {
        name: 'Kagera Region',
        abbreviation: 'Kagera Region'
      },
      {
        name: 'Kaskazini Pemba Region',
        abbreviation: 'Kaskazini Pemba Region'
      },
      {
        name: 'Kaskazini Unguja Region',
        abbreviation: 'Kaskazini Unguja Region'
      },
      {
        name: 'Katavi Region',
        abbreviation: 'Katavi Region'
      },
      {
        name: 'Kigoma Region',
        abbreviation: 'Kigoma Region'
      },
      {
        name: 'Kilimanjaro Region',
        abbreviation: 'Kilimanjaro Region'
      },
      {
        name: 'Kusini Pemba Region',
        abbreviation: 'Kusini Pemba Region'
      },
      {
        name: 'Kusini Unguja Region',
        abbreviation: 'Kusini Unguja Region'
      },
      {
        name: 'Lindi Region',
        abbreviation: 'Lindi Region'
      },
      {
        name: 'Manyara Region',
        abbreviation: 'Manyara Region'
      },
      {
        name: 'Mara Region',
        abbreviation: 'Mara Region'
      },
      {
        name: 'Mbeya Region',
        abbreviation: 'Mbeya Region'
      },
      {
        name: 'Mjini Magharibi Region',
        abbreviation: 'Mjini Magharibi Region'
      },
      {
        name: 'Morogoro Region',
        abbreviation: 'Morogoro Region'
      },
      {
        name: 'Mtwara Region',
        abbreviation: 'Mtwara Region'
      },
      {
        name: 'Mwanza Region',
        abbreviation: 'Mwanza Region'
      },
      {
        name: 'Njombe Region',
        abbreviation: 'Njombe Region'
      },
      {
        name: 'Pwani Region',
        abbreviation: 'Pwani Region'
      },
      {
        name: 'Rukwa Region',
        abbreviation: 'Rukwa Region'
      },
      {
        name: 'Ruvuma Region',
        abbreviation: 'Ruvuma Region'
      },
      {
        name: 'Shinyanga Region',
        abbreviation: 'Shinyanga Region'
      },
      {
        name: 'Simiyu Region',
        abbreviation: 'Simiyu Region'
      },
      {
        name: 'Singida Region',
        abbreviation: 'Singida Region'
      },
      {
        name: 'Songwe Region',
        abbreviation: 'Songwe Region'
      },
      {
        name: 'Tabora Region',
        abbreviation: 'Tabora Region'
      },
      {
        name: 'Tanga Region',
        abbreviation: 'Tanga Region'
      },
    ]
  },
  {
    country: 'Panama',
    code: 'PA',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Bocas del Toro',
        abbreviation: 'Bocas del Toro'
      },
      {
        name: 'Chiriquí',
        abbreviation: 'Chiriquí'
      },
      {
        name: 'Coclé',
        abbreviation: 'Coclé'
      },
      {
        name: 'Colón',
        abbreviation: 'Colón'
      },
      {
        name: 'Darién',
        abbreviation: 'Darién'
      },
      {
        name: 'Emberá',
        abbreviation: 'Emberá'
      },
      {
        name: 'Guna Yala',
        abbreviation: 'Guna Yala'
      },
      {
        name: 'Herrera',
        abbreviation: 'Herrera'
      },
      {
        name: 'Los Santos',
        abbreviation: 'Los Santos'
      },
      {
        name: 'Ngöbe-Buglé',
        abbreviation: 'Ngöbe-Buglé'
      },
      {
        name: 'Panamá',
        abbreviation: 'Panamá'
      },
      {
        name: 'Panamá Oeste',
        abbreviation: 'Panamá Oeste'
      },
      {
        name: 'Veraguas',
        abbreviation: 'Veraguas'
      }
    ]
  },
  {
    country: 'Guatemala',
    code: 'GT',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Alta Verapaz',
        abbreviation: 'Alta Verapaz'
      },
      {
        name: 'Baja Verapaz',
        abbreviation: 'Baja Verapaz'
      },
      {
        name: 'Chimaltenango',
        abbreviation: 'Chimaltenango'
      },
      {
        name: 'Chiquimula',
        abbreviation: 'Chiquimula'
      },
      {
        name: 'El Progreso',
        abbreviation: 'El Progreso'
      },
      {
        name: 'Escuintla',
        abbreviation: 'Escuintla'
      },
      {
        name: 'Guatemala',
        abbreviation: 'Guatemala'
      },
      {
        name: 'Huehuetenango',
        abbreviation: 'Huehuetenango'
      },
      {
        name: 'Izabal',
        abbreviation: 'Izabal'
      },
      {
        name: 'Jalapa',
        abbreviation: 'Jalapa'
      },
      {
        name: 'Jutiapa',
        abbreviation: 'Jutiapa'
      },
      {
        name: 'Petén',
        abbreviation: 'Petén'
      },
      {
        name: 'Quetzaltenango',
        abbreviation: 'Quetzaltenango'
      },
      {
        name: 'Quiché',
        abbreviation: 'Quiché'
      },
      {
        name: 'Retalhuleu',
        abbreviation: 'Retalhuleu'
      },
      {
        name: 'Sacatepéquez',
        abbreviation: 'Sacatepéquez'
      },
      {
        name: 'San Marcos',
        abbreviation: 'San Marcos'
      },
      {
        name: 'Santa Rosa',
        abbreviation: 'Santa Rosa'
      },
      {
        name: 'Sololá',
        abbreviation: 'Sololá'
      },
      {
        name: 'Suchitepéquez',
        abbreviation: 'Suchitepéquez'
      },
      {
        name: 'Totonicapán',
        abbreviation: 'Totonicapán'
      },
      {
        name: 'Zacapa',
        abbreviation: 'Zacapa'
      }
    ]
  },
  {
    country: 'Ecuador',
    code: 'EC',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Azuay',
        abbreviation: 'Azuay'
      },
      {
        name: 'Bolívar',
        abbreviation: 'Bolívar'
      },
      {
        name: 'Carchi',
        abbreviation: 'Carchi'
      },
      {
        name: 'Cañar',
        abbreviation: 'Cañar'
      },
      {
        name: 'Chimborazo',
        abbreviation: 'Chimborazo'
      },
      {
        name: 'Cotopaxi',
        abbreviation: 'Cotopaxi'
      },
      {
        name: 'El Oro',
        abbreviation: 'El Oro'
      },
      {
        name: 'Esmeraldas',
        abbreviation: 'Esmeraldas'
      },
      {
        name: 'Galápagos',
        abbreviation: 'Galápagos'
      },
      {
        name: 'Guayas',
        abbreviation: 'Guayas'
      },
      {
        name: 'Imbabura',
        abbreviation: 'Imbabura'
      },
      {
        name: 'Loja',
        abbreviation: 'Loja'
      },
      {
        name: 'Los Ríos',
        abbreviation: 'Los Ríos'
      },
      {
        name: 'Manabí',
        abbreviation: 'Manabí'
      },
      {
        name: 'Morona Santiago',
        abbreviation: 'Morona Santiago'
      },
      {
        name: 'Napo',
        abbreviation: 'Napo'
      },
      {
        name: 'Orellana',
        abbreviation: 'Orellana'
      },
      {
        name: 'Pastaza',
        abbreviation: 'Pastaza'
      },
      {
        name: 'Pichincha',
        abbreviation: 'Pichincha'
      },
      {
        name: 'Santa Elena',
        abbreviation: 'Santa Elena'
      },
      {
        name: 'Santo Domingo de los Tsáchilas',
        abbreviation: 'Santo Domingo de los Tsáchilas'
      },
      {
        name: 'Sucumbíos',
        abbreviation: 'Sucumbíos'
      },
      {
        name: 'Tungurahua',
        abbreviation: 'Tungurahua'
      },
      {
        name: 'Zamora Chinchipe',
        abbreviation: 'Zamora Chinchipe'
      }
    ]
  },
  {
    country: 'Bahrain',
    code: 'BH',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true
  },
  {
    country: 'Peru',
    code: 'PE',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Amazonas',
        abbreviation: 'Amazonas'
      },
      {
        name: 'Ancash',
        abbreviation: 'Ancash'
      },
      {
        name: 'Apurímac',
        abbreviation: 'Apurímac'
      },
      {
        name: 'Arequipa',
        abbreviation: 'Arequipa'
      },
      {
        name: 'Ayacucho',
        abbreviation: 'Ayacucho'
      },
      {
        name: 'Cajamarca',
        abbreviation: 'Cajamarca'
      },
      {
        name: 'Cusco',
        abbreviation: 'Cusco'
      },
      {
        name: 'El Callao',
        abbreviation: 'El Callao'
      },
      {
        name: 'Huancavelica',
        abbreviation: 'Huancavelica'
      },
      {
        name: 'Huánuco',
        abbreviation: 'Huánuco'
      },
      {
        name: 'Ica',
        abbreviation: 'Ica'
      },
      {
        name: 'Junín',
        abbreviation: 'Junín'
      },
      {
        name: 'La Libertad',
        abbreviation: 'La Libertad'
      },
      {
        name: 'Lambayeque',
        abbreviation: 'LALambayequeM'
      },
      {
        name: 'Lima',
        abbreviation: 'Lima'
      },
      {
        name: 'Loreto',
        abbreviation: 'Loreto'
      },
      {
        name: 'Madre de Dios',
        abbreviation: 'Madre de Dios'
      },
      {
        name: 'Moquegua',
        abbreviation: 'Moquegua'
      },
      {
        name: 'Municipalidad Metropolitana de Lima',
        abbreviation: 'Municipalidad Metropolitana de Lima'
      },
      {
        name: 'Pasco',
        abbreviation: 'Pasco'
      },
      {
        name: 'Piura',
        abbreviation: 'Piura'
      },
      {
        name: 'Puno',
        abbreviation: 'Puno'
      },
      {
        name: 'San Martín',
        abbreviation: 'San Martín'
      },
      {
        name: 'Tacna',
        abbreviation: 'Tacna'
      },
      {
        name: 'Tumbes',
        abbreviation: 'Tumbes'
      },
      {
        name: 'Ucayali',
        abbreviation: 'Ucayali'
      }
    ]
  },
  {
    country: 'Niger',
    code: 'NE',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true,
  },
  {
    country: 'North Macedonia',
    code: 'MK',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true
  },
  {
    country: 'French Southern Territories',
    code: 'TF',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true,
  },
  {
    country: 'Romania',
    code: 'RO',
    cityLabel: 'cityOrTown',
    postalCode: true,
    required: true,
    postalCodeRequired: true,
    states: [
      {
        name: 'Alba',
        abbreviation: 'AB'
      },
      {
        name: 'Arad',
        abbreviation: 'AR'
      },
      {
        name: 'Argeș',
        abbreviation: 'AG'
      },
      {
        name: 'Bacău',
        abbreviation: 'BC'
      },
      {
        name: 'Bihor',
        abbreviation: 'BH'
      },
      {
        name: 'Bistrița-Năsăud',
        abbreviation: 'BN'
      },
      {
        name: 'Botoșani',
        abbreviation: 'BT'
      },
      {
        name: 'Brașov',
        abbreviation: 'BV'
      },
      {
        name: 'Brăila',
        abbreviation: 'BR'
      },
      {
        name: 'București',
        abbreviation: 'B'
      },
      {
        name: 'Buzău',
        abbreviation: 'BZ'
      },
      {
        name: 'Caraș-Severin',
        abbreviation: 'CS'
      },
      {
        name: 'Cluj',
        abbreviation: 'CJ'
      },
      {
        name: 'Constanța',
        abbreviation: 'CT'
      },
      {
        name: 'Covasna',
        abbreviation: 'CV'
      },
      {
        name: 'Călărași',
        abbreviation: 'CL'
      },
      {
        name: 'Dolj',
        abbreviation: 'DJ'
      },
      {
        name: 'Dâmbovița',
        abbreviation: 'DB'
      },
      {
        name: 'Giurgiu',
        abbreviation: 'GR'
      },
      {
        name: 'Gorj',
        abbreviation: 'GJ'
      },
      {
        name: 'Harghita',
        abbreviation: 'HR'
      },
      {
        name: 'Hunedoara',
        abbreviation: 'HD'
      },
      {
        name: 'Ialomița',
        abbreviation: 'IL'
      },
      {
        name: 'Iași',
        abbreviation: 'IS'
      },
      {
        name: 'Ilfov',
        abbreviation: 'IF'
      },
      {
        name: 'Maramureș',
        abbreviation: 'MM'
      },
      {
        name: 'Mehedinți',
        abbreviation: 'MH'
      },
      {
        name: 'Mureș',
        abbreviation: 'MS'
      },
      {
        name: 'Neamț',
        abbreviation: 'NT'
      },
      {
        name: 'Olt',
        abbreviation: 'OT'
      },
      {
        name: 'Satu Mare',
        abbreviation: 'SM'
      },
      {
        name: 'Sibiu',
        abbreviation: 'SB'
      },
      {
        name: 'Suceava',
        abbreviation: 'SV'
      },
      {
        name: 'Sălaj',
        abbreviation: 'SJ'
      },
      {
        name: 'Teleorman',
        abbreviation: 'TR'
      },
      {
        name: 'Timiș',
        abbreviation: 'TM'
      },
      {
        name: 'Tulcea',
        abbreviation: 'TL'
      },
      {
        name: 'Vaslui',
        abbreviation: 'VS'
      },
      {
        name: 'Vrancea',
        abbreviation: 'VN'
      },
      {
        name: 'Vâlcea',
        abbreviation: 'VL'
      }
    ]
  },
  {
    country: 'Qatar',
    code: 'QA',
    cityLabel: 'cityOrTown',
    postalCode: false,
    postalCodeRequired: false,
  },
  {
    country: 'Mauritius',
    code: 'MU',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true
  },
  {
    country: 'Monaco',
    code: 'MC',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true
  },
  {
    country: 'Kazakhstan',
    code: 'KZ',
    cityLabel: 'cityOrTown',
    postalCode: true,
    postalCodeRequired: true,
  },
  {
    country: 'Australia',
    code: 'AU',
    cityLabel: 'cityOrTown',
    states: [
      {
        name: 'Australian Capital Territory',
        abbreviation: 'ACT'
      },
      {
        name: 'New South Wales',
        abbreviation: 'NSW'
      },
      {
        name: 'Northern Territory',
        abbreviation: 'NT'
      },
      {
        name: 'Queensland',
        abbreviation: 'QLD'
      },
      {
        name: 'South Australia',
        abbreviation: 'SA'
      },
      {
        name: 'Tasmania',
        abbreviation: 'TAS'
      },
      {
        name: 'Victoria',
        abbreviation: 'VIC'
      },
      {
        name: 'Western Australia',
        abbreviation: 'WA'
      }
    ],
    required: true,
    postalCode: true,
    postalCodeRequired: true,
  },
  {
    country: 'Austria',
    code: 'AT',
    cityLabel: 'city',
    states: [
      {
        name: 'Vienna',
        abbreviation: 'Wien'
      },
      {
        name: 'Lower Austria',
        abbreviation: 'Niederösterreich'
      },      
      {
        name: 'Upper Austria',
        abbreviation: 'Oberösterreich'
      },      
      {
        name: 'Styria',
        abbreviation: 'Steiermark'
      },
      {
        name: 'Tyrol',
        abbreviation: 'Tirol'
      },
      {
        name: 'Carinthia',
        abbreviation: 'Kärnten'
      },
      {
        name: 'Salzburg',
        abbreviation: 'Salzburg'
      },
      {
        name: 'Vorarlberg',
        abbreviation: 'Vorarlberg'
      },
      {
        name: 'Burgenland',
        abbreviation: 'Burgenland'
      }
    ],
    required: true,
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Belgium',
    code: 'BE',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Botswana',
    code: 'BW',
    cityLabel: 'cityOrTown',
    postalCodeRequired: false,
    postalCode: false
  },
  {
    country: 'Canada',
    code: 'CA',
    cityLabel: 'city',
    states: [
      {
        name: 'Alberta',
        abbreviation: 'AB'
      },
      {
        name: 'British Columbia',
        abbreviation: 'BC'
      },
      {
        name: 'Manitoba',
        abbreviation: 'MB'
      },
      {
        name: 'New Brunswick',
        abbreviation: 'NB'
      },
      {
        name: 'Newfoundland',
        abbreviation: 'NL'
      },
      {
        name: 'Nova Scotia',
        abbreviation: 'NS'
      },
      {
        name: 'Nunavut',
        abbreviation: 'NU'
      },
      {
        name: 'Ontario',
        abbreviation: 'ON'
      },
      {
        name: 'Prince Edward Island',
        abbreviation: 'PE'
      },
      {
        name: 'Quebec',
        abbreviation: 'QC'
      },
      {
        name: 'Saskatchewan',
        abbreviation: 'SK'
      },
      {
        name: 'Northwest Territories',
        abbreviation: 'NT'
      },
      {
        name: 'Yukon Territory',
        abbreviation: 'YT'
      },
    ],
    required: true,
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Cape Verde',
    code: 'CV',
    cityLabel: 'cityOrTown',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Croatia',
    code: 'HR',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Cyprus',
    code: 'CY',
    cityLabel: 'locality',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Czech Republic',
    code: 'CZ',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Denmark',
    code: 'DK',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Finland',
    code: 'FI',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'France',
    code: 'FR',
    cityLabel: 'cityOrTown',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Germany',
    code: 'DE',
    cityLabel: 'cityOrTown',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Greece',
    code: 'GR',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Hungary',
    code: 'HU',
    cityLabel: 'cityOrTown',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Italy',
    code: 'IT',
    cityLabel: 'town',
    states: [],
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Liechtenstein',
    code: 'LI',
    cityLabel: 'locality',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Luxembourg',
    code: 'LU',
    cityLabel: 'city',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Netherlands',
    code: 'NL',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'New Zealand',
    code: 'NZ',
    cityLabel: 'cityOrTown',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Norway',
    code: 'NO',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Poland',
    code: 'PL',
    cityLabel: 'cityOrTown',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Portugal',
    code: 'PT',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Serbia',
    code: 'RS',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Slovakia',
    code: 'SK',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Slovenia',
    code: 'SI',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'South Africa',
    code: 'ZA',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Spain',
    code: 'ES',
    cityLabel: 'city',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Sweden',
    code: 'SE',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Switzerland',
    code: 'CH',
    cityLabel: 'city',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'Turkey',
    code: 'TR',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'United Kingdom',
    code: 'GB',
    cityLabel: 'town',
    postalCodeRequired: true,
    postalCode: true
  },
  {
    country: 'United States',
    code: 'US',
    cityLabel: 'city',
    states: [
      {
        name: 'Alaska',
        abbreviation: 'AK'
      },
      {
        name: 'Alabama',
        abbreviation: 'AL'
      },
      {
        name: 'Arkansas',
        abbreviation: 'AR'
      },
      {
        name: 'Arizona',
        abbreviation: 'AZ'
      },
      {
        name: 'California',
        abbreviation: 'CA'
      },
      {
        name: 'Colorado',
        abbreviation: 'CO'
      },
      {
        name: 'Connecticut',
        abbreviation: 'CT'
      },
      {
        name: 'District of Columbia',
        abbreviation: 'DC'
      },
      {
        name: 'Delaware',
        abbreviation: 'DE'
      },
      {
        name: 'Florida',
        abbreviation: 'FL'
      },
      {
        name: 'Georgia',
        abbreviation: 'GA'
      },
      {
        name: 'Hawaii',
        abbreviation: 'HI'
      },
      {
        name: 'Iowa',
        abbreviation: 'IA'
      },
      {
        name: 'Idaho',
        abbreviation: 'ID'
      },
      {
        name: 'Illinois',
        abbreviation: 'IL'
      },
      {
        name: 'Indiana',
        abbreviation: 'IN'
      },
      {
        name: 'Kansas',
        abbreviation: 'KS'
      },
      {
        name: 'Kentucky',
        abbreviation: 'KY'
      },
      {
        name: 'Louisiana',
        abbreviation: 'LA'
      },
      {
        name: 'Massachusetts',
        abbreviation: 'MA'
      },
      {
        name: 'Maryland',
        abbreviation: 'MD'
      },
      {
        name: 'Maine',
        abbreviation: 'ME'
      },
      {
        name: 'Michigan',
        abbreviation: 'MI'
      },
      {
        name: 'Minnesota',
        abbreviation: 'MN'
      },
      {
        name: 'Missouri',
        abbreviation: 'MO'
      },
      {
        name: 'Mississippi',
        abbreviation: 'MS'
      },
      {
        name: 'Montana',
        abbreviation: 'MT'
      },
      {
        name: 'North Carolina',
        abbreviation: 'NC'
      },
      {
        name: 'North Dakota',
        abbreviation: 'ND'
      },
      {
        name: 'Nebraska',
        abbreviation: 'NE'
      },
      {
        name: 'New Hampshire',
        abbreviation: 'NH'
      },
      {
        name: 'New Jersey',
        abbreviation: 'NJ'
      },
      {
        name: 'New Mexico',
        abbreviation: 'NM'
      },
      {
        name: 'Nevada',
        abbreviation: 'NV',
      },
      {
        name: 'New York',
        abbreviation: 'NY'
      },
      {
        name: 'Ohio',
        abbreviation: 'OH',
      },
      {
        name: 'Oklahoma',
        abbreviation: 'OK'
      },
      {
        name: 'Oregon',
        abbreviation: 'OR'
      },
      {
        name: 'Pennsylvania',
        abbreviation: 'PA'
      },
      {
        name: 'Rhode Island',
        abbreviation: 'RI'
      },
      {
        name: 'South Carolina',
        abbreviation: 'SC'
      },
      {
        name: 'South Dakota',
        abbreviation: 'SD'
      },
      {
        name: 'Tennessee',
        abbreviation: 'TN'
      },
      {
        name: 'Texas',
        abbreviation: 'TX'
      },
      {
        name: 'Utah',
        abbreviation: 'UT'
      },
      {
        name: 'Virginia',
        abbreviation: 'VA'
      },
      {
        name: 'Vermont',
        abbreviation: 'VT'
      },
      {
        name: 'Washington',
        abbreviation: 'WA'
      },
      {
        name: 'West Virginia',
        abbreviation: 'WV'
      },
      {
        name: 'Wisconsin',
        abbreviation: 'WI'
      },
      {
        name: 'Wyoming',
        abbreviation: 'WY'
      }
    ],
    required: true,
    postalCodeRequired: true,
    postalCode: true
  }
];

export const excludedCountries = [
  { name: 'Russia', code: 'RU' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Libya', code: 'LY' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Syria', code: 'SY' },
  { name: 'South Sudan', code: 'SS' },
  { name: 'Korea, Democratic People\'s Republic Of', code: 'KP' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Iran', code: 'IR' },
  { name: 'China', code: 'CN' },
  { name: 'Pakistan', code: 'PK' }

];