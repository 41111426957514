import { Injectable } from '@angular/core';
import * as fetchIntercept from 'fetch-intercept';
import { SpinnerService } from "./spinner.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ServerUnavailableModalComponent } from "../components/server-unavailable-modal/server-unavailable-modal.component";
import { commonMessages, toastMsgTypes } from "../shared/constants";
import { AlertService } from "./alert.service";
import { Subject, Subscription, throwError, Observable, of } from "rxjs";
import { map, retryWhen, delay, mergeMap, take, concatWith } from "rxjs/operators";
import { AccountService } from "./account.service";
import { FormDataService } from './form-data.service';

export interface RequestConfig {
  body: any,
  headers: any,
  method: string
}

@Injectable()
export class FetchInterceptorService {

  public DELAY: number = 10000;
  public RECONNECT_ATTEMPTS: number = 6;
  public requestInProgress = new Subject<any>();

  subscription: Subscription;

  skipInterceptions = false;
  unregister: any;
  recentUrl: any;
  requestArchive: Map<string, {config: RequestConfig, accountID: string}> = new Map<string, {config: RequestConfig, accountID: string}>();

  constructor(
    private spinnerSvc: SpinnerService,
    private modalSvc: NgbModal,
    private alertSvc: AlertService,
    private accountService: AccountService,
    private formDataSvc: FormDataService
  ) {}

  initInterceptor() {
    this.unregister = fetchIntercept.register({
      request: (url, config) => {
        // Modify the url or config here
        const regex = new RegExp(/(?:\/accounts\/)(.*?)(?=\/)/, 'gm');
        let accId = regex.exec(url);
        this.requestArchive.set(url, {config: <RequestConfig> config, accountID: accId && accId.length > 1 ? accId[1] : null});
        return [url, config];
      },

      requestError: (error) => {
        // Called when an error occurred during another 'request' interceptor call
        return Promise.reject(error);
      },

      response: (response) => {
        this.handleResponse(response.clone());
        return response;
      },

      responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
      }
    });
  }

  deinitInterceptor() {
    if (this.unregister) {
      this.unregister();
    } else {
      console.log('here is no interceptors');
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleResponse(responseIntercepted: any) {
    switch (responseIntercepted.status) {
      case 201:
      case 200:
        if (this.recentUrl === responseIntercepted.url) {
          this.recentUrl = '';
          if (this.spinnerSvc.getShowSpinner() &&
            this.spinnerSvc.getMessage() === commonMessages.ERR_SERVICE_UNAVAILABLE_RETRY) {
            console.log('intercept, hide spinner');
            this.spinnerSvc.hide();
          }
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
          this.requestInProgress.next(false);
        }
        this.removeRequest(responseIntercepted.url);
        break;
      case 504:
        let failedAccountId = this.requestArchive.get(responseIntercepted.url).accountID;
        let currentAccount = this.accountService.getAccount();
        if (failedAccountId && currentAccount && failedAccountId === currentAccount.accountId && !this.skipInterceptions) {
          if (this.spinnerSvc.getShowSpinner()) {
            this.spinnerSvc.hide();
          }
          console.warn('Function: handleResponse 504, failedAccountId: ', failedAccountId);
          const modalRef = this.modalSvc.open(ServerUnavailableModalComponent);
          const contentComponentInstance = modalRef.componentInstance;
          contentComponentInstance.okPressed.subscribe(() => {
            this.formDataSvc.revertRoleAndNavigate();
            modalRef.close();
            this.removeRequest(responseIntercepted.url);
          });
          this.requestInProgress.next(true);
        }
        break;
      case 503:
        if (!this.skipInterceptions) {
          this.recentUrl = responseIntercepted.url;
          this.skipInterceptions = true;
          if (!this.spinnerSvc.getShowSpinner()) {
            this.spinnerSvc.show();
          }
          this.spinnerSvc.setMessage(commonMessages.ERR_SERVICE_UNAVAILABLE_RETRY);
          this.requestInProgress.next(true);
          if (this.requestArchive.get(responseIntercepted.url)) {
            const serviceStatusHandler = this.repeatFetch(responseIntercepted.url, this.requestArchive.get(responseIntercepted.url).config).pipe(
              map(response => {
                if (response && response.status === 503) {
                  if (!this.spinnerSvc.getShowSpinner()) {
                    this.spinnerSvc.show();
                    this.spinnerSvc.setMessage(commonMessages.ERR_SERVICE_UNAVAILABLE_RETRY);
                  }
                  throw response;
                }
                this.skipInterceptions = false;
                return response;
              }),
              retryWhen(statusResult => {
                return statusResult.pipe(
                  mergeMap((statusResult: any) => {
                    if (statusResult.error || statusResult.status === 503) {
                      return of(statusResult).pipe(delay(this.DELAY));
                    }
                    return throwError(() => new Error('No retry'));
                  }),
                  take(this.RECONNECT_ATTEMPTS),
                  concatWith(throwError(() => new Error('Sorry, there was an error (after 6 retries)')))
                )
              })
            );
            this.subscription = serviceStatusHandler.subscribe(() => {}, error1 => {
              if (this.spinnerSvc.getShowSpinner()) {
                this.spinnerSvc.hide();
              }
              this.alertSvc.setAlert(toastMsgTypes.ERROR, commonMessages.ERR_SERVICE_UNAVAILABLE);
              this.removeRequest(responseIntercepted.url);
              this.requestInProgress.next(false);
            })
          }
        }
        break;
      case 404:
        this.removeRequest(responseIntercepted.url);
        break;
      default:
        break;
    }
  }

  removeRequest(url) {
    if (this.requestArchive.get(url)) {
      this.requestArchive.delete(url)
    }
  }

  repeatFetch(url: string, requestConfig: RequestConfig): Observable<any> {
    return new Observable(observer => {
      fetch(url, requestConfig)
        .then(result => observer.next(result))
        .catch(reason => observer.next({error: reason}))
    });
  }
}
