import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { Subscription } from 'rxjs';
import { ProgressService } from '../../services/progress.service';
import { AccountService } from '../../services/account.service';
import { AdminService } from '../../services/admin.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../services/alert.service';
import { FormDataService } from '../../services/form-data.service';
import { commonErrorMessages, pbxStatus, pbxMessages, platformTypes, MessageType } from '../../shared/constants';
import { TunnelService } from '../../services/tunnel.service';
import { Pbxlink } from '@mitel/cloudlink-sdk/tunnel';
import { AppService } from '../../services/app.service';
import { ParentCommsService } from '../../services/parent-comms.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../shared/language.enum';
import { NavigationService } from '../../services/navigation.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
    accountId: string;
    siteId = 'new';
    pbxId = 'new';
    accountTags = {};
    partnerClaims: boolean;
    accountTagsSubscription: Subscription;
    accountSubscription: Subscription;
    siteSubscription: Subscription;
    pbxSubscription: Subscription;
    pbxLink: Pbxlink;
    platform: any;
    platformTypes = platformTypes;
    platformSubscription: Subscription;
    isIframed: boolean;
    isIframedSupport: boolean;
    launchedFromSystemInventory: boolean = false;
    componentIdFromSystemInventory: string = undefined;
    isAccountAdmin: boolean;
    languageSubscription: Subscription;
    currentLanguage: Language;
    hideNavBar: boolean = false;

    constructor(public router: Router,
                private appSvc: AppService,
                private progressSvc: ProgressService,
                private route: ActivatedRoute,
                private accountSvc: AccountService,
                private adminSvc: AdminService,
                private authSvc: AuthenticationService,
                private alertSvc: AlertService,
                private userSvc: UserService,
                private formDataSvc: FormDataService,
                private tunnelSvc: TunnelService,
                private translateSvc: TranslateService,
                private parentCommsService: ParentCommsService, 
                private navigationService: NavigationService) {
    };

    ngOnInit() {
        this.isIframed = this.parentCommsService.isInsideIframe();
        this.isIframedSupport = this.parentCommsService.isIframedSupportPage;
        this.launchedFromSystemInventory = this.parentCommsService.launchedFromSystemInventory;
        this.componentIdFromSystemInventory = this.launchedFromSystemInventory? this.parentCommsService.componentId : '*';
        if(this.isIframedSupport || this.launchedFromSystemInventory){
            this.hideNavBar = true;
        }
        if(this.launchedFromSystemInventory){
            if(!this.componentIdFromSystemInventory || this.componentIdFromSystemInventory === '' || this.componentIdFromSystemInventory === '*'){
                //don't proceed with making any backend calls if componentId is missing when launched from system inventory
                //app.component will handle redirecting and calling goNoGWPSupport.
                return;
            }
        }

        const browserLang = this.translateSvc.getBrowserLang();
        if(browserLang == 'en'){
          const culturalLang = this.translateSvc.getBrowserCultureLang();
          this.currentLanguage = culturalLang.match(/en-GB/)? Language[culturalLang] : Language.en;
        }
        else
          this.currentLanguage = browserLang.match(/fr|de|es|it|nl|pt/)
            ? Language[browserLang]
            : Language.en;
        this.isAccountAdmin = this.appSvc.isAccountAdmin();
        this.accountTagsSubscription = this.accountSvc.accountTagsChanged
                                    .subscribe(accountTags => {
                                        this.accountTags = accountTags;
                                    });
        this.accountSubscription = this.accountSvc.accountChanged
                                    .subscribe(account => {
                                        if (account) {
                                            this.accountId = account.accountId;
                                        }
                                    });
        this.siteSubscription = this.accountSvc.siteChanged
                                    .subscribe(site => {
                                        if (site) {
                                            this.siteId = site.siteId;
                                        }
                                    });

        this.pbxSubscription = this.accountSvc.pbxChanged
                                    .subscribe(pbx => {
                                        if (pbx) {
                                            this.pbxId = pbx._id;
                                            this.pbxLink = pbx;
                                        }
                                    });
        this.platformSubscription = this.accountSvc.platformChanged
                                        .subscribe(platform => this.platform = platform);
        this.languageSubscription = this.translateSvc.onLangChange
                                        .subscribe( () => {
                                            if (this.isIframed) {
                                                this.currentLanguage = Language[this.translateSvc.currentLang];
                                            }
                                        });


        // if assumed role, then show the partner button to go back to partner dashboard
        if (this.appSvc.isAssumedRole()) {
            this.authSvc.getPartnerClaims().then(claims => {
                if (claims == null) {
                    this.partnerClaims = false;
                } else {
                    this.partnerClaims = true;
                }
            });
        } else {
            const claims = this.appSvc.getClaims();
            if (claims.role === 'PARTNER_ADMIN') {
                this.partnerClaims = true;
            } else {
                this.partnerClaims = false;
            }
        }

        this.route.params
         .subscribe(
             async (params: Params) => {
                if (this.router.parseUrl(this.router.url).root.children['primary']) {
                    const routeSegments = this.router.parseUrl(this.router.url).root.children['primary'].segments;
                    this.accountId = routeSegments[1].path;
                    if (routeSegments[3]) {
                        this.siteId = routeSegments[3].path;
                    }
                    if (routeSegments[5]) {
                        this.pbxId = routeSegments[5].path;
                    }
                }

                console.log('getting accountTags');

                this.accountTags = this.accountSvc.getAccountTags();

                this.updateIds();
             }
        );
    }

    redirectSystemInventoryLaunchToErrorPage(message?: MessageType){
        //I think we should be showing a banner in more cases than just when GWP is launched from System Inventory.
        //But at this time, there is no requirement for that - perhaps a future enhancement
        if(this.launchedFromSystemInventory){
            this.hideNavBar = true;
            let msg = message? message : MessageType.NO_SUPPORT_FAILURE_TO_RETRIEVE_SYSTEM_DETAILS;
            this.navigationService.goNoGWPSupport(this.accountId, msg);
        }
    }

    setClasses(navigationStage: string) {
        return {
            'complete' : this.isStepComplete(navigationStage),
            'error': this.isStepError(navigationStage),
            'disabled': this.isDisabled(navigationStage),
            'navicon': true
        };
    }

    isStepComplete(navigationStage: string): boolean {
        if (this.accountTags && this.accountTags['on-board-progress']) {
            const lastStepIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress']['name']);
            const navigationStageIdx = this.progressSvc.getProgressIdx(navigationStage);
            return navigationStageIdx <= lastStepIdx;
        }

        return false;
    }

    isStepError(navigationStage: string): boolean {
        if (this.accountTags && this.accountTags['on-board-progress']) {
            if (this.accountTags['on-board-progress']['name'] === navigationStage &&
                    this.accountTags['on-board-progress']['succeeded'] === false) {
                return true;
            }
        }

        if (navigationStage !== 'account' && navigationStage !== 'site') {
            if (this.accountTags && this.accountTags['gateway-connection']) {
                if (navigationStage === 'pbx' &&
                    this.accountTags['gateway-connection']['pbxConnected'] === false) {
                    return true;
                } else if (!this.accountTags['gateway-connection']['connected']) {
                    return true;
                }
            }
        }

        if (navigationStage === 'pbx' || navigationStage === 'connect' || navigationStage === 'officelink') {
            if (this.accountTags && this.accountTags['pbx-status']) {
                if (this.accountTags['pbx-status']['status'] === pbxStatus.DOWN || this.accountTags['pbx-status']['status'] === pbxStatus.MISSING) {
                    return true;
                }
            }
        }

        return false;
    }

    isDisabled(navigationStage: string): boolean {
        if ((!this.accountTags || !this.accountTags['on-board-progress']) && navigationStage === 'account') {
            return false;
        }

        // Disable connect form when pbx has connect_error
        if (navigationStage === 'connect' && this.pbxLink && this.pbxLink['connect_error'] && this.pbxLink['connect_error'] !== '') {
            return true;
        }

        if (navigationStage === 'site' && this.accountTags && this.accountTags['tunnel-creds'] === false) {
            return true;
        }

        if (this.accountTags && this.accountTags['on-board-progress']) {
            const lastStepIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress']['name']);
            const navigationStageIdx = this.progressSvc.getProgressIdx(navigationStage);

            if (navigationStage === 'advanced') {
                const siteStepIdx = this.progressSvc.getProgressIdx('site');
                if (this.accountTags['on-board-progress']['succeeded']) {
                    return siteStepIdx > lastStepIdx;
                } else {
                    return siteStepIdx >= lastStepIdx;
                }
            } else if (this.accountTags['on-board-progress']['name'] === 'connect' && this.accountTags['on-board-progress']['succeeded']) {
                return false;
            } else if (!this.accountTags['on-board-progress']['succeeded']) {
                return navigationStageIdx > lastStepIdx;
            } else {
                return navigationStageIdx > lastStepIdx + 1;
            }
        }

        return true;
    }

    isError(): boolean {
        if (this.accountTags && this.accountTags['gateway-connection']
            && !this.accountTags['gateway-connection']['connected']) {
            return true;
        }

        if (this.accountTags && this.accountTags['pbx-status']
            && this.accountTags['pbx-status']['status'] === pbxStatus.DOWN) {
            return true;
        }

        return false;
    }

    isLongText(navItem: string): boolean {
        let specialCase = navItem === 'deployment' && (this.currentLanguage === Language.pt || this.currentLanguage === Language.de)

        if(this.translateSvc.instant('nav.' + navItem).length > 12) {
            return !specialCase;
        }
        return false;
    }

    updateIds() {
        if (!this.accountId || this.accountId === 'new') { //should not be this state if iframed
            this.accountSvc.setAccount(null);
            this.accountSvc.setAccountTags(null);
            this.accountSvc.setSite(null);
            this.accountSvc.setPbxlink(null);
            return;
        }
        this.adminSvc.getAccount(this.accountId)
            .then(async account => {
                this.accountId = account.accountId;

                 // account must be set after setting partner and adminContacts
                 this.accountSvc.setAccount(account);

                try {
                    const tags = await this.adminSvc.getAccountTags(account.accountId);
                    this.accountSvc.setAccountTags(tags);
                    console.log('account tags', this.accountSvc.getAccountTags());
                } catch (error) {
                    if (typeof error === 'string') {
                        console.error('failed to get account tags', error);
                    } else if (error && error.statusCode === 401) {
                        console.error('failed to get account tags', error)
                        this.authSvc.redirectToLogin();
                    } else if (typeof error.text === 'function') {
                        error.text().then(res => console.error('failed to get account tags', res));
                    } else if (error instanceof Error) {
                        console.error('failed to get account tags', error.message);
                        if (error.message === commonErrorMessages.AUTH_ERROR) {
                            this.authSvc.redirectToLogin();
                        }
                    } else {
                        console.error('failed to get account tags', error)
                    }
                    this.accountSvc.setAccountTags(null);
                }

                try {
                    let site = undefined;
                    this.siteId = undefined;

                    if(!this.launchedFromSystemInventory){
                        site = await this.adminSvc.getFirstSite(account.accountId);
                        if(site){
                            this.siteId = site.siteId;
                            this.accountSvc.setSite(site);
                        }
                    }
                    if(!site){
                        this.accountSvc.setSite(null);
                    }
                    if(site || this.launchedFromSystemInventory){
                        await this.getPlatform();
                        if (!this.platform || (this.platform.capabilities && this.platform.capabilities.read_network_settings)) {
                            await this.getNetworkSettings();
                        }
                    }
                } catch (error) {
                    if (typeof error  === 'string') {
                        console.error('failed to get sites', error);
                    } else if (error && error.statusCode === 401) {
                        console.error('failed to get sites', error);
                        this.authSvc.redirectToLogin();
                    } else if (typeof error.text === 'function') {
                        error.text().then(res => console.error('failed to get sites', res));
                    } else if (error instanceof Error) {
                        console.error('failed to get sites', error.message);
                        if (error.message === commonErrorMessages.AUTH_ERROR) {
                            this.authSvc.redirectToLogin();
                        }
                    } else {
                        console.error('failed to get sites', error);
                    }
                    this.accountSvc.setSite(null);
                }

                await this.getPbxData();
            }, reason => {
                let navigateToMessagePage = true;
                if (reason && reason.statusCode === 401) {
                    console.error('failed to get account', JSON.stringify(reason));
                    navigateToMessagePage = false;
                    this.authSvc.redirectToLogin();
                } else if (reason && reason.text && typeof reason.text === 'function') {
                    reason.text().then(res => console.error('failed to get account', res));
                } else if (reason instanceof Error) {
                    console.error('failed to get account', reason.message);
                    if (reason.message === commonErrorMessages.AUTH_ERROR) {
                        navigateToMessagePage = false;
                        this.authSvc.redirectToLogin();
                    }
                } else {
                    console.error('failed to get account', reason);
                }
                this.accountSvc.setAccount(null);
                this.accountSvc.setAccountTags(null);
                if(navigateToMessagePage){
                    this.redirectSystemInventoryLaunchToErrorPage(MessageType.FAILED_TO_GET_ACCOUNT);
                }
            }).catch(err => {
                console.error('failed to get account', err);
                this.redirectSystemInventoryLaunchToErrorPage(MessageType.FAILED_TO_GET_ACCOUNT);
            });

    }

    async getPbxData() {
        if (this.launchedFromSystemInventory || (this.accountId && this.accountId !== 'new'
            && this.siteId && this.siteId !== 'new'
            && this.accountTags && this.accountTags['on-board-progress'])) {
            let lastStepIdx;
            let tagParams = {};

            if(!this.launchedFromSystemInventory){
                lastStepIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress']['name']);
                tagParams = this.accountTags;
            }

            // get the pbxLinkinfo if the user has tried to add a pbx with success or fail
            if (this.launchedFromSystemInventory || lastStepIdx >= 3) {
                try {
                    let pbxLink = await this.tunnelSvc.getFirstPbxlink(this.componentIdFromSystemInventory);
                    tagParams['pbx-status'] = { status: pbxStatus.MISSING };
                    if(pbxLink){
                        this.pbxId =pbxLink._id;
                        this.accountSvc.setPbxlink(pbxLink);
                        tagParams['pbx-status'] = { status: pbxStatus.UP };
                    }
                    
                    if(!this.launchedFromSystemInventory){
                        await this.updateAccountTag(this.accountId, tagParams);
                    }
                } catch (reason) {
                    //CODE Warning: consider putting a return after redirectToLogin - do not need to continue.
                    //unit tests will be more sensible.
                    //occurs in many places
                    let errMsg = '';
                    let navigateToMessagePage = true;
                    tagParams['pbx-status'] = { status: pbxStatus.DOWN };
                    if (reason.statusCode === 503) {
                        tagParams['pbx-status']['reason'] = pbxMessages.CANNOT_CONNECT_PBX;
                    } else if (reason.statusCode === 504) {
                        tagParams['pbx-status']['reason'] = pbxMessages.SYSTEM_DATA_TIMEOUT;
                    } else if (typeof reason === 'string') {
                        console.error('failed to get PBX links', reason);
                        tagParams['pbx-status']['reason'] = (reason && reason !== '') ? reason : 'common.unknown';
                    } else if (reason && reason.statusCode === 401) {
                        console.error('failed to get PBX links', reason);
                        navigateToMessagePage = false;
                        this.authSvc.redirectToLogin();
                    } else if (reason instanceof Error) {
                        console.error('failed to get PBX links', reason.message);
                        if (reason.message === commonErrorMessages.AUTH_ERROR) {
                            navigateToMessagePage = false;
                            this.authSvc.redirectToLogin();
                        } else if (reason.message === commonErrorMessages.TYPE_ERROR) {
                            tagParams['pbx-status']['reason'] = pbxMessages.ERR_GET_PBX;
                        } else {
                            tagParams['pbx-status']['reason'] = (reason.message && reason.message !== '' ?
                                                                 reason.message : 'common.unknown');
                        }
                    } else {
                        //CODE Warning: can't hit 'common.unknown' case
                        if (reason && reason.body && reason.body.name) {
                            errMsg = reason.body.name;
                            tagParams['pbx-status']['reason'] = (errMsg && errMsg !== '') ? ('getPbxlinks.' + errMsg) : 'common.unknown';
                        }
                    }
                    if(!this.launchedFromSystemInventory){
                        await this.updateAccountTag(this.accountId, tagParams);
                    }
                    
                    this.accountSvc.setPbxlink(null);
                    if(navigateToMessagePage){
                        this.redirectSystemInventoryLaunchToErrorPage();
                    }
                }
            }
        }
    }

    async getNetworkSettings() {
        let okToGetNetworkSettings = false;
        if(this.launchedFromSystemInventory){
            okToGetNetworkSettings = true;
        }
        else if(this.accountId && this.accountId !== 'new' && this.siteId && this.siteId !== 'new'){
            okToGetNetworkSettings = true;
        }
        if (okToGetNetworkSettings) {
            try {
                const response = await this.tunnelSvc.getNetworkSettings(this.componentIdFromSystemInventory);
                this.accountSvc.setNetworkConfig(response.result);
            } catch (reason) {
                let navigateToMessagePage = true;
                if (typeof reason === 'string') {
                    console.error('failed to get network settings', reason);
                } else if (reason && reason.statusCode === 401) {
                    console.error('failed to get network settings', JSON.stringify(reason));
                    navigateToMessagePage = false;
                    this.authSvc.redirectToLogin();
                } else if (reason instanceof Error) {
                    console.error('failed to get network settings', reason.message);
                    if (reason.message === commonErrorMessages.AUTH_ERROR) {
                        navigateToMessagePage = false;
                        this.authSvc.redirectToLogin();
                    }
                } else {
                    console.error('failed to get network settings', JSON.stringify(reason));
                }
                this.accountSvc.setNetworkConfig(null);
                if(navigateToMessagePage){
                    this.redirectSystemInventoryLaunchToErrorPage();
                }
            }
        }
    }

    async getPlatform() {
        let okToGetPlatform = false;
        if(this.launchedFromSystemInventory){
            okToGetPlatform = true;
        }
        else if(this.accountId && this.accountId !== 'new' && this.siteId && this.siteId !== 'new'){
            okToGetPlatform = true;
        }
        if (okToGetPlatform) {
            try {
                this.platform = await this.tunnelSvc.getPlatform(this.componentIdFromSystemInventory);
                this.accountSvc.setPlatform(this.platform);
            } catch (reason) {
                let navigateToMessagePage = true;
                if (typeof reason === 'string') {
                    console.error('failed to get platform', reason);
                } else if (reason && reason.statusCode === 401) {
                    console.error('failed to get platform', JSON.stringify(reason));
                    navigateToMessagePage = false;
                    this.authSvc.redirectToLogin();
                } else if (reason instanceof Error) {
                    console.error('failed to get platform', reason.message);
                    if (reason.message === commonErrorMessages.AUTH_ERROR) {
                        navigateToMessagePage = false;
                        this.authSvc.redirectToLogin();
                    }
                } else {
                    console.error('failed to get platform', JSON.stringify(reason));
                }
                this.accountSvc.setPlatform(null);
                if(navigateToMessagePage){
                    this.redirectSystemInventoryLaunchToErrorPage();
                }
            }
        }
    }

    goToDashboard() {
        this.formDataSvc.redirectToDashboard();
    }

    private async updateAccountTag(accountId: string, tagParams: any) {
        try {
            const tags = await this.adminSvc.tryUpdateAccountTags(accountId, tagParams);
            this.accountSvc.setAccountTags(tags);
            if (tags['gateway-connection'] && tags['gateway-connection']['connected'] === true &&
                tags['pbx-status']['status'] === pbxStatus.DOWN) {
                this.router.navigateByUrl(`/accounts/${this.accountId}/pbx-connection-error`);
            }
        } catch (reason) {
            if (reason && reason.statusCode === 401) {
                console.error('failed to update account tags', JSON.stringify(reason));
                this.authSvc.redirectToLogin();
            } else if (reason instanceof Error) {
                console.error('failed to update account tags', reason.message);
                if (reason.message === commonErrorMessages.AUTH_ERROR) {
                    this.authSvc.redirectToLogin();
                }
            } else {
                console.error('failed to update account tags', reason);
            }
        }
    }

    ngOnDestroy() {
        sessionStorage.clear();
        this.alertSvc.clearAlert();
        this.accountSvc.setAccount(null);
        this.accountSvc.setAccountTags(null);
        this.accountSvc.setSite(null);
        this.accountSvc.setPbxlink(null);
        this.accountSvc.setNetworkConfig(null);
        this.accountSvc.setUpgradeSchedule(null);
        this.accountSvc.setSelectedPbxType(null);
        this.accountSvc.setPlatform(null);
        this.formDataSvc.resetData();
        if (this.accountTagsSubscription) {
            this.accountTagsSubscription.unsubscribe();
        }
        if (this.accountSubscription) {
            this.accountSubscription.unsubscribe();
        }
        if (this.siteSubscription) {
            this.siteSubscription.unsubscribe();
        }
        if (this.pbxSubscription) {
            this.pbxSubscription.unsubscribe();
        }
        if (this.platformSubscription) {
            this.platformSubscription.unsubscribe();
        }
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

}
