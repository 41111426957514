// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
	text-align: center;
}

.close-button {
	margin-top: -6px;
	padding-right: 13px;
}

.content-title {
	margin-bottom: 15px;
	font-family: MuseoSans-500;
	font-size: 24px;
	color: #15325F;
	letter-spacing: 0.01px;
}

.content p {
	margin-bottom: 5px;
	font-family: MuseoSans-300;
	font-size: 12px;
	color: #1C1D1D;
	letter-spacing: 0.01px;
	line-height: 1.25;
}

:host >>> .content span {
	font-family: MuseoSans-500;
}

:host >>> .content b {
	font-family: MuseoSans-900;
}

input {
	margin-top: 20px;
	width: 180px;
}

input:focus {
	outline: none;
	border: 1px solid #00A1E0 !important;
}

.actions {
	position: relative;
	bottom: 20px;
	margin-top: 30px;
}

button {
	width: 160px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/gateway-link-confirmation/gateway-link-confirmation.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;CACnB,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,sBAAsB;AACvB;;AAEA;CACC,kBAAkB;CAClB,0BAA0B;CAC1B,eAAe;CACf,cAAc;CACd,sBAAsB;CACtB,iBAAiB;AAClB;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,gBAAgB;CAChB,YAAY;AACb;;AAEA;CACC,aAAa;CACb,oCAAoC;AACrC;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb","sourcesContent":[".content {\n\ttext-align: center;\n}\n\n.close-button {\n\tmargin-top: -6px;\n\tpadding-right: 13px;\n}\n\n.content-title {\n\tmargin-bottom: 15px;\n\tfont-family: MuseoSans-500;\n\tfont-size: 24px;\n\tcolor: #15325F;\n\tletter-spacing: 0.01px;\n}\n\n.content p {\n\tmargin-bottom: 5px;\n\tfont-family: MuseoSans-300;\n\tfont-size: 12px;\n\tcolor: #1C1D1D;\n\tletter-spacing: 0.01px;\n\tline-height: 1.25;\n}\n\n:host >>> .content span {\n\tfont-family: MuseoSans-500;\n}\n\n:host >>> .content b {\n\tfont-family: MuseoSans-900;\n}\n\ninput {\n\tmargin-top: 20px;\n\twidth: 180px;\n}\n\ninput:focus {\n\toutline: none;\n\tborder: 1px solid #00A1E0 !important;\n}\n\n.actions {\n\tposition: relative;\n\tbottom: 20px;\n\tmargin-top: 30px;\n}\n\nbutton {\n\twidth: 160px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
