import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Config, HttpMethod } from '@mitel/cloudlink-sdk';

import { ConfigData } from '../shared/admin2/configData';
import { CloudAppliance, CloudAppliancePage, CloudApplianceUpdateParameters, CloudApplianceParameters } from '../shared/admin2/cloudAppliance';
import { CloudPbx, CloudPbxPage, CloudPbxUpdateParameters, CloudPbxParameters } from '../shared/admin2/cloudPbx';
import { CloudTrunking, CloudTrunkingPage, CloudTrunkingUpdateParameters, CloudTrunkingParameters } from '../shared/admin2/cloudTrunking';
import { Gateway, GatewayParameters, GatewayPage } from '../shared/admin2/gateway';

@Injectable()
export class Admin2Service {
    public bypassAdminSDKService: any;


    constructor() {
        console.log('connecting to', environment.authUrl, environment.adminUrl, environment.tunnelUrl);
 
        this.bypassAdminSDKService = {
            rootUrl: environment.adminUrl,
            _http: <HttpMethod>  Config.httpService,
            async getAnalyticsDataDirectly(applicationName: string): Promise<ConfigData>{
                let url = `${this.rootUrl}/config/apps/${applicationName}/analytics`
                return await this._http.get({url})
                .then(response => response.body);
            },
            async getCloudAppliancesDirectly(accountId: string): Promise<CloudAppliancePage>{
                let url = `${this.rootUrl}/accounts/${accountId}/services/appliances`;
                return await this._http.get({url})
                .then(response => response.body)
                .then((data: any) => {
                    if (data._embedded && data._embedded.items) {
                       if(data.count) {
                           return <CloudAppliancePage>{
                               cloudAppliances: <CloudAppliance[]>data._embedded.items,
                               count: data.count
                           };
                       } else {
                            return <CloudAppliancePage>{
                               cloudAppliances: <CloudAppliance[]>data._embedded.items
                           };
                       }        
                   } else {
                       return <CloudAppliancePage>{
                           cloudAppliances: [<CloudAppliance>data]
                       };
                   }
               });
            },
            async getCloudPbxsDirectly(accountId: string): Promise<CloudPbxPage> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/pbxs`;
                return await this._http.get({url})
                .then(response => response.body)
                .then((data: any) => {
                    if (data._embedded && data._embedded.items) {
                       if(data.count) {
                           return <CloudPbxPage>{
                               cloudPbxs: <CloudPbx[]>data._embedded.items,
                               count: data.count
                           };
                       } else {
                            return <CloudPbxPage>{
                               cloudPbxs: <CloudPbx[]>data._embedded.items
                           };
                       }        
                   } else {
                       return <CloudPbxPage>{
                           cloudPbxs: [<CloudPbx>data]
                       };
                   }
               });
            },
            async getCloudTrunkingsDirectly(accountId: string): Promise<CloudTrunkingPage> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/trunking`;
                return await this._http.get({url})
                .then(response => response.body)
                .then((data: any) => {
                    if (data._embedded && data._embedded.items) {
                       if(data.count) {
                           return <CloudTrunkingPage>{
                               cloudTrunkings: <CloudTrunking[]>data._embedded.items,
                               count: data.count
                           };
                       } else {
                            return <CloudTrunkingPage>{
                               cloudTrunkings: <CloudTrunking[]>data._embedded.items
                           };
                       }        
                   } else {
                       return <CloudTrunkingPage>{
                           cloudTrunkings: [<CloudTrunking>data]
                       };
                   }
               });
            },
            async getGatewaysDirectly(accountId: string): Promise<GatewayPage> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/gateways`;
                return await this._http.get({url})
                .then(response => response.body)
                .then((data: any) => {
                    if (data._embedded && data._embedded.items) {
                        if(data.count){
                            return <GatewayPage>{
                                gateways: <Gateway[]>data._embedded.items,
                                count: data.count
                            };
                        }else {
                             return <GatewayPage>{
                                gateways: <Gateway[]>data._embedded.items
                            };
                        }        
                    }else {
                        return <GatewayPage>{};
                    }
                });
            },
            async updateCloudApplianceDirectly(accountId: string, id: string, appliance: CloudApplianceUpdateParameters): Promise<CloudAppliance> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/appliances/${id}`;
                return await this._http.put({url, body: appliance})
                .then(response => response.body)
                .then((data: any) => <CloudAppliance>data);
            },
            async updateCloudPbxDirectly(accountId: string, id: string, pbx: CloudPbxUpdateParameters): Promise<CloudPbx> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/pbxs/${id}`;
                return await this._http.put({url, body: pbx})
                .then(response => response.body)
                .then((data: any) => <CloudPbx>data);
            },
            async updateCloudTrunkingDirectly(accountId: string, id: string, trunking: CloudTrunkingUpdateParameters): Promise<CloudTrunking> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/trunking/${id}`;
                return await this._http.put({url, body: trunking})
                .then(response => response.body)
                .then((data: any) => <CloudTrunking>data);
            },
            async createCloudApplianceDirectly(accountId: string, appliance: CloudApplianceParameters): Promise<CloudAppliance> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/appliances`;
                return await this._http.post({url, body: appliance})
                .then(response => response.body)
                .then((data: any) => <CloudAppliance>data);
            },
            async createCloudPbxDirectly(accountId: string, pbx: CloudPbxParameters): Promise<CloudPbx> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/pbxs`;
                return await this._http.post({url, body: pbx})
                .then(response => response.body)
                .then((data: any) => <CloudPbx>data);
            },
            async createCloudTrunkingDirectly(accountId: string, trunking: CloudTrunkingParameters): Promise<CloudTrunking> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/trunking`;
                return await this._http.post({url, body: trunking})
                .then(response => response.body)
                .then((data: any) => <CloudTrunking>data);
            },
            async createGatewayDirectly(accountId: string, gateway: GatewayParameters): Promise<Gateway> {
                let url = `${this.rootUrl}/accounts/${accountId}/services/gateways`;
                return await this._http.post({url, body: gateway})
                .then(response => response.body)
                .then((data: any) => <Gateway>data);
            }
        }
    }

    public getGateways(accountId: string): Promise<GatewayPage>{
        return this.bypassAdminSDKService.getGatewaysDirectly(accountId);
    }

    public createGateway(accountId: string, gateway: GatewayParameters): Promise<Gateway> {
        return this.bypassAdminSDKService.createGatewayDirectly(accountId, gateway);
    }

    public getCloudTrunkings(accountId: string): Promise<CloudTrunkingPage> {
        return this.bypassAdminSDKService.getCloudTrunkingsDirectly(accountId);
    }

    public createCloudTrunking(accountId: string, trunking: CloudTrunkingParameters): Promise<CloudTrunking> {
        return this.bypassAdminSDKService.createCloudTrunkingDirectly(accountId, trunking);
    }

    public updateCloudTrunking(accountId: string, id: string, trunking: CloudTrunkingUpdateParameters): Promise<CloudTrunking> {
        return this.bypassAdminSDKService.updateCloudTrunkingDirectly(accountId, id, trunking);
    }
    public getCloudPbxs(accountId: string): Promise<CloudPbxPage> {
        return this.bypassAdminSDKService.getCloudPbxsDirectly(accountId);
    }

    public createCloudPbx(accountId: string, pbx: CloudPbxParameters): Promise<CloudPbx> {
        return this.bypassAdminSDKService.createCloudPbxDirectly(accountId, pbx);
    }

    public updateCloudPbx(accountId: string, id: string, pbx: CloudPbxUpdateParameters): Promise<CloudPbx> {
        return this.bypassAdminSDKService.updateCloudPbxDirectly(accountId, id, pbx);
    }

    public getCloudAppliances(accountId: string): Promise<CloudAppliancePage> {
        return this.bypassAdminSDKService.getCloudAppliancesDirectly(accountId)
    }

    public createCloudAppliance(accountId: string, appliance: CloudApplianceParameters): Promise<CloudAppliance> {
        return this.bypassAdminSDKService.createCloudApplianceDirectly(accountId, appliance);
    }

    public updateCloudAppliance(accountId: string, id: string, appliance: CloudApplianceUpdateParameters): Promise<CloudAppliance> {
        return this.bypassAdminSDKService.updateCloudApplianceDirectly(accountId, id, appliance);
    }

    public getAnalyticsConfigurationData(applicationName: string): Promise<ConfigData> {
        return this.bypassAdminSDKService.getAnalyticsDataDirectly(applicationName);
    }
}
