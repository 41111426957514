import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { Subscription } from 'rxjs';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit, OnDestroy {
	public lottieConfigLoading: AnimationOptions;
  private anim: any;
  showSpinner = false;
  showSpinnerSubscription: Subscription;
  message: string;
  messageSubscription: Subscription;
  spinnerStyle: Partial<CSSStyleDeclaration> = {
    margin: 'auto'
  };
  
  @HostBinding('class.hide-spinner') hideSpinner = !this.showSpinner;

  constructor(private spinnerSvc: SpinnerService) {
  	this.lottieConfigLoading = {
      path: 'assets/animations/loading.json',
      autoplay: true,
      loop: true,
    };
  }

  ngOnInit() {
    this.showSpinner = this.spinnerSvc.getShowSpinner();
    this.message = this.spinnerSvc.getMessage();
    this.showSpinnerSubscription = this.spinnerSvc.showSpinnerChanged.subscribe(s => {
      this.showSpinner = s;
      this.hideSpinner = !this.showSpinner;
    });
    this.messageSubscription = this.spinnerSvc.messageChanged.subscribe(m => this.message = m);
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  ngOnDestroy() {
    // if(this.anim) {
    //   this.anim.stop(); this seems to cause stack traces with the new package
    // }
    if (this.showSpinnerSubscription) {
      this.showSpinnerSubscription.unsubscribe();
    }
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }
}
