import { Injectable } from '@angular/core';

import { MivbProvisioningService } from './mivb-provisioning.service';
import { ParentCommsService } from './parent-comms.service';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  constructor(
    private mivbProvisioningService: MivbProvisioningService,
    private parentCommService: ParentCommsService
  ) { }

  syncForMIVB(accountId: string, siteId: string) {
    if(this.parentCommService.launchedFromSystemInventory){
      //mivb-prov api has not been fixed to allow calling without accountId and siteId
      //also not allowing for individual sync when CLD
      return;
    }

    this.mivbProvisioningService.startSyncForMivb(accountId, siteId).then(response => {
      console.log("sync response for MiVB is ", response);
    })
    .catch((err => console.error('failed to start MiVB sync', err)))

  }
}
