import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AccountService } from '../services/account.service';
import { VersionUpdateService } from '../services/version-update.service';
import { EchoService } from '../services/echo.service';
import { TunnelService } from '../services/tunnel.service';
import { Account, Site } from '@mitel/cloudlink-sdk/admin';
import { Token } from '@mitel/cloudlink-sdk';
import { Pbxlink } from '@mitel/cloudlink-sdk/tunnel';
import { ProgressService, ProgressItem } from '../services/progress.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreReqComponent } from '../components/pre-req/pre-req.component';
import * as _ from 'lodash';
import { AdminService } from '../services/admin.service';
import { SpinnerService } from '../services/spinner.service';
import { AppService } from '../services/app.service';
import { ParentCommsService } from './parent-comms.service';
import { MessageType } from './../shared/constants';

import {
  gatewayConnectStateMessages,
  siteMessages,
  commonErrorMessages,
  commonMessages,
  pbxStatus,
  stepStatus
} from '../shared/constants';

/*
Most of the code for this service is copied from the account-item component.
Needed the code to determine the status of the account and determine to which page to redirect the user.
This service is only used in the case when the Gateway Portal is being iframed by the Accounts app.
Unlike the account-item component - here, navigation is blocked until the final status is determined.
*/

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
  account: Account;
  accountTags: any;
  site: Site;
  pbxLink: Pbxlink;
  progressItem: ProgressItem = null;
  progressItemStatus = '';
  status = '';
  isError = false;
  stepName: string;
  platform: any;
  showStep = false;
  showSystemUpdateAvailable: boolean;
  gatewayConnectStateMessages = gatewayConnectStateMessages;

  constructor(
      private authSvc: AuthenticationService,
      private router: Router,
      private accountSvc: AccountService,
      private progressSvc: ProgressService,
      private modalSvc: NgbModal,
      private adminSvc: AdminService,
      private tunnelSvc: TunnelService,
      private versionSvc: VersionUpdateService,
      private echoSvc: EchoService,
      private spinnerSvc: SpinnerService,
      private appSvc: AppService,
      private parentCommsService: ParentCommsService
  ){};

  getTagsandNavigate(accountId: string)
  {
    //need to get account first
    if(accountId) {
      this.adminSvc.getAccount(accountId).then(async account => {
        this.account = account;
        if(this.account) {
          await this.getAccountTagData();
          console.log("Status: ", this.status);
          if(this.parentCommsService.isIframedSupportPage){
            //Gateway Support
            this.goNextSupportPage();
          }else{
            //Account edit page
            this.goNext();
            this.spinnerSvc.hide();
          }
        } else {
          console.log("No account");
          this.authSvc.redirectToLogin();
        }
      }).catch(error => {
        console.error("Could not get Account: ", error);
        this.authSvc.redirectToLogin();
      });
    } else {
      console.log("No account id - can not continue");
      this.authSvc.redirectToLogin();
    }
  }

  assumeRoleAndNext(url: string, accountId: string) {
    // assume role, then navigate to url
    console.log("Going to this url: ", url);
    const claims = this.appSvc.getClaims();
    if(claims.role === "PARTNER_ADMIN")
    {
      const params = this.getAssumeRoleParams(accountId);
      this.authSvc.assumeRole(params)
        .then(token => {
          if (token) {
            console.log('Successfully set assumed role token');
          } else {
            console.log('Assumed role token not set');
          }
          this.router.navigateByUrl(url);
          window.scrollTo(0, 0);
        })
        .catch(error => {
          console.error('error creating token', error);
          this.router.navigateByUrl(url);
          window.scrollTo(0, 0);
        });
    } else{
      console.log("Assume role not required");
      this.router.navigateByUrl(url);
      window.scrollTo(0, 0);
    }
  }

  goNext() {
    if (!this.status || this.status === '') {
      console.log("NO STATUS - Going to Overview");
      this.goOverview();
      return;
    }

    this.accountSvc.setAccount(this.account);
    this.accountSvc.setSite(this.site);
    this.accountSvc.setAccountTags(this.accountTags);

    let siteId = this.site? this.site.siteId : null;
    let pbxId = (this.pbxLink && this.pbxLink._id) ? this.pbxLink._id : null

    let url: string;
    if (this.accountTags && this.accountTags['gateway-connection']
      && this.accountTags['gateway-connection']['pbxConnected'] === false) {
        if(this.accountTags['pbx-status'] && this.accountTags['pbx-status']['status'] === pbxStatus.MISSING){
          url = `/accounts/${this.account.accountId}/sites/${this.site.siteId}/pbx/new`;
        } else {
          url = `/accounts/${this.account.accountId}/sites/${this.site.siteId}/pbx/${pbxId}`;
        }
    } else {
      url = this.progressSvc.getNextUrl(this.progressItem.name, this.account.accountId, siteId, pbxId);
    }

    if (this.shouldCheckTunnelCreds()) {
      const tunnel = localStorage.getItem('tunnel');
      const username = localStorage.getItem('username');
      const password = localStorage.getItem('password');
      if (!(tunnel && username && password)) {
        const tag = _.cloneDeep(this.accountTags);
        tag['tunnel-creds'] = false;
        this.updateAccountTag(this.account.accountId, tag);
      } else {
        const tag =  _.cloneDeep(this.accountTags);
        tag['tunnel-creds'] = true;
        this.updateAccountTag(this.account.accountId, tag);
      }

      if (this.site && this.site.siteId){
        url = `/accounts/${this.account.accountId}/sites/${this.site.siteId}`;
      } else {
        url = `/accounts/${this.account.accountId}/sites/new`;
      }

    } else if (this.status === gatewayConnectStateMessages.SYSTEM_UPDATE_AVAILABLE) {
      url = `/accounts/${this.account.accountId}/sites/${this.site.siteId}/advanced`;
    }
    this.assumeRoleAndNext(url, this.account.accountId);
  }

  shouldCheckTunnelCreds() {
    if (this.accountTags && this.accountTags['on-board-progress']) {
      const lastStepIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress']['name']);
      const lastStepSucceeded = this.accountTags['on-board-progress'].succeeded;
      const reason = this.accountTags['on-board-progress'].reason;
      if (lastStepIdx > 2 || (lastStepIdx === 2 && lastStepSucceeded)) { // Has completed site page
        return false;
      } else if (lastStepIdx === 2 && !lastStepSucceeded &&
        (reason === siteMessages.ERR_SET_NETWORK || reason === siteMessages.ERR_SET_NETWORK_TIMEOUT)) {
        return false;
      }
    }

    return true;
  }

  goNextSupportPage() {
    if (this.account) {
      if (this.accountTags) {
        if(this.accountTags['gateway-connection'] && !this.accountTags['gateway-connection']['connected']){
          this.goNoGWPSupport();
        }else if(this.accountTags['on-board-progress']){
          const lastStepIdx = this.progressSvc.getProgressIdx(this.accountTags['on-board-progress']['name']);
          const siteStepIdx = this.progressSvc.getProgressIdx('site');
          
          if (this.accountTags['on-board-progress']['succeeded']) {
            siteStepIdx > lastStepIdx ? this.goNoGWPSupport() : this.goAdvanced();
          } else {
            siteStepIdx >= lastStepIdx ? this.goNoGWPSupport() : this.goAdvanced();
          }
        }
      }
    }
  }

  goAdvanced() {
    const url = `/accounts/${this.account.accountId}/sites/${this.site.siteId}/advanced`;
    this.assumeRoleAndNext(url, this.account.accountId);
  }
  
  goNoGWPSupport() {
    this.router.navigate(['accounts', this.account.accountId, 'message', MessageType.NO_SUPPORT]);
    this.spinnerSvc.hide();
  }

  goOverview() {
    if (this.account) {
      const url = `/accounts/${this.account.accountId}/overview`;
      this.assumeRoleAndNext(url, this.account.accountId);
    }
  }

  private async updateAccountTag(accountId: string, tagParams: any) {
    const tags = await this.adminSvc.tryUpdateAccountTags(accountId, tagParams);
    this.accountSvc.setAccountTags(tags);
  }

  async getAccountTagData() {
    if (this.account) {
        try {
          const tags = await this.adminSvc.getAccountTags(this.account.accountId);
          this.accountTags = tags;
          if (this.accountTags['on-board-progress']) {
            const lastStep = this.accountTags['on-board-progress'].name;
            const lastStepSucceeded = this.accountTags['on-board-progress'].succeeded;
            if (lastStepSucceeded) {

              this.progressItem = this.progressSvc.getProgressItem(lastStep);
              this.progressItemStatus = this.progressItem.status;
            } else {
              this.isError = true;
              this.progressItem = this.progressSvc.getPrevProgressItem(lastStep);
              this.stepName = lastStep;
              this.progressItemStatus = `${this.accountTags['on-board-progress'].reason}`;
            }
            // Get sites if progress step > 1
            if (this.progressItem.step > 1 || this.accountTags['gateway-connection']) {
              await this.getSite();
              await this.getPlatform();
              await this.getEcho();
            } else {
              if(this.isError && this.stepName === 'site'){
                await this.getSite(); //see if there is a site, so we can grab the id for the url
              }
              this.status = this.progressItemStatus;
            }
          } else {
            this.progressItem = this.progressSvc.getDefaultProgressItem();
            this.status = this.progressItem.status;
          }
        } catch (reason) {
          if (reason && reason.body) {
            if (reason.statusCode === 401) {
              this.authSvc.redirectToLogin();
            }
            console.error('failed to get account tags', reason.body)
          } else if (reason instanceof Error) {
            console.error('failed to get account tags', reason.message);
            if (reason.message === commonErrorMessages.AUTH_ERROR) {
              this.authSvc.redirectToLogin();
            }
          } else {
            console.error('failed to get account tags', reason);
          }
          this.progressItem = this.progressSvc.getDefaultProgressItem();
          this.status = this.progressItem.status;
        }
    }
  }
  async getSite() {
      try {
        const siteCollection = await this.adminSvc.getSites(this.account.accountId);
        if (siteCollection && siteCollection.count > 0 && siteCollection._embedded && siteCollection._embedded.items) {
          const sites = siteCollection._embedded.items;
          if (sites[0]) {
            this.site = sites[0];
          }
        }
      } catch (reason) {
        if (reason && reason.body) {
          if (reason.statusCode === 401) {
            this.authSvc.redirectToLogin();
          }
          console.error('failed to get site', JSON.stringify(reason.body));
        } else if (reason instanceof Error) {
          console.error('failed to get site', reason.message);
          if (reason.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to get site', reason);
        }
      }
  }

  async getPlatform() {
      if (this.account && this.site) {
          try {
              const options = await this.getAssumeRoleHeader(this.account.accountId);
              this.platform = await this.tunnelSvc.getPlatform(this.account.accountId, this.site.siteId, options);
              if (this.platform && this.platform.capabilities && this.platform.capabilities.embedded_with_pbx) {
                  const lastStep = this.accountTags['on-board-progress'].name;
                  const lastStepSucceeded = this.accountTags['on-board-progress'].succeeded;
                  if (lastStepSucceeded && lastStep === 'site') {
                  this.progressItem = this.progressSvc.getNextProgressItem(lastStep);
                  this.progressItemStatus = stepStatus.SYNC_PBX;
                  } else if (lastStepSucceeded && lastStep === 'pbx') {
                  this.progressItemStatus = stepStatus.SYNC_PBX;
                  }
              }
          } catch (reason) {
              if (typeof reason === 'string') {
                  console.error('failed to get platform', reason);
              } else if (reason instanceof Error) {
                  console.error('failed to get platform', reason.message);
                  if (reason.message === commonErrorMessages.AUTH_ERROR) {
                      this.authSvc.redirectToLogin();
                  }
              } else {
                  console.error('failed to get platform', JSON.stringify(reason));
              }
          }
      }
  }

  async getEcho() {
      if (this.account && this.site) {
          let options;
          try {
              options = await this.getAssumeRoleHeader(this.account.accountId);
              const result = await this.tunnelSvc.getEcho(this.account.accountId, this.site.siteId, options);
              if (result.data === 'ECHO..echo..echo') {
                  await this.updateGatewayConnectionTag(true, undefined, options);
              } else {
                  console.warn('unexpected echo response', result.data);
                  await this.updateGatewayConnectionTag(false, `connect fail: ${result.data && result.data !== '' ? result.data : 'unknown'}`, options);
              }
          } catch (reason) {
              if (typeof reason === 'string') {
                  console.warn('echo failed', reason);
                  await this.updateGatewayConnectionTag(false, `connect fail: ${reason}`, options);
              } else if (reason && reason.statusCode === 401) {
                  console.warn('echo failed', JSON.stringify(reason));
                   const errMsg = commonMessages.TUNNEL_AUTH_ERR;
                   await this.updateGatewayConnectionTag(false, `connect fail: ${errMsg}`, options);
              } else if (reason && reason.body) {
                  console.warn('echo failed', JSON.stringify(reason));
                  await this.updateGatewayConnectionTag(false,
                  `connect fail: ${reason.body.message && reason.body.message !== '' ? reason.body.message : 'unknown'}`, options);
              } else if (reason instanceof Error && reason.message === commonErrorMessages.AUTH_ERROR) {
                  console.error('echo failed', reason.message);
                  this.authSvc.redirectToLogin();
              } else {
                  console.warn('echo failed', JSON.stringify(reason));
                  await this.updateGatewayConnectionTag(false,
                  `connect fail: ${reason ? JSON.stringify(reason) : 'unknown'}`, options);
              }
          }
      }
  }

  async updateGatewayConnectionTag(connected: boolean, reason?: string, options?: any) {
      const tagParams = {};
      if (this.platform && this.platform.capabilities && this.platform.capabilities.requires_pbx_ip_address && connected) {
        const pbxStatusTagParams = await this.echoSvc.getPbxStatusAccountTagsParameters(this.account, this.site, this.accountTags, options);
        tagParams['pbx-status'] = pbxStatusTagParams;

        const gatewayConnectionTagParams = await this.echoSvc.getGatewayConnectionAccountTagsParameters(
          this.account, this.site, this.accountTags, options);
        tagParams['gateway-connection'] = gatewayConnectionTagParams;
        await this.checkSystemVersions(options);
      } else if (this.platform && this.platform.capabilities && this.platform.capabilities.embedded_with_pbx && connected) {
        tagParams['gateway-connection'] = { connected: connected };
        tagParams['pbx-status'] = { status: pbxStatus.UNKNOWN };
        await this.checkSystemVersions(options);
      } else {
        tagParams['gateway-connection'] = { connected: connected, reason: reason };
        tagParams['pbx-status'] = { status: pbxStatus.UNKNOWN };
      }

      try {
        if (!this.accountTags) {
          this.accountTags = {};
        }
        let tags = this.accountTags;
        if (this.accountTags['gateway-connection']) {
          const tagUpdate = await this.adminSvc.updateAccountTag(this.account.accountId, 'gateway-connection', tagParams['gateway-connection'])
          tags = {
            ...tags, ...{ 'gateway-connection': tagUpdate }
          };
        } else {

          tags = await this.adminSvc.createAccountTag(this.account.accountId, { 'gateway-connection': tagParams['gateway-connection'] })
        }

        if (this.accountTags['pbx-status']) {
          const tagUpdate = await this.adminSvc.updateAccountTag(this.account.accountId, 'pbx-status', tagParams['pbx-status']);
          tags = {
            ...tags, ...{ 'pbx-status': tagUpdate }
          };
        } else {
          tags = await this.adminSvc.createAccountTag(this.account.accountId, { 'pbx-status': tagParams['pbx-status'] });
        }

        this.accountTags = tags;

        if (!connected) {
          this.setErrorStatus(gatewayConnectStateMessages.LOST_GATEWAY_CONNECTION);
        } else if (tags['pbx-status']['status'] === pbxStatus.DOWN) {
          this.setErrorStatus(this.accountTags['pbx-status']['reason']);
        } else if (tags['gateway-connection']['pbxConnected'] !== false) {
          if (this.showSystemUpdateAvailable) {
            this.status = gatewayConnectStateMessages.SYSTEM_UPDATE_AVAILABLE;
          } else {
            this.showStep = true;
            this.status = this.progressItemStatus;
          }
        } else {
          this.setErrorStatus(tags['gateway-connection']['reason']);
        }
      } catch (error) {
        if (typeof error === 'string') {
          console.error('failed to update account tag', error);
        } else if (error && error.body) {
          if (error.statusCode === 401) {
            this.authSvc.redirectToLogin();
          }
          console.error('failed to update account tag', JSON.stringify(error.body));
        } else if (error instanceof Error) {
          console.error('failed to update account tag', error.message);
          if (error.message === commonErrorMessages.AUTH_ERROR) {
              this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to update account tag', JSON.stringify(error));
        }
      }
  }

  setErrorStatus(msg: string) {
      this.isError = true;
      this.status = msg;
      console.log("Error Status: ", this.status);
  }

  async checkSystemVersions(headers?: any) {
      // Get system versions
      try {
        const options = headers || await this.getAssumeRoleHeader(this.account.accountId);
        const versionResponse = await this.tunnelSvc.getSystemVersion(this.account.accountId, this.site.siteId, options);
        this.showSystemUpdateAvailable = !this.versionSvc.isAllVersionsUpdated(<any>versionResponse, this.platform);
      } catch (error) {
        if (typeof error === 'string') {
          console.error('failed to get system version', error);
        } else if (error && error.body) {
          console.error('failed to get system version', JSON.stringify(error.body));
          if (error.statusCode === 401) {
            this.authSvc.redirectToLogin();
          }
        } else if (error instanceof Error) {
          console.error('failed to get system version', error.message);
          if (error.message === commonErrorMessages.AUTH_ERROR) {
            this.authSvc.redirectToLogin();
          }
        } else {
          console.error('failed to get system version', JSON.stringify(error));
        }
      }
  }

  private getAssumeRoleParams(accountId) {
      let params;
      if (accountId) {
          params = { accountId: accountId, role: 'ACCOUNT_ADMIN' };
      }
      return params;
  }

  private getAssumedRoleOptions(token: Token) {
      const headers = { 'authorization': 'Bearer ' + token.access_token };
      const options = {
        headers: headers
      };

      return options;
  }

  private async getAssumeRoleHeader(accountId) {
      const assumeParams = this.getAssumeRoleParams(accountId);
      if (assumeParams) {
        const token = await this.authSvc.getAssumedRoleToken(assumeParams);
        return this.getAssumedRoleOptions(token);
      } else {
        return undefined;
      }
  }
}
